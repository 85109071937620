import { HttpClient ,HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Customer, Leave } from './customer';
import { Observable } from 'rxjs';

@Injectable({providedIn:'root'})
export class BuildReportService {
    constructor(private http: HttpClient) {}

    getCustomersLarge() {
        return this.http.get<any>('assets/Employee_data/disability-data.json')
            .toPromise()
            .then(res => <Customer[]>res)
            .then(data => { return data; });
    }
    getCustomersSummaryLarge() {
        return this.http.get<any>('assets/Employee_data/Disability-Summary.json')
            .toPromise()
            .then(res => <Customer[]>res)
            .then(data => { return data; });
    }

    getLeave() {
        return this.http.get<any>('assets/Employee_data/leave-data.json')
            .toPromise()
            .then(res => <Leave[]>res)
            .then(data => { return data; });
    }
    getLeaveSummary() {
        return this.http.get<any>('assets/Employee_data/Leave_Summary.json')
            .toPromise()
            .then(res => <Leave[]>res)
            .then(data => { return data; });
    }
    // setDisability(list): Observable<any> {
    //         let headers = new Headers();
    //         headers.append('Content-Type', 'application/json');
    //         const body=JSON.stringify(list);
    //         console.log(body)
    //         return this.http.post('assets/Employee_data/test.json', body)
    // }
    

}
