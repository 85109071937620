<div class="tibo-myrepo-output-wrapper tibo-right-container">

	<div id="" class="tibo-myrepo-output-container">
		
		<!-- <p-toast styleClass="tibo-myrepo-output-toast" position="center"></p-toast> -->

		<div id="myrepo-output-parameters-panel" class="tibo-myrepo-output-paramspanl">
		
			<span id="report-criteria-id" class="tibo-myrepo-output-paramspanl-title">Report Criteria</span>

			<button type="button" class="tibo-myrepo-output-paramspanl-arrow-icon" (click)="repoCriteriaPanelToggle()"></button>
			
			<p-messages [(value)]="alertMessage" [enableService]="false" styleClass="tibo-myrepo-output-params-filtrpanl-alert"></p-messages>

			<p-scrollPanel id="myrepo-output-paramspanl-scrollpanel" styleClass="tibo-myrepo-output-paramspanl-scrollpanel">
			
				<ng-container *ngFor="let row of parameterElementsArray let i = index">
					
						<div class="tibo-myrepo-output-param-container" *ngIf="parameterElementsArray[i]?.visible">
						
							<span class="tibo-myrepo-output-param-title">
								{{parameterElementsArray[i]?.label.replace("_", " ")}}
								<span class="tibo-myrepo-output-param-title-mand" *ngIf="parameterElementsArray[i]?.mandatory">*</span>
							</span>
							
							<input type="text" class="tibo-myrepo-output-param-inputtext"
								*ngIf="(parameterElementsArray[i]?.type == 'singleValueText') || (parameterElementsArray[i]?.type == 'singleValueNumber')"
								[attr.selectInfoIndex]=i
								placeholder="{{'Enter ' + parameterElementsArray[i]?.label.replace('_', ' ') }}"
								(keyup)="onChange($event)">
								
							<p-dropdown styleClass="tibo-myrepo-output-param-dropdown"
								*ngIf="parameterElementsArray[i].type == 'singleSelect'"
								appendTo="body"
								[attr.selectInfoIndex]=i
								[options]="parameterElementsArray[i].state?.options"
								[optionLabel]="parameterElementsArray[i].state?.options?.label"
								[optionValue]="parameterElementsArray[i].state?.options?.value"
								placeholder="{{'Select ' + parameterElementsArray[i]?.label.replace('_', ' ') }}"
								(onChange)="onChange($event,refEl)"
								#refEl>
							</p-dropdown>
							
							<p-multiSelect styleClass="tibo-myrepo-output-param-multiselect"
								*ngIf="parameterElementsArray[i].type == 'multiSelect'"
								appendTo="body"
								[attr.selectInfoIndex]=i
								[options]="parameterElementsArray[i].state?.options"
								[optionLabel]="parameterElementsArray[i].state?.options?.label"
								[optionValue]="parameterElementsArray[i].state?.options?.value"
								[defaultLabel]="'Please Select'"
								(onChange)="onChange($event,refEl)"
								#refEl>
							</p-multiSelect>
							
							<p-calendar styleClass="tibo-myrepo-output-param-calendar"
								*ngIf="parameterElementsArray[i].type == 'singleValueDate'"
								readonlyInput="true" 
								dateFormat="mm/dd/yy" 
								appendTo="body"
								[monthNavigator]="true" 
								[yearNavigator]="true" 
								yearRange="2000:2030"
								[attr.selectInfoIndex]=i
								showIcon="true"
								(onSelect)="onChange($event,refEl)"
								#refEl>
							</p-calendar>
							
						</div>
				
				</ng-container>
			
			</p-scrollPanel>
			
			<button type="button" pButton id="submitbutton-id" class="tibo-myrepo-output-paramspanl-submitbtn" label="Submit" (click)="onFilterSubmitClick()"></button>
			
		</div> <!-- parameters panel div -->
		
		<div id="myrepo-output-container" class="tibo-myrepo-output-view">
		
			<div class="tibo-myrepo-output-title">
				<label id="" class="">{{reportName?.substring(0, reportName?.lastIndexOf('.'))}}</label>
				<button class="tibo-myrepo-output-download-btn" type="button" pButton icon="pi pi-download" (click)="downloadtypemenu.toggle($event)"></button>
				<p-tieredMenu #downloadtypemenu styleClass="tibo-myrepo-output-download-overlay" [model]="downloadTypeList" [popup]="true" my="left top" at="left top" 
				></p-tieredMenu>
			</div>
			
			<p-progressSpinner styleClass="tibo-myrepo-output-spinner custom-spinner" animationDuration="2s"></p-progressSpinner>

			<iframe id="report-output-birt" class="tibo-myrepo-output-view-iframe" title=""></iframe>
			
			<div id="report-output-sql" class="tibo-myrepo-output-view-sqltable-cont"> <!--Children styling available in .css file-->
			
				<p-table #myreportssqloutputtable [columns]="sqlTableColumns" [value]="reportOutput" [sortField]="sqlTableSortColumn" [sortOrder]="sqlTableSortDir" [scrollable]="true" scrollHeight="flex" 
					(sortFunction)="customSort($event)" [customSort]="true" [globalFilterFields]="globalFilterFieldsArr">
					
					<ng-template pTemplate="caption">
						<div class="p-d-flex p-jc-between p-ai-center">
							<span class="p-input-icon-left p-ml-auto">
								<i class="pi pi-search"></i>
								<input pInputText type="text" (input)="myreportssqloutputtable.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" />
							</span>
							<label class="p-mx-2">Showing {{reportOutput?.length}} of {{sqlTotalRecords}}</label>
						</div>
					</ng-template>
					
					<ng-template pTemplate="header" let-columns>
						<tr>
							<th *ngFor="let col of columns" [pSortableColumn]="col.header">
								<div class="p-d-flex p-jc-between p-ai-center">
									{{col.header}}
									<p-sortIcon [field]="col.header"></p-sortIcon>
								</div>
							</th>
						</tr>
					</ng-template>
					
					<ng-template pTemplate="body" let-rowData let-columns="columns">
						<tr>
							<td *ngFor="let col of columns">
								{{rowData[col.header]}}
							</td>
						</tr>
					</ng-template>
					 
					<ng-template pTemplate="emptymessage">
						<tr>
							<td colspan="8">No records found</td>
						</tr>
					</ng-template>
				</p-table>
				
			</div>
			
			<p-paginator *ngIf="reportType.toLowerCase() == 'sql'" styleClass="tibo-myrepo-output-view-p-paginator" [rows]="250" [totalRecords]="sqlTotalRecords" (onPageChange)="paginate($event)"></p-paginator>
			
		</div>

	</div>

</div>
