<div class="tibo-myreport-container-wrapper tibo-right-container">
    <div id="myrepo-scroll-wrapper" class="tibo-myrepo-scroll-wrapper">
        <p-toast styleClass="tibo-buildrepo-toast" position="center"></p-toast>
        <div id="myreports-folderview-panel" class="tibo-myrepo-folderview-panel">
            <span id="all-reports-id" class="tibo-myrepo-folderview-panel-title">Filter</span>

            <button type="button" class="tibo-myrepo-folderview-panel-arrow-icon" (click)="folderPanelToggle()"></button>

            <p-scrollPanel id="myreport-folder-panel-scroll-div" styleClass="tibo-myrepo-folderview-panel-scrollpanel">
                <div class="scrollable pagecontent" resize="" *ngIf="!folderCollapse">
                    <ng-container>
                        <div class="tibo-buildrepo-repofolders-tree-cont">
                            <div class="rows">

                                <div>
        
                                    <div>
                                        <form [formGroup]="leaveForm" novalidate>
                                            <div class="company">
                                                <div class="rows ng-scope" style="font-size: 16px;">
                                                    <div class="list">
                                                        <div id="46" class="questionlabel"> <label id="s46"
                                                                class="list-group-item ng-binding title">Select A Client
                                                                <span class="required">*</span></label> </div>
                                                        <div class="list-group-item ng-binding ng-scope"
                                                            ng-repeat="a in questions.a" ng-show="checkVisibility(a,questions)">
        
        
                                                            <select formControlName="clientname" class="form-control value "
                                                                style=" width: 17rem;height: 2rem;">
                                                                <option value="ABC COMPANY" class="value" selected="selected"
                                                                    label="ABC COMPANY">
                                                                </option>
        
                                                            </select>
        
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="disability">
                                                <div class="rows ng-scope" style="font-size: 16px;">
                                                    <div class="list">
                                                        <div id="47" class="questionlabel"> <label id="s47"
                                                                class="list-group-item ng-binding title">Select A Disability
                                                                Type
                                                                <span class="required">*</span></label> </div>
                                                        <div class="list-group-item ng-binding ng-scope value">
                                                            <input type="radio" formControlName="disability" value="STD"
                                                                (click)="disabiltyType('STD')">
                                                            Short Term Disability
        
                                                        </div>
                                                        <div class="list-group-item ng-binding ng-scope value">
                                                            <input type="radio" formControlName="disability" value="LTD"
                                                                (click)="disabiltyType('LTD')">
                                                            Long Term Disability
        
                                                        </div>
                                                        <div class="list-group-item ng-binding ng-scope value">
                                                            <input type="radio" formControlName="disability" value="both"
                                                                (click)="disabiltyType('both')">
                                                            Both
        
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        <div class="date">
                                            <div class="rows ng-scope" style="font-size: 16px;">
                                                <div class="list">
                                                    <div id="48" class="questionlabel"> <label id="s48"
                                                            class="list-group-item ng-binding title">Select A Time Period<span
                                                                class="required">*</span></label> </div>
                                                                <div>
                                                                <div class="list" style="background-color:white">
                                                                    <div>
                                                                        <label class="list-group-item title">Start Date</label>
                                                                        <div class="p-field list-group-item" style="margin-top:3rem">
            
                                                                            <p-calendar formControlName="startdate" styleClass="tibo-buildrepo-output-param-calendar top"
                                                                                [showIcon]="true" inputId="icon"
                                                                                class="calendar" [(ngModel)]="startDate"
                                                                                (click)="leaveStartDate()">
                                                                            </p-calendar>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <label class="list-group-item title" for="basic">End
                                                                            Date</label>
                                                                        <div class="p-field list-group-item" style="margin-top:4rem">
            
                                                                            <p-calendar inputId="basic" styleClass="tibo-buildrepo-output-param-calendar-endate top"
                                                                                formControlName="enddate" class="calendar"
                                                                                [showIcon]="true" [(ngModel)]="endDate"
                                                                                (click)="leaveStartDate()">
                                                                            </p-calendar>
                                                                        </div>
            
                                                                    </div>
                                                                </div>
                                                            </div>
                                                </div>
                                            </div>
                                            </div>
                                            <div class="rows" style="font-size: 16px;">
                                                <div class="submit-btn"style="text-align: center; margin-left: 15px;">
                                                    <button type="submit" class="btn btn-default" (click)="report()" style="margin-right:0px;">Save
                                                        Report Parameters</button>
                                                        <button class="btn btn-default" (click)="resetForm()" style="margin-right:0px;"> Clear</button>
                                                    <button type="submit" class="tibo-data-disco-submitbtn"
                                                        (click)="onRun()"  style="margin-right:17px;">Run</button>
            
            
                                                </div>
                                                <hr>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </p-scrollPanel>

        </div>
            <div id="myreports-tile-list-scroll-view" class="tibo-myrepo-tile-list-scrolview-wrapper" *ngIf = "table">
                
                <div class="tibo-myrepo-output-title">
                    <h1>Disability Summary</h1>
                    <button class="tibo-mydash-chartcoloroptions-btn" type="button" pButton icon="pi pi-download" pTooltip="Download" (click)="download()"></button>
                </div> 
                <div #TABLE>
                    <table #tableData [dataSource]="leaveData">
                            <tr class="groupCol">
                              <td colspan="4" >Disability Summary</td>
                            </tr>
                            <tr class="groupCol">
                                <td colspan="4">Run Date: {{runDate | date: 'short'}}</td>
                              </tr>
                            <tr class="groupCol">
                                    <td colspan="1">Start Date:  {{startDate}}</td>
                                    <td colspan="2">End Date:  {{endDate}}</td>
                    </table>
                    </div>
                    <div>
                        <ag-grid-angular 
                            style="width: 100%; height: 475px;top:160px" 
                            class="ag-theme-alpine"
                            [rowData]="this.rowData"
                            [columnDefs]="this.columnDefs"
                            [defaultColDef]="defaultColDef"
                            [pagination]="true"
                            [gridOptions]="gridOptions"
                            (gridReady)="onGridReady($event)">
                        </ag-grid-angular>
                     </div>
            </div>
    </div>
</div>