<div class="tibo-repo-cat-container-wrapper tibo-right-container">
	<div id="schedrepo-scroll-wrapper" class="tibo-repo-cat-scroll-wrapper">
		<div class="tibo-repo-cat-tile-list-scrolview-wrapper">
		
		<div class="scheduled-reports-list-view" style="position:absolute; left:0px; width:100%; top:0px; height:calc(100% - 108px); background-color:#f1f1f4;">
				<p-table id="xyz" #reportcataloguetable [value]="reportCatalogueRecords" [sortField]="tableSortColumn" [sortOrder]="tableSortDir" [scrollable]="true" scrollHeight="flex" [globalFilterFields]="['clientName','authorizationName','authorizationDesc','reportType','location']" (sortFunction)="customSort($event)" [customSort]="true"
					[style]="{'position':'absolute','left':'0px','width':'calc(100% - 16px)','top':'0px','height':'100%','background-color':'#f1f1f4', 'margin':'8px'}">
					
					<ng-template pTemplate="caption">
						<div class="p-d-flex p-jc-between p-ai-center">
							<span class="p-input-icon-left p-ml-auto">
								<i class="pi pi-search"></i>
								<input pInputText type="text" (input)="reportcataloguetable.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" />
							</span>
							<label class="p-mx-2" style="position:relative; float:right;">Showing {{scheduledReportRecords?.length}} of {{totalRecords}}</label>
						</div>
					</ng-template>
						 
					<ng-template pTemplate="header">
						<tr>
							<th pSortableColumn="clientName">
								<div class="p-d-flex p-jc-between p-ai-center">
									Client Name
									<p-sortIcon field="clientName"></p-sortIcon>
									<p-columnFilter type="text" field="clientName" display="menu" class="p-ml-auto"></p-columnFilter>
								</div>
							</th>
							<th pSortableColumn="authorizationName">
								<div class="p-d-flex p-jc-between p-ai-center">
									Report Name
									<p-sortIcon field="authorizationName"></p-sortIcon>
									<p-columnFilter type="text" field="authorizationName" display="menu" class="p-ml-auto"></p-columnFilter>
								</div>
							</th>
							<th pSortableColumn="authorizationDesc">
								<div class="p-d-flex p-jc-between p-ai-center">
									Report Description
									<p-sortIcon field="authorizationDesc"></p-sortIcon>
									<p-columnFilter type="date" field="authorizationDesc" display="menu" class="p-ml-auto"></p-columnFilter>
									
								</div>
							</th>
							<th pSortableColumn="reportType">
								<div class="p-d-flex p-jc-between p-ai-center">
									Report Type
									<p-sortIcon field="reportType"></p-sortIcon>
									<p-columnFilter type="text" field="reportType" display="menu" class="p-ml-auto"></p-columnFilter>
								</div>
							</th>
							<th pSortableColumn="location">
								<div class="p-d-flex p-jc-between p-ai-center">
									Location
									<p-sortIcon field="location"></p-sortIcon>
									<p-columnFilter type="date" field="location" display="menu" class="p-ml-auto"></p-columnFilter>
								</div>
							</th>
							<th>
								<div class="p-ml-6 p-d-flex p-jc-between p-ai-center">
									Action
								</div>
							</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-car>
						<tr>
							<td>{{car?.clientName}}</td>
							<td>{{car?.authorizationName}}</td>
							<td>{{car?.authorizationDesc}}</td>
							<td>{{car?.reportType}}</td>
							<td>{{car?.location}}</td>
							<td>
								<div class="p-ml-6"  style="width:max-content; min-width:35px; padding:3px 18px; background-color:#e7f0f9; border-radius:20px; box-sizing:border-box;">
									<button id="" type="button" pButton icon="pi pi-info" pTooltip="Report information" tooltipPosition="top" style="background-color:#5ea7e0; width:30px; height:24px; border:0; border-radius:3px; border-color:#398dce;" (click)="show(car?.authorizationName, car?.authorizationId)"></button>
								</div>
							</td>
						</tr>
					</ng-template>
					
				</p-table>
		</div>
		
		<p-paginator [rows]="250" [totalRecords]="totalRecords" (onPageChange)="paginate($event)"
				[style]="{'position':'absolute','left':'2px','width':'calc(100% - 4px)','bottom':'2px','height':'45px','border-radius':'0px','padding':'0px'}"></p-paginator>
		
		</div>
	</div>
</div>
