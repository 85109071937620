import { Component, OnInit } from '@angular/core';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { MenuItem } from 'primeng/api';
import { ConfirmationService, MessageService} from 'primeng/api';
import { Router } from "@angular/router";

declare var d3:any;

@Component({
	selector: 'app-top-bar',
	templateUrl: './top-bar.component.html',
	styleUrls: ['./top-bar.component.css'],
	providers: [ConfirmationService,MessageService]
})

export class TopBarComponent implements OnInit {

	

	constructor(private confirmationService: ConfirmationService, private messageService: MessageService, private router: Router) { }
	
	userName = "";
	userButtonList: MenuItem[];

	ngOnInit(): void {
		
		this.userName = localStorage.getItem("userName");
		
		this.userButtonList = [
		  {label:'Log Out', icon:'pi pi-fw pi-sign-out', id:'', command: () => { this.logoutConfirmFunc(); }}
		]

		d3.selectAll(".tibo-menupanel-btn").style("background-color","transparent")

		d3.select(".tibo-topbar-title").text("Home") // setting title
		
		if(Number(d3.select("#tibo-large-leftpanel-container").node().getBoundingClientRect().width) == 64){
			setTimeout(function(){
				d3.select(".tibo-topbar-title").transition().delay(0).duration(0).style("left","230px").ease("linear")
				d3.select(".leave").transition().delay(0).duration(0).style("left","230px").ease("linear")
			},10)
		}
		else if(Number(d3.select("#tibo-large-leftpanel-container").node().getBoundingClientRect().width) == 230){
		
			
			d3.select(".tibo-topbar-title").transition().delay(0).duration(0).style("left","64px").ease("linear")
						
					} 
	}
	
	logoutConfirmFunc(){
		this.confirmationService.confirm({
            message: 'Are you sure you want to Log Out?',
            header: ' ',
            icon: 'pi pi-exclamation-triangle',
            accept: () => { this.onConfirm(); },
            reject: () => {}
        });
	}
	
	onConfirm() {
        this.logoutFunc();
    }

    logoutFunc(){
		
		localStorage.removeItem("menuItem");
		localStorage.removeItem("token");
		localStorage.removeItem("userEmail");
		localStorage.removeItem("userName");
		
		this.router.navigate(['./']);
		
		setTimeout(function(){ window.location.reload(); },10)
	}
	
}
