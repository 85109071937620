<div class="home-page-parent-container2">
	<div class="home-page-parent-container">
		<div class="p-grid">
			<div class="p-col-6">
				<div class="p-grid">
					<div class="p-col-12 col-class-widget" style="padding-top: 20px;"
						[ngClass]="{'animation-time-class':animationBoolean}">
						<div class="widget-parent-class">
							<div class="widgetClass left-right-transition-order-one" routerLink="/discovery">
								<h1 class="widgettext">Data Discovery</h1>
								<a class="home-comp-icons"><i class="pi pi-search" style="color: #f15216"></i></a>
							</div>
							<div class="widgetClass left-right-transition-order-two" routerLink="/build-report">
								<h1 class="widgettext"> Build a Report</h1>
								<a class="home-comp-icons"><i class='bx bx-notepad' style="color: #0092dc"></i></a>
							</div>
							<div class="widgetClass left-right-transition-order-three" routerLink="/create-dashboard">
								<h1 class="widgettext">Create Dashboard</h1>
								<a class="home-comp-icons"><i class="pi pi-chart-bar" style="color: #8a60a4"></i></a>
							</div>
						</div>
					</div>
					<div class="p-col-12 col-class-widget1" style="padding-top: 5px;"
						[ngClass]="{'animation-time-class':animationBoolean}">
						<div class="widget-parent-class">
							<div class="p-col-6 paddClass">
								<div class="status">
									<p class="chart-empty-message-class">{{chartStatus}}</p>
									<p class="mostUsedText-chart">Report Status</p>
									<div class="chartClass">
										<!-- <p-chart type="doughnut" [data]="data"></p-chart> -->
										<p-chart type="doughnut" [options]="options" [data]="data"></p-chart>
										<!-- <img class="imgClass" src="../../assets/images/chart.png"> -->
									</div>
								</div>
							</div>
							<div class="p-col-6 paddClass">
								<div class="details" (click)="adminProfile()">
									<i class="fa fa-user fa-userss" aria-hidden="true"></i>
									<div class="data2">Profile</div>
								</div>
							</div>
						</div>
					</div>
					<div class="p-col-12 paddClass">
						<div class="reports">
							<p class="mostUsedText">Report Scheduled for the Day</p>
							<button type="button" pbutton="" icon="pi pi-download" ptooltip="Download"
								class="tibo-mydash-chartcoloroptions-btn p-button p-component p-button-icon-only"
								ng-reflect-icon="pi pi-download"><span class="p-button-icon pi pi-download"
									aria-hidden="true"></span><span aria-hidden="true"
									class="p-button-label">&nbsp;</span></button>
							<div class="reports1">
								<table class="schduled-reports-table">
									<tbody>
										<tr *ngFor="let item of ScheduledReports">
											<td>{{item.ReportName}}</td>
											<td>{{item.ReportTime}}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<button pButton type="button" 
							routerLink="/scheduled-reports" routerLinkActive="active"
								label="Go to Scheduled-Reports"
								class="btn display-class submit-btn schduled-report-btn"></button>
						</div>
					</div>
				</div>
			</div>
			<div class="p-col-3">
				<div class="most-used-dashboard-container">
					<p class="mostUsedText1">Recently Viewed Dashboard's</p>
					<div class="dataClass">
						<a *ngFor="let d of Dashboard" class="dashboard">
							<p style="cursor: pointer;" class="dashboard-anchor-tag"
								(click)="goToDashboard(d.dashboardPage)">{{d.DashboardName}}</p>
						</a>
						<button pButton type="button" routerLink="/dashboard" routerLinkActive="active"
							label="Go to Dashboard"
							class="btn display-class submit-btn most-used-dashboard-btn"></button>
					</div>
				</div>
			</div>
			<div class="p-col-3">
				<div class="most-used-dashboard-container">
					<p class="mostUsedText1">Recently Viewed Report's</p>
					<div class="dataClass">
						<a *ngFor="let d of Report" class="dashboard">
							<p style="cursor: pointer;" class="dashboard-anchor-tag" (click)="goToReport(d.reportPage)">
								{{d.ReportName}}</p>
						</a>
						<button pButton type="button" routerLink="/my-reports" routerLinkActive="active"
							label="Go to Reports" class="btn display-class submit-btn most-used-dashboard-btn"></button>
					</div>
				</div>
			</div>


		</div>
	</div>
	<!-- user design -->
	<!-- <div class="home-page-parent-container" *ngIf="!resultUserRoleDetails.assignedRoles">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-12">
						<div class="header p-d-flex">
							<h1 class="headerText">AI-driven Business Intelligence Reporting and Data Analytics
								Platform
							</h1>
							<img class="imgClass" src="../../assets/images/16tb.svg">
						</div>
					</div>
					<div class="p-col-12 col-class-widget" [ngClass]="{'animation-time-class':animationBoolean}">
						<div class="widget-parent-class">
							<div class="widgetClass left-right-transition-order-one" routerLink="/discovery">
								<h1 class="widgettext">Data Discovery</h1>
								<a class="home-comp-icons"><i class="fa-solid fa-magnifying-glass"
										style="color: #f15216"></i></a>
							</div>
							<div class="widgetClass left-right-transition-order-two" routerLink="/build-report">
								<h1 class="widgettext"> Build A Report</h1>
								<a class="home-comp-icons"><i class="fa-regular fa-clipboard"
										style="color: #0092dc"></i></a>
							</div>
							<div class="widgetClass left-right-transition-order-three" routerLink="/my-reports">
								<h1 class="widgettext">Reports</h1>
								<a class="home-comp-icons"><i class="fa-solid fa-file-lines"
										style="color: #8a60a4"></i></a>
							</div>
							<div class="widgetClass left-right-transition-order-four" routerLink="/my-favorites">
								<h1 class="widgettext">Favorites</h1>
								<a class="home-comp-icons"><i class="fa-regular fa-star" style="color:#f1d900"></i></a>
							</div>
							<div class="widgetClass left-right-transition-order-three" routerLink="/settings">
								<h1 class="widgettext">Settings</h1>
								<a class="home-comp-icons"><i class="fa-solid fa-gear" style="color: #09b7a9"></i></a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="p-col-9 paddClass">
				<div class="reports">
					<p class="mostUsedText">Favorite Dashboards</p>
					<div class="reports1 reports1-table">
						<table class="schduled-reports-table schduled-reports-table2">
							<tbody>
								<tr *ngFor="let d of Dashboard" class="dashboard-hover-class">
									<td>{{d.DashboardName}}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<button pButton type="button" routerLink="/dashboard" routerLinkActive="active"
						label="Go to Dashboard" class="btn display-class submit-btn schduled-report-btn"></button>
				</div>
			</div>
			<div class="p-col-3 paddClass">
				<div class="details" (click)="adminProfile()">
					<i class="fa fa-user fa-userss" aria-hidden="true"></i>
					<div class="data2">{{this.userName}}</div>
				</div>
			</div>
		</div>
	</div> -->
	<!-- dialog section -->
	<p-dialog header="Profile" [draggable]="false" class="confirm-dialog" [position]="'top'" [modal]="true"
		[(visible)]="showProfileDialog" [style]="{ width: '780px' }"
		[ngClass]="{'label-class-width': tabHeaderName === 'Change Password'}">
		<div class="card">
			<div class="details1">
				<div class="adminUser">
					<i class="fa fa-user fa-userss1" aria-hidden="true"></i>
				</div>
				<div class="data3">{{this.userName}}</div>
			</div>
			<p-tabView (onChange)="switchHeaders($event);">
				<p-tabPanel header="Account Information">
					<form [formGroup]="accountForm" class="formClass" novalidate>
						<div class="p-field">
							<label for="first-name" class="labelClass">First Name <span
									style="color:red">*</span></label>
							<input id="first-name" pInputText type="text" required formControlName="firstName">
						</div>
						<div class="p-field">
							<label for="last-name" class="labelClass">Last Name</label>
							<input id="last-name" pInputText type="text" formControlName="lastName">
						</div>
						<div class="p-field">
							<label for="email" class="labelClass">Email Address <span style="color:red">*</span></label>
							<input id="email" pInputText type="email" required formControlName="email"
								pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
						</div>
						<div class="p-field">
							<label for="phone" class="labelClass">Phone Number </label>
							<input id="phone" pInputText type="text" formControlName="phone"
								placeholder="Ex +XXXXXXXXXX">
						</div>
					</form>
				</p-tabPanel>
				<p-tabPanel header="Change Password" class="tab1">
					<form [formGroup]="passwordForm" class="formClass" novalidate>
						<div class="p-field">
							<label for="oldPassword" class="labelClass">Enter Old Password <span
									style="color:red">*</span></label>
							<input id="oldPassword" pInputText required type="password" formControlName="oldPassword">
						</div>
						<div class="p-field">
							<label for="newPassword" class="labelClass">Enter New Password <span
									style="color:red">*</span></label>
							<input id="newPassword" pInputText required type="password" formControlName="newPassword">
						</div>
						<div class="p-field">
							<label for="reEnter" class="labelClass">Re-enter New Password <span
									style="color:red">*</span></label>
							<input id="reEnter" pInputText required type="password" formControlName="reEnter">
						</div>
					</form>
				</p-tabPanel>
			</p-tabView>
		</div>
		<ng-template pTemplate="footer">
			<p-button class="updateButton" label="Update" [disabled]="accountForm.invalid" (click)="updateAccount()"
				*ngIf="tabHeaderName === 'Account Information'"></p-button>
			<p-button label="Update" class="updateButton" [disabled]="passwordForm.invalid" (click)="updatePassword()"
				*ngIf="tabHeaderName === 'Change Password'"></p-button>
		</ng-template>
	</p-dialog>
</div>