import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MyFavoritesService } from './my-favorites.service'
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { SortEvent } from 'primeng/api';
import { ViewChild } from '@angular/core'
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { AllOutputFilesMyFavorites } from './all-output-files-my-favorites';
import { ConfirmationService} from 'primeng/api';

import { Router } from "@angular/router";

declare var d3:any;

@Component({
	selector: 'app-my-favorites',
	templateUrl: './my-favorites.component.html',
	styleUrls: ['./my-favorites.component.css'],
	providers: [MyFavoritesService, MessageService, DialogService, AllOutputFilesMyFavorites, ConfirmationService]
})

export class MyFavoritesComponent implements OnInit, OnDestroy {

  	@ViewChild('myfavoritestable', {static: true}) table: Table;

  	constructor(private messageService: MessageService, public dialogService: DialogService, private http: HttpClient, private myFavoritesService: MyFavoritesService, private router: Router, private confirmationService: ConfirmationService) { }

  	errorMsg = "";
	totalRecords = 0;
	myFavoritesReportRecords = [];
	requestPayload = { sort: [{property: "authorizationName", dir: "ASC"}], filters: [], filterType: "and"};
	tableSortColumn = "authorizationName";
	tableSortDir = 1;
	paginatorPageNo = 1;
	customSortBool:boolean = false;
	tableEmptyMessage:string = "";
	
	recordsSubscrip: Subscription;
	removeFileSubscript: Subscription;

	ref: DynamicDialogRef;

  	ngOnInit(): void {

		d3.select(".tibo-topbar-title").text("Home/Favorites"); // setting title
		
		if(Number(d3.select("#tibo-large-leftpanel-container").node().getBoundingClientRect().left) == 0){
			setTimeout(function(){
				d3.select(".tibo-right-container").transition().delay(0).duration(0).style("left","200px").ease("linear");
			},10)
		}

    	this.initialServiceCall();
	}

  	ngOnDestroy() {
		if(this.recordsSubscrip){
			this.recordsSubscrip.unsubscribe();
		}
		if(this.removeFileSubscript){
			this.removeFileSubscript.unsubscribe();
		}
		if (this.ref) {
            this.ref.close();
        }
	}

	initialServiceCall(){
		this.recordsSubscrip = this.myFavoritesService.recordsService(1,this.requestPayload).subscribe( (recordsResp: any) => { // records
			
			d3.select(".tibo-myfavo-reports-spinner").style("display","none") // hidding scheduled reports container spinner
			
			this.totalRecords = recordsResp.body?.totalRecords;
			recordsResp.body?.records ? this.myFavoritesReportRecords = recordsResp.body?.records : this.myFavoritesReportRecords = [];

			this.myFavoritesReportRecords.length > 0 ? this.tableEmptyMessage = "" : this.tableEmptyMessage = "No records found";

		}, error => { 
				//console.loglog("error")
				d3.select(".tibo-myfavo-reports-spinner").style("display","none") // hidding scheduled reports container spinner
				if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
				else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
				else { this.errorMsg = "Please try after some time." }
				
				this.addSingle();
		}, () => {})
	}

  	theadClick(event: any){
		event.target.attributes.class.value.includes("pi-filter-icon") ? this.customSortBool = false : this.customSortBool = true;
	}

	customSort(event: SortEvent){
		
		this.tableSortColumn = event.field;
		this.tableSortDir = event.order;
		
		this.table.reset();
		this.table.sortField = this.tableSortColumn;
		this.table._sortOrder = this.tableSortDir;
    
		if(this.customSortBool){
      
			this.customSortBool = false;
			
			d3.select(".tibo-myfavo-reports-spinner").style("display","block") // showing scheduled reports container spinner
			
			this.requestPayload.sort = [];
			
			if(event.order == 1){
				this.requestPayload.sort.push({ property: event.field, dir: "ASC"})
			}
			else if(event.order == -1){
				this.requestPayload.sort.push({ property: event.field, dir: "DESC"})
			}
			
			this.recordsSubscrip = this.myFavoritesService.recordsService(this.paginatorPageNo,this.requestPayload).subscribe( (recordsResp: any) => { // records
				
				d3.select(".tibo-myfavo-reports-spinner").style("display","none") // hidding scheduled reports container spinner
				
				let sortedData = [];
				recordsResp.body?.records ? sortedData = recordsResp.body.records : sortedData = [];
				
				event.data.splice(0, event.data.length)
				
				sortedData.forEach(d => { event.data.push(d) })
				
      		}, error => { 
				//console.loglog("error")
				d3.select(".tibo-myfavo-reports-spinner").style("display","none") // hidding scheduled reports container spinner
				if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
				else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
				else { this.errorMsg = "Please try after some time." }
				
				this.addSingle();
			}, () => {})
		}	

	}

	onRunButtonClick(reportId,reportName,reportType){
		if(reportType == null || reportType == undefined){
			reportType = ""; // setting report type as string if type is null
		}
		this.router.navigate(['/view'], { queryParams: { id: reportId, type: reportType, name: reportName} });
	}

	onScheduleBtnClick(reportId,reportName,authDesc){
		this.router.navigate(['./scheduler'], { queryParams: { id: reportId, name: reportName, authDesc: authDesc} });
	}

  	paginate(event: any) {
		
    	this.customSortBool = false;

		d3.select(".tibo-myfavo-reports-spinner").style("display","block") // showing scheduled reports container spinner
		
		this.paginatorPageNo = event.page + 1;
		
		this.myFavoritesReportRecords = [];
		
		this.recordsSubscrip = this.myFavoritesService.recordsService(this.paginatorPageNo,this.requestPayload).subscribe( (recordsResp: any) => { // records
			
      		d3.select(".tibo-myfavo-reports-spinner").style("display","none") // hidding scheduled reports container spinner
			
			recordsResp.body?.records ? this.myFavoritesReportRecords = recordsResp.body?.records : this.myFavoritesReportRecords = [];
				
    	}, error => { 
			//console.loglog("error")
			d3.select(".tibo-myfavo-reports-spinner").style("display","none") // hidding scheduled reports container spinner
			//d3.select(".tibo-myfavo-reports-spinner").style("display","none") // hidding reports container spinner
			if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
			else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
			else { this.errorMsg = "Please try after some time." }
			
			this.addSingle()
		}, () => {})
			
	}

  	addSingle() { this.messageService.add({severity:'error', summary:'', detail:this.errorMsg}); }

  	clear() { this.messageService.clear(); }

	showAllOutputFilesBtnClick(reportId) {
		
        this.ref = this.dialogService.open(AllOutputFilesMyFavorites, {
			data: {
				reportId: reportId
			},
            header: 'Output Files',
            width: '70%',
			height: '80%',
            contentStyle: {"min-height": "350px", "height":"100%", "background-color":"#f1f1f4", "overflow": "hidden"},
            baseZIndex: 10000
        });
	}

	removeReportFunc(reportId: any, reportName: any){
		this.confirmationService.confirm({
            message: "Are you sure you want to remove '"+ reportName.split('.').slice(0, -1).join('.') +"' file?",
            header: ' ',
            icon: 'pi pi-exclamation-triangle',
            accept: () => { this.onConfirm(reportId); },
            reject: () => {}
        });
	}

	onConfirm(reportId: any) {
        
		this.removeFileSubscript = this.myFavoritesService.removeFileService(reportId).subscribe( (response: any) => { // records
			
			if(response.status == 200){

				d3.select(".tibo-myfavo-reports-spinner").style("display","block") // showing scheduled reports container spinner
			
				this.recordsSubscrip = this.myFavoritesService.recordsService(this.paginatorPageNo,this.requestPayload).subscribe( (recordsResp: any) => { // records

					d3.select(".tibo-myfavo-reports-spinner").style("display","none") // hidding scheduled reports container spinner

					this.totalRecords = recordsResp.body?.totalRecords;
					recordsResp.body?.records ? this.myFavoritesReportRecords = recordsResp.body?.records : this.myFavoritesReportRecords = [];

					this.myFavoritesReportRecords.length > 0 ? this.tableEmptyMessage = "" : this.tableEmptyMessage = "No records found";
					
				}, error => { 
					//console.loglog("error")
					d3.select(".tibo-myfavo-reports-spinner").style("display","none") // hidding scheduled reports container spinner
					if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
					else { this.errorMsg = "Please try after some time." }

					this.addSingle();
				}, () => {})

			}

		}, error => { 
				
				if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
				else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
				else { this.errorMsg = "Please try after some time." }
				
				this.addSingle();
		}, () => {})

    }

}