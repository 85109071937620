<div class="tibo-settings-container-wrapper tibo-right-container settings-parent-container">

	<p-toast styleClass="tibo-settings-toast" position="center"></p-toast>

	<div class="tibo-settings-accord-container p-px-5 p-py-4">
	
		<p-accordion styleClass="accordion-head">
		
			<p-accordionTab header="Application Settings" [selected]="true">
				<div class="p-px-5">    
					<p-scrollPanel styleClass="tibo-stgs-accord-scroll-panel">
						<div class="container">
							<p-card>
								<ng-template pTemplate="header">Dashboard</ng-template>
								<div class="p-grid">
									<div class="p-col-12 p-mb-sm-2 p-mb-md-2 p-md-6 p-xl-3">
										<div class="p-mb-2"><label>Set default dashboard</label></div>
										<p-dropdown [options]="defaultDashboardData" [(ngModel)]="savedDefaultDashboard" optionLabel="name" optionValue="code" 
										(onChange)="defaultDashboard($event)" placeholder="Select a dashboard"></p-dropdown>
									</div>
									<!--<p-divider layout="vertical"></p-divider>-->
									<div class="p-col-12 p-mb-sm-2 p-mb-md-2 p-md-6 p-xl-3 p-pl-6">
										<div class="p-mb-2"><label >Auto refresh</label></div>
										<p-inputSwitch [(ngModel)]="autoRefreshCheckBool" (onChange)="handleChange($event)"></p-inputSwitch>
									</div>
									<!--<p-divider layout="vertical"></p-divider>-->
									<div class="p-col-12 p-mb-sm-2 p-md-6 p-xl-3">
										<div class="p-mb-2"><label>Refresh frequency</label></div>
										<p-inputNumber styleClass="tibo-settings-number-input" [(ngModel)]="requestPayload.dashboardRefreshTime" [showButtons]="true" [min]="3" (onInput)="onChangeFrequency($event)">
										</p-inputNumber> minutes
									</div>
									<!--<p-divider layout="vertical"></p-divider>-->
									<div class="p-col-12 p-md-6 p-xl-3">
										<div class="p-mb-2"><label >Create Dashboard widgets limit</label></div>
										<p-inputNumber styleClass="tibo-settings-number-input" [(ngModel)]="requestPayload.userDashboardWidgetLimit" [showButtons]="true" [min]="3" [step]="3" (onInput)="onChangeWidgetLimit($event)">
										</p-inputNumber>
									</div>
									
										<div class="p-col-12 p-md-6 p-xl-3">
											<div class="p-mb-2"><label> Font Family</label></div>
											<p-dropdown [options]="fonts" [(ngModel)]="savedDefaultDashboard" optionLabel="name" optionValue="value" 
											(onChange)="defaultDashboard($event)" placeholder="Select a font"></p-dropdown>
										</div>
										<!--<p-divider layout="vertical"></p-divider>-->
										<div class="p-col-12 p-md-6 p-xl-3">
										<div class="p-mb-2"><label>Font size</label></div>
											<p-inputNumber styleClass="tibo-settings-number-input" [(ngModel)]="requestPayload.dashboardRefreshTime" [showButtons]="true" [min]="3" (onInput)="onChangeFrequency($event)">
											</p-inputNumber> PX
											</div>
										<!--<p-divider layout="vertical"></p-divider>-->
										<div class="p-col-12 p-mb-sm-2 p-md-6 p-xl-3">
											<div class="p-mb-2"><label>Font Style</label></div>
											<p-dropdown [options]="fontStyle" [(ngModel)]="savedLandingPage" optionLabel="style" optionValue="value" 
											(onChange)="defaultDashboard($event)" placeholder="Select a font"></p-dropdown>
										</div> <br><br>
								</div> 

                                
								
						<div class="p-row parentDiv">
								<div class="widget-color-button" class="p-col-12 p-md-6 p-lg-6">
									<label>Default Widget Colour</label><br><br>
									<div class="widget-color-border">

										<div class="single-row-color-set">
										<div class="p-p-2" [ngStyle]="{'background-color': colorSetThree[i], cursor:'pointer'}" *ngFor='let row of colorSetThree let i = index'><span pTooltip="{{row}}"  class="inside-color">{{row}}</span></div>
									</div>
										<span class="widget-color-down-arrow" (click)="widgetBackcolorsetsoverlaypanel.toggle($event)" ></span>
							    </div>
							  <p-overlayPanel #widgetBackcolorsetsoverlaypanel styleClass="" [showTransitionOptions]="'100ms'" appendTo="body" [showCloseIcon]=false>
								<p class="theme-color">Theme Color</p>	
								<div class="color-main">
										<div class="color-set">
										<div class="single-row-color-set">
											<div class="p-p-2" [ngStyle]="{'background-color': colorSetOne[i], cursor:'pointer', heigth:'30px',width:'50px'}" *ngFor='let row of colorSetOne let i = index' (click)="changeChartColorsFunc('one')"><span pTooltip="{{row}}"class="inside-color">{{row}}</span></div>
										</div>
											<div class="single-row-color-set">
											<div class="p-p-2" [ngStyle]="{'background-color': colorSetTwo[i], cursor:'pointer'}"  *ngFor='let row of colorSetTwo let i = index' (click)="changeChartColorsFunc('two')"><span pTooltip="{{row}}" class="inside-color">{{row}}</span></div>
										</div>
										
										<div class="single-row-color-set">
											<div class="p-p-2" [ngStyle]="{'background-color': colorSetThree[i], cursor:'pointer'}" *ngFor='let row of colorSetThree let i = index' (click)="changeChartColorsFunc('three')"><span pTooltip="{{row}}"  class="inside-color">{{row}}</span></div>
										</div>
						
										<div class="single-row-color-set">
											<div class="p-p-2" [ngStyle]="{'background-color': colorSetFour[i], cursor:'pointer'}" *ngFor='let row of colorSetFour let i = index' (click)="changeChartColorsFunc('four')"><span pTooltip="{{row}}"  class="inside-color">{{row}}</span></div>
										</div>
										
										<div class="single-row-color-set">
											<div class="p-p-2" [ngStyle]="{'background-color': colorSetFive[i], cursor:'pointer'}" *ngFor='let row of colorSetFive let i = index' (click)="changeChartColorsFunc('five')"><span pTooltip="{{row}}"  class="inside-color">{{row}}</span></div>
										</div>
										<div class="single-row-color-set">
											<div class="p-p-2" [ngStyle]="{'background-color': colorSetSix[i], cursor:'pointer'}" *ngFor='let row of colorSetSix let i = index' (click)="changeChartColorsFunc('six')"><span pTooltip="{{row}}"  class="inside-color">{{row}}</span></div>
										</div>
										<div class="single-row-color-set">
											<div class="p-p-2" [ngStyle]="{'background-color': colorSetSeven[i], cursor:'pointer'}" *ngFor='let row of colorSetSeven let i = index' (click)="changeChartColorsFunc('seven')"><span pTooltip="{{row}}"  class="inside-color">{{row}}</span></div>
										</div>
										<div class="single-row-color-set">
											<div class="p-p-2" [ngStyle]="{'background-color': colorSetEight[i], cursor:'pointer'}" *ngFor='let row of colorSetEight let i = index' (click)="changeChartColorsFunc('seven')"><span pTooltip="{{row}}"  class="inside-color">{{row}}</span></div>
										</div>
									</div>
								</div>
							</p-overlayPanel>
								
							</div>

								<div class="widget-color-button" class="p-col-12 p-md-6 p-lg-6">
									<label>Default Background Colour</label><br><br>
									<div class="widget-back-color">
								<div class="tibo-mydash-chartcoloroptions-btn tibo-mydash-chartcoloroptions-btn-icon" type="button" pButton pTooltip="Color Sets"  class="widget-red-icon" [ngStyle]="{'background-color': widgetBackColor}"></div><span class="down-arrow" (click)="chartscolorsetsoverlaypanel.toggle($event)" ></span>
							    </div>
								<p-overlayPanel #chartscolorsetsoverlaypanel styleClass="" [showTransitionOptions]="'100ms'" appendTo="body" [showCloseIcon]=false>
                            	<p class="theme-color">Theme Color</p>
									<div class="color-setone">
									<button class="rowOne" *ngFor="let color of colorOne ; let i = index" [ngStyle]="{'background-color': color}" (click)="getcolor(color, i)" ><span class="focus" *ngIf="i === buttonIndex" [ngValue]="color">&#10003;</span></button>
									<button class="rowOne" [ngStyle]="{'background-image': 'linear-gradient(red,lightgreen,lightblue)'}" (click)="getcolor(colorOne, 12)"><span class="focus" *ngIf="allColorButton">&#10003;</span></button>
								</div>  
								</p-overlayPanel>
							</div>
						</div>	
								
							</p-card>
						</div>
						
						<div class="container">
							<p-card>
								<ng-template pTemplate="header">Reports</ng-template>
									<div class="p-grid">
										<div class="p-col-12 p-mb-sm-2 p-md-6 p-xl-3">
											<div class="p-mb-2"><label>Default display style</label></div>
											<p-selectButton [options]="displayOptions" [(ngModel)]="requestPayload.viewStyle" optionLabel="label"
												optionValue="value" (onChange)="displayStyleChange($event)"></p-selectButton>
										</div>
											<!--<p-divider layout="vertical"></p-divider>-->
										<div class="p-col-12 p-md-6 p-xl-3">
											<div class="p-mb-2"><label>Default reports lists</label></div>
											<p-selectButton [options]="reportLists" [(ngModel)]="requestPayload.directoryView" optionLabel="label" 
											optionValue="value" (onChange)="displayStyleViewChange($event)"></p-selectButton>
										</div>
									</div>        
							</p-card>
						</div>
						<div class="container">
							<p-card>
								<ng-template pTemplate="header">Report Inbox</ng-template>
									<div class="p-grid">
									  <div class="p-col-6">
										<div class="p-mb-2"><label>Keep inbox files for</label></div>
										<p-inputNumber styleClass="tibo-settings-number-input" [(ngModel)]="requestPayload.retentionDays" [showButtons]="true" 
										(onInput)="onChangeRepInboxLimit($event)" [min]=90 [max]=180>
										</p-inputNumber> days
									  </div>
									</div>        
							</p-card>
						</div>
					</p-scrollPanel>
				</div>
		   </p-accordionTab>

			<p-accordionTab header="General Settings">
				<div class="p-px-5">
					<p-scrollPanel styleClass="tibo-stgs-accord-scroll-panel">
						<div class="container">
							<p-card >
								<ng-template pTemplate="header">Set default landing page</ng-template>
								<div class="p-grid">
									<p-dropdown [options]="landingPages" [(ngModel)]="savedLandingPage" optionLabel="name" optionValue="code"
									(onChange)="landingPage($event)"></p-dropdown>
								</div>
							</p-card>
						</div>
						
					  
						<div class="container">
							<p-card styleClass="tibo-settings-theme">
								<ng-template pTemplate="header">Set your favorite theme</ng-template>
								<div class="flex-container tibo-settings-themes-container">
									<div class="tibo-stgs-color-theme"></div>
									<div class="tibo-stgs-color-theme"></div>
									<div class="tibo-stgs-color-theme"></div> 
									<div class="tibo-stgs-color-theme"></div>
									<div class="tibo-stgs-color-theme"></div>
									<!--<div></div> 
									<div></div>-->
									<div class="tibo-stgs-color-theme"></div>
									<div class="tibo-stgs-color-theme"></div>
									<div class="tibo-stgs-color-theme"></div>
								</div>
							</p-card>
						</div>

					</p-scrollPanel>
				</div>
			</p-accordionTab>
			
		</p-accordion>

		<div class="tibo-settings-apply-changes-btn p-grid p-jc-center">
			<button pButton type="button" label="Apply Changes" class="p-button-Primary" (click)="applyChanges($event)"></button>
		</div>
		
	</div>
	
</div>
