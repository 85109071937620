<div class="container-fluid discovery-main-container tibo-right-container">
    <div>
        <button pbutton icon="pi pi-angle-left" ptooltip="Back to Search"
            class="tibo-mydash-chartcoloroptions-btn p-button p-component p-button-icon-only">
            <span class="pi pi-angle-left"></span>
        </button>
    </div>
    <div class="table-header">
        <div class="table-header-title-section">
            <label class="table-header-title"></label>
        </div>
        <button type="button"
            pbutton ptooltip="Export" tooltipposition="left"
            class="download-button p-button p-component p-button-icon-only ng-star-inserted"><span
                class="p-button-icon pi pi-download" aria-hidden="true"></span>
                <span aria-hidden="true"
                class="p-button-label">&nbsp;</span>
            </button>
    </div>
    <div>
        <p-card></p-card>
    </div>
</div>