import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map, catchError } from 'rxjs/operators';

@Injectable()
export class LoginService {
    
  constructor(private http: HttpClient) { }

    tokenService(body: any) {
		return this.http.post('api/user/authenticate', body, 
        { headers: this.getHeaders() }).pipe(map(
            (response) => {
                return response;
            }
        ))
    }
	
	loggedIn() {
		return !!localStorage.getItem("token");
	}
	
	user() {
		return this.http.post('api/v1/current/user', {}, { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
    }

    config() {
        return this.http.get('api/v1/user/config', { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
    }

    getHeaders() {
        let headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Access-Control-Allow-Origin', '*');
        return headers;
    }

}