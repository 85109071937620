<div class="tibo-schedhist-container-wrapper tibo-right-container">
	<div id="schedhist-scroll-wrapper" class="tibo-schedhist-scroll-wrapper">
		<div class="tibo-schedhist-tile-list-scrolview-wrapper">
		
			<!-- <p-toast styleClass="tibo-schedhist-toast" position="center"></p-toast> -->
			
			<p-progressSpinner styleClass="tibo-schedhist-reports-spinner custom-spinner" animationDuration="2s"></p-progressSpinner>
			
			<div class="tibo-schedhist-reports-listview-cont"> <!--Children styling available in .css file-->
					<p-table #schedulehistorytable [value]="scheduleHistoryRecords" [sortField]="tableSortColumn" [sortOrder]="tableSortDir" [scrollable]="true" scrollHeight="flex" 
						[globalFilterFields]="['jobName','status','startTime','endTime']" (sortFunction)="customSort($event)" [customSort]="true">
						
						<ng-template pTemplate="caption">
							<div class="p-d-flex p-jc-between p-ai-center">
								<span class="p-input-icon-left p-ml-auto">
									<i class="pi pi-search"></i>
									<input pInputText type="text" (input)="schedulehistorytable.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" />
								</span>
								<label class="p-mx-2">Showing {{scheduleHistoryRecords?.length}} of {{totalRecords}}</label>
							</div>
						</ng-template>
							
						<ng-template pTemplate="header">
							<tr>
								<th pSortableColumn="jobName" (click)="theadClick($event)">
									<div class="p-d-flex p-jc-between p-ai-center">
										Job Name
										<p-sortIcon field="jobName"></p-sortIcon>
										<p-columnFilter type="text" field="jobName" display="menu" class="p-ml-auto"></p-columnFilter>
									</div>
								</th>
								<th>
									<!--<p-columnFilter type="text" field="status" display="menu" class="p-ml-auto"></p-columnFilter>-->
									<div class="p-d-flex p-jc-between p-ai-center">
										Status
										<p-columnFilter field="filterStatus" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" [showApplyButton]="false" [showClearButton]="false" class="p-ml-auto" (click)="filterBtnClick()">
											<ng-template pTemplate="filter" let-value let-filter="filterCallback">
												<p-multiSelect class="tibo-sche-hist-fltr-multiSelect" styleClass="prime-slider-override" [ngModel]="value" [options]="statuses" placeholder="Select Status" optionLabel="filterStatus" [filter]="false" (onChange)="filter($event.value)">
													<ng-template let-option>
														<div>
															<span class="p-ml-1">{{option.filterStatus}}</span>
														</div>
													</ng-template>
												</p-multiSelect>
											</ng-template>
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="startTime" (click)="theadClick($event)">
									<div class="p-d-flex p-jc-between p-ai-center">
										Scheduled On
										<p-sortIcon field="startTime"></p-sortIcon>
										<p-columnFilter type="date" field="startTime" display="menu" class="p-ml-auto"></p-columnFilter>
										
									</div>
								</th>
								<th pSortableColumn="endTime" (click)="theadClick($event)">
									<div class="p-d-flex p-jc-between p-ai-center">
										Completed On
										<p-sortIcon field="endTime"></p-sortIcon>
										<p-columnFilter type="date" field="endTime" display="menu" class="p-ml-auto"></p-columnFilter>
									</div>
								</th>
							</tr>
						</ng-template>

						<ng-template pTemplate="body" let-car>
							<tr>
								<td>{{car?.jobName}}</td>
								<td>{{car?.status}}</td>
								<td>{{car?.startTime | date:'MM/dd/YYYY h:mm a'}}</td>
								<td>{{car?.endTime | date:'MM/dd/YYYY h:mm a'}}</td>
							</tr>
						</ng-template>
						
						<ng-template pTemplate="emptymessage">
							<tr>
								<td>{{ tableEmptyMessage }}</td>
							</tr>
						</ng-template>

					</p-table>
			</div>
			
			<p-paginator styleClass="tibo-schedhist-listView-p-paginator" [rows]="250" [totalRecords]="totalRecords" (onPageChange)="paginate($event)"></p-paginator>
		
		</div>
	</div>
</div>
