<div class="tibo-myfavorepo-container-wrapper tibo-right-container">
	<div id="schedrepo-scroll-wrapper" class="tibo-myfavorepo-scroll-wrapper">
		<div class="tibo-myfavorepo-tile-list-scrolview-wrapper">
		
		<p-toast styleClass="tibo-myfavorepo-toast" position="center"></p-toast>
		
		<p-progressSpinner styleClass="tibo-myfavo-reports-spinner custom-spinner" animationDuration="2s"></p-progressSpinner>

		<p-confirmDialog styleClass="tibo-myfavo-remove-report-confirm-dialog" acceptLabel="Ok" acceptIcon="" rejectLabel="Cancel" rejectIcon="" [baseZIndex]="10000" defaultFocus="none" rejectButtonStyleClass="p-button-text"></p-confirmDialog>
		
		<div class="tibo-myfavo-listview-cont"> <!--Children styling available in .css file-->
				<p-table #myfavoritestable [value]="myFavoritesReportRecords" [sortField]="tableSortColumn" [sortOrder]="tableSortDir" [scrollable]="true" scrollHeight="flex" 
					[globalFilterFields]="['authorizationName','reportType']" (sortFunction)="customSort($event)" [customSort]="true">
					
					<ng-template pTemplate="caption">
						<div class="p-d-flex p-jc-between p-ai-center">
							<span class="p-input-icon-left p-ml-auto">
								<i class="pi pi-search"></i>
								<input pInputText type="text" (input)="myfavoritestable.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" />
							</span>
							<label class="p-mx-2">Showing {{myFavoritesReportRecords?.length}} of {{totalRecords}}</label>
						</div>
					</ng-template>
						 
					<ng-template pTemplate="header">
						<tr>
							<th pSortableColumn="authorizationName" (click)="theadClick($event)">
								<div class="p-d-flex p-jc-between p-ai-center">
									Report Name
									<p-sortIcon field="authorizationName"></p-sortIcon>
									<p-columnFilter type="text" field="authorizationName" display="menu" class="p-ml-auto"></p-columnFilter>
								</div>
							</th>
							<th pSortableColumn="reportType" (click)="theadClick($event)">
								<div class="p-d-flex p-jc-between p-ai-center">
									Report Type
									<p-sortIcon field="reportType"></p-sortIcon>
									<p-columnFilter type="text" field="reportType" display="menu" class="p-ml-auto"></p-columnFilter>
								</div>
							</th>
							<th>Actions</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-reportObj>
						<tr>
							<td>{{reportObj?.authorizationName.split('.').slice(0, -1).join('.')}}</td>
							<td>{{reportObj?.reportType}}</td>
							<td>
								<div class="tibo-myfavo-listview-btns-cont">
									<button *ngIf="reportObj?.isRunnable.toLowerCase() == 'y'" class="p-mr-2" type="button" pButton icon="pi pi-play" pTooltip="Run Report" tooltipPosition="left" (click)="onRunButtonClick(reportObj?.authorizationId,reportObj?.authorizationName,reportObj?.reportType)"></button>
									<button *ngIf="reportObj?.isSchedulable.toLowerCase() == 'y'" class="p-mr-2" type="button" pButton icon="pi pi-calendar-plus" pTooltip="Schedule Report" tooltipPosition="left" (click)="onScheduleBtnClick(reportObj?.authorizationId,reportObj?.authorizationName,reportObj?.authorizationDesc)"></button>
									<button class="p-mr-2" type="button" pButton icon="pi pi-file" pTooltip="Show all output files" tooltipPosition="left" (click)="showAllOutputFilesBtnClick(reportObj?.authorizationId)"></button>
									<button class="" type="button" pButton icon="pi pi-trash" pTooltip="Remove from Favorites" tooltipPosition="left" (click)="removeReportFunc(reportObj?.authorizationId,reportObj?.authorizationName)"></button>
								</div>
							</td>
						</tr>
					</ng-template>
					<ng-template pTemplate="emptymessage">
						<tr>
							<td>{{ tableEmptyMessage }}</td>
						</tr>
					</ng-template>

				</p-table>
			</div>
		
			<p-paginator styleClass="tibo-myfavo-listView-p-paginator" [rows]="250" [totalRecords]="totalRecords" (onPageChange)="paginate($event)"></p-paginator>
		
		</div>
	</div>
</div>
