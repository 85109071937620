
<div class="tibo-mydash-wrapper tibo-right-container">

	<!-- <button id="resizeBtn" class="tibo-mydash-resize-btn" type="button">Resize All</button> -->
	
	<div class="tibo-mydash-dashlistbtn-dashoptsbtn-container">
		
		<button id="mydash-dashlist-toggle-btn" class="tibo-mydash-dashlist-btn" type="button" pButton icon="pi pi-chevron-down" pTooltip="Dashboards" (click)="dashboardListFunc()"></button>
		<div id="tibo-mydash-dashlist-container-id" class="tibo-mydash-dashlist-container">
			<div class="dashlist-search">
				<span>Dashboard List</span><input type="text" [(ngModel)]="searchText" placeholder="search......"> <i class="fa fa-search"></i>
			</div>
			<p-panelMenu [model]="items1" styleClass="tibo-mydash-dashlist-panel" [multiple]=false ></p-panelMenu>
		</div>
		
		<div class="tibo-mydash-openeddashlist-chip-container">
			<p-chip styleClass="tibo-mydash-openeddashlist-chips" (click)="visitedDashFunc(selectedDashboard)">{{selectedDashboard}}</p-chip>
			<!-- <img src="../../assets/images/history.png" alt="" height="20px" width="20px" type="button" class="tibo-mydash-openeddashlist-chips-dwn-arrow-btn" (click)="dashlistoverlaypanel.toggle($event)" > -->
		</div>
		<p-overlayPanel #dashlistoverlaypanel styleClass="tibo-mydash-openeddashlist-overlaypanel" [showTransitionOptions]="'100ms'" appendTo="body" [showCloseIcon]=false>
			<ng-template pTemplate="body">
				<div>
					<p-chip *ngFor="let dashboard of viewDashboardsList" styleClass="tibo-mydash-openeddashlist-overlaypanel-chips" (click)="visitedDashFunc(dashboard); dashlistoverlaypanel.hide()">{{dashboard}}</p-chip>
				</div>
			</ng-template>
		</p-overlayPanel>
		<div class="tibo-mydash-openeddashlist-chip-history">
			<i class="fa fa-history"  (click)="dashlistoverlaypanel.toggle($event)"></i>
		</div>
	
		<label id="mydash-countdown-timer-label" class="tibo-mydash-refreshtime-label"></label>
		
		<button class="tibo-mydash-more-btn-icon tibo-mydash-chartcoloroptions-btn" type="button" pButton icon="pi pi-ellipsis-v" pTooltip="Options" (click)="moreoptions.toggle($event)"></button>
		<p-tieredMenu #moreoptions [model]="moreItems" [popup]="true" appendTo="body"></p-tieredMenu>
		<button class="tibo-mydash-chartcoloroptions-btn" type="button" pButton icon="pi pi-plus" pTooltip="Create-Dashboard" (click) = "navigateToCreate()"></button>
		<button class="tibo-mydash-chartcoloroptions-btn" type="button" pButton icon="pi pi-download" pTooltip="Download" (click)="downloadCharts()"></button>
		<button class="tibo-mydash-chartcoloroptions-btn" type="button" pButton icon="pi pi-window-maximize" pTooltip="Full Screen" (click)="fullscreen()"></button>
		<button class="tibo-mydash-chartcoloroptions-btn tibo-mydash-chartcoloroptions-btn-icon" type="button" pButton icon="pi pi-palette" pTooltip="Color Sets" (click)="chartscolorsetsoverlaypanel.toggle($event)"></button>
		<p-overlayPanel #chartscolorsetsoverlaypanel styleClass="" [showTransitionOptions]="'100ms'" appendTo="body" [showCloseIcon]=false>
            <label class="p-grid p-ml-2 p-mb-2 theme-color" style="font-size: 16px;">Theme Colors</label>
            <p-tabView>
            <p-tabPanel header="Set Widget Color">
            <div class="color-main">
                <div class="theme-color">
                </div>
                <div class="color-set">
                <div class="single-row-color-set">
                    <div class="p-p-2" [ngStyle]="{'background-color': colorSetOne[i], cursor:'pointer', heigth:'30px',width:'50px'}" *ngFor='let row of colorSetOne let i = index' (click)="changeChartColorsFunc('one')"><span pTooltip="{{row}}"class="inside-color">{{row}}</span></div>
                </div>
                <div class="single-row-color-set">
                    <div class="p-p-2" [ngStyle]="{'background-color': colorSetTwo[i], cursor:'pointer'}"  *ngFor='let row of colorSetTwo let i = index' (click)="changeChartColorsFunc('two')"><span pTooltip="{{row}}" class="inside-color">{{row}}</span></div>
                </div>
                <div class="single-row-color-set">
                    <div class="p-p-2" [ngStyle]="{'background-color': colorSetThree[i], cursor:'pointer'}" *ngFor='let row of colorSetThree let i = index' (click)="changeChartColorsFunc('three')"><span pTooltip="{{row}}"  class="inside-color">{{row}}</span></div>
                </div>
                <div class="single-row-color-set">
                    <div class="p-p-2" [ngStyle]="{'background-color': colorSetFour[i], cursor:'pointer'}" *ngFor='let row of colorSetFour let i = index' (click)="changeChartColorsFunc('four')"><span pTooltip="{{row}}"  class="inside-color">{{row}}</span></div>
                </div>
                <div class="single-row-color-set">
                    <div class="p-p-2" [ngStyle]="{'background-color': colorSetFive[i], cursor:'pointer'}" *ngFor='let row of colorSetFive let i = index' (click)="changeChartColorsFunc('five')"><span pTooltip="{{row}}"  class="inside-color">{{row}}</span></div>
                </div>
                <div class="single-row-color-set">
                    <div class="p-p-2" [ngStyle]="{'background-color': colorSetSix[i], cursor:'pointer'}" *ngFor='let row of colorSetSix let i = index' (click)="changeChartColorsFunc('six')"><span pTooltip="{{row}}"  class="inside-color">{{row}}</span></div>
                </div>
                <div class="single-row-color-set">
                    <div class="p-p-2" [ngStyle]="{'background-color': colorSetSeven[i], cursor:'pointer'}" *ngFor='let row of colorSetSeven let i = index' (click)="changeChartColorsFunc('seven')"><span pTooltip="{{row}}"  class="inside-color">{{row}}</span></div>
                </div>
                <div class="single-row-color-set">
                    <div class="p-p-2" [ngStyle]="{'background-color': colorSetEight[i], cursor:'pointer'}" *ngFor='let row of colorSetEight let i = index' (click)="changeChartColorsFunc('seven')"><span pTooltip="{{row}}"  class="inside-color">{{row}}</span></div>
                </div>
            </div>
        </div>
        </p-tabPanel>
        <p-tabPanel header="Set Background Color">
			<div class="color-setone">
                <button class="rowOne" *ngFor="let color of colorOne ; let i = index" [ngStyle]="{'background-color': color}" (click)="getcolor(color, i)"><span class="focus" *ngIf="i === buttonIndex" [ngValue]="color">&#10003;</span></button>
            <button class="rowOne" [ngStyle]="{'background-image': 'linear-gradient(red,lightgreen,lightblue)'}" (click)="getcolor(colorOne, 12)"><span class="focus" *ngIf="allColorButton">&#10003;</span></button>
		</div>
			<!-- <button class="rowOne" *ngFor="let color of colorOne,index as i" [ngStyle]="{'background-color': color}" (click)="getcolor(color)"><span >&#10003;</span></button> -->
	    </p-tabPanel>
    </p-tabView>
        </p-overlayPanel>
		<p-dialog  [(visible)]="shareDashDialBoxDisplayBool" styleClass="tibo-mydash-sharedash-box" [modal]="true" [draggable]="false" [resizable]="false" (onHide)="dialogBoxOnCloseFunc($event)">
			<ng-template pTemplate="header">
				<label class="tibo-mydash-sharedash-box-sharelabel" >Share {{selectedDashboard.name}}</label>
			</ng-template>
			<p-tabView [(activeIndex)]="shareDashTabIndex" (onChange)="shareDashTabChanFunc($event)" styleClass="share-tabview">
				<p-tabPanel header="Provide access to" class="tibo-mydash-sharedash-box-tabpanel">
					<div class="p-grid p-col-12 p-ai-center vertical-container">
						<label class="p-col-12">Enter User Name(s) <span class="tibo-mydash-paramdash-param-title-mand">*</span></label>
						<p-autoComplete [autofocus]="true" [(ngModel)]="shareDashUserNamesArr" [suggestions]="shareDashSrchUserNamesArr" (completeMethod)="search($event)" [multiple]="true" scrollHeight="155px"></p-autoComplete>
					</div>
				</p-tabPanel>
				<p-tabPanel header="One time share by Email" class="tibo-mydash-sharedash-box-tabpanel">
					<div class="p-grid p-col-12 p-ai-center vertical-container">
						<label class="p-col-12">Enter Email Id(s) <span class="tibo-mydash-paramdash-param-title-mand">* </span> 
						<span class="p-ml-2 tibo-mydash-sharedash-box-tabpanel-span">(To add multiple Email ids, enter an email id and press Enter)</span></label>
						<!--<input class="p-p-1 share-dash-inputtext" type="text" pInputText />-->
						<p-chips class="p-p-1" [(ngModel)]="shareDashEmailIdsArr" [allowDuplicate]="false"></p-chips>
					</div>
				</p-tabPanel>
			</p-tabView>
			<ng-template pTemplate="footer">
				<p-button icon="pi pi-share-alt" label="Share" (click)="shareDashFunc($event)" ></p-button>
			</ng-template>
		</p-dialog>
	
	</div>
	<!-- <p-progressSpinner styleClass="tibo-mydash-dash-spinner custom-spinner" animationDuration=".5s"></p-progressSpinner> -->

		<div class="chartContainer" #chartContainerDiv id="chartscontainerid"  (click)="chartsDivContainerClick($event)">
			<!-- <div class="chartTitle" id="dashboardtitle">{{ dashboardName }}</div> -->
			<div *ngIf="selectedDashboard == 'Disability-Client'">
				
			<div class="chartTitle" id="dashboardtitle">{{ dashboardName }}</div>
			
				<div class="chartDiv" id="chart1">
				</div>
				<div class="chartDiv" id="chart2" >
				</div>
				<div class="chartDiv" id="chart3">
				</div>
				<div class="chartDiv" id="chart4">
				</div>
				<div class="chartDiv" id="chart5">
				</div>
				<div class="chartDiv" id="chart6">
				</div>
				<div class="chartDiv" id="chart7">
				</div>
				<div class="chartDiv" id="chart8">
				</div>
				<div class="chartDiv" id="chart11">
				</div>
				<div class="chartDiv" id="chart10">
				</div>
			</div>
		
			<button style="display: none;" class="chartResiz" id = "resizeBtn" type="button" onclick="btnClickedFunc(this.id)" ></button>

			<div *ngIf="selectedDashboard == 'Leave'">
				<div class="chartTitle" id="dashboardtitle">{{ dashboardName }}</div>
					<div class="chartDiv" id="chart12">
					</div>
					<div class="chartDiv" id="chart13">
					</div>
					<div class="chartDiv1" id="chart9">
					</div>
					<!-- <div class="chartDiv" id="chart10">
					</div> -->
					
				</div>
				<button class="chartResiz" id = "resizeBtn" type="button" onclick="btnClickedFunc(this.id)" >Resize All</button>
				<div *ngIf="selectedDashboard == 'Employee Engagement'">
					<div class="chartTitle" id="dashboardtitle">{{ dashboardName }}</div>
						<div class="chartDiv2" id="chart24">
						</div>
						<div class="chartDiv2" id="chart25" >
						</div>
						<div class="chartDiv2" id="chart16">
						</div>
						<div class="chartDiv1" id="chart17">
						</div>
						
					</div>
					<button style="display: none;" class="chartResiz" id = "resizeBtn" type="button" onclick="btnClickedFunc(this.id)" ></button>
				<div *ngIf="selectedDashboard == 'Customer Service'">
						<div class="chartTitle" id="dashboardtitle">{{ dashboardName }}</div>
							<div class="chartDiv" id="chart18">
							</div>
							<div class="chartDiv" id="chart19" >
							</div>
							<div class="chartDiv" id="chart20">
							</div>
							<div class="chartDiv" id="chart21">
							</div>
						</div>
						<button style="display: none;" class="chartResiz" id = "resizeBtn" type="button" onclick="btnClickedFunc(this.id)" ></button>
				<div *ngIf="selectedDashboard == 'Customer Satisfactiont'">
							<div class="chartTitle" id="dashboardtitle">{{ dashboardName }}</div>
								<div class="chartDiv" id="chart22">
								</div>
								<div class="chartDiv" id="chart23" >
								</div>
							</div>
				<button style="display: none;" class="chartResiz" id = "resizeBtn" type="button" onclick="btnClickedFunc(this.id)" ></button>
				
				<div *ngIf="selectedDashboard == 'HR Dashboard'">
					<div class="chartTitle" id="dashboardtitle">{{ dashboardName }}</div>
					<div class="chartDiv2" id="chart24">
					</div>
					<div class="chartDiv2" id="chart25" >
					</div>
					<div class="chartDiv2" id="chart16">
					</div>
					<div class="chartDiv" id="chart20">
					</div>
					<div class="chartDiv" id="chart18">
					</div>
					<div class="chartDiv2" id="chart19" >
					</div>
					<div class="chartDiv2" id="chart17">
					</div>
					<div class="chartDiv2" id="chart21">
					</div>
					<div class="chartDiv" id="chart22">
					</div>
					<div class="chartDiv" id="chart23" >
					</div>
				</div>
				<div *ngIf="selectedDashboard == 'Absence Blueprint'">
				
					<div class="chartTitle" id="dashboardtitle">{{ dashboardName }}</div>
					<div style="display: flex; width: 100%;">
					    <div class="chartDiv" id="chart40">
					    </div>
						<div class="chartDiv" id="chart30">
					    </div>
						<div class="chartDiv" id="chart31">
					    </div>
					</div>
						<div class="chartDiv" id="chart33">
						</div>
						<div class="chartDiv" id="chart34" >
						</div>
						<div class="chartDiv" id="chart35">
						</div>
						<div class="chartDiv" id="chart36">
						</div>
						<div style="display: flex; width: 100%;">
							<div class="chartDiv" id="chart32">
							</div>
							<div class="chartDiv" id="chart39">
							</div>
							<div class="chartDiv" id="chart38">
							</div>
						</div>
					</div>
		<button style="display: none;" class="chartResiz" id = "resizeBtn" type="button" onclick="btnClickedFunc(this.id)" ></button>	
	</div>		
</div>
<p-dialog [(visible)]="showDialog" [header]="title" [modal]="true" [draggable]="true" [resizable]="true"  (click)="chartsDivContainerClick($event)" (onHide)="onDialogHide()">
	<div *ngIf="firstClick">
		<div class="chartDiv2" id="chart14"></div>
	</div>
	<div *ngIf="secondClick">
		<div class="chartDiv2" id="chart15">
		<div class="container1"  *ngIf="this.showGrid">
            <div class="drpFilterChkbox" style="float:right">
                    <button type="button" class="drpButton" (click)="showdropdown=!showdropdown"><i class="pi pi-eye" pTooltip="show/hide column" tooltipPosition="top"></i></button>
                    <button class="drpButton" (click)="Refresh(coldata )"><i class="pi pi-refresh" pTooltip="Refresh" tooltipPosition="top"></i></button>
                    <button class="drpButton" (click)="showmenu=!showmenu"><i class="pi pi-download" pTooltip="Download" tooltipPosition="top"></i></button>
                    <span class="p-input-icon-right">
                        <i class="pi pi-search"></i>
                        <input type="text" pInputText [(ngModel)]="searchValue"  placeholder="Search"/>
                    </span>
                    <div *ngIf="this.showdropdown" class="DropMenus" >
                        <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Search...">
                        <div class="dropContainer" *ngFor="let d of coldata  |filter: searchText ">
                        <input type="checkbox"  name="{{d.field}}" (change)="binSelect($event,d)" [checked]="d.checked" >{{d.headerName}}<br></div></div>
                        <div *ngIf="showmenu" class="downloadmenu">
                            <button type="button" class="downloadall" (click)="download1()" >Download All</button><br>
                            <button type="button" class="downloadall" (click)="download2()">Download filtered grid</button>
                </div>
            </div>
            <div class="aggriid" >
                <ag-grid-angular 
                style="width: 100%; height:482px;clear: both;overflow:scroll"
                class="ag-theme-alpine"
                [rowData]="rowData |filter: searchValue" 
                [columnDefs]="columnDefs "
                [defaultColDef]="defaultColDef"
                [enableFilter]="true"
                [pagination]="true"
                [gridOptions]="gridOptions"
                [setQuickFilter]="quickFilterText" 
                (gridReady)="onGridReady($event)"
                >
            </ag-grid-angular>
            </div>
            </div>
		</div>
	</div>
	<button class="chartResiz" id = "resizeBtn" type="button" onclick="btnClickedFunc(this.id)" >Resize All</button>
</p-dialog>

