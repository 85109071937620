import { Component, OnInit } from '@angular/core';
import { Message,MessageService } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
    template: `
		<div class="modal-container" style="background-image: url('/assets/images/dashboardinfo.png'); background-repeat: no-repeat; background-size: contain; background-position:center; position:relative; float:left; width:50%; height:100%; padding:1.5rem; background-color:transparent;">
		</div>
		<div class="modal-container" style="position:relative; float:left; width:50%; height:fit-content; padding:1.5rem; background-color:transparent;">
			<p class="dashboard-info">
				Timebound’s Interactive Dashboard enables rich and simplified data visualization for key dimensions of business metrics, 
				with interactive data that you can drill–down to details.
				The Interactive Dashboard assists executives, operations managers, supervisors and head of departments to make right 
				decisions at the right time based on informational data view.
			</p>
			<ul class="a">
				<li>Provides different views of the same data set, enabling users to change the data view and chart, as well as the ability to enable filters.</li>
				<li>Provides interactive data presentation like sorting, searching, and filtering on charts and data grid levels.</li>
				<li>It's easy to design your dashboard using features like drag and drop charts, tables and interactive controls.</li>
				<li>Provides and supports multiple data sources in a single dashboard.</li>
				</ul>
		</div>
	`,
	styleUrls: ['./home.component.css'],
	providers: [MessageService]
	
})


export class DashboardInfo implements OnInit {

	constructor(private messageService: MessageService, public config: DynamicDialogConfig) { }
	
	ngOnInit() {}
	
}