<div class="tibo-mydash-wrapper tibo-right-container">

    <!-- <p-toast styleClass="tibo-mydash-toast" position="center"></p-toast> -->

    <p-dialog [(visible)]="saveDashDialBoxDisplayBool" styleClass="tibo-mydash-sharedash-box" [modal]="true" [draggable]="false" [resizable]="false" (onHide)="dialogBoxOnCloseFunc($event)">
        <ng-template pTemplate="header">
            <label class="tibo-mydash-sharedash-box-sharelabel">Dashboard Details</label>
        </ng-template>

            <div class="p-grid p-col-12 p-ml-1 p-p-0">

                <div class="p-grid p-col-12 p-mr-3 p-pt-4 p-xl-6 p-lg-12 p-md-12 p-sm-12">
                    <label class="p-col-12" style="font-family: Roboto; font-size:12px;">Select Domain <span class="tibo-mydash-paramdash-param-title-mand">*</span></label>
                    <p-dropdown styleClass="dropdown"
                        class="p-col-12 p-p-0"
                        appendTo="body"
                        [options]="domainsList"
                        optionLabel="label"
                        optionValue="value"
                        placeholder="Select a domain"
                        (onChange)="onDomainSelect($event)">
                    </p-dropdown>
                </div>
            
                <div class="p-grid p-col-12 p-pt-4 p-xl-6 p-lg-12 p-md-12 p-sm-12">
                    <label class="p-col-12" style="font-family: Roboto; font-size:12px;">Dashboard Name <span class="tibo-mydash-paramdash-param-title-mand">*</span></label>
                    <input id="dashboard-name-id" type="text" class="tibo-myrepo-schelr-elements-inputtext" pInputText [(ngModel)]="dashboardName" placeholder="Enter dashboard name">
                </div>
            
            </div>
            
        <ng-template pTemplate="footer">
            <p-button label="Save" (click)="createDashboard()"></p-button>
        </ng-template>
    </p-dialog>

    <button id="resizeBtn" class="tibo-mydash-resize-btn" type="button">Resize All</button>
     
    <div class="tibo-mydash-charts-container">

        <div id="dashboard-parameterized-scroll-wrapper" class="tibo-mydash-paramdash-wrapper">

            <div id="dashboard-parameterized-filter" class="tibo-mydash-paramdash-filtrpanl" >
               
                <!-- <div class="p-grid p-col-11 p-ai-center vertical-container p-mx-2 p-mt-2" style="min-height:35px; height:auto; background-color:rgb(255, 255, 255);">

                    <span class="p-my-2" style="padding: 0px; background-color: rgb(255, 255, 255); font-family: Roboto; font-size: 12px;">
                        <input class="p-mr-1" type="radio" name="createdashmetric" (change)="radioSwitch('search metric')">
                        <label style="position:relative; top:-2px; font-family:Roboto; font-size: 12px;">Search Metric</label>
                    </span>
                    
                    <span *ngIf="metricSearchBool" class="p-col-12" style="padding: 5px; background-color: rgb(255, 255, 255); font-family: Roboto; font-size: 12px;">
                        <input type="text" class="tibo-myrepo-schelr-elements-inputtext" pInputText [(ngModel)]="text" placeholder="Metric name">
                        <button pButton pRipple icon="pi pi-search" (click)="search()"></button>
                    </span>
                    
                </div> -->

                <div class="p-grid p-col-11 p-ai-center vertical-container p-mx-2 p-mb-2 detail-conainer"  style="min-height:35px; height:auto; background-color:rgb(255, 255, 255);">

                    <span class="p-my-2" style="padding: 0px; background-color: rgb(255, 255, 255); font-family: Roboto; font-size: 12px;">
                        <input class="p-mr-1" type="radio" name="createdashmetric" (change)="radioSwitch('metric from dashboards')">
                        <label style="position:relative; top:-2px; font-family:Roboto; font-size: 12px;">Metric from dashboards</label>
                    </span>

                    <span *ngIf="metricFrmDashBool" class="p-col-12" style="padding: 5px; background-color: rgb(255, 255, 255); font-family: Roboto; font-size: 12px;">
                        <p-dropdown styleClass="dropdown"
                            id="monthly-occurrence-drop-one"
                            appendTo="body"
                            [options]="dashboardsList"
                            optionLabel="label"
                            optionValue="value"
                            placeholder="Select a dashboard"
                            (onChange)="onDashboardSelect($event)">
                        </p-dropdown>
                    </span>

                </div>
                
                <p-progressSpinner styleClass="tibo-mydash-widget-spinner custom-spinner" 
                                    strokeWidth="4" 
                                    fill="#FFFFFF" 
                                    animationDuration="1.5s">
                </p-progressSpinner>
                <div *ngIf="showSearch1" class="listClass" [ngClass]="{'listClass': showSearch1}">List
                    of Widgets</div>
                <p-scrollPanel id="tibo-mydash-nonparamdash-scrollpanel" styleClass="p-my-4 tibo-mydash-nonparamdash-scrollpanel" *ngIf="showSearch1">
                
                    <!--<div id="abc" class="p-col-12 p-my-4" style="min-height:35px; height:100%; background-color:#83be77; overflow: auto;">-->
                        <!--[ngClass]="{'parameterized-widget': searchOrDashResult... }" Currently hidding the parameterized widget names-->
                        <div [id]="'draggable-'+ widget.id" 
                            class="tibo-mydash-widget p-grid p-col-11 p-ai-center vertical-container"
                            draggable="true" 
                            (dragstart)="dragStart($event,i)" 
                            (dragend)="dragEnd($event)" 
                            *ngFor="let widget of widgetsList let i = index">
                            <span style="display:contents">
                                <img [src]="widget.imageUrl"
                                style='width: 33px;border: 1px solid #f0f0f0;position: absolute;margin: -3px 0px 0px 15px;'>
                            <p style="padding-left: 50px;text-align: left; margin-left: 5px;">  {{ widget.name}} </p>
                        </span>
                          
                        </div>
                    
                    <!--</div>-->

                </p-scrollPanel>
            </div>

            <div id="dashboard-parameterized-scroll-view" class="tibo-mydash-paramdash-scroll-wrapper">

                <p-scrollPanel styleClass="tibo-mydash-paramdash-scrollpanel custombar2">

                    <div class="tibo-mydash-paramdash-widget" *ngFor="let row of rows let i = index"> 
    					<div class="widget-class create-dashboard-widget-container" 
							[ngClass]="'widget-class-3'" 
							[attr.objectCordinates] = "(i+1)+','+(j+1)" 
                            (drop)="drop($event)"
                            (dragover)="allowDrop($event)"
							*ngFor="let column of columns; let j = index;">
                            <!--<p *ngIf="i+','+j == '0,0'">click{{i+","+j}}</p>-->
                            <!--{{ i+','+j == '0,0' ? '0,0' : ''}}-->
                            <p-progressSpinner styleClass="tibo-mydash-widget-spinner1 custom-spinner" 
                                    strokeWidth="4" 
                                    fill="#FFFFFF" 
                                    animationDuration="1.5s">
                            </p-progressSpinner>
                        </div>
                    </div>

                </p-scrollPanel>

            </div>
            <div class="" style="position:absolute; left:280px; bottom:0px; width:calc(100% - 280px); height:40px; background-color: #f1f1f4;">
                <p-button label="Save Dashboard" styleClass="tibo-discovery-submit" (click)="saveDashboard()"></p-button>
                <!-- <button  class="btn btn-default" style="float:right;margin-right: 35px;height: 35px;width: 84px;" (click)="delete($event)">clear</button> -->
            </div>
        
        </div>

    </div>

</div>
