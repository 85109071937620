import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable()
export class MyFavoritesService {
    
    constructor(private http: HttpClient) { }
	
	recordsService(pageNo:any, requestPayload:any) {
		return this.http.post('api/v1/report/favourite/search/list-view?depth=0&limit=250&page='+pageNo, requestPayload,
		{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}

	removeFileService(reportId){
		return this.http.delete('api/v1/report/'+reportId+'/favourite',
		{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	
}

