<div class="tibo-mydash-wrapper tibo-right-container">
	
	<button id="resizeBtn" class="tibo-mydash-resize-btn" type="button">Resize All</button>

	<div class="tibo-mydash-charts-container">
		<div class="tibo-mydash-dashboard-title">{{ dashboardName }}</div>
		<div id="dashboard-scroll-wrapper" class="tibo-mydash-nonparamdash-wrapper">
			<!--<div class="tibo-mydash-nonparamdash-widget" *ngFor="let row of randomIdArray let i = index">-->
				<div class="widget-class"
					[attr.objectCordinates] = "1+','+1"  
					[attr.id] = randomIdArray>  
					
					<p-progressSpinner styleClass="tibo-mydash-widget-spinner custom-spinner" 
									   strokeWidth="4" 
									   fill="#FFFFFF" 
									   animationDuration="1.5s">
					</p-progressSpinner>

				</div>
			<!--</div>-->
		</div>
	</div>
</div>
