<!-- <div class="tibo-myreport-container-wrapper tibo-right-container">

	<div id="myrepo-scroll-wrapper" class="tibo-myrepo-scroll-wrapper">

		<p-toast styleClass="tibo-myrepo-toast" position="center"></p-toast>

		<div id="myreports-folderview-panel" class="tibo-myrepo-folderview-panel">

			<span id="all-reports-id" class="tibo-myrepo-folderview-panel-title">All Reports</span>

			<button type="button" class="tibo-myrepo-folderview-panel-arrow-icon"
				(click)="folderPanelToggle()"></button>

			<p-scrollPanel id="myreport-folder-panel-scroll-div" styleClass="tibo-myrepo-folderview-panel-scrollpanel">
				<p-tree styleClass="tibo-myrepo-repofolders-tree-cont" [value]="files" selectionMode="single"
					[(selection)]="selectedFile" scrollable="true" emptyMessage="Please wait..."
					(onNodeSelect)="nodeSelect($event)" (onNodeUnselect)="nodeUnselect($event)"
					(onNodeExpand)="nodeExpand($event)"></p-tree>
			</p-scrollPanel>

		</div>

		<div id="myreports-tile-list-scroll-view" class="tibo-myrepo-tile-list-scrolview-wrapper">
			<div class="searchI">
				<span class="p-input-icon-left p-ml-auto"><i class="pi pi-search"></i>
					<input pInputText type="text" (input)="myreportstable.filterGlobal($event.target.value, 'contains')"
						placeholder="Search keyword" />
				</span>
			</div>

			<div class="p-grid p-col-12 p-ai-center vertical-container tibo-myrepo-repotitle-tilelist-switch-cont">
		
				<label>
					<span id="" class="pi pi-folder-open"></span> {{selectedFile?.label}}
				</label>



				<p-selectButton [options]="stateOptions" [(ngModel)]="listOrTileView" optionLabel="label"
					optionValue="value" (onChange)="changeViewFunc($event)"></p-selectButton>

			</div>



			<div class="tibo-myrepo-tileview-cont my-reports-tile-view">

				<p *ngIf="myReportRecords.length == 0" class="p-ml-5">{{ tableEmptyMessage }}</p>

				<p-scrollPanel *ngIf="myReportRecords.length > 0" styleClass="tibo-myrepo-titleview-scrollpanel">
					<div class="p-grid p-nogutter">
						<div class="tibo-myrepo-tile-box p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-4"
							*ngFor="let reportObj of myReportRecords; let j = index;">

							<div>
								<div class="p-grid p-col-12 p-ai-center vertical-container">
									<label>{{reportObj?.authorizationName.substring(0,
										reportObj?.authorizationName.lastIndexOf('.'))}}</label>
								</div>
								<div>
									<button *ngIf="reportObj?.isRunnable.toLowerCase() == 'y'" id="" class="p-mr-2"
										type="button" pButton icon="pi pi-play" pTooltip="Run Report"
										tooltipPosition="top"
										(click)="onRunButtonClick(reportObj?.authorizationId,reportObj?.authorizationName,reportObj?.reportType)"></button>
									<button *ngIf="reportObj?.isSchedulable.toLowerCase() == 'y'" id="" class="p-mr-2"
										type="button" pButton icon="pi pi-calendar-plus" pTooltip="Schedule Report"
										tooltipPosition="top"
										(click)="onScheduleBtnClick(reportObj?.authorizationId,reportObj?.authorizationName,reportObj?.authorizationDesc)"></button>
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-star"
										pTooltip="Add to Favorites" tooltipPosition="top"
										(click)="onAddToMyFavoritesBtnClick(reportObj?.authorizationId,reportObj?.authorizationName,reportObj?.authorizationDesc)"></button>
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-file"
										pTooltip="Show all output files" tooltipPosition="top"
										(click)="showAllOutputFilesBtnClick(reportObj?.authorizationId)"></button>

								</div>
							</div>

						</div>
					</div>
				</p-scrollPanel>
			</div>

			<div class="tibo-myrepo-listview-cont my-reports-list-view">
				<p-table #myreportstable [value]="myReportRecords" [scrollable]="true" scrollHeight="flex"
					[globalFilterFields]="['authorizationName','reportType']" (sortFunction)="customSort($event)"
					[customSort]="true" [paginator]="true"
					currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rows]="250"
					[totalRecords]="totalRecords" [showCurrentPageReport]=true>


					<ng-template pTemplate="header">
						<tr>
							<th pSortableColumn="authorizationName">
								<div class="p-d-flex p-jc-between p-ai-center">
									Report Name
									<p-sortIcon field="authorizationName"></p-sortIcon>
									<p-columnFilter type="text" field="authorizationName" display="menu"
										class="p-ml-auto"></p-columnFilter>
								</div>
							</th>
							<th pSortableColumn="reportType">
								<div class="p-d-flex p-jc-between p-ai-center">
									Report Type
									<p-sortIcon field="reportType"></p-sortIcon>
									<p-columnFilter type="text" field="reportType" display="menu"
										class="p-ml-auto"></p-columnFilter>
								</div>
							</th>
							<th>Actions</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-reportObj>
						<tr>
							<td>{{reportObj?.authorizationName.substring(0,
								reportObj?.authorizationName.lastIndexOf('.'))}}</td>
							<td>{{reportObj?.reportType}}</td>
							<td>
								<div class="tibo-myrepo-listview-btns-cont">
									<button *ngIf="reportObj?.isRunnable.toLowerCase() == 'y'" id="" class="p-mr-2"
										type="button" pButton icon="pi pi-play" pTooltip="Run Report"
										tooltipPosition="left"
										(click)="onRunButtonClick(reportObj?.authorizationId,reportObj?.authorizationName,reportObj?.reportType)"></button>
									<button *ngIf="reportObj?.isSchedulable.toLowerCase() == 'y'" id="" class="p-mr-2"
										type="button" pButton icon="pi pi-calendar-plus" pTooltip="Schedule Report"
										tooltipPosition="left"
										(click)="onScheduleBtnClick(reportObj?.authorizationId,reportObj?.authorizationName,reportObj?.authorizationDesc)"></button>
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-star"
										pTooltip="Add to Favorites" tooltipPosition="left"
										(click)="onAddToMyFavoritesBtnClick(reportObj?.authorizationId,reportObj?.authorizationName,reportObj?.authorizationDesc)"></button>
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-file"
										pTooltip="Show all output files" tooltipPosition="left"
										(click)="showAllOutputFilesBtnClick(reportObj?.authorizationId)"></button>
								</div>
							</td>
						</tr>
					</ng-template>
					<ng-template pTemplate="emptymessage">
						<tr>
							<td>{{ tableEmptyMessage }}</td>
						</tr>
					</ng-template>
				</p-table>
			</div>


		</div>

	</div>

</div> -->

<!--  -->

<div class="container-fluid p-d-flex report-page-parent-container">
	<div class="side-menu-content" [ngClass]="{'side-menu-collapsed':sideMenuCollapse}">
		<div class="side-menu-header" [ngClass]="{'p-d-flex':!sideMenuCollapse}">
			<div class="header-first-section">
				<h2 class="side-menu-h1" [ngClass]="{'side-menu-collapsed-h1':sideMenuCollapse}">All Reports</h2>
			</div>
			<div class="header-second-section">
				<i class='pi pi-angle-double-left side-menu-left-icon' *ngIf="!sideMenuCollapse"
					(click)="collapseSideMenu()"></i>
				<i class='pi pi-angle-double-right side-menu-right-icon' *ngIf="sideMenuCollapse"
					(click)="collapseSideMenu()"></i>
			</div>
		</div>
		<div class="p-fluid p-formgrid p-grid tree-formgrid">
			<p-tree styleClass="tibo-myrepo-repofolders-tree-cont tree-element" [value]="files" selectionMode="single"
				[(selection)]="selectedFile" scrollable="true" emptyMessage="Please wait..."
				(onNodeSelect)="nodeSelect($event,selectedFile)" (onNodeUnselect)="nodeUnselect($event)"
				(onNodeExpand)="nodeExpand($event)" *ngIf="!sideMenuCollapse"></p-tree>
		</div>
	</div>
	<div class="main-content">
		<!-- <ag-grid-angular #myGrid style="width: 100%; height: 100%" class="ag-theme-alpine" [columnDefs]="columnDefs"
			[defaultColDef]="defaultColDef" [rowData]="scheduledReportRecords" [rowSelection]="'single'"
			[animateRows]="true" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)"
			(paginationChanged)="onPaginationChanged($event)" [cacheOverflowSize]="cacheOverflowSize"
			[maxConcurrentDatasourceRequests]="maxConcurrentDatasourceRequests"
			[infiniteInitialRowCount]="infiniteInitialRowCount" (sortChanged)="sortFun($event)"
			(filterChanged)="filterFun($event)">
		</ag-grid-angular> -->
		<div class="table-top">
			<!-- <div class="first-section-table-top"> -->
				<span id="" class="pi pi-folder-open folder-open-table-top"></span> <span>{{selectedFile?.label ? selectedFile?.label:'Absence'}}</span> 
			<!-- </div> -->
			<!-- <div class="second-section-table-top"> -->
				<!-- <div class=""> -->
					<!-- <span class="p-input-icon-left p-ml-auto input-section"><i class="pi pi-search"></i>
						<input pInputText type="text" (input)="myreportstable.filterGlobal($event.target.value, 'contains')"
							placeholder="Search keyword" />
					</span> -->
				<!-- </div> -->
				<!-- <p-selectButton [options]="stateOptions" [(ngModel)]="listOrTileView" optionLabel="label"
				optionValue="value" (onChange)="changeViewFunc($event)" class="view-icon-section2" *ngIf="tileView"></p-selectButton> -->
				<p-selectButton [options]="stateOptions" [(ngModel)]="listOrTileView" optionLabel="label"
                    optionValue="value" class="view-icon-section2" (onChange)="changeViewFunc($event)" *ngIf="tileView">
                    <ng-template let-option pTemplate="item">
                        <span [pTooltip]="option.tooltip">
                            <i [class]="option.icon"></i> 
                        </span>
                    </ng-template>
                </p-selectButton>
			<!-- </div> -->
		</div>
		<!--  -->
		<p-scrollPanel *ngIf="myReportRecords.length > 0 && tileView">
			<div class="p-grid p-nogutter">
				<div class="tibo-myrepo-tile-box p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-4"
					*ngFor="let reportObj of myReportRecords; let j = index;">

					<div class="box-class">
						<div class="p-grid p-col-12 p-ai-center vertical-container">
							<label>{{reportObj?.authorizationName.substring(0,
								reportObj?.authorizationName.lastIndexOf('.'))}}</label>
						</div>
						<div>
							<button *ngIf="reportObj?.isRunnable.toLowerCase() == 'y'" id="" class="p-mr-2"
								type="button" pButton icon="pi pi-play" pTooltip="Run Report" tooltipPosition="top"
								(click)="onRunButtonClick(reportObj?.authorizationId,reportObj?.authorizationName,reportObj?.reportType)"></button>
							<button id="" class="p-mr-2"
								type="button" pButton icon="pi pi-calendar-plus" pTooltip="Schedule Report"
								tooltipPosition="top"
								(click)="onScheduleBtnClick(reportObj?.authorizationId,reportObj?.authorizationName,reportObj?.authorizationDesc)"></button>
							<button id="" class="p-mr-2" type="button" pButton icon="pi pi-star"
								pTooltip="Add to Favorites" tooltipPosition="top"
								(click)="onAddToMyFavoritesBtnClick(reportObj?.authorizationId,reportObj?.authorizationName,reportObj?.authorizationDesc)"></button>
							<button id="" class="p-mr-2" type="button" pButton icon="pi pi-file"
								pTooltip="Show all output files" tooltipPosition="top"
								(click)="showAllOutputFilesBtnClick(reportObj?.authorizationId)"></button>

						</div>
					</div>

				</div>
			</div>
		</p-scrollPanel>
		<p-table #myreportstable [value]="myReportRecords" [scrollable]="true"
			[globalFilterFields]="['authorizationName','reportType']" (sortFunction)="customSort($event)"
			[customSort]="true" [paginator]="true" 
			currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rows]="250"
			[totalRecords]="totalRecords" [showCurrentPageReport]=true *ngIf="!tileView">
			<ng-template pTemplate="caption">
                <div class="table-header">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="myreportstable.filterGlobal($event.target.value, 'contains')" placeholder="Search" />
                    </span>
					<!-- <p-selectButton [options]="stateOptions" [(ngModel)]="listOrTileView" optionLabel="label"
				optionValue="value" (onChange)="changeViewFunc($event)" class="view-icon-section"></p-selectButton> -->
				<p-selectButton [options]="stateOptions" [(ngModel)]="listOrTileView" optionLabel="label"
                    optionValue="value" class="tile-list-view-btn" (onChange)="changeViewFunc($event)">
                    <ng-template let-option pTemplate="item">
                        <span [pTooltip]="option.tooltip">
                            <i [class]="option.icon"></i> 
                        </span>
                    </ng-template>
                </p-selectButton>
                </div>
				
            </ng-template>
			<ng-template pTemplate="header">
				<tr>
					<th pSortableColumn="authorizationName">
						<div class="p-d-flex p-jc-between p-ai-center">
							Report Name
							<!-- <p-sortIcon field="authorizationName"></p-sortIcon> -->
							<p-columnFilter type="text" field="authorizationName" display="menu"
								class="p-ml-auto"></p-columnFilter>
						</div>
					</th>
					<!-- <th pSortableColumn="reportType">
						<div class="p-d-flex p-jc-between p-ai-center">
							Report Type
							<p-sortIcon field="reportType"></p-sortIcon>
							<p-columnFilter type="text" field="reportType" display="menu"
								class="p-ml-auto"></p-columnFilter>
						</div>
					</th> -->
					<th>Actions</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-reportObj>
				<tr>
					<td>{{reportObj?.authorizationName.substring(0,
						reportObj?.authorizationName.lastIndexOf('.'))}}</td>
					<!-- <td>{{reportObj?.reportType}}</td> -->
					<td>
						<div class="tibo-myrepo-listview-btns-cont">
							<button *ngIf="reportObj?.isRunnable.toLowerCase() == 'y'" id="" class="p-mr-2"
								type="button" pButton icon="pi pi-play" pTooltip="Run Report" tooltipPosition="left"
								(click)="onRunButtonClick(reportObj?.authorizationId,reportObj?.authorizationName,reportObj?.reportType)"></button>
							<button id="" class="p-mr-2"
								type="button" pButton icon="pi pi-calendar-plus" pTooltip="Schedule Report"
								tooltipPosition="left"
								(click)="onScheduleBtnClick(reportObj?.authorizationId,reportObj?.authorizationName,reportObj?.authorizationDesc)"></button>
							<button id="" class="p-mr-2" type="button" pButton icon="pi pi-star"
								pTooltip="Add to Favorites" tooltipPosition="left"
								(click)="onAddToMyFavoritesBtnClick(reportObj?.authorizationId,reportObj?.authorizationName,reportObj?.authorizationDesc)"></button>
							<button id="" class="p-mr-2" type="button" pButton icon="pi pi-file"
								pTooltip="Show all output files" tooltipPosition="left"
								(click)="showAllOutputFilesBtnClick(reportObj?.authorizationId)"></button>
						</div>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage">
				<tr>
					<td style="text-align: center;">{{ tableEmptyMessage }}</td>
				</tr>
			</ng-template>
		</p-table>

	</div>
</div>