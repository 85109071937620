import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ScheduleHistoryService } from './schedule-history.service'
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { SortEvent } from 'primeng/api';
import { ViewChild } from '@angular/core';

import { Router } from "@angular/router";

declare var d3:any;

interface Status {
	filterStatus?: string;
	value?: string;
}

@Component({
	selector: 'app-schedule-history',
	templateUrl: './schedule-history.component.html',
	styleUrls: ['./schedule-history.component.css'],
	providers: [ScheduleHistoryService, MessageService],
	//encapsulation: ViewEncapsulation.
})
export class ScheduleHistoryComponent implements OnInit, OnDestroy {
	
	@ViewChild('schedulehistorytable', {static: true}) table: Table;
	
	statuses: Status[];
	
	constructor(private messageService: MessageService, private http: HttpClient, private scheduleHistoryService: ScheduleHistoryService, private router: Router) { }
	
	errorMsg = "";
	totalRecords = 0;
	scheduleHistoryRecords = [];
	requestPayload = { sort: [{property: "startTime", dir: "DESC"}], filters: [], filterType: "and"};
	tableSortColumn = "startTime";
	tableSortDir = -1;
	paginatorPageNo = 1;
	customSortBool:boolean = false;
	tableEmptyMessage:string = "";
	
	recordsSubscrip: Subscription;

	ngOnInit(): void {
		
		d3.select(".tibo-topbar-title").text("Home/Schedule History"); // setting title
		
		if(Number(d3.select("#tibo-large-leftpanel-container").node().getBoundingClientRect().left) == 0){
			setTimeout(function(){
				d3.select(".tibo-right-container").transition().delay(0).duration(0).style("left","200px").ease("linear");
			},10)
		}
		
		this.statuses = [
			{ filterStatus: "Running", value: "Running" },
			{ filterStatus: "Pending", value: "Pending" },
			{ filterStatus: "Failed", value: "Failed" },
			{ filterStatus: "Completed", value: "Completed" },
		];
		
		this.recordsSubscrip = this.scheduleHistoryService.recordsService(1,this.requestPayload).subscribe( (recordsResp: any) => { // records
				
				d3.select(".tibo-schedhist-reports-spinner").style("display","none") // hidding reports container spinner
				
				this.totalRecords = recordsResp.body?.totalRecords;
				recordsResp.body?.records ? this.scheduleHistoryRecords = recordsResp.body?.records : this.scheduleHistoryRecords = [];
				
				this.scheduleHistoryRecords.forEach(d => {
					if(d?.startTime != null){
						d.startTime = new Date(d?.startTime);
					}
					if(d?.endTime != null){
						d.endTime = new Date(d?.endTime);
					}
					switch (Number(d?.status)) {
						case 15:
							d.status = "Running";
							d["filterStatus"] = {filterStatus: "Running", value: "Running"}
							break;
						case 16:
							d.status = "Pending";
							d["filterStatus"] = {filterStatus: "Pending", value: "Pending"}
							break;
						case 17:
							d.status = "Failed";
							d["filterStatus"] = {filterStatus: "Failed", value: "Failed"}
							break;
						case 18:
							d.status = "Completed";
							d["filterStatus"] = {filterStatus: "Completed", value: "Completed"}
							break;
					}
				});
				
				this.scheduleHistoryRecords.length > 0 ? this.tableEmptyMessage = "" : this.tableEmptyMessage = "No records found";

				/*d3.select(".tibo-myrepo-reports-spinner").style("display","none") // hidding reports container spinner
				this.totalRecords = recordsResp.body.totalRecords;
				recordsResp.body?.records ? this.myReportRecords = recordsResp.body.records : this.myReportRecords = [];*/
				
		}, error => { 
			d3.select(".tibo-schedhist-reports-spinner").style("display","none") // hidding reports container spinner
			
			if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
			else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
			else { this.errorMsg = "Please try after some time." }
			
			this.addSingle();
		}, () => {})
	}
	
	ngOnDestroy() {
		if(this.recordsSubscrip){
			this.recordsSubscrip.unsubscribe();
		}
	}
	
	theadClick(event){
		event.target.attributes.class.value.includes("pi-filter-icon") ? this.customSortBool = false : this.customSortBool = true;
	}

	customSort(event: SortEvent){
		
		this.tableSortColumn = event.field;
		this.tableSortDir = event.order;
		
		this.table.reset();
		this.table.sortField = this.tableSortColumn;
		this.table._sortOrder = this.tableSortDir;

		if(this.customSortBool){

			this.customSortBool = false;

			d3.select(".tibo-schedhist-reports-spinner").style("display","block") // showing reports container spinner
			
			this.requestPayload.sort = [];
			
			if(event.order == 1){
				this.requestPayload.sort.push({ property: event.field, dir: "ASC"})
			}
			else if(event.order == -1){
				this.requestPayload.sort.push({ property: event.field, dir: "DESC"})
			}
			
			this.recordsSubscrip = this.scheduleHistoryService.recordsService(this.paginatorPageNo,this.requestPayload).subscribe( (recordsResp: any) => { // records
				//console.log(recordsResp)
				/*this.totalRecords = recordsResp.body?.totalRecords;
				recordsResp.body?.records ? this.scheduledReportRecords = recordsResp.body?.records : this.scheduledReportRecords = [];
				
				this.scheduledReportRecords.forEach(d => d.jobCreatedDate = new Date(d.jobCreatedDate));*/
				
				d3.select(".tibo-schedhist-reports-spinner").style("display","none") // hidding reports container spinner
				
				let sortedData = [];
				recordsResp.body?.records ? sortedData = recordsResp.body.records : sortedData = [];
				
				sortedData.forEach(d => {
					if(d?.startTime != null){
						d.startTime = new Date(d?.startTime);
					}
					if(d?.endTime != null){
						d.endTime = new Date(d?.endTime);
					}
					switch (Number(d?.status)) {
						case 15:
							d.status = "Running";
							d["filterStatus"] = {filterStatus: "Running", value: "Running"}
							break;
						case 16:
							d.status = "Pending";
							d["filterStatus"] = {filterStatus: "Pending", value: "Pending"}
							break;
						case 17:
							d.status = "Failed";
							d["filterStatus"] = {filterStatus: "Failed", value: "Failed"}
							break;
						case 18:
							d.status = "Completed";
							d["filterStatus"] = {filterStatus: "Completed", value: "Completed"}
							break;
					}
				});
				
				event.data.splice(0, event.data.length)
				
				sortedData.forEach(d => { event.data.push(d) })
				
				/*d3.select(".tibo-myrepo-reports-spinner").style("display","none") // hidding reports container spinner
				this.totalRecords = recordsResp.body.totalRecords;
				recordsResp.body?.records ? this.myReportRecords = recordsResp.body.records : this.myReportRecords = [];*/
					
			}, error => { 
				d3.select(".tibo-schedhist-reports-spinner").style("display","none") // hidding reports container spinner
				
				if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
				else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
				else { this.errorMsg = "Please try after some time." }
				
				this.addSingle();
			}, () => {})
		}
	}
	
	paginate(event) {
		
		this.customSortBool = false;

		d3.select(".tibo-schedhist-reports-spinner").style("display","block") // showing reports container spinner
		
		this.paginatorPageNo = event.page + 1;
		
		this.scheduleHistoryRecords = [];
		
		this.recordsSubscrip = this.scheduleHistoryService.recordsService(this.paginatorPageNo,this.requestPayload).subscribe( (recordsResp: any) => { // records
			//console.log(recordsResp)
			
			d3.select(".tibo-schedhist-reports-spinner").style("display","none") // hidding reports container spinner
			
			recordsResp.body?.records ? this.scheduleHistoryRecords = recordsResp.body?.records : this.scheduleHistoryRecords = [];
				
			this.scheduleHistoryRecords.forEach(d => {
				if(d?.startTime != null){
					d.startTime = new Date(d?.startTime);
				}
				if(d?.endTime != null){
					d.endTime = new Date(d?.endTime);
				}
				switch (Number(d?.status)) {
					case 15:
						d.status = "Running";
						d["filterStatus"] = {filterStatus: "Running", value: "Running"}
						break;
					case 16:
						d.status = "Pending";
						d["filterStatus"] = {filterStatus: "Pending", value: "Pending"}
						break;
					case 17:
						d.status = "Failed";
						d["filterStatus"] = {filterStatus: "Failed", value: "Failed"}
						break;
					case 18:
						d.status = "Completed";
						d["filterStatus"] = {filterStatus: "Completed", value: "Completed"}
						break;
				}
			});
			
			
			/*d3.select(".tibo-myrepo-reports-spinner").style("display","none") // hidding reports container spinner
			this.totalRecords = recordsResp.body.totalRecords;
			recordsResp.body?.records ? this.myReportRecords = recordsResp.body.records : this.myReportRecords = [];*/
				
		}, error => { 
			d3.select(".tibo-schedhist-reports-spinner").style("display","none") // hidding reports container spinner
			
			if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
			else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
			else { this.errorMsg = "Please try after some time." }
			
			this.addSingle();
		}, () => {})
			
	}
	
	filterBtnClick(){
		d3.selectAll(".tibo-sche-hist-fltr-multiSelect").selectAll("div.p-component").style("padding","2px 0px 4px 0px")
		d3.selectAll(".tibo-sche-hist-fltr-multiSelect").selectAll("div.p-multiselect-trigger").style("padding","3px 0px")
	}
	
	addSingle() { this.messageService.add({severity:'error', summary:'', detail:this.errorMsg}); }

    clear() { this.messageService.clear(); }

}
