<div class="tibo-schedrepo-container-wrapper tibo-right-container">
	<div id="schedrepo-scroll-wrapper" class="tibo-schedrepo-scroll-wrapper">
		<div class="tibo-schedrepo-tile-list-scrolview-wrapper">
		
			<!-- <p-toast styleClass="tibo-schedrepo-toast" position="center"></p-toast> -->
			
			<p-progressSpinner styleClass="tibo-schedrepo-reports-spinner custom-spinner" animationDuration="2s"></p-progressSpinner>
			
			<div class="tibo-schedrepo-reports-listview-cont"> <!--Children styling available in .css file-->
					<p-table #scheduledreportstable [value]="scheduledReportRecords" [sortField]="tableSortColumn" [sortOrder]="tableSortDir" [scrollable]="true" scrollHeight="flex" 
					[globalFilterFields]="['name','reportName','jobCreatedDate','isActive','lastRun','nextRun']" (sortFunction)="customSort($event)" [customSort]="true">
						
						<ng-template pTemplate="caption">
							<div class="p-d-flex p-jc-between p-ai-center">
								<span class="p-input-icon-left p-ml-auto">
									<i class="pi pi-search"></i>
									<input pInputText type="text" (input)="scheduledreportstable.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" />
								</span>
								<label class="p-mx-2">Showing {{scheduledReportRecords?.length}} of {{totalRecords}}</label>
							</div>
						</ng-template>
							
						<ng-template pTemplate="header">
							<tr>
								<th pSortableColumn="name" (click)="theadClick($event)">
									<div class="p-d-flex p-jc-between p-ai-center">
										Job Name
										<p-sortIcon field="name"></p-sortIcon>
										<p-columnFilter type="text" field="name" display="menu" class="p-ml-auto"></p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="reportName" (click)="theadClick($event)">
									<div class="p-d-flex p-jc-between p-ai-center">
										Report Name
										<p-sortIcon field="reportName"></p-sortIcon>
										<p-columnFilter type="text" field="reportName" display="menu" class="p-ml-auto"></p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="jobCreatedDate" (click)="theadClick($event)">
									<div class="p-d-flex p-jc-between p-ai-center">
										Created Date
										<p-sortIcon field="jobCreatedDate"></p-sortIcon>
										<p-columnFilter type="date" field="jobCreatedDate" display="menu" class="p-ml-auto"></p-columnFilter>
									</div>
								</th>
								<th>
									<!--<p-columnFilter type="text" field="isActive" display="menu" class="p-ml-auto"></p-columnFilter>-->
									<div class="p-d-flex p-jc-between p-ai-center">
										Status
										<p-columnFilter field="status" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" [showApplyButton]="false" [showClearButton]="false" class="p-ml-auto" (click)="filterBtnClick()">
											<ng-template pTemplate="filter" let-value let-filter="filterCallback">
												<p-multiSelect class="tibo-sche-repos-fltr-multiSelect" styleClass="prime-slider-override" [ngModel]="value" [options]="statuses" placeholder="Select Status" optionLabel="isActive" [filter]="false" (onChange)="filter($event.value)">
													<ng-template let-option>
														<div>
															<span class="p-ml-1">{{option.isActive}}</span>
														</div>
													</ng-template>
												</p-multiSelect>
											</ng-template>
										</p-columnFilter>
									</div>
								</th>
								<th>
									<div class="p-d-flex p-jc-between p-ai-center">
										Last Run Date/Time
										<p-columnFilter type="date" field="lastRun" display="menu" class="p-ml-auto"></p-columnFilter>
									</div>
								</th>
								<th>
									<div class="p-d-flex p-jc-between p-ai-center">
										Next Run Date/Time
										<p-columnFilter type="date" field="nextRun" display="menu" class="p-ml-auto"></p-columnFilter>
									</div>
								</th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-col>
							<tr>
								<td>{{col?.name}}</td>
								<td>{{col?.reportName}}</td>
								<td>{{col?.jobCreatedDate | date:'MM/dd/YYYY h:mm a'}}</td>
								<td>{{col?.isActive === "Y" ? "Active" : "InActive"}}</td>
								<td>{{col?.lastRun | date:'MM/dd/YYYY h:mm a'}}</td>
								<td>{{col?.nextRun | date:'MM/dd/YYYY h:mm a'}}</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td>{{ tableEmptyMessage }}</td>
							</tr>
						</ng-template>

					</p-table>
			</div>
			
			<p-paginator styleClass="tibo-schedrepo-listView-p-paginator" [rows]="250" [totalRecords]="totalRecords" (onPageChange)="paginate($event)"></p-paginator>
		</div>
	</div>
</div>
