import { Component, OnInit, OnDestroy } from '@angular/core';
import { MyReportsOutputService } from './my-reports-output.service'
import { Subscription } from 'rxjs';
import { Message,MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Table } from 'primeng/table';
import { SortEvent } from 'primeng/api';
import { ViewChild } from '@angular/core'

import { ActivatedRoute } from '@angular/router'

import { MenuItem } from 'primeng/api';

declare var d3:any;

@Component({
	selector: 'app-my-reports-output',
	templateUrl: './my-reports-output.component.html',
	styleUrls: ['./my-reports-output.component.css'],
	providers: [MyReportsOutputService, MessageService]
})

export class MyReportsOutputComponent implements OnInit, OnDestroy {
	
	@ViewChild('myreportssqloutputtable', {static: true}) table: Table;
	
	constructor(private messageService: MessageService, public activatedRoute: ActivatedRoute, private myRepoOutputService: MyReportsOutputService, private location: Location, private router: Router) { }
	
	reportId?: any;
	reportName?: any;
	reportType?: any;
	reportOutput?: any;
	reportOutputStr = "";
	birtDocumentName = "";
	sqlTotalRecords?: any;
	sqlTableColumns?: any;
	parameterElementsArray = [];
	requestPayloadParameterObj = { parameters: {} };
	repoCritPanelToggleBool: boolean = false;
	downloadTypeList: MenuItem[];
	downloadTypeListArray = [];
	dwnlRepoSerRequestPayload = { sort: [], filters: [], filterType: "and", parameters: {} };
	paginatorPageNo = 1;
	sqlTableSortColumn = undefined;
	sqlTableSortDir?: any;
	globalFilterFieldsArr = [];

	reportIdSubscrip: Subscription;
	reportParamSerSubscrip: Subscription;
	getReportSerSubscrip: Subscription;

	alertMessage: Message[];
	alertSeverity:string = "error";
	alertMsg:string = "";
	
	ngOnInit(): void {

		// if(Number(d3.select("#tibo-large-leftpanel-container").node().getBoundingClientRect().left) == 10){
		// 	setTimeout(function(){
		// 		d3.select(".tibo-right-container").transition().delay(0).duration(0).style("left","200px").ease("linear");
		// 	},10)
		// }
		
		this.reportIdSubscrip = this.activatedRoute.queryParams.subscribe(params => {
			
			this.reportId = params.id;
			this.reportType = params.type;
			this.reportName = params.name;
			
			let obj = {}, hasDrillKey = false;
			
			for (let key in params) {
				if(key.substr(0, 6) == "drill_"){
					hasDrillKey = true;
					let sub = key.substr(6, key.length)
					obj[sub] = params[key];
				}
			}
			
			if(hasDrillKey){
				
				d3.select("#myrepo-output-parameters-panel").style("display","none")//.style("pointer-events","none")
				d3.select("#myrepo-output-container").style("left","0px").style("width","100%")
				
				this.reportParamSerSubscrip = this.myRepoOutputService.reportParameterService(params.id).subscribe( (response: any) => { // report parameters 
					this.parameterElementsArray = response.body?.reportInputControl;
					
					response.body.exportType.forEach(expType => {
						
						this.downloadTypeListArray.push({ label:expType.toUpperCase(), command: (event) => { this.downloadReportFunc(event); } });
						
						if(expType.toLowerCase() == "xlsx"){
							this.downloadTypeListArray.push({ label:'Save XLSX to Inbox', command: (event) => { this.downloadReportFunc(event); } });
						}
						else if(expType.toLowerCase() == "pdf"){
							this.downloadTypeListArray.push({ label:'Save PDF to Inbox', command: (event) => { this.downloadReportFunc(event); } });
						}
						else if(expType.toLowerCase() == "csv"){
							this.downloadTypeListArray.push({ label:'Save CSV to Inbox', command: (event) => { this.downloadReportFunc(event); } });
						}
						
					})
					
					this.downloadTypeList = this.downloadTypeListArray;
					
				}, error => { 
					this.alertSeverity = "error";
					if(Number(error.status) == 401){ this.alertMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.alertMsg = "Internal error" }
					else { this.alertMsg = "Please try after some time." }
					this.addSingle();
				}, () => {})
				
				
				this.myRepoOutputService.reportDrillDownService(params.id, {parameters: obj}).subscribe( (drillDownResp: any) => { // report output drill down 
												
					drillDownResp.body.output = drillDownResp.body.output.replace(/target=\"_self\"/gi, 'target=\"_top\"')
					drillDownResp.body.output = drillDownResp.body.output.replace(/target=\'_self\'/gi, 'target=\"_top\"')
					
					const iframe =  document.getElementById('report-output-birt') as HTMLIFrameElement;
					iframe.setAttribute("srcdoc",drillDownResp.body.output)
					iframe.style.background = 'white';
					iframe.contentWindow.document.body.style.backgroundColor = 'white';
					
					//console.log.log(drillDownResp);
					
				}, error => { 
					this.alertSeverity = "error";
					if(Number(error.status) == 401){ this.alertMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.alertMsg = "Internal error" }
					else { this.alertMsg = "Please try after some time." }
					this.addSingle();
				}, () => {})
			}
			else {
				
				let hasParameter = false;
				
				this.reportParamSerSubscrip = this.myRepoOutputService.reportParameterService(params.id).subscribe( (response: any) => { // report parameters 
					this.parameterElementsArray = response.body?.reportInputControl
					//console.log.log(response.body.exportType);
					
					response.body.exportType.forEach(expType => {
						
						this.downloadTypeListArray.push({ label:expType.toUpperCase(), command: (event) => { this.downloadReportFunc(event); } });
						
						if(expType.toLowerCase() == "xlsx"){
							this.downloadTypeListArray.push({ label:'Save XLSX to Inbox', command: (event) => { this.downloadReportFunc(event); } });
						}
						else if(expType.toLowerCase() == "pdf"){
							this.downloadTypeListArray.push({ label:'Save PDF to Inbox', command: (event) => { this.downloadReportFunc(event); } });
						}
						else if(expType.toLowerCase() == "csv"){
							this.downloadTypeListArray.push({ label:'Save CSV to Inbox', command: (event) => { this.downloadReportFunc(event); } });
						}
						
					})
					
					this.downloadTypeList = this.downloadTypeListArray;
					
					/*this.downloadTypeList = [
						{label:'XLSX', id:'', command: (event) => { this.logoutFunc(event); }},
						{label:'Save XLSX to Inbox', id:'', command: (event) => { this.logoutFunc(event); }}
					]*/
					
					for (let i = 0; i < response.body.reportInputControl.length; i++) {
						if(response.body.reportInputControl[i].visible){
							hasParameter = true;
							break;
						}
					}
					
					if(!hasParameter){
						d3.select("#myrepo-output-parameters-panel").style("display","none")//.style("pointer-events","none")
						d3.select("#myrepo-output-container").style("left","0px").style("width","100%")
						this.getReportDataFunc();
					}
					
				}, error => { 
					this.alertSeverity = "error";
					if(Number(error.status) == 401){ this.alertMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.alertMsg = "Internal error" }
					else { this.alertMsg = "Please try after some time." }
					this.addSingle();
				}, () => {})
			}
			
		});
		
	}
	
	ngOnDestroy() {
		if(this.reportIdSubscrip){
			this.reportIdSubscrip.unsubscribe();
		}
		if(this.reportParamSerSubscrip){
			this.reportParamSerSubscrip.unsubscribe();
		}
		if(this.getReportSerSubscrip){
			this.getReportSerSubscrip.unsubscribe();
		}
			
	}
	
	onChange(event,refEl) {
		
		if(refEl != undefined){
			if(refEl.el.nativeElement.nodeName == "P-CALENDAR"){
				
				var value = ("0" + (event.getMonth() + 1)).slice(-2) + "/" + ("0" + event.getDate()).slice(-2) + "/" + event.getFullYear();
				var obj = this.parameterElementsArray[Number(refEl.el.nativeElement.attributes.selectInfoIndex.value)];
				
				obj.paramElementFilledBool = true;
				
				this.requestPayloadParameterObj.parameters[obj.id] = value;
				
			}
			else if(refEl.el.nativeElement.nodeName == "P-MULTISELECT"){
				
				var obj = this.parameterElementsArray[Number(refEl.el.nativeElement.attributes.selectInfoIndex.value)];
				
				if(event.value.length == 0){ obj.paramElementFilledBool = false; }
				else { obj.paramElementFilledBool = true; }
				
				this.requestPayloadParameterObj.parameters[obj.id] = event.value;
				
			}
			else if(refEl.el.nativeElement.nodeName == "P-DROPDOWN"){
				
				var value1 = event.value
				var obj = this.parameterElementsArray[Number(refEl.el.nativeElement.attributes.selectInfoIndex.value)];
				
				obj.paramElementFilledBool = true;
				
				this.requestPayloadParameterObj.parameters[obj.id] = value1;
				
			}
		}
		else if(refEl == undefined){
			
			var value1 = event.target.value;
			var obj = this.parameterElementsArray[Number(event.target.attributes.selectInfoIndex.value)]
			
			if(event.target.type == "text"){
				if(event.target.value.length == 0) { obj.paramElementFilledBool = false;}
				else{ obj.paramElementFilledBool = true; }
			}
			
			this.requestPayloadParameterObj.parameters[obj.id] = value1;
			
		}
	} // onChange function
	
	onFilterSubmitClick(){
		
		let showAlertBool:Boolean = false;
		
		this.parameterElementsArray.forEach((ele) => {
			
			if(ele.visible == true && ele.mandatory == true && ele.paramElementFilledBool != true){
				showAlertBool = true;
			}
		})
		
		if(showAlertBool == true){
			this.addMessages();
		}
		else {
			
			this.clearMessages();

			d3.select(".tibo-myrepo-output-spinner").style("display","block"); // Showing report output spinner

			d3.selectAll("#myrepo-output-paramspanl-scrollpanel, #submitbutton-id").transition().delay(200).duration(400).style("display","none")
			d3.select("#myrepo-output-parameters-panel").transition().delay(200).duration(500).style("width","40px")
			d3.select("#myrepo-output-container").transition().delay(200).duration(500).style("left","50px")//.style("width","calc(100% - 50px)")
			d3.select("#myrepo-output-parameters-panel").select("button").style("transform","rotate(180deg)")
			d3.select("#report-criteria-id").transition().delay(100).duration(100)
			.style({'left':'-25px','top':'85px'}).style("transform","rotate(-90deg)")
			this.repoCritPanelToggleBool = true;
			
			if(this.reportType.toLowerCase() == "sql"){
				
				this.requestPayloadParameterObj["filterType"] = "and";
				this.requestPayloadParameterObj["filters"] = [];
				this.requestPayloadParameterObj["sort"] = [];
				
				this.reportOutput = [];
				
				this.getReportSerSubscrip = this.myRepoOutputService.getReportService(this.reportId, this.requestPayloadParameterObj, this.reportType, 1).subscribe( (response: any) => { // report parameters 
					this.reportOutput = response.body.records;
					this.sqlTotalRecords = response.body.totalRecords;
					this.sqlTableColumns = response.body.headers;
					
					d3.select(".tibo-myrepo-output-spinner").style("display","none"); // Hidding report output spinner
					document.getElementById("report-output-sql").style.display = "block";
				
				}, error => { 
					d3.select(".tibo-myrepo-output-spinner").style("display","none"); // Hidding report output spinner
					this.alertSeverity = "error";
					if(Number(error.status) == 401){ this.alertMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.alertMsg = "Internal error" }
					else { this.alertMsg = "Please try after some time." }
					
					this.addSingle();
				}, () => {})
			}
			else {
				this.getReportSerSubscrip = this.myRepoOutputService.getReportService(this.reportId, this.requestPayloadParameterObj, this.reportType, 1).subscribe( (response: any) => { // report parameters 
					
					d3.select(".tibo-myrepo-output-spinner").style("display","none"); // Hidding report output spinner

					this.birtDocumentName = response.body.document;
					this.reportOutputStr = response.body.output;
					
					const iframe =  document.getElementById('report-output-birt') as HTMLIFrameElement;
					//iframe.setAttribute("srcdoc",this.reportOutputStr.replace(/target=\"_self\"/gi, 'target=\"_top\"').replace(/target=\'_self\'/gi, 'target=\"_top\"'))
					this.reportOutputStr = this.reportOutputStr.replace(/target=\"_self\"/gi, 'target=\"_top\"')
					this.reportOutputStr = this.reportOutputStr.replace(/target=\'_self\'/gi, 'target=\"_top\"')
					//this.reportOutputStr = this.reportOutputStr.replace(/target=\"_parent\"/gi, 'target=\"\"')
					iframe.setAttribute("srcdoc",this.reportOutputStr)
					iframe.style.background = 'white';
					iframe.contentWindow.document.body.style.backgroundColor = 'white';
					
					
					/*iframe.onload = function() {
						////console.log.log(d3.select(iframe.contentDocument.body).selectAll("[href]"))
						d3.select(iframe.contentDocument.body).selectAll("a[href], area[href], polygon[href], ellipse[href]").on("click",function(e){
							////console.log.log(d3.event.target.href != undefined);
							let href = d3.event.target.href
							let target = d3.event.target.target
							////console.log.log(d3.event.target.target)
							if(d3.event.target.href != undefined){
								
								d3.event.preventDefault()
								
								try {
										let decodedURL = decodeURI(d3.event.target.href);
										////console.log.log(decodedURL)
										let obj = {}, hasDrillKey = false;
										
										decodedURL.split('&').forEach(function(d){
											if(d.substr(0, 6) == "drill_"){
												////console.log.log(d.substr(0, 6) == "drill_")
												//var a = "{" + d.substr(6, d.length).replace("=",":") + "}"
												let sub = d.substr(6, d.length)
												////console.log.log(sub.substr(0,sub.indexOf("=")).replace(%20/g," "), sub.substr((sub.indexOf("=") + 1) , sub.length).replace(%20/g," "))
												////console.log.log(decodeURI(sub.substr(0,sub.indexOf("="))))
												//let obj = '{"' + sub.substr(0,sub.indexOf("=")) + '":' + '"' + sub.substr((sub.indexOf("=") + 1) , sub.length) + '"}'
												
												obj[sub.substr(0,sub.indexOf("="))] = sub.substr((sub.indexOf("=") + 1) , sub.length);
												////console.log.log(obj)
												
												////console.log.log(JSON.parse(obj))
												//globalServiceVar.reportDrillDownService(globalClass.reportId, {parameters: JSON.parse(obj)}).subscribe( (drillDownResp: any) => { // report output drill down 
													////console.log.log(drillDownResp);
													//iframe.setAttribute("srcdoc", drillDownResp.body.output)
													
												//})
												hasDrillKey = true;
											}
										})
										
										if(hasDrillKey){
											//console.log.log(obj)
											
											globalServiceVar.reportDrillDownService(globalClass.reportId, {parameters: obj}).subscribe( (drillDownResp: any) => { // report output drill down 
												
												drillDownResp.body.output = drillDownResp.body.output.replace(/target=\"_self\"/gi, 'target=\"_top\"')
												drillDownResp.body.output = drillDownResp.body.output.replace(/target=\'_self\'/gi, 'target=\"_top\"')
												
												localStorage.setItem("my-reports-document", drillDownResp.body.output);
												
												//console.log.log(drillDownResp);
												//window.location = href;
												window.open(href,target);
											})
										}
										else {
											window.open(href,target);
										}
										
									} catch (e) { 
								  //console.log.error(e);
								} // catch block
								
							}
							
						})
					}*/
    
					/*d3.select("html").on("click",function(){
						//console.log.log(d3.event);
					})*/
					
				}, error => { 
					d3.select(".tibo-myrepo-output-spinner").style("display","none"); // Hidding report output spinner
					this.alertSeverity = "error";
					if(Number(error.status) == 401){ this.alertMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.alertMsg = "Internal error" }
					else { this.alertMsg = "Please try after some time." }
					
					this.addSingle();
				}, () => {})
				
			} // birt report else block
			
		} // showAlertBool else block
	
	} // onFilterSubmitClick
	
	getReportDataFunc(){
		
		d3.select(".tibo-myrepo-output-spinner").style("display","block"); // Showing report output spinner
		
		if(this.reportType.toLowerCase() == "sql"){
			this.requestPayloadParameterObj["filterType"] = "and";
			this.requestPayloadParameterObj["filters"] = [];
			this.requestPayloadParameterObj["sort"] = [];
			
			this.reportOutput = [];
			
			this.getReportSerSubscrip = this.myRepoOutputService.getReportService(this.reportId, this.requestPayloadParameterObj, this.reportType, 1).subscribe( (response: any) => { // report parameters 
				this.reportOutput = response.body.records;
				this.sqlTotalRecords = response.body.totalRecords;
				this.sqlTableColumns = response.body.headers;
				
				this.globalFilterFieldsArr = this.sqlTableColumns.map(obj => obj.header);
				
				d3.select(".tibo-myrepo-output-spinner").style("display","none"); // Hidding report output spinner

				document.getElementById("report-output-sql").style.display = "block";
			
			}, error => { 
				d3.select(".tibo-myrepo-output-spinner").style("display","none"); // Hidding report output spinner
				this.alertSeverity = "error";
				if(Number(error.status) == 401){ this.alertMsg = "Please try after some time!" }
				else if(Number(error.status) == 500){ this.alertMsg = "Internal error" }
				else { this.alertMsg = "Please try after some time." }
				
				this.addSingle();
			}, () => {})
		}
		else {
			this.getReportSerSubscrip = this.myRepoOutputService.getReportService(this.reportId, this.requestPayloadParameterObj, this.reportType, 1).subscribe( (response: any) => { // report parameters 
				
				d3.select(".tibo-myrepo-output-spinner").style("display","none"); // Hidding report output spinner

				this.birtDocumentName = response.body.document;
				this.reportOutputStr = response.body.output;
				
				const iframe =  document.getElementById('report-output-birt') as HTMLIFrameElement;
				
				this.reportOutputStr = this.reportOutputStr.replace(/target=\"_self\"/gi, 'target=\"_top\"')
				this.reportOutputStr = this.reportOutputStr.replace(/target=\'_self\'/gi, 'target=\"_top\"')
				iframe.setAttribute("srcdoc",this.reportOutputStr)
				iframe.style.background = 'white';
				iframe.contentWindow.document.body.style.backgroundColor = 'white';
				
			}, error => { 
				d3.select(".tibo-myrepo-output-spinner").style("display","none"); // Hidding report output spinner
				this.alertSeverity = "error";
				if(Number(error.status) == 401){ this.alertMsg = "Please try after some time!" }
				else if(Number(error.status) == 500){ this.alertMsg = "Internal error" }
				else { this.alertMsg = "Please try after some time." }
				
				this.addSingle();
			}, () => {})
						
		} // birt report else block
	}
	
	paginate(event) {
		
		var showAlertBool:Boolean = false;
		
		this.parameterElementsArray.forEach((ele) => {
			
			if(ele.visible == true && ele.mandatory == true && ele.paramElementFilledBool != true){
				showAlertBool = true;
			}
		})
		
		if(showAlertBool == true){
			this.addMessages();
		}
		else {
			
			this.clearMessages();

			d3.select(".tibo-myrepo-output-spinner").style("display","block"); // Showing report output spinner

			this.paginatorPageNo = event.page + 1;
			
			this.requestPayloadParameterObj["filterType"] = "and";
			this.requestPayloadParameterObj["filters"] = [];
			this.requestPayloadParameterObj["sort"] = [];
			
			//this.table.clear();
			this.reportOutput = [];
			
			this.getReportSerSubscrip = this.myRepoOutputService.getReportService(this.reportId, this.dwnlRepoSerRequestPayload, this.reportType, (event.page + 1)).subscribe( (response: any) => { // report parameters 
				//this.reportOutput = response.body.records;

				d3.select(".tibo-myrepo-output-spinner").style("display","none"); // Hidding report output spinner

				response.body?.records ? this.reportOutput = response.body.records : this.reportOutput = [];
				
			}, error => { 
				d3.select(".tibo-myrepo-output-spinner").style("display","none"); // Hidding report output spinner
				this.alertSeverity = "error";
				if(Number(error.status) == 401){ this.alertMsg = "Please try after some time!" }
				else if(Number(error.status) == 500){ this.alertMsg = "Internal error" }
				else { this.alertMsg = "Please try after some time." }
				
				this.addSingle();
			}, () => {})
			
		}
	}
	
	repoCriteriaPanelToggle() {
		//this.files = this.directoryArray;
		if(this.repoCritPanelToggleBool == false){
			d3.selectAll("#myrepo-output-paramspanl-scrollpanel, #submitbutton-id").transition().delay(200).duration(400).style("display","none")
			d3.select("#myrepo-output-parameters-panel").transition().delay(200).duration(500).style("width","40px")
			d3.select("#myrepo-output-container").transition().delay(200).duration(500).style("left","50px")//.style("width","calc(100% - 50px)")
			d3.select("#myrepo-output-parameters-panel").select("button").style("transform","rotate(180deg)")
			d3.select("#report-criteria-id").transition().delay(100).duration(100)
			.style({'left':'-25px','top':'85px'}).style("transform","rotate(-90deg)")
			this.repoCritPanelToggleBool = true;
		}
		else {
			d3.select("#myrepo-output-paramspanl-scrollpanel").transition().delay(300).duration(400).style("display","block")
			d3.select("#submitbutton-id").transition().delay(500).duration(400).style("display","block")
			d3.select("#myrepo-output-parameters-panel").transition().delay(200).duration(500).style("width","350px")
			d3.select("#myrepo-output-container").transition().delay(200).duration(500).style("left","360px")//.style("width","calc(100% - 360px)")
			d3.select("#myrepo-output-parameters-panel").select("button").style("transform","rotate(0deg)")
			d3.select("#report-criteria-id").transition().delay(250).duration(100)
			.style({'left':'10px','top':'10px'}).style("transform","rotate(0deg)")
			this.repoCritPanelToggleBool = false;
		}
	}
	
	customSort(event: SortEvent){ // sql-report table sorting function
		
		d3.select(".tibo-myrepo-output-spinner").style("display","block"); // Showing report output spinner

		this.dwnlRepoSerRequestPayload.sort = [];
		
		if(event.order == 1){
			this.dwnlRepoSerRequestPayload.sort.push({ property: event.field, dir: "ASC"})
		}
		else if(event.order == -1){
			this.dwnlRepoSerRequestPayload.sort.push({ property: event.field, dir: "DESC"})
		}
		
		this.sqlTableSortColumn = event.field;
		this.sqlTableSortDir = event.order;
		
		this.getReportSerSubscrip = this.myRepoOutputService.getReportService(this.reportId, this.dwnlRepoSerRequestPayload, this.reportType, this.paginatorPageNo).subscribe( (response: any) => { // report parameters 
			//this.reportOutput = response.body.records;

			d3.select(".tibo-myrepo-output-spinner").style("display","none"); // Hidding report output spinner

			let sortedData = [];
			response.body?.records ? sortedData = response.body.records : sortedData = [];
			
			event.data.splice(0, event.data.length)
			
			sortedData.forEach(d => { event.data.push(d) })
			
		}, error => { 
			d3.select(".tibo-myrepo-output-spinner").style("display","none"); // Hidding report output spinner
			this.alertSeverity = "error";
			if(Number(error.status) == 401){ this.alertMsg = "Please try after some time!" }
			else if(Number(error.status) == 500){ this.alertMsg = "Internal error" }
			else { this.alertMsg = "Please try after some time." }
			
			this.addSingle();
		}, () => {})
		
		/*event.data.sort((data1, data2) => {
            let value1 = data1[event.field];
            let value2 = data2[event.field];
            let result = null;

            if (value1 == null && value2 != null)
                result = -1;
            else if (value1 != null && value2 == null)
                result = 1;
            else if (value1 == null && value2 == null)
                result = 0;
            else if (typeof value1 === 'string' && typeof value2 === 'string')
                result = value1.localeCompare(value2);
            else
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
			
            return (event.order * result);
        });*/
		
	}
	
	downloadReportFunc(event) {
		
		if(this.reportType.toLowerCase() == "sql"){
		
			if(event.item.label == "XLS"){
				this.myRepoOutputService.XLSReportDownloadService(this.reportId, this.dwnlRepoSerRequestPayload, undefined).subscribe( (response: any) => { // XLS report download unique name service
				
					this.myRepoOutputService.XLSReportDownloadService(this.reportId, this.dwnlRepoSerRequestPayload, "uniqueName="+response.body.uniqueName).subscribe( (repoResp: any) => { // XLSX report download service
					
						let blob = new Blob([repoResp.body],{type:'application/vnd.ms-excel'});
						
						var a = document.createElement("a");
						a.href = URL.createObjectURL(blob);
						a.download = this.reportName + ".xls";
						a.click();
					
					}, error => { 
						this.alertSeverity = "error";
						if(Number(error.status) == 401){ this.alertMsg = "Please try after some time!" }
						else if(Number(error.status) == 500){ this.alertMsg = "Internal error" }
						else { this.alertMsg = "Please try after some time." }
						
						this.addSingle();
					}, () => {})
					
				}, error => { 
					this.alertSeverity = "error";
					if(Number(error.status) == 401){ this.alertMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.alertMsg = "Internal error" }
					else { this.alertMsg = "Please try after some time." }
					
					this.addSingle();
				}, () => {})
			}
			else if(event.item.label == "XLSX"){
				
				this.myRepoOutputService.XLSXReportDownloadService(this.reportId, this.dwnlRepoSerRequestPayload, undefined).subscribe( (response: any) => { // XLSX report download unique name service
					
					this.myRepoOutputService.XLSXReportDownloadService(this.reportId, this.dwnlRepoSerRequestPayload, "uniqueName="+response.body.uniqueName).subscribe( (repoResp: any) => { // XLSX report download service
					
						let blob = new Blob([repoResp.body],{type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
						
						var a = document.createElement("a");
						a.href = URL.createObjectURL(blob);
						a.download = this.reportName + ".xlsx";
						a.click();
						
					}, error => { 
						this.alertSeverity = "error";
						if(Number(error.status) == 401){ this.alertMsg = "Please try after some time!" }
						else if(Number(error.status) == 500){ this.alertMsg = "Internal error" }
						else { this.alertMsg = "Please try after some time." }
						
						this.addSingle();
					}, () => {})
					
				}, error => { 
					this.alertSeverity = "error";
					if(Number(error.status) == 401){ this.alertMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.alertMsg = "Internal error" }
					else { this.alertMsg = "Please try after some time." }
					
					this.addSingle();
				}, () => {})
			}
			else if(event.item.label == "Save XLSX to Inbox"){
				
				this.myRepoOutputService.XLSXReportSaveToInboxService(this.reportId, this.dwnlRepoSerRequestPayload, undefined).subscribe( (response: any) => { // XLSX report save to inbox unique name service
				
					this.myRepoOutputService.XLSXReportSaveToInboxService(this.reportId, this.dwnlRepoSerRequestPayload, "uniqueName="+response.body.uniqueName).subscribe( (repoResp: any) => { // XLSX report save to inbox service
						if(repoResp.status == 200){
							this.alertSeverity = "success"
							this.alertMsg = "Saved to Inbox!";
							this.addSingle();
						}
					}, error => { 
						this.alertSeverity = "error";
						if(Number(error.status) == 401){ this.alertMsg = "Please try after some time!" }
						else if(Number(error.status) == 500){ this.alertMsg = "Internal error" }
						else { this.alertMsg = "Please try after some time." }
						
						this.addSingle();
					}, () => {})
					
				}, error => { 
					this.alertSeverity = "error";
					if(Number(error.status) == 401){ this.alertMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.alertMsg = "Internal error" }
					else { this.alertMsg = "Please try after some time." }
					
					this.addSingle();
				}, () => {})
			}
			else if(event.item.label == "CSV"){
				
				this.myRepoOutputService.CSVReportDownloadService(this.reportId, this.dwnlRepoSerRequestPayload, undefined).subscribe( (response: any) => { // CSV report download unique name service
					
					this.myRepoOutputService.CSVReportDownloadService(this.reportId, this.dwnlRepoSerRequestPayload, "uniqueName="+response.body.uniqueName).subscribe( (repoResp: any) => { // CSV report download service
						
						let blob = new Blob([repoResp.body],{type:'text/csv;charset=utf-8'})
						
						var a = document.createElement("a");
						a.href = URL.createObjectURL(blob);
						a.download = this.reportName + ".csv";
						a.click();
						
						/*var blob = new Blob([repoResp.body], { type: 'text/csv' });
						var url = window.URL.createObjectURL(blob);

						if(navigator.msSaveOrOpenBlob) {
							navigator.msSaveBlob(blob, this.reportName);
						} else {
							var a = document.createElement("a");
							a.href = url;
							a.download = this.reportName + '.csv';
							document.body.appendChild(a);
							a.click();
							document.body.removeChild(a);
						}
						window.URL.revokeObjectURL(url);*/
						
						
					}, error => { 
						this.alertSeverity = "error";
						if(Number(error.status) == 401){ this.alertMsg = "Please try after some time!" }
						else if(Number(error.status) == 500){ this.alertMsg = "Internal error" }
						else { this.alertMsg = "Please try after some time." }
						
						this.addSingle();
					}, () => {})
				
				}, error => { 
					this.alertSeverity = "error";
					if(Number(error.status) == 401){ this.alertMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.alertMsg = "Internal error" }
					else { this.alertMsg = "Please try after some time." }
					
					this.addSingle();
				}, () => {})
			}
			else if(event.item.label == "Save CSV to Inbox"){
				
				this.myRepoOutputService.CSVReportSaveToInboxService(this.reportId, this.dwnlRepoSerRequestPayload, undefined).subscribe( (response: any) => { // CSV report save to inbox unique name service
				
					this.myRepoOutputService.CSVReportSaveToInboxService(this.reportId, this.dwnlRepoSerRequestPayload, "uniqueName="+response.body.uniqueName).subscribe( (repoResp: any) => { // CSV report save to inbox service
						if(repoResp.status == 200){
							this.alertSeverity = "success"
							this.alertMsg = "Saved to Inbox!";
							this.addSingle();
						}
					}, error => { 
						this.alertSeverity = "error";
						if(Number(error.status) == 401){ this.alertMsg = "Please try after some time!" }
						else if(Number(error.status) == 500){ this.alertMsg = "Internal error" }
						else { this.alertMsg = "Please try after some time." }
						
						this.addSingle();
					}, () => {})
					
				}, error => { 
					this.alertSeverity = "error";
					if(Number(error.status) == 401){ this.alertMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.alertMsg = "Internal error" }
					else { this.alertMsg = "Please try after some time." }
					
					this.addSingle();
				}, () => {})
				
			}
			else if(event.item.label == "TXT"){
				this.myRepoOutputService.TXTReportDownloadService(this.reportId, this.dwnlRepoSerRequestPayload, undefined).subscribe( (response: any) => { // TXT report download unique name service
					
					this.myRepoOutputService.CSVReportDownloadService(this.reportId, this.dwnlRepoSerRequestPayload, "uniqueName="+response.body.uniqueName).subscribe( (repoResp: any) => { // TXT report download service
						
						let blob = new Blob([repoResp.body],{type:'text/text'})
						
						var a = document.createElement("a");
						a.href = URL.createObjectURL(blob);
						a.download = this.reportName + ".txt";
						a.click();
					
					}, error => { 
						this.alertSeverity = "error";
						if(Number(error.status) == 401){ this.alertMsg = "Please try after some time!" }
						else if(Number(error.status) == 500){ this.alertMsg = "Internal error" }
						else { this.alertMsg = "Please try after some time." }
						
						this.addSingle();
					}, () => {})
					
				}, error => { 
					this.alertSeverity = "error";
					if(Number(error.status) == 401){ this.alertMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.alertMsg = "Internal error" }
					else { this.alertMsg = "Please try after some time." }
					
					this.addSingle();
				}, () => {})
			}
		}
		else {
			if(event.item.label == "XLS"){
				this.myRepoOutputService.XLSBirtReportDownloadService(this.reportId, "rptDocumentName="+this.birtDocumentName).subscribe( (repoResp: any) => { // XLS report download service
							
					let blob = new Blob([repoResp.body],{type:'application/vnd.ms-excel'});
					
					var a = document.createElement("a");
					a.href = URL.createObjectURL(blob);
					a.download = this.reportName.replace(".rptdesign",".xls");
					a.click();
				
				}, error => { 
					this.alertSeverity = "error";
					if(Number(error.status) == 401){ this.alertMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.alertMsg = "Internal error" }
					else { this.alertMsg = "Please try after some time." }
					
					this.addSingle();
				}, () => {})
			}
			else if(event.item.label == "XLSX"){
				this.myRepoOutputService.XLSXBirtReportDownloadService(this.reportId, "rptDocumentName="+this.birtDocumentName).subscribe( (repoResp: any) => { // XLSX report download service
							
					let blob = new Blob([repoResp.body],{type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
					
					var a = document.createElement("a");
					a.href = URL.createObjectURL(blob);
					a.download = this.reportName.replace(".rptdesign",".xlsx");
					
					a.click();
				
				}, error => { 
					this.alertSeverity = "error";
					if(Number(error.status) == 401){ this.alertMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.alertMsg = "Internal error" }
					else { this.alertMsg = "Please try after some time." }
					
					this.addSingle();
				}, () => {})
			}
			else if(event.item.label == "Save XLSX to Inbox"){
				this.myRepoOutputService.XLSXBirtReportSaveToInboxService(this.reportId, "rptDocumentName="+this.birtDocumentName).subscribe( (repoResp: any) => { // XLSX report save to inbox service
					if(repoResp.status == 200){
						this.alertSeverity = "success"
						this.alertMsg = "Saved to Inbox!";
						this.addSingle();
					}		
				}, error => { 
					this.alertSeverity = "error";
					if(Number(error.status) == 401){ this.alertMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.alertMsg = "Internal error" }
					else { this.alertMsg = "Please try after some time." }
					
					this.addSingle();
				}, () => {})
			}
			else if(event.item.label == "PDF"){
				this.myRepoOutputService.PDFBirtReportDownloadService(this.reportId, "rptDocumentName="+this.birtDocumentName).subscribe( (repoResp: any) => { // PDF report download service
							
					let blob = new Blob([repoResp.body],{type:'application/pdf'})
					
					var a = document.createElement("a");
					a.href = URL.createObjectURL(blob);
					a.download = this.reportName.replace(".rptdesign",".pdf");
					a.click();
				
				}, error => { 
					// this.alertSeverity = "error";
					// if(Number(error.status) == 401){ this.alertMsg = "Please try after some time!" }
					// else if(Number(error.status) == 500){ this.alertMsg = "Internal error" }
					// else { this.alertMsg = "Please try after some time." }
					
					// this.addSingle();
				}, () => {})
			}
			else if(event.item.label == "Save PDF to Inbox"){
				this.myRepoOutputService.PDFBirtReportSaveToInboxService(this.reportId, "rptDocumentName="+this.birtDocumentName).subscribe( (repoResp: any) => { // PDF report save to inbox service
					if(repoResp.status == 200){
						this.alertSeverity = "success"
						this.alertMsg = "Saved to Inbox!";
						this.addSingle();
					}
				}, error => { 
					this.alertSeverity = "error";
					if(Number(error.status) == 401){ this.alertMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.alertMsg = "Internal error" }
					else { this.alertMsg = "Please try after some time." }
					
					this.addSingle();
				}, () => {})
			}
			else if(event.item.label == "DOC"){
				this.myRepoOutputService.DOCBirtReportDownloadService(this.reportId, "rptDocumentName="+this.birtDocumentName).subscribe( (repoResp: any) => { // DOC report download service
							
					let blob = new Blob([repoResp.body],{type:'application/msword'});
					
					var a = document.createElement("a");
					a.href = URL.createObjectURL(blob);
					a.download = this.reportName + ".doc";
					a.click();
				
				}, error => { 
					this.alertSeverity = "error";
					if(Number(error.status) == 401){ this.alertMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.alertMsg = "Internal error" }
					else { this.alertMsg = "Please try after some time." }
					
					this.addSingle();
				}, () => {})
			}
			else if(event.item.label == "PPTX"){
				this.myRepoOutputService.PPTXBirtReportDownloadService(this.reportId, "rptDocumentName="+this.birtDocumentName).subscribe( (repoResp: any) => { // PPTX report download service
							
					let blob = new Blob([repoResp.body],{type:'application/vnd.openxmlformats-officedocument.presentationml.presentation'});
					
					var a = document.createElement("a");
					a.href = URL.createObjectURL(blob);
					a.download = this.reportName + ".pptx";
					a.click();
				
				}, error => { 
					this.alertSeverity = "error";
					if(Number(error.status) == 401){ this.alertMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.alertMsg = "Internal error" }
					else { this.alertMsg = "Please try after some time." }
					
					this.addSingle();
				}, () => {})
			}
		}
	}
	
	addMessages() {
        this.alertMessage = [ {severity:'error', summary:'Error', detail:"Please fill all required fields."} ];
    }

    clearMessages() { this.alertMessage = []; }

	addSingle() { this.messageService.add({severity:this.alertSeverity, summary:'', detail:this.alertMsg}); }

    clear() { this.messageService.clear(); }

}
