<div class="tibo-data-analytics-container-wrapper tibo-right-container">
    
    <div *ngIf="showStories" class="tibo-data-disco-domains-dropdown-container p-grid p-jc-center" style="background-color:#f1f1f4;"> 
        <div class="p-md-6 p-sm-9 p-px-0 " style="background-color:#f1f1f4;">

            <input type="text" id="" class="tibo-data-disco-param-inputtext" 
            placeholder="What data story would you like to experience?"/>
            <button pButton type="button" icon="pi pi-search" iconPos="center" class="tibo-data-disco-submitbtn"></button>

        </div>
    </div>

    <div *ngIf="showStories" class="p-px-6 tibo-data-disco-domains-story-container" style="background-color:#f1f1f4;"> 

        <div class="p-grid p-nogutter p-px-6">
            <div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-4 p-px-4 p-mb-5" style="border:0px solid green;  box-sizing:border-box; min-width:0px; height:130px; margin:0px;">
                <div class=""  style="border:0px solid #e8e9e8; box-sizing:border-box;  height:85%; margin:3.5% 8%; background-color:rgb(47, 110, 187); cursor:pointer;" (click)="storyClickFunc('Employee')">
                    <div class="p-grid p-col-12 p-ai-center vertical-container"  style="background-color:rgb(47, 110, 187); box-sizing:border-box;  margin-left:0; margin-top:0; height:100%; cursor:pointer;">
                        <label class="p-col-12" style="color:#eaedf1; font-family:Roboto; font-size:15px; text-align:center; cursor: pointer;">Employee</label>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-4 p-px-4 p-mb-5" style="border:0px solid green;  box-sizing:border-box; min-width:0px; height:130px; margin:0px;">
                <div class=""  style="border:0px solid #e8e9e8; box-sizing:border-box;  height:85%; margin:3.5% 8%; background-color:#d3ac41; cursor:pointer;" (click)="storyClickFun('Absence')">
                    <div class="p-grid p-col-12 p-ai-center vertical-container"  style="background-color:#d3ac41; box-sizing:border-box;  margin-left:0; margin-top:0; height:100%; cursor:pointer;">
                        <label class="p-col-12" style="color:#eaedf1; font-family:Roboto; font-size:15px; text-align:center; cursor:pointer;">Absence</label>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-4 p-px-4 p-mb-5" style="border:0px solid green;  box-sizing:border-box; min-width:0px; height:130px; margin:0px;">
                <div class=""  style="border:0px solid #e8e9e8; box-sizing:border-box;  height:85%; margin:3.5% 8%; background-color:#cb3e1b;">
                    <div class="p-grid p-col-12 p-ai-center vertical-container"  style="background-color:#cb3e1b; box-sizing:border-box;  margin-left:0; margin-top:0; height:100%; ">
                        <label class="p-col-12" style="color:#eaedf1; font-family:Roboto; font-size:15px; text-align:center;">ADA</label>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-4 p-px-4 p-mb-5" style="border:0px solid green;  box-sizing:border-box; min-width:0px; height:130px; margin:0px;">
                <div class=""  style="border:0px solid #e8e9e8; box-sizing:border-box;  height:85%; margin:3.5% 8%; background-color:#1baf80;">
                    <div class="p-grid p-col-12 p-ai-center vertical-container"  style="background-color:#1baf80; box-sizing:border-box;  margin-left:0; margin-top:0; height:100%; ">
                        <label class="p-col-12" style="color:#eaedf1; font-family:Roboto; font-size:15px; text-align:center;">Finance</label>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-4 p-px-4 p-mb-5" style="border:0px solid green;  box-sizing:border-box; min-width:0px; height:130px; margin:0px;">
                <div class=""  style="border:0px solid #e8e9e8; box-sizing:border-box;  height:85%; margin:3.5% 8%; background-color:#2a2a2f;">
                    <div class="p-grid p-col-12 p-ai-center vertical-container"  style="background-color:#2a2a2f; box-sizing:border-box;  margin-left:0; margin-top:0; height:100%; ">
                        <label class="p-col-12" style="color:#eaedf1; font-family:Roboto; font-size:15px; text-align:center;">Supplemental</label>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

    <div *ngIf="panel">
        <div class="tibo-data-analytics-container-wrapper tibo-right-container" style="background-color:white;" >
        
    <div id="buildreports-folderview-panel" class="tibo-buildrepo-folderview-panel" >

        <span id="all-reports-id" class="tibo-buildrepo-folderview-panel-title">Employee</span>

        <button type="button" class="tibo-buildrepo-folderview-panel-arrow-icon"
            (click)="folderPanelToggle()"></button>

        <p-scrollPanel id="buildreport-folder-panel-scroll-div"
            styleClass="tibo-buildrepo-folderview-panel-scrollpanel">
            
            

            <div class="p-md-6 p-sm-9 p-px-0" style="background-color:#f1f1f4;">
                <form [formGroup]="employeeForm" novalidate>
                <div class="company">
                    <div class="rows ng-scope" style="font-size: 16px;">
                        <div class="list">
                                <div id="46" class="questionlabel"> <label id="s46"
                                        class="list-group-item ng-binding title">What Time period are you interested in?</label> </div>
                                <div class="list-group-item ng-binding ng-scope">
                                </div>
                            </div>
                    </div>
                    </div>
                <div class="date">
                    <div class="rows ng-scope" style="font-size: 16px;">
                        <div class="list">
                            <div id="48" class="questionlabel"> <label id="s48"
                                    class="list-group-item ng-binding title">Custom<span
                                        class="required">*</span></label> </div>
                                        <div >
                                        <div class="list" style="background-color:white">
                                            <div>
                                                <label class="list-group-item title">From</label>
                                                <div class="p-field list-group-item" style="margin-top:3rem">

                                                    <p-calendar  styleClass="tibo-buildrepo-output-param-calendar top"
                                                        [showIcon]="true" inputId="icon"
                                                        class="calendar" formControlName="Startdate" placeholder="MM/DD/YYYY"
                                                        >
                                                    </p-calendar>
                                                </div>
                                            </div>
                                            <div>
                                                <label class="list-group-item title" for="basic">To
                                                    </label>
                                                <div class="p-field list-group-item" style="margin-top:4rem">

                                                    <p-calendar inputId="basic" styleClass="tibo-buildrepo-output-param-calendar-endate top"
                                                        class="calendar" formControlName="enddate"
                                                        [showIcon]="true" placeholder="MM/DD/YYYY"
                                                        >
                                                    </p-calendar>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                        </div>
                    </div>
                    </div>
                      
                    <div class="company">
                        <div class="rows ng-scope" style="font-size: 16px;">
                            <div class="list">
                                    <div id="46" class="questionlabel"> <label id="s46"
                                            class="list-group-item ng-binding title">Date Range
                                    <span class="required">*</span></label> </div>
                                    <div class="list-group-item ng-binding ng-scope"
                                        >
                                        <p-dropdown styleClass="tibo-discovery-domains-dropdown p-my-1"
                                        #usermenu
                                        appendTo="body"
                                        [options]="dateRangeArray"
                                        optionLabel="label"
                                        optionValue="value"
                                        placeholder="Select the Date Range"
                                        formControlName="name"
                                        class="form-control value"
                                        #refEl>
                                </p-dropdown>
    
                                    </div>
                                </div>
                        </div>
                        </div>

                        <div class="p-grid p-jc-center p-col-12 p-mt-6" style="background-color: #f1f1f4; margin-left: 7px;">
                            <!-- <p-button  class="btn btn-default"  (onClick)="backButtonFunc()">Back</p-button> -->
                            <button   class="btn btn-default" (click)="backButtonFunc()">Back</button>
                            <div style="float: left">
                            <button class="btn btn-default" (click)="resetForm()" style="height: 34px; margin-left: 12px;">Clear</button>
                          </div>
                            <p-button (onClick)="submitButtonFunc()">Submit</p-button>
                        
                        </div>
                </form> 
            </div>
        </p-scrollPanel>
    </div>


    <div id="buildreports-tile-list-scroll-view" class="tibo-buildrepo-tile-list-scrolview-wrapper">
        <div class="p-grid p-col-12 p-ai-center vertical-container tibo-buildrepo-repotitle-tilelist-switch-cont" >
            <div class="tibo-buildrepo-reports-listview-cont " >
    <iframe id="data-analytics-iframe" *ngIf="showReport" class="p-mt-2" style="width: 100%; height: calc(100% - 50px);" frameborder="0"></iframe>
            </div>
        </div>
    </div>
        </div>
    </div>

    
    <div *ngIf="Absence">
        <div class="tibo-data-analytics-container-wrapper tibo-right-container" style="background-color:white;" >
        
    <div id="buildreports-folderview-panel" class="tibo-buildrepo-folderview-panel" >

        <span id="all-reports-id" class="tibo-buildrepo-folderview-panel-title">Absence</span>

        <button type="button" class="tibo-buildrepo-folderview-panel-arrow-icon"
            (click)="folderPanelToggle()"></button>

        <p-scrollPanel id="buildreport-folder-panel-scroll-div"
            styleClass="tibo-buildrepo-folderview-panel-scrollpanel">
            
            <div class="p-md-6 p-sm-9 p-px-0" style="background-color:#f1f1f4;">
                <form [formGroup]="absenceForm" novalidate>
                <div class="company">
                    <div class="rows ng-scope" style="font-size: 16px;">
                        <div class="list">
                                <div id="46" class="questionlabel"> <label id="s46"
                                        class="list-group-item ng-binding title">What Time period are you interested in?</label> </div>
                                <div class="list-group-item ng-binding ng-scope">
                                </div>
                            </div>
                    </div>
                    </div>
                <div class="date">
                    <div class="rows ng-scope" style="font-size: 16px;">
                        <div class="list">
                            <div id="48" class="questionlabel"> <label id="s48"
                                    class="list-group-item ng-binding title">Custom<span
                                        class="required">*</span></label> </div>
                                        <div >
                                        <div class="list" style="background-color:white">
                                            <div>
                                                <label class="list-group-item title">From</label>
                                                <div class="p-field list-group-item" style="margin-top:3rem">

                                                    <p-calendar  styleClass="tibo-buildrepo-output-param-calendar top"
                                                        [showIcon]="true" inputId="icon"
                                                        class="calendar"  formControlName="fromdate" placeholder="MM/DD/YYYY"
                                                        >
                                                    </p-calendar>
                                                </div>
                                            </div>
                                            <div>
                                                <label class="list-group-item title" for="basic">To
                                                    </label>
                                                <div class="p-field list-group-item" style="margin-top:4rem">

                                                    <p-calendar inputId="basic" styleClass="tibo-buildrepo-output-param-calendar-endate top"
                                                        class="calendar"
                                                        [showIcon]="true" formControlName="todate" placeholder="MM/DD/YYYY"
                                                        >
                                                    </p-calendar>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                        </div>
                    </div>
                    </div>
                      
                    <div class="company">
                        <div class="rows ng-scope" style="font-size: 16px;">
                            <div class="list">
                                    <div id="46" class="questionlabel"> <label id="s46"
                                            class="list-group-item ng-binding title">Date Range
                                    <span class="required">*</span></label> </div>
                                    <div class="list-group-item ng-binding ng-scope"
                                        >
    
    
                                        <p-dropdown styleClass="tibo-discovery-domains-dropdown p-my-1"
                                        #usermenu
                                        appendTo="body"
                                        [options]="dateRangeArray"
                                        optionLabel="label"
                                        optionValue="value"
                                        placeholder="Select the Date Range"
                                        formControlName="range"
                                        #refEl>
                                </p-dropdown>
    
                                    </div>
                                </div>
                        </div>
                        </div>
    

    
                        <div class="p-grid p-jc-center p-col-12 p-mt-6" style="background-color: #f1f1f4; margin-left: 7px;">
                            <button   class="btn btn-default" (click)="backButtonFunc()">Back</button>
                            <div style="float: left">
                            <button class="btn btn-default" (click)="resetForm()" style="height: 34px; margin-left: 12px;">Clear</button>
                          </div>
                            <p-button (onClick)="submitButtonFunc()">Submit</p-button>
                        
                        </div>
                
                
            </form>
            </div>
        </p-scrollPanel>

    </div>

    <div id="buildreports-tile-list-scroll-view" class="tibo-buildrepo-tile-list-scrolview-wrapper">

        <div class="p-grid p-col-12 p-ai-center vertical-container tibo-buildrepo-repotitle-tilelist-switch-cont" >
       
            <div class="tibo-buildrepo-reports-listview-cont " >
    <iframe id="data-analytics-iframe" *ngIf="showReport" class="p-mt-2" style="width: 100%; height: calc(100% - 50px);" frameborder="0"></iframe>
            </div></div></div>
        </div>
    </div>
   
    