import { Component, OnInit } from '@angular/core';
import { Message,MessageService } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
    template: `
		
		<div class="modal-container" style="background-image: url('/assets/images/reportinfo1.png'); background-repeat: no-repeat; background-size: contain; background-position:center; position:relative; float:left; width:50%; height:100%; padding:1.5rem; background-color:transparent;">
		</div>
		
		<div class="modal-container" style="position:relative; float:left; width:50%; height:100%; padding:1.5rem; background-color:transparent; overflow-y:auto;">
			<p class="dashboard-info">
				Timebound is designed to run, schedule, download, share, and distribute a report(s) with modern and personalized reports 
				listing views. Users can provide star ratings for report quality and write comments.
				<br><br>
				The reporting framework supports the integration of open source Business Intelligence reports.
			</p>
			<ul class="a">
				<li>Designed to run a report online and render the result output as a HTML format.</li>
				<li>Allows to schedule a report that can distribute to users via email or the report Inbox.</li>
				<li>Users can upload custom reports and share them online.</li>
				<li>Ability to report logs and monitor of scheduled reports are integrated with reporting framework.</li>
				<li>Easy to schedule a report that provides advanced and flexible scheduling options.</li>
				<li>Easy to schedule a report with different scheduling options, it provides advanced and flexible scheduling options.</li>
				<li>Customizable scheduling options based on company needs.</li>
				<li>ETL tool is used to extract and upload datas to the DB/target tables.</li>
				<li>ETL tool can be automated to do parsing using timebound scheduler.</li>
			</ul>
		</div>
	`,
	styleUrls: ['./home.component.css'],
	providers: [MessageService]
	
})


export class ReportsInfo implements OnInit {

	constructor(private messageService: MessageService, public config: DynamicDialogConfig) { }
	
	ngOnInit() {}
	
}