//import { RouteReuseStrategy } from '@angular/router';
import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle} from '@angular/router';

export class CustomReuseStrategy implements RouteReuseStrategy {
	handlers: {[key: string]: DetachedRouteHandle} = {};
	lastVisitedRoute?: any;
	
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
		//console.debug('CustomReuseStrategy:shouldDetach', route);
		this.lastVisitedRoute = route.routeConfig.path;
        return !!route.data && !!(route.data as any).shouldDetach;
	}

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        //console.debug('CustomReuseStrategy:store', route, handle);
        this.handlers[route.routeConfig.path] = handle;
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        //console.debug('CustomReuseStrategy:shouldAttach', route); 
		if((this.lastVisitedRoute == "my-dashboard") || (this.lastVisitedRoute == "data-discovery") || 
		(this.lastVisitedRoute == "discovery") || (this.lastVisitedRoute == "data-analytics") || 
		(this.lastVisitedRoute == "my-reports") || (this.lastVisitedRoute == "build-report") ||
        (this.lastVisitedRoute == "dashboard") ||
		(this.lastVisitedRoute == "scheduled-reports") || (this.lastVisitedRoute == "schedule-history") || 
		(this.lastVisitedRoute == "home") || (this.lastVisitedRoute == "my-report-inbox") ||
		(this.lastVisitedRoute == "my-favorites") || (this.lastVisitedRoute == "settings")){ return false; }
		else { return !!route.routeConfig && !!this.handlers[route.routeConfig.path]; }
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        //console.debug('CustomReuseStrategy:retrieve', route);
        if (!route.routeConfig) { return null; }
        return this.handlers[route.routeConfig.path];
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        //console.debug('CustomReuseStrategy:shouldReuseRoute', future, curr);
		return future.routeConfig === curr.routeConfig;
		
    }

}