<!-- <div class="tibo-topbar-panel">
	<span class="tibo-topbar-title" routerLink = "home">Home</span>
	<span class="tibo-topbar-username p-mr-5">{{userName}}</span>
	<button class="tibo-topbar-button" type="button" pButton icon="pi pi-user" (click)="usermenu.toggle($event)"></button>
	<p-tieredMenu #usermenu [model]="userButtonList" [popup]="true" my="left top" at="left bottom"></p-tieredMenu>
</div>

<p-confirmDialog styleClass="tibo-logout-confirm-dialog" acceptLabel="Log Out" acceptIcon="" rejectLabel="Cancel" rejectIcon=""  defaultFocus="none" rejectButtonStyleClass="p-button-text"></p-confirmDialog> -->

<p-menubar class="top-menu-bar">
    <ng-template pTemplate="start">
        <span class="tibo-topbar-title" routerLink = "home">Home</span>
    </ng-template>
    <ng-template pTemplate="end">
		<div class="top-menu-bar-end">
			<p class="tibo-topbar-username">{{userName}}</p>
			<button class="tibo-topbar-button" type="button" pButton icon="pi pi-user" (click)="usermenu.toggle($event)"></button>
			<p-tieredMenu #usermenu [model]="userButtonList" [popup]="true" my="left top" at="left bottom"></p-tieredMenu>
		</div>
    </ng-template>
</p-menubar>
<p-confirmDialog styleClass="tibo-logout-confirm-dialog" acceptLabel="Log Out" acceptIcon="" rejectLabel="Cancel" rejectIcon=""  defaultFocus="none" rejectButtonStyleClass="p-button-text"></p-confirmDialog>