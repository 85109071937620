import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class MyReportsOutputService {
	
	constructor(private http: HttpClient) { }
	
	reportParameterService(reportId){
        return this.http.get('api/v1/report/'+reportId+'/parameters', { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
    }
	
	getReportService(reportId,requestPayload,reportType,pageNum) {
		if(reportType.toLowerCase() == "sql"){
			return this.http.post('/api/v1/report/'+reportId+'/sql/run?limit=250&page='+pageNum, requestPayload,
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
		}
		else {
			return this.http.post('/api/v1/report/'+reportId+'/birt/run?page=1&rptDocumentName=', requestPayload,
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
		}
		
	}
	
	reportDrillDownService(reportId,requestPayload){
		return this.http.post('/api/v1/report/'+reportId+'/birt/run?page=1&rptDocumentName=', requestPayload,
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
		
	}
	
	XLSReportDownloadService(reportId,requestPayload,uniqueName){
		
		if(uniqueName == undefined){ 
			return this.http.post('/api/v1/report/sql/'+reportId+'/export-filename?format=xls', requestPayload,   // to get uniqueName from service
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
		}
		else{
			
			return this.http.get('/api/v1/report/sql/'+reportId+'/export?'+uniqueName, 
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, 'responseType': 'blob' as 'json', observe: 'response' })
		}
	}
	
	XLSXReportDownloadService(reportId,requestPayload,uniqueName){
		
		if(uniqueName == undefined){ 
			return this.http.post('/api/v1/report/sql/'+reportId+'/export-filename?format=xlsx', requestPayload,   // to get uniqueName from service
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
		}
		else{
			
			return this.http.get('/api/v1/report/sql/'+reportId+'/export?'+uniqueName, 
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, 'responseType': 'blob' as 'json', observe: 'response' })
		}
	}
	
	XLSXReportSaveToInboxService(reportId,requestPayload,uniqueName){
		
		if(uniqueName == undefined){ 
			return this.http.post('/api/v1/report/sql/'+reportId+'/export-filename?format=xlsx', requestPayload,   // to get uniqueName from service
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
		}
		else{
			
			return this.http.get('api/v1/report/sql/'+reportId+'/export?'+uniqueName+'&formatType=savePdfToInbox', 
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
		}
	}
	
	CSVReportDownloadService(reportId,requestPayload,uniqueName){
		
		if(uniqueName == undefined){ 
			return this.http.post('/api/v1/report/sql/'+reportId+'/export-filename?format=csv', requestPayload,   // to get uniqueName from service
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
		}
		else{
			
			return this.http.get('/api/v1/report/sql/'+reportId+'/export?'+uniqueName, 
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, 'responseType': 'text', observe: 'response' })
		}
	}
	
	CSVReportSaveToInboxService(reportId,requestPayload,uniqueName){
		
		if(uniqueName == undefined){ 
			return this.http.post('/api/v1/report/sql/'+reportId+'/export-filename?format=csv', requestPayload,   // to get uniqueName from service
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
		}
		else{
			
			return this.http.get('api/v1/report/sql/'+reportId+'/export?'+uniqueName+'&formatType=savePdfToInbox', 
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
		}
	}
	
	TXTReportDownloadService(reportId,requestPayload,uniqueName){
		
		if(uniqueName == undefined){ 
			return this.http.post('/api/v1/report/sql/'+reportId+'/export-filename?format=txt', requestPayload,   // to get uniqueName from service
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
		}
		else{
			
			return this.http.get('/api/v1/report/sql/'+reportId+'/export?'+uniqueName, 
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, 'responseType': 'text', observe: 'response' })
		}
	}
	
	
	XLSBirtReportDownloadService(reportId,documentName){
		
		return this.http.get('/api/v1/report/'+reportId+'/export?'+documentName+'&format=xls', 
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, 'responseType': 'blob' as 'json', observe: 'response' })
			
	}
	
	XLSXBirtReportDownloadService(reportId,documentName){
		
		return this.http.get('/api/v1/report/'+reportId+'/export?'+documentName+'&format=xlsx', 
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, 'responseType': 'blob' as 'json', observe: 'response' })
			
	}
	
	XLSXBirtReportSaveToInboxService(reportId,documentName){
		
		return this.http.get('/api/v1/report/'+reportId+'/export?'+documentName+'&format=xlsx&formatType=savePdfToInbox', 
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
		
	}
	
	PDFBirtReportDownloadService(reportId,documentName){
		
		return this.http.get('/api/v1/report/'+reportId+'/export?'+documentName+'&format=pdf', 
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, 'responseType': 'blob' as 'json', observe: 'response' })
		
	}
	
	PDFBirtReportSaveToInboxService(reportId,documentName){
		
		return this.http.get('/api/v1/report/'+reportId+'/export?'+documentName+'&format=pdf&formatType=savePdfToInbox', 
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
		
	}
	
	DOCBirtReportDownloadService(reportId,documentName){
		
		return this.http.get('/api/v1/report/'+reportId+'/export?'+documentName+'&format=doc', 
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, 'responseType': 'blob' as 'json', observe: 'response' })
		
	}
	
	PPTXBirtReportDownloadService(reportId,documentName){
		
		return this.http.get('/api/v1/report/'+reportId+'/export?'+documentName+'&format=doc', 
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, 'responseType': 'blob' as 'json', observe: 'response' })
		
	}
	
}