<div class="sidebar" [ngClass]="{ 'expanded': isExpanded}" id="tibo-large-leftpanel-container">

    <div class="content">
        <div class="link">
            <div class="logo">
                <span> <img src="../../assets/TB LOGO.png" alt=""></span>
                <div class="logo_name">Timebound</div>
            </div>
        </div><br><br>

        <div class="alignment">
            <ul>
                <li>
                    <a href="#" routerLink="home" (click)="onLinkClick()" (click)="activefun('home')"
                        [ngClass]="{'active':activeString === 'home'}" pTooltip="Home" tooltipPosition="right"
                        [tooltipDisabled]="isExpanded">
                        <i class='bx bx-home'></i>
                        <span class="group_name">Home</span>
                    </a>
                    <!-- <a> <span class="tooltip ">Home</span></a> -->
                </li>

                <li>
                    <a href="#" routerLink="discovery" (click)="onLinkClick()" (click)="activefun('dataDiscovery')"
                        [ngClass]="{'active':activeString === 'dataDiscovery'}" pTooltip="Data Discovery"
                        tooltipPosition="right" [tooltipDisabled]="isExpanded">
                        <i class='bx bx-search'></i>
                        <span class="group_name">Data Discovery</span>
                    </a>
                    <!-- <span class="tooltip">Data Discovery</span> -->
                </li>
                <li>
                    <a href="#" routerLink="dashboard" (click)="onLinkClick()" (click)="activefun('dashboard')"
                        [ngClass]="{'active':activeString === 'dashboard'}" pTooltip="My Dashboards"
                        tooltipPosition="right" [tooltipDisabled]="isExpanded">
                        <i class='bx bx-grid-alt'></i>
                        <span class="group_name ">My Dashboards</span>
                    </a>
                    <!-- <span class="tooltip">Dashboard</span> -->
                </li>
                <li>
                    <a href="#" routerLink="build-report" (click)="onLinkClick()" (click)="activefun('buildReport')"
                        [ngClass]="{'active':activeString === 'buildReport'}" pTooltip="Build A Report"
                        tooltipPosition="right" [tooltipDisabled]="isExpanded">
                        <i class='bx bx-notepad'></i>
                        <span class="group_name ">Build A Report</span>
                    </a>
                    <!-- <span class="tooltip ">Build Report</span> -->
                </li>
                <li>
                    <a href="#" routerLink="my-reports" (click)="onLinkClick()" (click)="activefun('report')"
                        [ngClass]="{'active':activeString === 'report'}" pTooltip="My Reports" tooltipPosition="right"
                        [tooltipDisabled]="isExpanded">
                        <i class='bx bx-folder'></i>
                        <span class="group_name ">My Reports</span>
                    </a>
                    <!-- <span class="tooltip">Report</span> -->
                </li>
                <li>
                    <a href="#" routerLink="customer" (click)="onLinkClick()" (click)="activefun('customer')"
                        [ngClass]="{'active':activeString === 'customer'}" pTooltip="My InfoGraph" tooltipPosition="right"
                        [tooltipDisabled]="isExpanded">
                        <i class='bx bx-chart'></i>
                        <span class="group_name "> My InfoGraph</span>
                    </a>
                    <!-- <span class="tooltip">Report</span> -->
                </li>
                <li>
                    <a href="#" routerLink="settings" (click)="onLinkClick()" (click)="activefun('settings')"
                        [ngClass]="{'active':activeString === 'settings'}" pTooltip="Settings" tooltipPosition="right"
                        [tooltipDisabled]="isExpanded">
                        <i class='bx bx-cog'></i>
                        <span class="group_name ">Settings</span>
                    </a>
                    <!-- <span class="tooltip ">Settings</span> -->
                </li>
            </ul>
        </div>
    </div>
    <div class="menu-button">
        <ul class="nav-list">
            <li class="menu_btn">
                <i class='bx bx-chevrons-left menu-btn' (click)="menuFunc()" id="menu-btn"></i>
            </li>
        </ul>
    </div>

</div>