import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class DataDiscoveryService {
    
    constructor(private http: HttpClient) { }
	
	requestPayload = {"sort":[], "filters":[], "filterType":"and"}
	
	domainsService() {
        return this.http.get('api/v1/custom-reports/domains/dataDiscovery', { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
    }
	
	reportAndMatricsGetService(domainId,keyword,pageNo){
		return this.http.post('api/v1/datadiscovery/view/'+domainId+'/matrics?searchString='+keyword+'&limit=250&page='+pageNo, this.requestPayload,
		{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	
	dashWidgetsGetService(keyword){
		return this.http.get('api/v1/user-dashboard/widget/search/'+keyword, { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	
	/*recordsService(param,page) {
		return this.http.post('api/v1/directory/'+param+'/report/search/tile-view?depth=0&limit=50&page='+page, this.requestPayload,
		{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	
	settingsGetService(){
		return this.http.get('api/v1/user/config', { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	
	settingsPostService(settingsReqPayLoad){
		return this.http.put('api/v1/user/config', settingsReqPayLoad, { headers: {'X-Auth-Token': localStorage.getItem("token")}, 
		observe: 'response' })
	}*/
}

