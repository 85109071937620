import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ButtonModule } from 'primeng/button';
import { AccordionModule } from 'primeng/accordion';
import { ToastModule } from 'primeng/toast';
import { InputTextModule } from 'primeng/inputtext';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TooltipModule } from 'primeng/tooltip';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { PanelMenuModule } from 'primeng/panelmenu';
import { TreeModule } from 'primeng/tree';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { SelectButtonModule } from 'primeng/selectbutton';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ChipModule } from 'primeng/chip';
import { InputNumberModule } from 'primeng/inputnumber';
import { CheckboxModule } from 'primeng/checkbox';
import { MenuModule } from 'primeng/menu';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ListboxModule } from 'primeng/listbox';
import { CardModule } from 'primeng/card';
import { PanelModule } from 'primeng/panel';
import { DividerModule } from "primeng/divider";
import { InputSwitchModule } from 'primeng/inputswitch';
import {BadgeModule} from 'primeng/badge';
import { LoginComponent } from './login/login.component';
import { MenuPanelComponent } from './menu-panel/menu-panel.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { HomeComponent } from './home/home.component';
import { MyDashboardComponent } from './my-dashboard/my-dashboard.component';
import { MyReportsComponent } from './my-reports/my-reports.component';
import { MyReportsOutputComponent } from './my-reports-output/my-reports-output.component';
import { EditorModule } from 'primeng/editor';
import { DashboardInfo } from './home/dashboard-info';
import { ReportsInfo } from './home/reports-info';
import { DrillThruOutput } from './my-dashboard/drill-thru-output';
import { AllOutputFiles } from './my-reports/all-output-files';
import { AllOutputFilesMyFavorites } from './my-favorites/all-output-files-my-favorites';

import { AuthGuard } from './auth.guard'
import { LoginService } from './login/login.service';
import { MyDashboardService } from './my-dashboard/my-dashboard.service';
import { MyReportsService } from './my-reports/my-reports.service';
import { MyReportsOutputService } from './my-reports-output/my-reports-output.service';
import { DashboardOutputService } from './dashboard-output/dashboard-output.service';

import { RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from './reuse-strategy';
import { SchedulerComponent } from './scheduler/scheduler.component';
import { ScheduledReportsComponent } from './scheduled-reports/scheduled-reports.component';
import { ScheduleHistoryComponent } from './schedule-history/schedule-history.component';
import { DataDiscoveryComponent } from './data-discovery/data-discovery.component';
import { DashboardOutputComponent } from './dashboard-output/dashboard-output.component';
import { WidgetOutputComponent } from './widget-output/widget-output.component';
import { DiscoveryComponent } from './discovery/discovery.component';
import { SettingsComponent } from './settings/settings.component';
import { ReportCatalogueComponent } from './report-catalogue/report-catalogue.component';
import { MyReportInboxComponent } from './my-report-inbox/my-report-inbox.component';
import { MyFavoritesComponent } from './my-favorites/my-favorites.component';
import { DataAnalyticsComponent } from './data-analytics/data-analytics.component';
import { CreateDashboardComponent } from './create-dashboard/create-dashboard.component';
import { BuildReportComponent } from './build-report/build-report.component';
import { ConfirmationService } from 'primeng/api/confirmationservice';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalComponent } from './modal/modal.component';
import { DatePipe } from '@angular/common';
import { SplitButtonModule } from 'primeng/splitbutton';
import { BuildReportService } from './build-report/build-report.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardService } from './dashboard/dashboard.service';
import { DashboardOutput } from './dashboard/dashboard.output';
import { OldDashboardComponent } from './old-dashboard/old-dashboard.component';
import { DashboardV3Component } from './dashboard-v3/dashboard-v3.component';
import { DashboardChartComponent } from './dashboard-v3/dashboard-chart/dashboard-chart.component';
import {FilterPipe} from '././build-report/filter.pipe'
import { AgGridModule } from 'ag-grid-angular';
import { CommonModule } from '@angular/common';
import { MenubarModule } from 'primeng/menubar';
import { LeaveDetailsTableComponent } from './leave-details-table/leave-details-table.component';
import { DisabilityDetailsComponent } from './disability-details/disability-details.component';
import { DisabilitySummaryComponent } from './disability-summary/disability-summary.component';
import { LeaveSummaryComponent } from './leave-summary/leave-summary.component';
import { ChartModule } from 'primeng/chart';
import { AskQuestionsComponent } from './ask-questions/ask-questions.component';
import { DiscoveryReportsComponent } from './discovery-reports/discovery-reports.component';
import { AbsenceSsoReportComponent } from './absence-sso-report/absence-sso-report.component';
import { AbsenceUrlReportComponent } from './absence-url-report/absence-url-report.component';
import { ClickOutsideDirective } from './click-outside.directive';
import { CustomerExperienceComponent } from './customer-experience/customer-experience.component';



@NgModule({
  declarations: [
    AppComponent,
    MenuPanelComponent,
    LoginComponent,
    TopBarComponent,
    HomeComponent,
    MyDashboardComponent,
    MyReportsComponent,
	  MyReportsOutputComponent,
    SchedulerComponent,
    ScheduledReportsComponent,
    ScheduleHistoryComponent,
    DataDiscoveryComponent,
    DashboardOutputComponent,
    WidgetOutputComponent,
    DiscoveryComponent,
    SettingsComponent,
    ReportCatalogueComponent,
    MyReportInboxComponent,
    MyFavoritesComponent,
    BuildReportComponent,
    DataAnalyticsComponent,
    FilterPipe,

    DashboardInfo,
    ReportsInfo,
    DrillThruOutput,
    DashboardOutput,
    AllOutputFiles,
    AllOutputFilesMyFavorites,
    DashboardChartComponent,
    CreateDashboardComponent,
         BuildReportComponent,
         ModalComponent,
         DashboardComponent,
         OldDashboardComponent,
         DashboardV3Component,
         LeaveDetailsTableComponent,
         DisabilityDetailsComponent,
         DisabilitySummaryComponent,
         LeaveSummaryComponent,
         AskQuestionsComponent,
         DiscoveryReportsComponent,
         AbsenceSsoReportComponent,
         AbsenceUrlReportComponent,
         ClickOutsideDirective,
         CustomerExperienceComponent,

        
    
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
	BrowserAnimationsModule,
	AccordionModule,
  BadgeModule,
	ButtonModule,
    ToastModule,
    HttpClientModule,
    InputTextModule,
    ScrollPanelModule,
    TieredMenuModule,
    DropdownModule,
    FormsModule,
    SplitButtonModule,
    ProgressSpinnerModule,
    CalendarModule,
    MultiSelectModule,
	RadioButtonModule,
	TooltipModule,
	MessagesModule,
	MessageModule,
	DynamicDialogModule,
	PanelMenuModule,
	TreeModule,
	PaginatorModule,
	TableModule,
	SelectButtonModule,
	OverlayPanelModule,
	ChipModule,
	InputNumberModule,
	CheckboxModule,
	MenuModule,
	DialogModule,
	TabViewModule,
	AutoCompleteModule,
	ChipsModule,
	ConfirmDialogModule,
	ListboxModule,
	CardModule,
	PanelModule,
	DividerModule,
	InputSwitchModule,
  ReactiveFormsModule,
  AgGridModule,
  MenubarModule,
  ChartModule,
  ChartModule,
  EditorModule,
  ButtonModule
  ],
  entryComponents: [
    
  ],
  providers: [DialogService,LoginService,BuildReportService,AuthGuard,DatePipe, MyDashboardService,DashboardService, MyReportsService, MyReportsOutputService, DashboardOutputService,{provide: RouteReuseStrategy, useClass: CustomReuseStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
