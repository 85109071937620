import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MyReportInboxService } from './my-report-inbox.service'
import { Subscription } from 'rxjs';
import { Message,MessageService } from 'primeng/api';
import { MenuItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { SortEvent } from 'primeng/api';
import { ViewChild } from '@angular/core';

import { Router } from "@angular/router";

declare var d3:any;

interface Source {
	filterSource?: string;
	value?: string;
}

@Component({
	selector: 'app-my-report-inbox',
	templateUrl: './my-report-inbox.component.html',
	styleUrls: ['./my-report-inbox.component.css'],
	providers: [MyReportInboxService, MessageService]
})

export class MyReportInboxComponent implements OnInit {
	
	@ViewChild('reportsinboxtable', {static: true}) table: Table;
	
	sources: Source[];
	moreItems: MenuItem[];
	
	constructor(private messageService: MessageService, private http: HttpClient, private myReportInboxService: MyReportInboxService) { }
	
	selectedOption = "Inbox";
	userServiceResp: any;
	
	errorMsg = "";
	totalRecords = 0;
	reportInboxRecords = [];
	requestPayload = { sort :[{property:"createdDate", dir:"DESC"}], filters:[{property:"tabName",operator:"eq",value:"My Inbox"}], filterType:"and"};
	tableSortColumn = "createdDate";
	tableSortDir = -1;
	paginatorPageNo = 1;
	customSortBool:boolean = false;
	tableEmptyMessage:string = "";
	
	recordsSubscrip: Subscription;
	userServiceSubcribtion: Subscription;
	
	id: any;
	
	ngOnInit(): void {
		
		d3.select(".tibo-topbar-title").text("Home/Report Inbox"); // setting title
		
		if(Number(d3.select("#tibo-large-leftpanel-container").node().getBoundingClientRect().left) == 0){
			setTimeout(function(){
				d3.select(".tibo-right-container").transition().delay(0).duration(0).style("left","200px").ease("linear");
			},10)
		}
		
		this.sources = [
			{ filterSource: "Schedule", value: "Schedule" },
			{ filterSource: "OnDemand", value: "OnDemand" },
			{ filterSource: "Adhoc", value: "Adhoc" },
		];
		
		this.initialServiceCall();

	}

	ngOnDestroy() {
		if(this.recordsSubscrip){
			this.recordsSubscrip.unsubscribe();
		}
		if(this.userServiceSubcribtion){ 
			this.userServiceSubcribtion.unsubscribe(); 
		}
	}

	initialServiceCall(){

		this.recordsSubscrip = this.myReportInboxService.recordsService(1,this.requestPayload).subscribe( (recordsResp: any) => { // records
				
			d3.select(".tibo-myrepoinbox-reports-spinner").style("display","none") // hidding scheduled reports container spinner
			
			this.totalRecords = recordsResp.body?.totalRecords;
			recordsResp.body?.records ? this.reportInboxRecords = recordsResp.body?.records : this.reportInboxRecords = [];
			
			this.reportInboxRecords.forEach(d => {
				if(d?.createdDate != null){
					d.createdDate = new Date(d?.createdDate);
				}
				if(d.source == "Schedule"){
					d["filterSource"] = {filterSource: "Schedule", value: "Schedule"}
				}
				else if(d.source == "OnDemand"){
					d["filterSource"] = {filterSource: "OnDemand", value: "OnDemand"}
				}
				else if(d.source == "Adhoc"){
					d["filterSource"] = {filterSource: "Adhoc", value: "Adhoc"}
				}
			});
			
			this.reportInboxRecords.length > 0 ? this.tableEmptyMessage = "" : this.tableEmptyMessage = "No records found";
			
		}, error => { 
			d3.select(".tibo-myrepoinbox-reports-spinner").style("display","none") // hidding scheduled reports container spinner

			if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
			else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
			else { this.errorMsg = "Please try after some time." }
			
			this.addSingle();
		}, () => {})
		
		this.userServiceSubcribtion = this.myReportInboxService.user().subscribe( (userRes: any) => {
			
			this.userServiceResp = userRes.body;
			
			if(userRes.body.canShareFiles == "Y"){
				
				this.moreItems = [
					{
						label: 'Inbox',
						id: "1",
						command: e => this.optionsFunc(e),
						icon: "pi pi-inbox",
						//styleClass : 'active'
					},
					/*{
						label: 'My Uploads',
						id: "2",
						command: e => this.optionsFunc(e),
						icon: "pi pi-upload"
					},*/
					{
						label: 'History',
						id: "3",
						command: e => this.optionsFunc(e),
						icon: "pi pi-clock"
					},
					{
						label: 'Shared By Me',
						id: "3",
						command: e => this.optionsFunc(e),
						icon: "pi pi-share-alt"
					},
					{
						label: 'Shared With Me',
						id: "3",
						command: e => this.optionsFunc(e),
						icon: "pi pi-share-alt"
					}
				]
				
			}
			else {
				this.moreItems = [
					{
						label: 'Inbox',
						id: "1",
						command: e => this.optionsFunc(e),
						icon: "pi pi-inbox",
						//styleClass : 'active'
					},
					/*{
						label: 'My Uploads',
						id: "2",
						command: e => this.optionsFunc(e),
						icon: "pi pi-upload"
					},*/
					{
						label: 'History',
						id: "3",
						command: e => this.optionsFunc(e),
						icon: "pi pi-clock"
					}
				]
			}
					
		}, error => { 
			if(Number(error.status) == 401){ this.errorMsg = "Please check Username & Password!" }
			else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
			else { this.errorMsg = "Please try after some time." }
			
			this.addSingle();
		},
		() => {})

	}
	
	theadClick(event){
		event.target.attributes.class.value.includes("pi-filter-icon") ? this.customSortBool = false : this.customSortBool = true;
	}

	customSort(event: SortEvent){
		
		this.tableSortColumn = event.field;
		this.tableSortDir = event.order;
		
		this.table.reset();
		this.table.sortField = this.tableSortColumn;
		this.table._sortOrder = this.tableSortDir;
		
		if(this.customSortBool){

			this.customSortBool = false;

			d3.select(".tibo-myrepoinbox-reports-spinner").style("display","block") // showing scheduled reports container spinner

			//console.loglog(event.field)
			
			this.requestPayload.sort = [];
			
			if(event.order == 1){
				this.requestPayload.sort.push({ property: event.field, dir: "ASC"})
			}
			else if(event.order == -1){
				this.requestPayload.sort.push({ property: event.field, dir: "DESC"})
			}
			
			this.recordsSubscrip = this.myReportInboxService.recordsService(this.paginatorPageNo,this.requestPayload).subscribe( (recordsResp: any) => { // records
				
				d3.select(".tibo-myrepoinbox-reports-spinner").style("display","none") // hidding scheduled reports container spinner
				
				this.totalRecords = recordsResp.body?.totalRecords;
				
				let sortedData = [];
				recordsResp.body?.records ? sortedData = recordsResp.body.records : sortedData = [];
				
				sortedData.forEach(d => {
					if(d?.createdDate != null){
						d.createdDate = new Date(d?.createdDate);
					}
					if(d.source == "Schedule"){
						d["filterSource"] = {filterSource: "Schedule", value: "Schedule"}
					}
					else if(d.source == "OnDemand"){
						d["filterSource"] = {filterSource: "OnDemand", value: "OnDemand"}
					}
					else if(d.source == "Adhoc"){
						d["filterSource"] = {filterSource: "Adhoc", value: "Adhoc"}
					}
				});
				
				event.data.splice(0, event.data.length)
				
				sortedData.forEach(d => { event.data.push(d) })
				
			}, error => { 
				d3.select(".tibo-myrepoinbox-reports-spinner").style("display","none") // hidding scheduled reports container spinner
				
				d3.select(".tibo-myrepoinbox-reports-spinner").style("display","none") // hidding reports container spinner
				if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
				else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
				else { this.errorMsg = "Please try after some time." }
				
				this.addSingle();
			}, () => {})
		}
		
	}
	
	paginate(event) {
		/*//console.loglog(this.table)
		
		this.table.clear();
		this.myReportRecords = [];
		
		//d3.select(".tibo-myrepoinbox-reports-spinner").style("display","block") // showing reports container spinner*/

		this.customSortBool = false;
		
		d3.select(".tibo-myrepoinbox-reports-spinner").style("display","block") // showing scheduled reports container spinner
		
		this.paginatorPageNo = event.page + 1;
		
		this.reportInboxRecords = [];
		
		this.recordsSubscrip = this.myReportInboxService.recordsService(this.paginatorPageNo,this.requestPayload).subscribe( (recordsResp: any) => { // records
			
			d3.select(".tibo-myrepoinbox-reports-spinner").style("display","none") // hidding scheduled reports container spinner
			
			this.totalRecords = recordsResp.body?.totalRecords;
			
			recordsResp.body?.records ? this.reportInboxRecords = recordsResp.body?.records : this.reportInboxRecords = [];
				
			this.reportInboxRecords.forEach(d => {
				if(d?.createdDate != null){
					d.createdDate = new Date(d?.createdDate);
				}
				if(d.source == "Schedule"){
					d["filterSource"] = {filterSource: "Schedule", value: "Schedule"}
				}
				else if(d.source == "OnDemand"){
					d["filterSource"] = {filterSource: "OnDemand", value: "OnDemand"}
				}
				else if(d.source == "Adhoc"){
					d["filterSource"] = {filterSource: "Adhoc", value: "Adhoc"}
				}
			});
			
		}, error => { 
			d3.select(".tibo-myrepoinbox-reports-spinner").style("display","none") // hidding scheduled reports container spinner
			
			d3.select(".tibo-myrepoinbox-reports-spinner").style("display","none") // hidding reports container spinner
			if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
			else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
			else { this.errorMsg = "Please try after some time." }
			
			this.addSingle();
		}, () => {})
			
	}
	
	optionsFunc(event){
		
		d3.select(".tibo-myrepoinbox-reports-spinner").style("display","block") // showing scheduled reports container spinner
		
		this.selectedOption = event.item.label;
		
		this.tableSortColumn = "createdDate";
		this.tableSortDir = -1;
		
		if(this.selectedOption == "Inbox"){
			this.requestPayload = { sort :[{property:"createdDate", dir:"DESC"}], filters:[{property:"tabName",operator:"eq",value:"My Inbox"}], filterType:"and"};
			
			this.reportInboxRecords = [];
		
			this.recordsSubscrip = this.myReportInboxService.recordsService(this.paginatorPageNo,this.requestPayload).subscribe( (recordsResp: any) => { // records
				
				d3.select(".tibo-myrepoinbox-reports-spinner").style("display","none") // hidding scheduled reports container spinner
				
				this.totalRecords = recordsResp.body?.totalRecords;
				
				recordsResp.body?.records ? this.reportInboxRecords = recordsResp.body?.records : this.reportInboxRecords = [];
					
				this.reportInboxRecords.forEach(d => {
					if(d?.createdDate != null){
						d.createdDate = new Date(d?.createdDate);
					}
				});
				
			}, error => { 
				d3.select(".tibo-myrepoinbox-reports-spinner").style("display","none"); // hidding reports container spinner

				if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
				else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
				else { this.errorMsg = "Please try after some time." }
				
				this.addSingle();
			}, () => {})
			
		}
		/*else if(this.selectedOption == "My Uploads"){
			this.requestPayload = { sort :[{property:"createdDate", dir:"DESC"}], filters:[{property:"tabName",operator:"eq",value:"Custom File"}], filterType:"and"};
			
			this.reportInboxRecords = [];
		
			this.recordsSubscrip = this.myReportInboxService.recordsService(this.paginatorPageNo,this.requestPayload).subscribe( (recordsResp: any) => { // records
				//console.loglog(recordsResp)
				
				d3.select(".tibo-myrepoinbox-reports-spinner").style("display","none") // hidding scheduled reports container spinner
				
				this.totalRecords = recordsResp.body?.totalRecords;
				
				recordsResp.body?.records ? this.reportInboxRecords = recordsResp.body?.records : this.reportInboxRecords = [];
					
				this.reportInboxRecords.forEach(d => {
					if(d?.createdDate != null){
						d.createdDate = new Date(d?.createdDate);
					}
				});
				
				
			}, error => { 
				d3.select(".tibo-myrepoinbox-reports-spinner").style("display","none") // hidding scheduled reports container spinner
				//console.loglog("error")
				//d3.select(".tibo-myrepoinbox-reports-spinner").style("display","none") // hidding reports container spinner
				//if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
				//else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
				//else { this.errorMsg = "Please try after some time." }
				
				//this.addSingle()
			}, () => {})
		}*/
		else if(this.selectedOption == "History"){
			this.requestPayload = { sort :[{property:"createdDate", dir:"DESC"}], filters:[{property:"tabName",operator:"eq",value:"History"}], filterType:"and"};
			
			this.reportInboxRecords = [];
		
			this.recordsSubscrip = this.myReportInboxService.historyRecordsService(this.paginatorPageNo,this.requestPayload).subscribe( (recordsResp: any) => { // records
				
				d3.select(".tibo-myrepoinbox-reports-spinner").style("display","none") // hidding scheduled reports container spinner
				
				this.totalRecords = recordsResp.body?.totalRecords;
				
				recordsResp.body?.records ? this.reportInboxRecords = recordsResp.body?.records : this.reportInboxRecords = [];
					
				this.reportInboxRecords.forEach(d => {
					if(d?.createdDate != null){
						d.createdDate = new Date(d?.createdDate);
					}
				});
				
			}, error => { 
				d3.select(".tibo-myrepoinbox-reports-spinner").style("display","none") // hidding scheduled reports container spinner
				
				d3.select(".tibo-myrepoinbox-reports-spinner").style("display","none") // hidding reports container spinner
				if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
				else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
				else { this.errorMsg = "Please try after some time." }
				
				this.addSingle();
			}, () => {})
		}
		else if(this.selectedOption == "Shared By Me"){
			this.requestPayload = { sort :[{property:"createdDate", dir:"DESC"}], filters:[{property:"tabName",operator:"eq",value:"Shared By Me"}], filterType:"and"};
			
			this.reportInboxRecords = [];
		
			this.recordsSubscrip = this.myReportInboxService.recordsService(this.paginatorPageNo,this.requestPayload).subscribe( (recordsResp: any) => { // records
				
				d3.select(".tibo-myrepoinbox-reports-spinner").style("display","none") // hidding scheduled reports container spinner
				
				this.totalRecords = recordsResp.body?.totalRecords;
				
				recordsResp.body?.records ? this.reportInboxRecords = recordsResp.body?.records : this.reportInboxRecords = [];
					
				this.reportInboxRecords.forEach(d => {
					if(d?.createdDate != null){
						d.createdDate = new Date(d?.createdDate);
					}
				});
				
			}, error => { 
				d3.select(".tibo-myrepoinbox-reports-spinner").style("display","none") // hidding scheduled reports container spinner
				
				d3.select(".tibo-myrepoinbox-reports-spinner").style("display","none") // hidding reports container spinner
				if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
				else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
				else { this.errorMsg = "Please try after some time." }
				
				this.addSingle();
			}, () => {})
		}
		else if(this.selectedOption == "Shared With Me"){
			this.requestPayload = { sort :[{property:"createdDate", dir:"DESC"}], filters:[{property:"tabName",operator:"eq",value:"Shared To Me"}], filterType:"and"};
			
			this.reportInboxRecords = [];
		
			this.recordsSubscrip = this.myReportInboxService.recordsService(this.paginatorPageNo,this.requestPayload).subscribe( (recordsResp: any) => { // records
				//console.loglog(recordsResp)
				
				d3.select(".tibo-myrepoinbox-reports-spinner").style("display","none") // hidding scheduled reports container spinner
				
				this.totalRecords = recordsResp.body?.totalRecords;
				
				recordsResp.body?.records ? this.reportInboxRecords = recordsResp.body?.records : this.reportInboxRecords = [];
					
				this.reportInboxRecords.forEach(d => {
					if(d?.createdDate != null){
						d.createdDate = new Date(d?.createdDate);
					}
				});
				
			}, error => { 
				d3.select(".tibo-myrepoinbox-reports-spinner").style("display","none") // hidding scheduled reports container spinner
				
				d3.select(".tibo-myrepoinbox-reports-spinner").style("display","none") // hidding reports container spinner
				if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
				else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
				else { this.errorMsg = "Please try after some time." }
				
				this.addSingle();
			}, () => {})
		}
		
	}
	
	filterBtnClick(){
		d3.selectAll(".tibo-repo-inbox-fltr-multiSelect").selectAll("div.p-component").style("padding","2px 0px 4px 0px")
		d3.selectAll(".tibo-repo-inbox-fltr-multiSelect").selectAll("div.p-multiselect-trigger").style("padding","3px 0px")
	}

	addSingle() { this.messageService.add({severity:'error', summary:'', detail:this.errorMsg}); }

    clear() { this.messageService.clear(); }
	
}
