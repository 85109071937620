<div class="container-fluid discovery-main-container tibo-right-container">
	<!-- <div class="card"> -->
		<p-tabView class="custom-tabview"  [(activeIndex)]="activeIndex" (onChange)="onChangeTab()">
			<p-tabPanel id="tab1" header="Search">
				<div id="schedrepo-scroll-wrapper" class="tibo-schedrepo-scroll-wrapper">
					<div class="top" *ngIf="first">
						<div *ngIf="topbar" class="search-bar">
							<div class="p-md-6 p-sm-9 p-px-0">
								<div class="search-div" *ngIf="false">

									<p-autoComplete class="search-input" placeholder="What can I help you find?"
										[(ngModel)]="searchText"
										field="question" [minLength]="1">
									</p-autoComplete>
									<!-- <span class="microphone-icon" tooltip="Search by Voice" (click)="toggleListening()"><i class="fa fa-microphone"></i></span> -->
									<button pButton type="button" icon="pi pi-search" iconPos="center"
										class="tibo-data-disco-submitbtn" (click)="onSearchBtnClick()" (keyup.enter)="onSearchBtnClick()"></button>

								</div>
								<div class="search-div">
									<input type="text" [(ngModel)]="searchText" (keyup.enter)="onSearchBtnClick()"
										class="search-input" [placeholder]="placeHolderValue"
										pInputText />
											<!-- <span class="microphone-icon" tooltip="Search by Voice" (click)="toggleListening()"><i class="fa fa-microphone"></i></span> -->
									<button pButton type="button" icon="pi pi-search" iconPos="center"
										class="tibo-data-disco-submitbtn" (click)="onSearchBtnClick()" (keyup.enter)="onSearchBtnClick()"></button>

								</div>
								<div class="check-parent">
									<div *ngFor="let category of categories" class="checkboxes">
										<div class="single-check">
											<p-checkbox name="group2" value="category" [(ngModel)]="selectedCategories"
												[value]="category" [inputId]="category.key" (onChange)="checkboxClick()">
											</p-checkbox>&nbsp;
											<label [for]="category.key" class="category-name">{{ category.name }}</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					
					<!-- <div class="bottom" *ngIf="widget">
						<div class="bottom-content">
							<i class="fa fa-search icon"></i>
							<h1></h1>
						</div>
					</div> -->
					<div class="abbc" *ngIf="report">
						<div *ngIf="resultsBool" class="tibo-data-disco-results-container p-grid p-jc-start">

							<div id="buildreports-folderview-panel" class="tibo-buildrepo-folderview-panel">

								<div style="width: 100%; height: 40px; z-index: 11;">
									<span id="all-reports-id" class="tibo-buildrepo-folderview-panel-title">Search
										Result</span>
								</div>

								<div id="buildreport-folder-panel-scroll-div"
									styleClass="tibo-buildrepo-folderview-panel-scrollpanel">
									<div class=" p-col-12 p-p-0 data-disc-reports-plistbox">
										<span
											style="font-family:Roboto; font-size:14px; font-weight:bold; color:#0f528c;margin: 20px 0px 10px 10px;">Reports</span>
										<div class="reports">
											<p-listbox [options]="reportList" class="list-box"
												[(ngModel)]="selectedCity" optionLabel="name"
												(onClick)="itemSelectedFunc($event,'report')"></p-listbox>
										</div>
									</div>
									<div class=" p-col-12 p-p-0 data-disc-dashboards-plistbox">
										<span
											style="font-family:Roboto; font-size:14px; font-weight:bold; color:#0f528c;margin: 20px 0px 10px 10px;">Dashboards</span>
										<div class="dash">
											<p-listbox class="list-box" [options]="dashList" [(ngModel)]="selectedCity"
												optionLabel="name"
												(onClick)="itemSelectedFunc($event,'dashboard')"></p-listbox>
										</div>
									</div>
									<div class=" p-col-12 p-p-0 data-disc-widgets-plistbox">
										<span
											style="font-family:Roboto; font-size:14px; font-weight:bold; color:#0f528c;margin: 20px 0px 10px 10px;">Widgets</span>
										<div class="widgets">
											<p-listbox [options]="widgetList" class="list-box"
												[(ngModel)]="selectedCity" optionLabel="name"
												(onClick)="itemSelectedFunc($event,'widget')"></p-listbox>
										</div>
									</div>
								</div>
							</div>
						</div>






						<div *ngIf="widgetContainerBool" class="tibo-data-disco-res-output-cont">
							<!-- <p-progressSpinner styleClass="tibo-data-dis-output-spinner custom-spinner"
								class="tibo-data-dis-result-spinner" strokeWidth="2" fill="#FFFFFF" animationDuration="1.5s"
								style="z-index:1000;">
							</p-progressSpinner> -->

							<div *ngIf="headerbtn" class="tibo-mydash-dashboard-title" id="full-screen-mode-btn">
								<!-- <button class="tibo-mydash-chartcoloroptions-btn" type="button" pButton icon="pi pi-angle-left" (click)="back()" pTooltip="Back to Search"></button>
					</div> -->

								<div *ngIf="showDashboard" class="chartContainer" id="chartscontainerid">
									<div *ngIf="dashboardName == 'Disability'">
										<div class="chartTitle" id="dashboardtitle">{{ dashboardName }}</div>
										<div class="chartDiv" id="chart1">
										</div>
										<div class="chartDiv" id="chart2">
										</div>
										<div class="chartDiv" id="chart3">
										</div>
										<div class="chartDiv" id="chart4">
										</div>
										<div class="chartDiv" id="chart5">
										</div>
										<div class="chartDiv" id="chart6">
										</div>
										<div class="chartDiv" id="chart7">
										</div>
										<div class="chartDiv" id="chart8">
										</div>
										<div class="chartDiv" id="chart11">
										</div>
										<div class="chartDiv" id="chart10">
										</div>

									</div>
									<button class="chartResiz" id="resizeBtn" type="button"
										onclick="btnClickedFunc(this.id)">Resize
										All</button>

									<div *ngIf="dashboardName == 'Leave'">
										<div class="chartTitle" id="dashboardtitle">{{ dashboardName }}</div>
										<div class="chartDiv" id="chart12">
										</div>
										<div class="chartDiv" id="chart13">
										</div>
										<div class="chartDiv1" id="chart9">
										</div>
										<!-- <div class="chartDiv" id="chart10">
								</div> -->

									</div>
									<button class="chartResiz" id="resizeBtn" type="button"
										onclick="btnClickedFunc(this.id)">Resize
										All</button>
								</div>

								<div *ngIf="showWidgets" class="chartContainer" id="chartscontainerid">
									<div class="chartTitle" id="dashboardtitle">{{ dashboardName }}</div>
									<div class="chartDiv3" [id]="this.chartId">
									</div>
									<button class="chartResiz" id="resizeBtn" type="button"
										onclick="btnClickedFunc(this.id)">Resize
										All</button>
								</div>
								<div *ngIf="showReport " class="chartContainer" id="chartscontainerid">
									<div class="container1">
										<div class="aggriid">
											<ag-grid-angular style="width: 100%; height:579px;clear: both;"
												class="ag-theme-alpine" [rowData]="rowData" [columnDefs]="columnDefs "
												[defaultColDef]="defaultColDef" [enableFilter]="true"
												[pagination]="true" [gridOptions]="gridOptions"
												[setQuickFilter]="quickFilterText">
											</ag-grid-angular>
										</div>
									</div>
								</div>

							</div>


						</div>
					</div>
				</div>
			</p-tabPanel>
			<p-tabPanel id="tab2" header="Ask Questions">
				<app-ask-questions></app-ask-questions>
			</p-tabPanel>
		</p-tabView>
	<!-- </div> -->

</div>