import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReportCatalogueService } from './report-catalogue.service'
import { Subscription } from 'rxjs';
import { Message,MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { SortEvent } from 'primeng/api';
import { ViewChild } from '@angular/core';

import { ReportCatalogueModelWindow } from './model-window.component';
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

import { Router } from "@angular/router";

declare var d3:any;

@Component({
	selector: 'app-report-catalogue',
	templateUrl: './report-catalogue.component.html',
	styleUrls: ['./report-catalogue.component.css'],
	providers: [DialogService, ReportCatalogueService, MessageService]
})

export class ReportCatalogueComponent implements OnInit {

	constructor(public dialogService: DialogService, private messageService: MessageService, private http: HttpClient, private reportCatalogueService: ReportCatalogueService, private router: Router) { }
	
	totalRecords = 0;
	reportCatalogueRecords = [];
	requestPayload = { sort: [{property: "authorizationName", dir: "ASC"}], filters: [], filterType: "and"};
	tableSortColumn = "authorizationName";
	tableSortDir = 1;
	paginatorPageNo = 1;
	
	recordsSubscrip: Subscription;
	
	ref: DynamicDialogRef;
	id: any;

	ngOnInit(): void {
		
		d3.select(".tibo-topbar-title").text("Home/Report Catalogue"); // setting title
		
		if(Number(d3.select("#tibo-large-leftpanel-container").node().getBoundingClientRect().left) == 0){
			setTimeout(function(){
				d3.select(".tibo-right-container").transition().delay(0).duration(0).style("left","200px").ease("linear");
			},10)
		}
		
		this.recordsSubscrip = this.reportCatalogueService.recordsService(1,this.requestPayload).subscribe( (recordsResp: any) => { // records
				//console.loglog(recordsResp)
				this.totalRecords = recordsResp.body?.totalRecords;
				recordsResp.body?.records ? this.reportCatalogueRecords = recordsResp.body?.records : this.reportCatalogueRecords = [];
				console.log(this.reportCatalogueRecords)
				/*this.reportCatalogueRecords.forEach(d => {
					if(d?.jobCreatedDate != null){
						d.jobCreatedDate = new Date(d?.jobCreatedDate);
					}
					if(d?.lastRun != null){
						d.lastRun = new Date(d?.lastRun);
					}
					if(d.nextRun != null){
						d.nextRun = new Date(d?.nextRun);
					}
				});*/
				
				
				/*d3.select(".tibo-repo-cat-spinner").style("display","none") // hidding reports container spinner
				this.totalRecords = recordsResp.body.totalRecords;
				recordsResp.body?.records ? this.myReportRecords = recordsResp.body.records : this.myReportRecords = [];*/
				
		}, error => { 
			//console.loglog("error")
			/*d3.select(".tibo-repo-cat-spinner").style("display","none") // hidding reports container spinner
			if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
			else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
			else { this.errorMsg = "Please try after some time." }
			
			this.addSingle()*/
		}, () => {})
		
	}
	
	ngOnDestroy() {
		if(this.recordsSubscrip){
			this.recordsSubscrip.unsubscribe();
		}
		if (this.ref) {
            this.ref.close();
        }
	}
	
	customSort(event: SortEvent){
		
		this.tableSortColumn = event.field;
		this.tableSortDir = event.order;
		
		//console.loglog(event.field)
		
		this.requestPayload.sort = [];
		
		if(event.order == 1){
			this.requestPayload.sort.push({ property: event.field, dir: "ASC"})
		}
		else if(event.order == -1){
			this.requestPayload.sort.push({ property: event.field, dir: "DESC"})
		}
		
		this.recordsSubscrip = this.reportCatalogueService.recordsService(this.paginatorPageNo,this.requestPayload).subscribe( (recordsResp: any) => { // records
			//console.loglog(recordsResp)
			/*this.totalRecords = recordsResp.body?.totalRecords;
			recordsResp.body?.records ? this.reportCatalogueRecords = recordsResp.body?.records : this.reportCatalogueRecords = [];
			
			this.reportCatalogueRecords.forEach(d => d.jobCreatedDate = new Date(d.jobCreatedDate));*/
			
			let sortedData = [];
			recordsResp.body?.records ? sortedData = recordsResp.body.records : sortedData = [];
			
			/*sortedData.forEach(d => {
				if(d?.jobCreatedDate != null){
					d.jobCreatedDate = new Date(d?.jobCreatedDate);
				}
				if(d?.lastRun != null){
					d.lastRun = new Date(d?.lastRun);
				}
				if(d.nextRun != null){
					d.nextRun = new Date(d?.nextRun);
				}
			});*/
			
			event.data.splice(0, event.data.length)
			
			sortedData.forEach(d => { event.data.push(d) })
			
			
			/*d3.select(".tibo-repo-cat-spinner").style("display","none") // hidding reports container spinner
			this.totalRecords = recordsResp.body.totalRecords;
			recordsResp.body?.records ? this.myReportRecords = recordsResp.body.records : this.myReportRecords = [];*/
				
		}, error => { 
			//console.loglog("error")
			/*d3.select(".tibo-repo-cat-spinner").style("display","none") // hidding reports container spinner
			if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
			else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
			else { this.errorMsg = "Please try after some time." }
			
			this.addSingle()*/
		}, () => {})
		
	}
	
	paginate(event) {
		/*//console.loglog(this.table)
		
		this.table.clear();
		this.myReportRecords = [];
		
		//d3.select(".tibo-repo-cat-spinner").style("display","block") // showing reports container spinner
		
		this.recordsSubscrip = this.myRepoService.recordsService(this.authorizationId,(event.page + 1)).subscribe( (recordsResp: any) => { // records
			//console.loglog(recordsResp.body)
			
			d3.select(".tibo-repo-cat-spinner").style("display","none") // hidding reports container spinner
			this.totalRecords = recordsResp.body.totalRecords;
			recordsResp.body?.records ? this.myReportRecords = recordsResp.body.records : this.myReportRecords = [];
			
		}, error => { 
			//console.loglog("error")
			if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
			else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
			else { this.errorMsg = "Please try after some time." }
			
			this.addSingle()
		}, () => {})*/
		
		this.paginatorPageNo = event.page + 1;
		
		this.reportCatalogueRecords = [];
		
		this.recordsSubscrip = this.reportCatalogueService.recordsService(this.paginatorPageNo,this.requestPayload).subscribe( (recordsResp: any) => { // records
			//console.loglog(recordsResp)
			recordsResp.body?.records ? this.reportCatalogueRecords = recordsResp.body?.records : this.reportCatalogueRecords = [];
				
			/*this.reportCatalogueRecords.forEach(d => {
				if(d?.jobCreatedDate != null){
					d.jobCreatedDate = new Date(d?.jobCreatedDate);
				}
				if(d?.lastRun != null){
					d.lastRun = new Date(d?.lastRun);
				}
				if(d.nextRun != null){
					d.nextRun = new Date(d?.nextRun);
				}
			});*/
			
			
			/*d3.select(".tibo-repo-cat-spinner").style("display","none") // hidding reports container spinner
			this.totalRecords = recordsResp.body.totalRecords;
			recordsResp.body?.records ? this.myReportRecords = recordsResp.body.records : this.myReportRecords = [];*/
				
		}, error => { 
			//console.loglog("error")
			/*d3.select(".tibo-repo-cat-spinner").style("display","none") // hidding reports container spinner
			if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
			else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
			else { this.errorMsg = "Please try after some time." }
			
			this.addSingle()*/
		}, () => {})
			
	}
	
	show(reportName, reportId) {
		
        this.ref = this.dialogService.open(ReportCatalogueModelWindow, {
			data: reportId,
            header: reportName,
            width: '50%',
            contentStyle: {"max-height": "500px", "overflow": "auto"},
            baseZIndex: 10000
        });

        /*this.ref.onClose.subscribe((product: Product) =>{
            if (product) {
                this.messageService.add({severity:'info', summary: 'Product Selected', detail: product.name});
            }
        });*/
    }

}
