<div class="tibo-mydash-wrapper tibo-right-container">

	<button id="resizeBtn" class="tibo-mydash-resize-btn" type="button">Resize All</button>
	
	<div class="tibo-mydash-dashlist-container">
		
		<!--<p-dropdown id="dashboard-list-dropdown-id" 
					styleClass="tibo-mydash-dash-list-dropdown"
					panelStyleClass="tibo-mydash-dash-list-dropdown-overlay"
					[scrollHeight]="scrollHeight"
					[options]="dashboardInfo"
					[(ngModel)]="selectedDashboard"
					optionLabel="name"
					[virtualScroll]="true"
					filter="true"
					dropdownIcon=""
					placeholder="Select a dashboard"
					(onChange)="getDashboard($event)">
		</p-dropdown>-->
		
		<!--<p-panelMenu [model]="items" [style]="{'width':'300px'}" styleClass="tibo-mydash-dash-list-dropdown"
					panelStyleClass="tibo-mydash-dash-list-dropdown-overlay"[multiple]=false ></p-panelMenu>-->
					
		<button id="abbc" class="tibo-topbar-button" type="button" pButton icon="pi pi-list" pTooltip="Dashboards" style="display:none; width:26px; height:24px; border-radius:3px;"(click)="dashboardListFunc()"></button>
		<div id="abc" class="abc" style="position:absolute; top:25px; width:260px; height:350px; background-color:#ffffff; z-index:1000; overflow:auto; display:none; border-radius:3px;
			box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12); padding:10px 3px;">
			<p-panelMenu #abcd [model]="items" [style]="{'width':'100%','height':'100%','overflow-y':'scroll'}" styleClass=""
					panelStyleClass="" [multiple]=false ></p-panelMenu>
		</div>
		
		<!--<div *ngIf="viewDashboardsList.length > 0" style="position:absolute; top:0px; left: 40px; min-width:250px; width:auto; height:25px; background-color:#e1e1e1; z-index:1000; font-size:12px; color:#0f528c; padding: 0 5px 0 20px; border-radius:20px;">
			Viewed: <p-chip [style]="{margin:'0px 5px', 'height':'25px', 'background-color':'#3580db
', 'color':'white', 'cursor':'pointer', 'padding':'0 0.7rem', 'font-family':'Roboto', 'font-size':'0.73rem'}" (click)="visitedDashFunc(selectedDashboard?.name, selectedDashboard?.code)">{{selectedDashboard?.name}}</p-chip>
			<button id="abbc" class="tibo-topbar-button" type="button" pButton icon="pi pi-chevron-down" style="position:relative; float:right; width:24px; height:24px; border-radius:15px; font-size:12px;"(click)="op.toggle($event)"></button>
		</div>
		
		<p-overlayPanel id="op" #op [style]="{'position':'absolute', 'left':'110px', width: '300px', 'max-height':'200px', 'overflow-y':'auto', 'box-shadow': 'rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px'}" appendTo="body" [showCloseIcon]=false>
			<ng-template pTemplate="body">
			<div>
				<p-chip *ngFor="let dashboard of viewDashboardsList" [style]="{margin:'5px 5px', 'height':'25px', 'background-color':'#3580db
', 'color':'white', 'cursor':'pointer', 'padding':'0 0.7rem', 'font-family':'Roboto', 'font-size':'0.73rem'}" (click)="visitedDashFunc(dashboard?.name,dashboard?.code); op.hide()">{{dashboard?.name}}</p-chip>
			</div>
			</ng-template>
		</p-overlayPanel>-->
		
		<label id="countdown-label" style="position:absolute; background-color:#f1f1f4; right:40px; width:auto; height:24px; color:#496a85; line-height:25px; font-size:0.84rem;"></label>
		<button id="" class="tibo-topbar-button tibo-mydash-more-btn-icon" type="button" pButton icon="pi pi-ellipsis-h" pTooltip="Options" style="position:absolute; background-color:#f1f1f4; right:5px; width:26px; height:24px; border:0px; color:#3580db
;" (click)="moreOptions.toggle($event)"></button>
		<p-menu #moreOptions [model]="moreItems"  [popup]="true" appendTo="body"></p-menu>

		<p-dialog [(visible)]="display" [style]="{top:'-5%', width: '70vw'}" [modal]="true" [draggable]="false" [resizable]="false" (onHide)="dialogBoxOnCloseFunc($event)">
			<ng-template pTemplate="header">
				<label [style]="{'font-family':'Roboto', 'font-weight':'Bold', 'font-size':'16px'}" >Share {{selectedDashboard.name}}</label>
			</ng-template>
			<p-tabView [(activeIndex)]="shareDashTabIndex" (onChange)="shareDashTabChanFunc($event)" styleClass="share-tabview">
				<p-tabPanel  header="Provide access to" [style]="{'height':'300px', 'font-family':'Roboto', 'font-weight':'Bold', 'font-size':'14px'}">
					<div class="p-grid p-col-12 p-ai-center vertical-container">
						<label class="p-col-12">Enter User Name(s) <span class="tibo-mydash-paramdash-param-title-mand">*</span></label>
						<p-autoComplete [autofocus]="true" [(ngModel)]="texts" [suggestions]="results" (completeMethod)="search($event)" [multiple]="true" scrollHeight="155px"></p-autoComplete>
					</div>
				</p-tabPanel>
				<p-tabPanel header="One time share by Email" [style]="{'font-family':'Roboto', 'font-weight':'Bold', 'font-size':'14px'}">
					<div class="p-grid p-col-12 p-ai-center vertical-container">
						<label class="p-col-12">Enter Email Id(s) <span class="tibo-mydash-paramdash-param-title-mand">* </span> 
						<span class="p-ml-2"style="font-size:12px; font-weight:normal;">(To add multiple Email ids, enter an email id and press Enter)</span></label>
						<!--<input class="p-p-1 share-dash-inputtext" type="text" pInputText />-->
						<p-chips class="p-p-1" [(ngModel)]="values2" [allowDuplicate]="false"></p-chips>
					</div>
				</p-tabPanel>
			</p-tabView>
			<ng-template pTemplate="footer">
				<p-button icon="pi pi-share-alt" label="Share" (click)="shareDashFunc($event)" ></p-button>
			</ng-template>
		</p-dialog>
		
	</div>
	
	<div class="tibo-mydash-charts-container">
		
		<p-toast styleClass="tibo-mydash-toast" position="center"></p-toast>
		
		<p-progressSpinner styleClass="tibo-mydash-dash-spinner custom-spinner" animationDuration="2s"></p-progressSpinner>
		
		<div class="tibo-mydash-dashboard-title">{{ dashboardName }}</div>
		
		<div id="dashboard-scroll-wrapper" class="tibo-mydash-nonparamdash-wrapper">
			
			<p-scrollPanel styleClass="tibo-mydash-nonparamdash-scrollpanel custombar2 ">
			
				<div class="tibo-mydash-nonparamdash-widget" *ngFor="let row of randomIdArray let i = index"> 
					<div class="widget-class"
						[ngClass]="{'widget-class-1': totalColumns.length == 1,'widget-class-2': totalColumns.length == 2,
									'widget-class-3': totalColumns.length == 3}"
						[attr.objectCordinates] = "(i+1)+','+(j+1)"  
						[attr.id] = randomIdArray[i][j]
						[attr.empty] = "true"
						*ngFor="let column of randomIdArray[i]; let j = index;">  
						
						<p-progressSpinner styleClass="tibo-mydash-widget-spinner custom-spinner" 
										   strokeWidth="4" 
										   fill="#FFFFFF" 
										   animationDuration="1.5s">
						</p-progressSpinner>

					</div>
				</div>

			</p-scrollPanel>
		
		</div> <!-- dashboard scroll wrapper -->

		<div id="dashboard-parameterized-scroll-wrapper" class="tibo-mydash-paramdash-wrapper">

			<div id="dashboard-parameterized-filter" class="tibo-mydash-paramdash-filtrpanl">
				
				<span id="filter-id" class="tibo-mydash-paramdash-filtrpanl-title">Filter</span>

				<button type="button" class="tibo-mydash-paramdash-filtrpanl-arrow-icon" (click)="filterPanelToggle()"></button>
				
				<p-messages [(value)]="alertMessage" [enableService]="false" styleClass="tibo-mydash-paramdash-filtrpanl-alert"></p-messages>
				
				<p-scrollPanel id="parameterized-dashboard-filter-panel-scroll-div" styleClass="tibo-mydash-paramdash-filtrpanl-scrollpanel">
				
					<ng-container *ngFor="let row of parameterElementsArray let i = index">
					
						<div class="tibo-mydash-paramdash-param-container" *ngIf="parameterElementsArray[i].fetchedRecordsBool">
						
							<span class="tibo-mydash-paramdash-param-title">
								{{parameterElementsArray[i].parameterName.replace("_", " ") != "" ? parameterElementsArray[i].parameterName.replace("_", " ") : parameterElementsArray[i].parameterLabel.replace("_", " ")}}
								<span class="tibo-mydash-paramdash-param-title-mand" *ngIf="parameterElementsArray[i].isMandatory == 'Y'">*</span>
							</span>
							
							<input type="text" class="tibo-mydash-paramdash-param-inputtext"
								*ngIf="parameterElementsArray[i].displayType == 'Text Field'"
								[attr.selectInfoIndex]=i
								placeholder="{{'Enter ' + parameterElementsArray[i]?.parameterName.replace('_', ' ') }}"
								(keyup)="onChange($event)">
							
							<div class="tibo-mydash-paramdash-param-radio-wrapper" *ngIf="parameterElementsArray[i].displayType == 'Radio'">
								 <div class="tibo-mydash-paramdash-param-radio-outter" *ngFor="let radioItem of (parameterElementsArray[i].fetchedRecords?.records?.records ? parameterElementsArray[i].fetchedRecords.records.records : parameterElementsArray[i].defaultValue.split(','))">
									<input type="radio" name="radiobutton" class="tibo-mydash-paramdash-param-radiobtn"
											[value]="radioItem[parameterElementsArray[i].fetchedRecords.records.parameterValue] || radioItem"
											[attr.selectInfoIndex]=i
											(change)="onChange($event)">
									<label class="tibo-mydash-paramdash-param-radiobtn-lbl">
										{{ radioItem[parameterElementsArray[i].fetchedRecords.records.parameterLabel] || radioItem }}
									</label>
									
								</div>
							</div>
							
							<p-dropdown styleClass="tibo-mydash-paramdash-param-dropdown"
								*ngIf="parameterElementsArray[i].displayType == 'Select'"
								appendTo="body"
								[attr.selectInfoIndex]=i
								[options]="parameterElementsArray[i].fetchedRecords?.records?.records"
								[optionLabel]="parameterElementsArray[i].fetchedRecords?.records?.parameterLabel"
								[optionValue]="parameterElementsArray[i].fetchedRecords?.records?.parameterValue"
								placeholder="{{'Select ' + parameterElementsArray[i].fetchedRecords?.records?.parameterLabel.replace('_', ' ') }}"
								(onChange)="onChange($event,refEl)"
								#refEl>
							</p-dropdown>
							
							<p-multiSelect styleClass="tibo-mydash-paramdash-param-multiselect"
								*ngIf="parameterElementsArray[i].displayType == 'Multi Select'"
								appendTo="body"
								[attr.selectInfoIndex]=i
								[options]="parameterElementsArray[i].fetchedRecords?.listEle"
								[optionLabel]="parameterElementsArray[i].fetchedRecords?.records?.parameterLabel"
								[optionValue]="parameterElementsArray[i].fetchedRecords?.records?.parameterValue"
								[defaultLabel]="'Please Select'"
								(onChange)="onChange($event,refEl)"
								#refEl>
							</p-multiSelect>
							
							<p-calendar styleClass="tibo-mydash-paramdash-param-calendar"
								*ngIf="parameterElementsArray[i].displayType == 'Date'"
								readonlyInput="true" 
								dateFormat="mm/dd/yy" 
								appendTo="body"
								[monthNavigator]="true"
								[yearNavigator]="true" 
								yearRange="2000:2030"
								[attr.selectInfoIndex]=i
								showIcon="true"
								(onSelect)="onChange($event,refEl)"
								#refEl>
							</p-calendar>
							
						</div>
						
					</ng-container>
					
				</p-scrollPanel>

				<button pButton type="button" id="submitbutton-id" class="tibo-mydash-paramdash-param-submitbtn" label="Submit" (click)="onFilterSubmitClick()"></button>

			</div> <!-- filter panel div -->

			<div id="dashboard-parameterized-scroll-view" class="tibo-mydash-paramdash-scroll-wrapper">
				<p-scrollPanel styleClass="tibo-mydash-paramdash-scrollpanel custombar2">

					<div class="tibo-mydash-paramdash-widget" *ngFor="let row of parameterizedDashboardRandomArray let i = index"> 
						<div class="widget-class" 
							[ngClass]="{'widget-class-1': totalColumns.length == 1,'widget-class-2': totalColumns.length == 2,
										'widget-class-3': totalColumns.length == 3}"
							[attr.objectCordinates] = "(i+1)+','+(j+1)"  
							[attr.id] = parameterizedDashboardRandomArray[i][j]
							[attr.empty] = "true"
							*ngFor="let column of parameterizedDashboardRandomArray[i]; let j = index;">  
							
							<p-progressSpinner styleClass="tibo-mydash-widget-spinner custom-spinner" 
												strokeWidth="4" 
												fill="#FFFFFF" 
												animationDuration="1.5s">
							</p-progressSpinner>

						</div>
					</div>

				</p-scrollPanel>
			</div>

		</div> <!-- parameterized wrapper -->

	</div> <!-- id="abc" -->
	
	<!--<router-outlet></router-outlet>-->
	
</div>



