import { Component, OnInit, OnDestroy } from '@angular/core';
import { Message,MessageService } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { Table } from 'primeng/table';
import { SortEvent } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { ViewChild } from '@angular/core'

declare var d3:any;

@Component({
    template: `
		<p-toast styleClass="tibo-myrepo-toast" position="center"></p-toast>
		
		
		<button class="p-my-2" type="button" pButton [label]="hisOrWeklyBoolVal" style="" (click)="historyOrWeeklyBtnClick()"></button>
		
		<p-table #reportoutputfilestable class="tibo-myrepo-output-files-table" [value]="myReportRecords" [sortField]="tableSortColumn" [sortOrder]="tableSortDir" 
		[scrollable]="true" scrollHeight="flex" scrollHeight="flex" (sortFunction)="customSort($event)" [customSort]="true"
		[style]="{'position':'relative','left':'0px','width':'100%','top':'0px','height':'calc(100% - 115px)','background-color':'#f1f1f4'}">
			<ng-template pTemplate="caption">
				<div class="p-d-flex p-jc-between p-ai-center">
					<span class="p-input-icon-left p-ml-auto">
						<label>Showing {{myReportRecords?.length}} of {{totalRecords}}</label>
					</span>
				</div>
			</ng-template>
			<ng-template pTemplate="header">
				<tr>
					<th pSortableColumn="authorizationName" (click)="theadClick()">
						<div class="p-d-flex p-jc-between p-ai-center">
							File Name
							<p-sortIcon field="authorizationName"></p-sortIcon>
							<!--<p-columnFilter type="text" field="authorizationName" display="menu" class="p-ml-auto"></p-columnFilter>-->
						</div>
					</th>
					<th pSortableColumn="authorizationDesc" (click)="theadClick()">
						<div class="p-d-flex p-jc-between p-ai-center">
							Report Type
							<p-sortIcon field="authorizationDesc"></p-sortIcon>
							<!--<p-columnFilter type="text" field="authorizationDesc" display="menu" class="p-ml-auto"></p-columnFilter>-->
						</div>
					</th>
					<th pSortableColumn="createdDate" (click)="theadClick()">
						<div class="p-d-flex p-jc-between p-ai-center">
							Run Date/Time
							<p-sortIcon field="createdDate"></p-sortIcon>
							<!--<p-columnFilter type="date" field="createdDate" display="menu" class="p-ml-auto"></p-columnFilter>-->
							
						</div>
					</th>
					<th>Action</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-reportObj>
				<tr>
					<td>{{reportObj?.authorizationName}}</td>
					<td>{{reportObj?.authorizationDesc}}</td>
					<td>{{reportObj?.createdDate | date:'MM/dd/YYYY h:mm a'}}</td>
					<td>
						<div class="" style="width:max-content; min-width:50px; padding:3px 18px; background-color:#e7f0f9; border-radius:20px; box-sizing:border-box;">
							<button id="" class="" type="button" pButton icon="pi pi-download" pTooltip="Download" tooltipPosition="top" style="background-color:#5ea7e0; width:26px; height:24px; border:0; border-radius:3px; border-color:#398dce;" (click)="downloadOutputFileBtnClick(reportObj?.authorizationId,reportObj?.authorizationName)"></button>
						</div>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage">
				<tr>
					<td>{{ tableEmptyMessage }}</td>
				</tr>
			</ng-template>
		</p-table>
	
	<p-paginator #pagenoindicator [rows]="100" [totalRecords]="totalRecords" (onPageChange)="paginate($event)"
	[style]="{'position':'absolute','left':'24px','width':'calc(100% - 48px)','bottom':'10px','height':'45px','border-radius':'0px','padding':'0px'}"></p-paginator>
	`,
	styleUrls: ['./my-reports.component.css'],
	providers: [MessageService]
})


export class AllOutputFiles implements OnInit, OnDestroy {

	@ViewChild('pagenoindicator', {static: true}) paginator: Paginator;
	
	@ViewChild('reportoutputfilestable', {static: true}) table: Table;
	
	errorMsg = "";
	paginatorPageNo = 1;
	totalRecords = 0;
	myReportRecords = [];
	hisOrWeklyBoolVal = "History";
	customSortBool:boolean = false;
	tableEmptyMessage:string = "";
	
	constructor(private messageService: MessageService, public config: DynamicDialogConfig, private http: HttpClient) { }
	
	requestPayload = {"sort":[{"property":"createdDate","dir":"DESC"}],"filters":[],"filterType":"and"};
	tableSortColumn = "createdDate";
	tableSortDir = -1;
	
	outputFilesHistorySubscrip: Subscription;
	outputFilesDownLoadSubscrip: Subscription;

    ngOnInit() {
        
		this.outputFilesHistorySubscrip = this.http.post('api/v1/report/'+this.config.data?.reportId+'/output/search?depth=0&limit=100&page='+this.paginatorPageNo, this.requestPayload,
		{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' }).subscribe( (recordsResp: any) => {
			
			d3.selectAll(".tibo-myrepo-output-files-spinner").style("display","none") // hidding reports container spinner
			
			this.totalRecords = recordsResp.body.totalRecords;
			recordsResp.body?.records ? this.myReportRecords = recordsResp.body.records : this.myReportRecords = [];
			
			this.myReportRecords.forEach(d => {
				if(d?.createdDate != null){
					d.createdDate = new Date(d?.createdDate);
				}
			});

			this.myReportRecords.length > 0 ? this.tableEmptyMessage = "" : this.tableEmptyMessage = "No records found";
			
		}, error => { 
			d3.selectAll(".tibo-myrepo-output-files-spinner").style("display","none") // hidding reports container spinner
			if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
			else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
			else { this.errorMsg = "Please try after some time." }
			
			this.addSingle();
		})
    }
	
	ngOnDestroy() {
		if(this.outputFilesHistorySubscrip){
			this.outputFilesHistorySubscrip.unsubscribe();
		}
		if(this.outputFilesDownLoadSubscrip){
			this.outputFilesDownLoadSubscrip.unsubscribe();
		}
	}
	
	theadClick(){
		this.customSortBool = true;
	}

    customSort(event: SortEvent){
		
		this.tableSortColumn = event.field;
		this.tableSortDir = event.order;
		
		this.table.reset();
		this.table.sortField = this.tableSortColumn;
		this.table._sortOrder = this.tableSortDir;
		
		if(this.customSortBool){
			
			this.customSortBool = false;
			
			this.requestPayload.sort = [];
			
			if(event.order == 1){
				this.requestPayload.sort.push({ property: event.field, dir: "ASC"})
			}
			else if(event.order == -1){
				this.requestPayload.sort.push({ property: event.field, dir: "DESC"})
			}
			
			d3.selectAll(".tibo-myrepo-output-files-spinner").style("display","block") // showing reports container spinner
			
			if(this.hisOrWeklyBoolVal == "History"){
			
				this.outputFilesHistorySubscrip = this.http.post('api/v1/report/'+this.config.data?.reportId+'/output/search?depth=0&limit=100&page='+this.paginatorPageNo, this.requestPayload,
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' }).subscribe( (recordsResp: any) => {
					
					d3.selectAll(".tibo-myrepo-output-files-spinner").style("display","none") // hidding reports container spinner
					
					this.totalRecords = recordsResp.body.totalRecords;
					let sortedData = [];
					recordsResp.body?.records ? sortedData = recordsResp.body.records : sortedData = [];
					
					sortedData.forEach(d => {
						if(d?.createdDate != null){
							d.createdDate = new Date(d?.createdDate);
						}
					});
					
					event.data.splice(0, event.data.length)
					
					sortedData.forEach(d => { event.data.push(d) })

					sortedData.length > 0 ? this.tableEmptyMessage = "" : this.tableEmptyMessage = "No records found";
					
				}, error => { 
					d3.selectAll(".tibo-myrepo-output-files-spinner").style("display","none") // hidding reports container spinner
					if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
					else { this.errorMsg = "Please try after some time." }
					
					this.addSingle();
				})
				
			}
			else {
			
				this.outputFilesHistorySubscrip = this.http.post('api/v1/report/'+this.config.data?.reportId+'/output/history/search?depth=0&limit=100&page='+this.paginatorPageNo, this.requestPayload,
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' }).subscribe( (recordsResp: any) => {
					
					d3.selectAll(".tibo-myrepo-output-files-spinner").style("display","none") // hidding reports container spinner
					
					this.totalRecords = recordsResp.body.totalRecords;
					let sortedData = [];
					recordsResp.body?.records ? sortedData = recordsResp.body.records : sortedData = [];
					
					sortedData.forEach(d => {
						if(d?.createdDate != null){
							d.createdDate = new Date(d?.createdDate);
						}
					});
					
					event.data.splice(0, event.data.length)
					
					sortedData.forEach(d => { event.data.push(d) })

					sortedData.length > 0 ? this.tableEmptyMessage = "" : this.tableEmptyMessage = "No records found";

				}, error => { 
					d3.selectAll(".tibo-myrepo-output-files-spinner").style("display","none") // hidding reports container spinner
					if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
					else { this.errorMsg = "Please try after some time." }
					
					this.addSingle();
				})
				
			}
		}
	}
	
	paginate(event) {
		
		this.paginatorPageNo = event.page + 1;
		this.myReportRecords = [];
		
		d3.selectAll(".tibo-myrepo-output-files-spinner").style("display","block") // showing reports container spinner
		
		if(this.hisOrWeklyBoolVal == "History"){
		
			this.outputFilesHistorySubscrip = this.http.post('api/v1/report/'+this.config.data?.reportId+'/output/search?depth=0&limit=100&page='+this.paginatorPageNo, this.requestPayload,
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' }).subscribe( (recordsResp: any) => {
				
				d3.selectAll(".tibo-myrepo-output-files-spinner").style("display","none") // hidding reports container spinner
				
				//this.totalRecords = recordsResp.body.totalRecords;
				recordsResp.body?.records ? this.myReportRecords = recordsResp.body.records : this.myReportRecords = [];
				
				this.myReportRecords.forEach(d => {
					if(d?.createdDate != null){
						d.createdDate = new Date(d?.createdDate);
					}
				});
				
			}, error => { 
				d3.selectAll(".tibo-myrepo-output-files-spinner").style("display","none") // hidding reports container spinner
				if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
				else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
				else { this.errorMsg = "Please try after some time." }
				
				this.addSingle();
			})
		
		}
		else {
			
			this.outputFilesHistorySubscrip = this.http.post('api/v1/report/'+this.config.data?.reportId+'/output/history/search?depth=0&limit=100&page='+this.paginatorPageNo, this.requestPayload,
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' }).subscribe( (recordsResp: any) => {
				
				d3.selectAll(".tibo-myrepo-output-files-spinner").style("display","none") // hidding reports container spinner
				
				//this.totalRecords = recordsResp.body.totalRecords;
				recordsResp.body?.records ? this.myReportRecords = recordsResp.body.records : this.myReportRecords = [];
				
				this.myReportRecords.forEach(d => {
					if(d?.createdDate != null){
						d.createdDate = new Date(d?.createdDate);
					}
				});
				
			}, error => { 
				d3.selectAll(".tibo-myrepo-output-files-spinner").style("display","none") // hidding reports container spinner
				if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
				else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
				else { this.errorMsg = "Please try after some time." }
				
				this.addSingle();
			})
			
		}
		
	}
	
	historyOrWeeklyBtnClick(){
		
		this.tableSortColumn = "createdDate";
		this.tableSortDir = -1;
		
		/*this.table.reset();
		this.table.sortField = this.tableSortColumn;
		this.table._sortOrder = this.tableSortDir;*/
		
		this.tableEmptyMessage = "";

		this.requestPayload = {"sort":[{"property":"createdDate","dir":"DESC"}],"filters":[],"filterType":"and"};
		this.paginatorPageNo = 1;
		this.myReportRecords = [];
		this.totalRecords = 0;
		
		d3.selectAll(".tibo-myrepo-output-files-spinner").style("display","block") // showing reports container spinner
		
		if(this.hisOrWeklyBoolVal == "History"){
		
			this.hisOrWeklyBoolVal = "Weekly";
			
			this.outputFilesHistorySubscrip = this.http.post('api/v1/report/'+this.config.data?.reportId+'/output/history/search?depth=0&limit=100&page='+this.paginatorPageNo, this.requestPayload,
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' }).subscribe( (recordsResp: any) => {
				
				d3.selectAll(".tibo-myrepo-output-files-spinner").style("display","none") // hidding reports container spinner
				
				this.totalRecords = recordsResp.body.totalRecords;
				recordsResp.body?.records ? this.myReportRecords = recordsResp.body.records : this.myReportRecords = [];
				
				this.myReportRecords.forEach(d => {
					if(d?.createdDate != null){
						d.createdDate = new Date(d?.createdDate);
					}
				});
				
				this.myReportRecords.length > 0 ? this.tableEmptyMessage = "" : this.tableEmptyMessage = "No records found";
				
				this.paginator.changePage(0);
				
			}, error => { 
				d3.selectAll(".tibo-myrepo-output-files-spinner").style("display","none") // hidding reports container spinner
				if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
				else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
				else { this.errorMsg = "Please try after some time." }
				
				this.addSingle();
			})
			
		}
		else {
		
			this.hisOrWeklyBoolVal = "History";
			
			this.outputFilesHistorySubscrip = this.http.post('api/v1/report/'+this.config.data?.reportId+'/output/search?depth=0&limit=100&page='+this.paginatorPageNo, this.requestPayload,
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' }).subscribe( (recordsResp: any) => {
				
				d3.selectAll(".tibo-myrepo-output-files-spinner").style("display","none") // hidding reports container spinner
				
				this.totalRecords = recordsResp.body.totalRecords;
				recordsResp.body?.records ? this.myReportRecords = recordsResp.body.records : this.myReportRecords = [];
				
				this.myReportRecords.forEach(d => {
					if(d?.createdDate != null){
						d.createdDate = new Date(d?.createdDate);
					}
				});
				
				this.myReportRecords.length > 0 ? this.tableEmptyMessage = "" : this.tableEmptyMessage = "No records found";
				
				this.paginator.changePage(0);
				
			}, error => { 
				d3.selectAll(".tibo-myrepo-output-files-spinner").style("display","none") // hidding reports container spinner
				if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
				else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
				else { this.errorMsg = "Please try after some time." }
				
				this.addSingle();
			})
			
		}
		
	}
	
	downloadOutputFileBtnClick(fileId,fileName){
		
		if(fileName.split('.').pop().toLowerCase() == "xls"){
		
			this.outputFilesDownLoadSubscrip = this.http.get('api/v1/file/'+fileId+'/export', 
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, 'responseType': 'blob' as 'json', observe: 'response' })
				.subscribe( (response: any) => {
		
					let blob = new Blob([response.body],{type:'application/vnd.ms-excel'});
						
					var a = document.createElement("a");
					a.href = URL.createObjectURL(blob);
					a.download = fileName;
					a.click();
			
				}, error => { 
					if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
					else { this.errorMsg = "Please try after some time." }
					
					this.addSingle();
				})
			
		}
		else if(fileName.split('.').pop().toLowerCase() == "xlsx"){
		
			this.outputFilesDownLoadSubscrip = this.http.get('api/v1/file/'+fileId+'/export',
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, 'responseType': 'blob' as 'json', observe: 'response' })
				.subscribe( (response: any) => {
				
					let blob = new Blob([response.body],{type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
							
					var a = document.createElement("a");
					a.href = URL.createObjectURL(blob);
					a.download = fileName;
					a.click();
					
				}, error => { 
					if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
					else { this.errorMsg = "Please try after some time." }
					
					this.addSingle();
				})
		}
		else if(fileName.split('.').pop().toLowerCase() == "csv"){
			
			this.outputFilesDownLoadSubscrip = this.http.get('api/v1/file/'+fileId+'/export', 
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, 'responseType': 'text', observe: 'response' })
				.subscribe( (response: any) => {
				
					let blob = new Blob([response.body],{type:'text/csv;charset=utf-8'});
						
					var a = document.createElement("a");
					a.href = URL.createObjectURL(blob);
					a.download = fileName;
					a.click();
					
				}, error => { 
					if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
					else { this.errorMsg = "Please try after some time." }
					
					this.addSingle();
				})
			
		}
		else if(fileName.split('.').pop().toLowerCase() == "txt"){
		
			this.outputFilesDownLoadSubscrip = this.http.get('api/v1/file/'+fileId+'/export',
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, 'responseType': 'text', observe: 'response' })
				.subscribe( (response: any) => {
					
					let blob = new Blob([response.body],{type:'text/text'})
						
					var a = document.createElement("a");
					a.href = URL.createObjectURL(blob);
					a.download = fileName;
					a.click();
					
				}, error => { 
					if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
					else { this.errorMsg = "Please try after some time." }
					
					this.addSingle();
				})
		
		}
		else if(fileName.split('.').pop().toLowerCase() == "pdf"){
			
			this.outputFilesDownLoadSubscrip = this.http.get('api/v1/file/'+fileId+'/export',
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, 'responseType': 'blob' as 'json', observe: 'response' })
				.subscribe( (response: any) => {
					
					let blob = new Blob([response.body],{type:'application/pdf'})
						
					var a = document.createElement("a");
					a.href = URL.createObjectURL(blob);
					a.download = fileName;
					a.click();
					
				}, error => { 
					if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
					else { this.errorMsg = "Please try after some time." }
					
					this.addSingle();
				})
			
		}
		else if(fileName.split('.').pop().toLowerCase() == "doc"){
			
			this.outputFilesDownLoadSubscrip = this.http.get('api/v1/file/'+fileId+'/export',
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, 'responseType': 'blob' as 'json', observe: 'response' })
			.subscribe( (response: any) => {
			
				let blob = new Blob([response.body],{type:'application/msword'});
					
				var a = document.createElement("a");
				a.href = URL.createObjectURL(blob);
				a.download = fileName;
				a.click();
			
			}, error => { 
				if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
				else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
				else { this.errorMsg = "Please try after some time." }
				
				this.addSingle();
			})
			
		}
		else if(fileName.split('.').pop().toLowerCase() == "pptx"){
		
			this.outputFilesDownLoadSubscrip =  this.http.get('api/v1/file/'+fileId+'/export',
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, 'responseType': 'blob' as 'json', observe: 'response' })
			.subscribe( (response: any) => {
				
				let blob = new Blob([response.body],{type:'application/vnd.openxmlformats-officedocument.presentationml.presentation'});
					
				var a = document.createElement("a");
				a.href = URL.createObjectURL(blob);
				a.download = fileName;
				a.click();
				
			}, error => { 
				if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
				else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
				else { this.errorMsg = "Please try after some time." }
				
				this.addSingle();
			})
		
		}
	}
	
	addSingle() { this.messageService.add({severity:'error', summary:'', detail:this.errorMsg}); }

    clear() { this.messageService.clear(); }
}