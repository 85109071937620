import { Component, OnInit, ElementRef, ChangeDetectorRef, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { MenuItem, Message, MessageService } from 'primeng/api';
import { ViewChild } from '@angular/core'

import { Router } from "@angular/router";
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DrillThruOutput } from '../my-dashboard/drill-thru-output';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DashboardService } from '../dashboard/dashboard.service';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridReadyEvent, ColumnApi, SideBarDef, GridOptions } from '@ag-grid-community/core';
import { BuildReportService } from '../build-report/build-report.service';
//import { }

declare var d3: any;
declare var acinfotech: any;

interface City {
	name: string,
	code: string
}

interface DashboardInfo {
	name: string,
	code: string
}
@Component({
	selector: 'app-ask-questions',
	templateUrl: './ask-questions.component.html',
	styleUrls: ['./ask-questions.component.css']
})
export class AskQuestionsComponent implements OnInit {


	@ViewChild('data_disco_business_metric_text_id') inputElement: ElementRef;

	reports: City[];
	dashboards: City[];
	widgets: City[];
	ref: DynamicDialogRef;
	domainsArray = [];
	selectedDomain: any;
	businessMetricText: any;
	selectedCity: City;
	tempObj = {};
	repomatricAndWidgetsArray = [];
	shareDashDialBoxDisplayBool: boolean = false;
	alertMessage: Message[];
	second: boolean = false;
	sec: boolean = false;
	first: boolean = true;
	topbar: boolean = true;
	widgetdash: boolean = false;
	maindash: boolean = true;
	headerbtn: boolean = false;
	shareDashUserNamesArr: any;
	shareDashSuccMsg: string;
	searchText: string = '';
	reportList: any = [];
	dashList: any = [];
	widgetList: any = [];
	detail1: any; detail2: any; detail3: any; detail4: any; detail5: any; detail6: any; detail7: any; detail8: any; detail9: any; detail10: any; detail11: any; detail13: any; detail12: any;
	chartElement1: any; chartElement2: any; chartElement3: any; chartElement4: any; chartElement5: any; chartElement6: any; chartElement7: any; chartElement8: any; chartElement9: any;
	chartElement10: any; chartElement11: any; chartElement12: any; chartElement13: any;
	widgetContainer: boolean = false;
	selectedChart: any;
	selectedEvent: any = {};
	chartId: any;
	showDashboard: boolean = false;
	showWidgets: boolean = false;
	showReport: boolean = false;
	recognition: any;
	isListening: boolean = false;
	spokenText: string = '';
	//Ag grid
	selectedColumn: string[] = [];
	coldata: any = [];
	public gridOptions: GridOptions;
	columnDefs: ColDef[] = [];
	public rowData: any = [];
	public defaultColDef: ColDef = {
		cellStyle: { 'padding-left': 0, 'text-align': "center" },
		flex: 1,
		minWidth: 150,
		maxWidth: 250,
		resizable: true,
		sortable: true,
		filter: true,
		editable: true,
		getQuickFilterText: params => {
			return params.value.name;
		}

	};
	leaveData: any = [];
	coldata1: any = [];
	gridApi: any;
	gridShow = false;
	disData: any = [];
	searchHide: boolean = false;
	Suggestions: { question: string; "typeid": string; id: number; }[];
	filteredItems: any;
	chartElement: any;
	isWriting: boolean = false;
	isWritingText: any;
	constructor(private customerService: BuildReportService, private cdRef: ChangeDetectorRef, private messageService: MessageService, private http: HttpClient, public dialogService: DialogService, private router: Router, private dashService: DashboardService) {
		this.gridOptions = {
			rowSelection: 'single',
			context: {},
			pagination: true,
			//paginationPageSize: 25,
			paginationAutoPageSize: true,
			onGridReady: (params) => {
				params.api.sizeColumnsToFit();
			},
			onGridSizeChanged: (params) => {
				params.api.sizeColumnsToFit();
			},
			getMainMenuItems: () => {
				return ['generalMenuTab', 'columnsMenuTab']
			},


		}
		this.recognition = new (window as any).webkitSpeechRecognition();
		this.recognition.continuous = true;
		this.recognition.interimResults = true;
	}
	colorSetOne = ["#00A9E9", "#BC851C", "#004990", "#87189D", "#C5003E", "#28724F", "#92C1E9", "#CEB888", "#678787", "#B884CB", "#EF6079", "#85B09A"]
	colorSetTwo = ["#E95044", "#F28A4C", "#F0D444", "#A2DF9C", "#93DBE4", "#B091D8", "#DD1D24", "#E35724", "#EFE60C", "#39A654", "#0C9FDC", "#812888"] //third set
	colorSetThree = ["#01204F", "#006A8D", "#03848C", "#64A79D", "#E9CFA1", "#F2A465", "#EF6727", "#F03113", "#E6DCAB", "#E6DCAB", "#DDBD36", "#926A00"]; //yellow
	colorSetFour = ["#f7e37a", "#fbdd38", "#ebc403", "#f8c32a", "#e2a83d", "#f3b064", "#f68933", "#f5636d", "#ff4e5b", "#e42f24", "#bc2025", "#c02024"]; //yellow to red	
	colorSetFive = ["#012c66", "#86bedf", "#cfe8f7", "#cb2624", "#e34746", "#f47473", "#046365", "#13a1a4", "#68dddb", "#e7c414", "#ffe242", "#ffe87b"]; //Neon
	colorSetSix = ["#6E2C00", "#873600", "#A04000", "#BA4A00", "#D35400", "#DC7633", "#E59866", "#EDBB99", "#F6DDCC", "#FBEEE6", "#fff2eb", "#fff7f2"];// 6 cube set brown
	colorSetSeven = ["#002E55", "#014C7C", "#005B7C", "#008098", "#0E89B5", "#0695AA", "#35B5C1", "#397C82", "#87C9CF", "#ACCBC6", "#C0E0DB", "#E4EBEA"]; //Brown color set
	colorSetEight = ["#3d818c", "#63adb3", "#95cdcf", "#b9d0d0", "#cfe6e6", "#e5eeee", "#eeebe3", "#c3bca1", "#c3bca1", "#c4ad91", "#b89f87", "#a08d76"]; //4 cube set



	selected = {
		color: '#3580db'
	}

	resultsBool: boolean = false;
	dataDiscoverySubscrip: Subscription;
	reportsAndMatricsSubscrip: Subscription;
	dashboardWidgetsSubscrip: Subscription;
	widgetContainerBool: boolean = false;


	dashboardInfo: DashboardInfo[];
	selectedDashboard: DashboardInfo;
	errorMsg = "";

	dashboardName: string = "";
	totalWidgets = [];
	eachWidgetRecords = [];
	randomIdArray = [];
	parameterizedDashboardRandomArray = [];
	tempArray = [];
	dashboardRecords = [];
	totalRows = []
	totalColumns = []
	rowIndex = [];
	finalCallCounter = 0;
	parameterElementsArray = [];
	parameterElementsDataArray = [];
	parameterizedFilterArray = [];
	filterPanelToggleBool: boolean = false;
	requestPayloadParametersArray = [];
	userParameterObject = {};
	requestPayloadParameterObj = { filterType: "and", filters: [], sort: [], parameters: [] };
	defaultValuesOfParameterArray = [];
	cascadeParametersObject = {};
	dashboardsListSubscrip: Subscription;
	defaultDashboardSubscrip: Subscription;
	getADashboardSubscrip: Subscription;
	getWidgetDataSubscrip: Subscription;
	getPrmDashItmRecsSubscrip: Subscription;
	getPrmDashCasRecsCalSubscrip: Subscription;
	getPrmDashCasRecsMultSubscrip: Subscription;
	getPrmDashCasRecsDropSubscrip: Subscription;
	getPrmDashCasRecsRadioSubscrip: Subscription;
	getPrmDashWidgetDataSubscrip: Subscription;
	usersNameSubscrip: Subscription;
	shareDashSubscrip: Subscription;
	viewDashboardsList = [];
	dashboardRefreshTime: any;
	refreshCountDownTimerStr: string = "";
	refreshInterval;
	countDownLabel: any;
	folderPanelToggleBool: boolean = false;
	// dashboardInfoObject: any;
	selectedChartsColorSet = "three";
	moreItems: MenuItem[];
	dashboardInfoObject: any;
	placeHolderValue = 'What can I help you find?'
	// firstpanel = true;
	secondpanel = false;
	widget = true;
	report = false;
	display: boolean = false;
	texts: string[];
	results: string[];
	usersArray = [];
	values2: string[];
	shareDashTabIndex = 0; // setting default value as 0 (1st tab) for share dashboard modal window's tab
	selectedItemType = "";

	categories: any[];
	selectedCategories: string[] = [];

	dashboardModeBool: boolean = false;
	//dashMaxAndMinBtn: string = "pi pi-window-maximize";
	dashMaxAndMinBtn: string = "Full Screen Mode";
	dashMaxAndMinBtnTTip: string = "Maximize";

	parametersSubscrip: Subscription;
	showOrHideSeriesBool = true;
	showOrHideLegendBool = true;
	shareDashSrchUserNamesArr: string[];
	shareDashEmailIdsArr = [];
	wigetBackgroundColor: any = "#d9d9d9";
	value: any;
	backColor: any;
	
	ngOnInit(): void {
		this.chartId = '';
		this.chartElement = '';
		this.countDownLabel = document.getElementById('mydash-countdown-timer-label') as HTMLElement;
		this.moreItems = [
			{
				label: 'Share',
				id: "1",
				command: e => this.optionsFunc(e),
				icon: "pi pi-share-alt"
			},

			{
				label: 'Hide/Show',
				icon: "pi pi-eye",
				items: [{
					label: 'Show Series Number',
					id: "2",
					command: e => this.showLegend(e),
					icon: 'pi pi-eye',
				},
				{
					label: 'Show Legend Number',
					id: "3",
					command: e => this.showLegend(e),
					icon: 'pi pi-eye',
				},
				]
			},
		];

		d3.select("html").on("click", function () {
			if (!d3.event.target.hasAttribute("non-hide")) {
				d3.select('#tibo-mydash-dashlist-container-id').style("display", "none");
			}
		})
		d3.select("html").on("click", function () {
			if (!d3.event.target.hasAttribute("non-hide")) {
				d3.select('#tibo-mydash-dashlist-container').style("display", "none");
			}
		})


		d3.select(".tibo-topbar-title").text("Home / Data Discovery");
		d3.select(".tibo-data-dis-result-spinner").style("display", "none") // hidding domain and keyword spinner
		d3.select(".tibo-data-dis-output-spinner").style("display", "none") // hiding report, dasbhoard or widget output container spinner

		this.categories = [
			{ name: 'Reports', key: 'R' },
			{ name: 'Dashboards', key: 'D' },
			{ name: 'Widgets', key: 'W' },
		];

		this.selectedCategories = this.categories;
		this.reports = [
			{ name: 'Leave Detail', code: 'NY' },
			{ name: 'Disability Detail', code: 'NY' }
		]

		this.dashboards = [
			{ name: 'Leave', code: 'NY' },
			{ name: 'Disability', code: 'NY' },
			//{name: 'Call Center Analysis of 2021', code: 'RM'},
			//{name: 'Call Center Analysis of 2020', code: 'LDN'},
		];

		this.widgets = [
			{ name: 'New Leave By Reason', code: 'NY' },
			{ name: 'Continous vs Intermittent', code: 'RM' },
			{ name: 'Open Leave By Reason', code: 'LDN' },
		];
		this.rowData = 0;
		this.columnDefs = [];
		this.Suggestions = [
			{
				"question": "What are the new claims?",
				"typeid": "widget",
				"id": 1
			},
			{
				"question": "How many new claims have been opened?",
				"typeid": "widget",
				"id": 1
			},
			{
				"question": "New claims open in the period",
				"typeid": "widget",
				"id": 1
			},
			{
				"question": "What are the new leaves?",
				"typeid": "widget",
				"id": 12
			},
			{
				"question": "List the new leaves by reason?",
				"typeid": "widget",
				"id": 12
			},
			{
				"question": "What are the claims paid in millions?",
				"typeid": "widget",
				"id": 3
			},
			{
				"question": "What is the approval-denial rate?",
				"typeid": "widget",
				"id": 4
			},
			{
				"question": "What is the rate of approval denial?",
				"typeid": "widget",
				"id": 4
			},
			{
				"question": "What were the claims paid in the previous year?",
				"typeid": "widget",
				"id": 7
			},
			{
				"question": "What are the total number of claims?",
				"typeid": "widget",
				"id": 11
			},
			{
				"question": "How many new claims",
				"typeid": "widget",
				"id": 1
			},
			{
				"question": "How many claims were paid",
				"typeid": "widget",
				"id": 3
			},
			{
				"question": "How many new leaves",
				"typeid": "widget",
				"id": 12
			},
			{
				"question": "How many open leaves",
				"typeid": "widget",
				"id": 9
			},
			{
				"question": "Disability Dashboard",
				"typeid": "Dashboard",
				"id": 2019
			},
			{
				"question": "Looking for a disability dashboard",
				"typeid": "Dashboard",
				"id": 2019
			},
			{
				"question": "Leave Dashboard",
				"typeid": "Dashboard",
				"id": 2020
			}
		]
		// this.Suggestions = [
		// 	{
		// 		"question": "What are the new claims?",
		// 		"typeid": "widget",
		// 		"id": 1
		// 	},
		// 	{
		// 		"question": "What are the new leaves?",
		// 		"typeid": "widget",
		// 		"id": 12
		// 	},
		// 	{
		// 		"question": "What are the claims paid in millions?",
		// 		"typeid": "widget",
		// 		"id": 3
		// 	},
		// 	{
		// 		"question": "What is the approval-denial rate?",
		// 		"typeid": "widget",
		// 		"id": 4
		// 	},
		// 	{
		// 		"question": "What were the claims paid in the previous year?",
		// 		"typeid": "widget",
		// 		"id": 7
		// 	},
		// 	{
		// 		"question": "What are the total number of claims?",
		// 		"typeid": "widget",
		// 		"id": 11
		// 	},
		// 	{
		// 		"question": "How many new claims",
		// 		"typeid": "widget",
		// 		"id": 1
		// 	},
		// 	{
		// 		"question": "How many claims were paid",
		// 		"typeid": "widget",
		// 		"id": 3
		// 	},
		// 	{
		// 		"question": "How many new leaves",
		// 		"typeid": "widget",
		// 		"id": 12
		// 	},
		// 	{
		// 		"question": "How many open leaves",
		// 		"typeid": "widget",
		// 		"id": 9
		// 	},
		// 	{
		// 		"question": "Disibility Dashboard",
		// 		"typeid": "Dashboard",
		// 		"id": 2019
		// 	},
		// 	{
		// 		"question": "Leave Dashboard",
		// 		"typeid": "Dashboard",
		// 		"id": 2020
		// 	}

		// ];
		acinfotech.requestType(true);
	}
	filterCountry(event) {
		this.chartId = ''
		console.log(event)
		this.filteredItems = this.Suggestions.filter(item => item?.question.toLowerCase().includes(event.query.toLowerCase()));
	}
	clearText(){
		this.searchText = '';
		this.filteredItems = [];
		this.widgetContainerBool = false;
	}
	onOptionSelect(event) {
		// this.chartId = '';
		console.log(event.value)
		this.widget = false;
		this.isWriting = false;
		this.chartId = "chart" + event.id;
		this.widget = false;
		this.isWritingText = '';
		this.searchText = event.question;
		this.report = true;
		this.isListening = false;
		this.resultsBool = false;
		// console.log(this.resultsBool)
		// this.reloadDashboardWithNewColorSetFunc();
		// this.widgetContainerBool = true;
		this.selectedItemType = event.typeid;
		if (event.id == 12 || event.id == 13 || event.id == 9) { this.dashboardName = "Leave" } else { this.dashboardName = 'Disability' }

		this.getDashboard(event);
	}
	onGridReady(params) {
		this.gridApi = params.api;
	}
	ngOnDestroy() {
		if (this.dataDiscoverySubscrip) {
			this.dataDiscoverySubscrip.unsubscribe();
		}
		if (this.reportsAndMatricsSubscrip) {
			this.reportsAndMatricsSubscrip.unsubscribe();
		}
		if (this.dashboardWidgetsSubscrip) {
			this.dashboardWidgetsSubscrip.unsubscribe();
		}
		if (this.getADashboardSubscrip) {
			this.getADashboardSubscrip.unsubscribe();
		}
		if (this.getWidgetDataSubscrip) {
			this.getWidgetDataSubscrip.unsubscribe();
		}
	}

	dashboardMode() {

		if (!this.dashboardModeBool) {
			this.headerbtn = true;
			// this.topbar = true;
			// this.first = true;
			d3.select(".abbc").style({ "position": "absolute", "left": "0px", "top": "0px", "width": "100%", "height": "100vh", "background": "#fff","border-top": "7px solid white" })
			d3.select(".tibo-data-disco-results-container").style({ "display": "none" })
			d3.select(".tibo-data-disco-res-output-cont").style({ "position": "absolute", "left": "0px", "top": "0px", "width": "96%", "height": "99%" })
			d3.select(".top").style("display", "none");
			this.dashboardModeBool = true;

			//this.dashMaxAndMinBtn = "pi pi-window-minimize";	
			this.dashMaxAndMinBtn = "Widget View Mode";
			this.dashMaxAndMinBtnTTip = "Minimize";

			window.dispatchEvent(new Event('resize'));
		}
		// else {
		// 	this.headerbtn = true;
		// 	// this.topbar = true;
		// 	// this.first = true;
		// 	d3.select(".abbc").style({"position":"relative","float":"left","width":"100%","height":"calc(100vh - 170px)"})
		// 	d3.select(".tibo-data-disco-results-container").style({"display":"none"})
		// 	//d3.select(".tibo-data-disco-res-output-cont").style({"position":"absolute","top":"25.6%","width":"calc(100% - 40px)","height":"580px"})
		// 	d3.select(".top").style("display","none");
		// 	this.dashboardModeBool = false;

		// 	//this.dashMaxAndMinBtn = "pi pi-window-maximize";
		// 	this.dashMaxAndMinBtn = "Full Screen Mode";
		// 	this.dashMaxAndMinBtnTTip = "Maximize";

		// 	window.dispatchEvent(new Event('resize'));
		// }
	}

	domainsOnChange(event) {
		this.selectedDomain = event.value;
		// console.log(this.selectedDomain)

		//this.businessMetricText = document.getElementById("data-disco-business-metric-text-id") as HTMLInputElement;
		//this.inputElement.nativeElement.focus()

		setTimeout(() => {
			this.inputElement.nativeElement.focus()
		}, 250)

	}


	onSearchBtnClick() {

		d3.select(".tibo-data-dis-result-spinner").style("display", "block") // showing domain and keyword spinner

		this.resultsBool = false; // hiding the reports, dashboards, and widgets boxes container

		this.widgetContainerBool = false;

		this.selectedCity = { name: "", code: "" };
		this.reportList = [];
		this.dashList = [];
		this.widgetList = [];
		this.filteredItems = [];
		this.dashboardName = "";
		this.widget = false;
		this.report = true;
		if (this.searchText.trim()) {
			this.reports.forEach(data => {
				if (data.name.toLowerCase().includes(this.searchText.toLowerCase())) {
					this.reportList.push(data)
				}
			});
			this.dashboards.forEach(data => {
				if (data.name.toLowerCase().includes(this.searchText.toLowerCase())) {
					this.dashList.push(data)
				}
			});
			this.widgets.forEach(data => {
				if (data.name.toLowerCase().includes(this.searchText.toLowerCase())) {
					this.widgetList.push(data)
				}
			});
			console.log(this.reportList);
			setTimeout(() => {
				d3.selectAll(".data-disc-reports-plistbox, .data-disc-dashboards-plistbox, .data-disc-widgets-plistbox").style("display", "block");

				d3.select(".tibo-data-dis-result-spinner").style("display", "none") // hidding domain and keyword spinner

				this.resultsBool = true; // showing reports, dashboards, and reports container

			}, 10)
		}

	}


	checkboxClick() {

		d3.selectAll(".data-disc-reports-plistbox, .data-disc-dashboards-plistbox, .data-disc-widgets-plistbox").style("display", "none");

		this.selectedCategories.forEach((d: any) => {
			if (d.name == "Reports") {
				d3.select(".data-disc-reports-plistbox").style("display", "block")
			}
			else if (d.name == "Dashboards") {
				d3.select(".data-disc-dashboards-plistbox").style("display", "block")
			}
			else if (d.name == "Widgets") {
				d3.select(".data-disc-widgets-plistbox").style("display", "block")
			}
		})
	}

	itemSelectedFunc(event, type) {
		this.headerbtn = false;
		this.widgetContainerBool = true;
		this.resultsBool = false;
		this.dashboardName = "";
		d3.select(".tibo-data-dis-output-spinner").style("display", "block") // showing report, dasbhoard or widget output container spinner
		this.selectedItemType = type;
		this.selectedEvent = { name: event.value.name, code: event.value.code }
		console.log(this.selectedEvent);

		this.getDashboard("");


	}

	getDashboard(event: any) {
		// this.topbar = false;
		d3.select(document).selectAll(".d3-tip").remove() // hiding d3-tip tool tip on dashboard change
		d3.select(".tibo-mydash-dash-spinner").style("display", "block") //  showing dashboard spinner
		d3.select(".tibo-data-disco-res-output-cont").style({ "display": "block" })
		this.headerbtn = false;
		setTimeout(() => {
			this.chartElement1 = d3.select('#chart1');
			this.detail1 = {
				data: [
					{
						"YEAR": "2021",
						"STD": 1759,
						"VP": 518
					},
					{
						"YEAR": "2022",
						"STD": 1730,
						"VP": 477
					},
					{
						"YEAR": "2023",
						"STD": 484,
						"VP": 120
					}
				],
				title: "New Claims",
				keyLabel: 'YEAR',
				keyGroup: ['STD', 'VP'],
				xLable: "YEAR",
				yLable: "",
			}
		}, 1000)
		setTimeout(() => {
			this.chartElement2 = d3.select('#chart2')
			this.detail2 = {
				data: [
					{
						"CLIENT": 388,
						"YEAR_VALUE": "2021",
						"AVERAGE_DURATION": 64
					},
					{
						"CLIENT": 388,
						"YEAR_VALUE": "2022",
						"AVERAGE_DURATION": 66
					},
					{
						"CLIENT": 388,
						"YEAR_VALUE": "2023",
						"AVERAGE_DURATION": 75
					}
				],
				title: "Avg Duration(Closed Claims)",
				keyLabel: "YEAR_VALUE",
				keyGroup: "AVERAGE_DURATION",
				xLable: "YEAR",
				yLable: "AVERAGE_DURATION",
			}
		}, 1000)
		setTimeout(() => {
			this.chartElement3 = d3.select('#chart3')
			this.detail3 = {
				data: [
					{
						"YEAR": "2021",
						"STD": 15.27,
						"VP": 6.2
					},
					{
						"YEAR": "2022",
						"STD": 16.09,
						"VP": 5.85
					},
					{
						"YEAR": "2023",
						"STD": 3.74,
						"VP": 1.4
					}
				],
				title: "Claims Paid in Million",
				keyLabel: 'YEAR',
				keyGroup: ['STD', 'VP'],
				xLable: "YEAR",
				yLable: "",
			}
		}, 1000)
		setTimeout(() => {
			this.chartElement4 = d3.select('#chart4')
			this.detail4 = {
				data: [
					{
						"YEAR": "2021",
						"APPROVED": 92,
						"DENIED": 8
					},
					{
						"YEAR": "2022",
						"APPROVED": 88,
						"DENIED": 12
					},
					{
						"YEAR": "2023",
						"APPROVED": 92,
						"DENIED": 8
					}
				],
				title: "Approval Denial Rate ",
				keyLabel: 'YEAR',
				keyGroup: ['APPROVED', 'DENIED'],
				xLable: "",
				yLable: "YEAR",
			}
		}, 1000)
		setTimeout(() => {
			this.chartElement5 = d3.select('#chart5')
			this.detail5 = {
				data: [
					{
						"MATRIX_CLIENT_CODE": 388,
						"DIAGNOSIS": "Pregnancy, childbirth & the puerperium (O00-O9A)",
						"CLAIM": 85
					},
					{
						"MATRIX_CLIENT_CODE": 388,
						"DIAGNOSIS": "Musculoskeletal (M00-M99)",
						"CLAIM": 79
					},
					{
						"MATRIX_CLIENT_CODE": 388,
						"DIAGNOSIS": "Not reported",
						"CLAIM": 34
					},
					{
						"MATRIX_CLIENT_CODE": 388,
						"DIAGNOSIS": "Mental, Behavioral & Neurodevelopmental disorders (F01-F99)",
						"CLAIM": 64
					},
					{
						"MATRIX_CLIENT_CODE": 388,
						"DIAGNOSIS": "Injury, Poisoning (S00-T88)",
						"CLAIM": 72
					}
				],
				title: "Average Duration - Top Diagnose (Previous Year)",
				keyLabel: "DIAGNOSIS",
				keyGroup: "CLAIM",
				xLable: "Avg-duration",
				yLable: "Diagnosis",
			}
		}, 1000)
		setTimeout(() => {
			this.chartElement6 = d3.select('#chart6')
			this.detail6 = {
				data: [
					{
						"MATRIX_CLIENT_CODE": 388,
						"DIAGNOSIS": "Pregnancy, childbirth & the puerperium (O00-O9A)",
						"CLAIM": 112
					},
					{
						"MATRIX_CLIENT_CODE": 388,
						"DIAGNOSIS": "Musculoskeletal (M00-M99)",
						"CLAIM": 78
					},
					{
						"MATRIX_CLIENT_CODE": 388,
						"DIAGNOSIS": "Not reported",
						"CLAIM": 50
					},
					{
						"MATRIX_CLIENT_CODE": 388,
						"DIAGNOSIS": "Mental, Behavioral & Neurodevelopmental disorders (F01-F99)",
						"CLAIM": 80
					},
					{
						"MATRIX_CLIENT_CODE": 388,
						"DIAGNOSIS": "Injury, Poisoning (S00-T88)",
						"CLAIM": 90
					}
				],
				title: "Average Duration - Top Diagnose YTD",
				keyLabel: "DIAGNOSIS",
				keyGroup: "CLAIM",
				xLable: "Avg-duration",
				yLable: "Diagnosis",
			}
		}, 1000)
		setTimeout(() => {
			this.chartElement7 = d3.select('#chart7')
			this.detail7 = {
				data: [
					{
						"CLI_CODE": 388,
						"DIAGNOSIS": "Injury Poisoning (S00-T88)",
						"AMOUNT": 1.69
					},
					{
						"CLI_CODE": 388,
						"DIAGNOSIS": "Mental Behavioral & Neurodevelopmental disorders (F01-F99)",
						"AMOUNT": 2.74
					},
					{
						"CLI_CODE": 388,
						"DIAGNOSIS": "Musculoskeletal (M00-M99)",
						"AMOUNT": 3.35
					},
					{
						"CLI_CODE": 388,
						"DIAGNOSIS": "Not reported",
						"AMOUNT": 0.58
					},
					{
						"CLI_CODE": 388,
						"DIAGNOSIS": "Pregnancy childbirth & the puerperium (O00-O9A)",
						"AMOUNT": 7.22
					}
				],
				title: "Claims Paid In Millions - Top Diagnose (Previous Year) ",
				keyLabel: "DIAGNOSIS",
				keyGroup: "AMOUNT",
				xLable: "Avg-duration",
				yLable: "Diagnosis",
			}
		}, 1000)
		setTimeout(() => {
			this.chartElement8 = d3.select('#chart8')
			this.detail8 = {
				data: [
					{
						"CLI_CODE": 388,
						"DIAGNOSIS": "Injury Poisoning (S00-T88)",
						"AMOUNT": 0.32
					},
					{
						"CLI_CODE": 388,
						"DIAGNOSIS": "Mental Behavioral & Neurodevelopmental disorders (F01-F99)",
						"AMOUNT": 0.66
					},
					{
						"CLI_CODE": 388,
						"DIAGNOSIS": "Musculoskeletal (M00-M99)",
						"AMOUNT": 0.84
					},
					{
						"CLI_CODE": 388,
						"DIAGNOSIS": "Not reported",
						"AMOUNT": 0.24
					},
					{
						"CLI_CODE": 388,
						"DIAGNOSIS": "Pregnancy childbirth & the puerperium (O00-O9A)",
						"AMOUNT": 1.61
					}
				],
				title: "Claims Paid In Millions - Top Diagnose (YTD)",
				keyLabel: "DIAGNOSIS",
				keyGroup: "AMOUNT",
				xLable: "Avg-duration",
				yLable: "Diagnosis",
			}
		}, 1000)
		setTimeout(() => {
			this.chartElement10 = d3.select('#chart10');
			console.log("called 10")
			this.detail10 = {
				data: [
					{
						"STATE": "NC",
						"CLAIMS": 9
					},
					{
						"STATE": "ND",
						"CLAIMS": 2
					},
					{
						"STATE": "PA",
						"CLAIMS": 9
					},
					{
						"STATE": "MD",
						"CLAIMS": 2
					},
					{
						"STATE": "NY",
						"CLAIMS": 30
					},
					{
						"STATE": "KS",
						"CLAIMS": 5
					},
					{
						"STATE": "SC",
						"CLAIMS": 9
					},
					{
						"STATE": "UT",
						"CLAIMS": 2
					},
					{
						"STATE": "TN",
						"CLAIMS": 6
					},
					{
						"STATE": "NH",
						"CLAIMS": 1
					},
					{
						"STATE": "CA",
						"CLAIMS": 508
					},
					{
						"STATE": "MI",
						"CLAIMS": 50
					},
					{
						"STATE": "MN",
						"CLAIMS": 227
					},
					{
						"STATE": "MO",
						"CLAIMS": 23
					},
					{
						"STATE": "WA",
						"CLAIMS": 15
					},
					{
						"STATE": "OTHER",
						"CLAIMS": 5
					},
					{
						"STATE": "OR",
						"CLAIMS": 4
					},
					{
						"STATE": "ME",
						"CLAIMS": 78
					},
					{
						"STATE": "LA",
						"CLAIMS": 16
					},
					{
						"STATE": "WI",
						"CLAIMS": 10
					},
					{
						"STATE": "ID",
						"CLAIMS": 1
					},
					{
						"STATE": "AL",
						"CLAIMS": 2
					},
					{
						"STATE": "OK",
						"CLAIMS": 3
					},
					{
						"STATE": "CT",
						"CLAIMS": 1
					},
					{
						"STATE": "IL",
						"CLAIMS": 240
					},
					{
						"STATE": "WV",
						"CLAIMS": 2
					},
					{
						"STATE": "OH",
						"CLAIMS": 147
					},
					{
						"STATE": "AZ",
						"CLAIMS": 54
					},
					{
						"STATE": "NJ",
						"CLAIMS": 42
					},
					{
						"STATE": "VA",
						"CLAIMS": 92
					},
					{
						"STATE": "MA",
						"CLAIMS": 60
					},
					{
						"STATE": "FL",
						"CLAIMS": 31
					},
					{
						"STATE": "GA",
						"CLAIMS": 7
					},
					{
						"STATE": "KY",
						"CLAIMS": 7
					},
					{
						"STATE": "NE",
						"CLAIMS": 2
					},
					{
						"STATE": "IN",
						"CLAIMS": 20
					},
					{
						"STATE": "CO",
						"CLAIMS": 6
					},
					{
						"STATE": "VT",
						"CLAIMS": 1
					},
					{
						"STATE": "TX",
						"CLAIMS": 143
					},
					{
						"STATE": "AR",
						"CLAIMS": 5
					},
					{
						"STATE": "NV",
						"CLAIMS": 1
					}
				],
				title: "Claims Count By State",
				keyLabel: "STATE",
				keyGroup: "CLAIMS",
				xLable: "MONTH",
				yLable: "",

			}
		}, 1000);
		setTimeout(() => {
			this.chartElement11 = d3.select('#chart11');
			console.log("called 11")
			this.detail11 = {
				data: [
					{
						"Number_of_employees": 1000
					}
				],
				title: "Total No of Claims",
			}
		}, 1000);
		setTimeout(() => {
			this.chartElement9 = d3.select('#chart9');
			this.detail9 = {
				data: [
					{
						"MONTH": "Jan",
						"Own": 17,
						"Child": 4,
						"Military": 0,
						"Newborn": 4,
						"Parent": 7,
						"Personal": 0,
						"Pregnancy": 1,
						"Spouse": 2,
						"Surrogacy": 0
					},
					{
						"MONTH": "Feb",
						"Own": 10,
						"Child": 3,
						"Military": 1,
						"Newborn": 8,
						"Parent": 3,
						"Personal": 4,
						"Pregnancy": 0,
						"Spouse": 5,
						"Surrogacy": 0
					},
					{
						"MONTH": "Mar",
						"Own": 15,
						"Child": 3,
						"Military": 0,
						"Newborn": 4,
						"Parent": 6,
						"Personal": 3,
						"Pregnancy": 0,
						"Spouse": 4,
						"Surrogacy": 0
					},
					{
						"MONTH": "Apr",
						"Own": 12,
						"Child": 1,
						"Military": 1,
						"Newborn": 11,
						"Parent": 4,
						"Personal": 2,
						"Pregnancy": 0,
						"Spouse": 5,
						"Surrogacy": 1
					},
					{
						"MONTH": "May",
						"Own": 8,
						"Child": 0,
						"Military": 0,
						"Newborn": 9,
						"Parent": 5,
						"Personal": 3,
						"Pregnancy": 0,
						"Spouse": 1,
						"Surrogacy": 0
					},
					{
						"MONTH": "Jun",
						"Own": 20,
						"Child": 1,
						"Military": 0,
						"Newborn": 10,
						"Parent": 12,
						"Personal": 2,
						"Pregnancy": 0,
						"Spouse": 7,
						"Surrogacy": 0
					},
					{
						"MONTH": "Jul",
						"Own": 20,
						"Child": 3,
						"Military": 0,
						"Newborn": 8,
						"Parent": 7,
						"Personal": 3,
						"Pregnancy": 1,
						"Spouse": 1,
						"Surrogacy": 0
					},
					{
						"MONTH": "Aug",
						"Own": 25,
						"Child": 3,
						"Military": 0,
						"Newborn": 16,
						"Parent": 5,
						"Personal": 3,
						"Pregnancy": 2,
						"Spouse": 4,
						"Surrogacy": 0
					},
					{
						"MONTH": "Sep",
						"Own": 39,
						"Child": 0,
						"Military": 1,
						"Newborn": 18,
						"Parent": 8,
						"Personal": 3,
						"Pregnancy": 4,
						"Spouse": 3,
						"Surrogacy": 0
					},
					{
						"MONTH": "Oct",
						"Own": 68,
						"Child": 8,
						"Military": 2,
						"Newborn": 41,
						"Parent": 20,
						"Personal": 4,
						"Pregnancy": 16,
						"Spouse": 6,
						"Surrogacy": 0
					},
					{
						"MONTH": "Nov",
						"Own": 87,
						"Child": 4,
						"Military": 1,
						"Newborn": 81,
						"Parent": 23,
						"Personal": 6,
						"Pregnancy": 16,
						"Spouse": 6,
						"Surrogacy": 0
					},
					{
						"MONTH": "Dec",
						"Own": 131,
						"Child": 4,
						"Military": 1,
						"Newborn": 77,
						"Parent": 25,
						"Personal": 4,
						"Pregnancy": 12,
						"Spouse": 2,
						"Surrogacy": 1
					}
				],
				title: " Open Leave By Reason",
				//keyLabel:['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
				keyLabel: "MONTH",
				keyGroup: ["Own", "Child", "Military", "Newborn", "Parent", "Personal", "Pregnancy", "Spouse", "Surrogacy"],
				xLable: "MONTH",
				yLable: "",

			}
		}, 1000);
		setTimeout(() => {
			this.chartElement12 = d3.select('#chart12');
			this.detail12 = {
				data: [
					{
						"Total": 5,
						"Leave Type": "Bereave",
						"Leave_Type": "Bereave"
					},
					{
						"Total": 37,
						"Leave Type": "Child",
						"Leave_Type": "Child"
					},
					{
						"Total": 8,
						"Leave Type": "Military",
						"Leave_Type": "Military"
					},
					{
						"Total": 326,
						"Leave Type": "Newborn",
						"Leave_Type": "Newborn"
					},
					{
						"Total": 1,
						"Leave Type": "Other",
						"Leave_Type": "Other"
					},
					{
						"Total": 519,
						"Leave Type": "Own",
						"Leave_Type": "Own"
					},
					{
						"Total": 134,
						"Leave Type": "Parent",
						"Leave_Type": "Parent"
					},
					{
						"Total": 41,
						"Leave Type": "Personal",
						"Leave_Type": "Personal"
					},
					{
						"Total": 65,
						"Leave Type": "Pregnancy",
						"Leave_Type": "Pregnancy"
					},
					{
						"Total": 53,
						"Leave Type": "Spouse",
						"Leave_Type": "Spouse"
					},
					{
						"Total": 2,
						"Leave Type": "Surrogacy",
						"Leave_Type": "Surrogacy"
					}
				],
				title: "New Leave By Reason",
				keyLabel: "Leave Type",
				keyGroup: "Total",
				xLable: "Leave Type",
				yLable: "Total",

			}
		}, 1000);
		setTimeout(() => {
			this.chartElement13 = d3.select('#chart13')
			this.detail13 = {
				data: [
					{
						"Continuous": 13,
						"Intermittent": 12,
						"Month": "Jan"
					},
					{
						"Continuous": 11,
						"Intermittent": 13,
						"Month": "Feb"
					},
					{
						"Continuous": 11,
						"Intermittent": 13,
						"Month": "Mar"
					},
					{
						"Continuous": 14,
						"Intermittent": 11,
						"Month": "Apr"
					},
					{
						"Continuous": 13,
						"Intermittent": 8,
						"Month": "May"
					},
					{
						"Continuous": 22,
						"Intermittent": 14,
						"Month": "Jun"
					},
					{
						"Continuous": 13,
						"Intermittent": 14,
						"Month": "Jul"
					},
					{
						"Continuous": 29,
						"Intermittent": 16,
						"Month": "Aug"
					},
					{
						"Continuous": 25,
						"Intermittent": 29,
						"Month": "Sep"
					},
					{
						"Continuous": 55,
						"Intermittent": 57,
						"Month": "Oct"
					},
					{
						"Continuous": 76,
						"Intermittent": 75,
						"Month": "Nov"
					},
					{
						"Continuous": 90,
						"Intermittent": 88,
						"Month": "Dec"
					}
				],
				title: "Continous vs Intermittent",
				keyLabel: 'Month',
				keyGroup: ['Continuous', 'Intermittent'],
				xLable: "Month",
				yLable: "",
			}
		}, 1000);
		if (this.selectedItemType == 'report') {
			this.reportData();
		}
		if (this.selectedItemType == 'Dashboard') {
			this.Dashboard();
		}
		if (this.selectedItemType == 'widget') {
			this.widgetData(event);
		}
	}
	Dashboard() {
		//this.dashboardName = this.selectedEvent.name;
		this.headerbtn = true;
		this.showDashboard = true;
		this.showReport = false;
		this.showWidgets = false;
		this.widgetContainerBool = true;
		if (this.dashboardName == 'Disability') {
			setTimeout(() => { this.groupedBarChart(this.chartElement1, this.detail1) }, 1000)
			setTimeout(() => { this.donutChart(this.chartElement2, this.detail2) }, 1000);
			setTimeout(() => { this.groupedBarChart(this.chartElement3, this.detail3) }, 1000);
			setTimeout(() => { this.groupedBarChart(this.chartElement4, this.detail4) }, 1000);
			setTimeout(() => { this.pieChart(this.chartElement5, this.detail5) }, 1000);
			setTimeout(() => { this.pieChart(this.chartElement6, this.detail6) }, 1000);
			setTimeout(() => { this.bubbleChart(this.chartElement7, this.detail7) }, 1000);
			setTimeout(() => { this.bubbleChart(this.chartElement8, this.detail8) }, 1000);
			setTimeout(() => { this.numberGauge(this.chartElement11, this.detail11) }, 1000);
			setTimeout(() => { this.map(this.chartElement10, this.detail10) }, 1000);

		}

		if (this.dashboardName == 'Leave') {

			setTimeout(() => { this.pieChart(this.chartElement12, this.detail12) }, 1000);
			setTimeout(() => { this.lineChart(this.chartElement13, this.detail13) }, 1000);
			setTimeout(() => { this.stackedBarChart(this.chartElement9, this.detail9) }, 1000);

		}

	}
	widgetData(event) {
		// d3.selectAll("#chart12 > *").remove();
		// d3.selectAll("#chart13 > *").remove();
		// d3.selectAll("#chart9 > *").remove();
		//this.dashboardName = this.selectedEvent.name;

		this.headerbtn = true;
		this.showDashboard = false;
		this.showWidgets = true;
		this.showReport = false;
		// this.chartId = '';
		this.widgetContainerBool = true;
		// this.chartId = "chart" +event.id;
		if (this.chartId == 'chart12') { setTimeout(() => { this.pieChart(this.chartElement12, this.detail12) }, 1000); }
		if (this.chartId == 'chart13') { setTimeout(() => { this.lineChart(this.chartElement13, this.detail13) }, 1000); }
		if (this.chartId == 'chart9') { setTimeout(() => { this.stackedBarChart(this.chartElement9, this.detail9) }, 1000); }
		if (this.chartId == 'chart1') { setTimeout(() => { this.groupedBarChart(this.chartElement1, this.detail1); }, 1000); }
		if (this.chartId == 'chart2') { setTimeout(() => { this.donutChart(this.chartElement2, this.detail2) }, 1000); }
		if (this.chartId == 'chart3') { setTimeout(() => { this.groupedBarChart(this.chartElement3, this.detail3) }, 1000); }
		if (this.chartId == 'chart4') { setTimeout(() => { this.groupedBarChart(this.chartElement4, this.detail4) }, 1000); }
		if (this.chartId == 'chart5') { setTimeout(() => { this.pieChart(this.chartElement5, this.detail5) }, 1000); }
		if (this.chartId == 'chart6') { setTimeout(() => { this.pieChart(this.chartElement6, this.detail6) }, 1000); }
		if (this.chartId == 'chart7') { setTimeout(() => { this.bubbleChart(this.chartElement7, this.detail7) }, 1000); }
		if (this.chartId == 'chart8') { setTimeout(() => { this.bubbleChart(this.chartElement8, this.detail8) }, 1000); }
		if (this.chartId == 'chart11') { setTimeout(() => { this.numberGauge(this.chartElement11, this.detail11) }, 1000); }
		if (this.chartId == 'chart10') { setTimeout(() => { this.map(this.chartElement10, this.detail10) }, 1000); }

	}
	reportData() {
		this.searchHide = false;
		this.dashboardName = this.selectedEvent.name;
		this.headerbtn = true;
		this.showDashboard = false;
		this.showWidgets = false;
		this.showReport = true;
		this.widgetContainerBool = true;
		if (this.dashboardName == 'Leave Detail') {

			setTimeout(() => {
				this.customerService.getLeave().then((res: any) => {
					this.leaveData = res;
					const keys = Object.keys(this.leaveData[0]);
					keys.forEach((key, index) => {
						let mappedColumn = {
							headerName: key.replace(/[^\w\s]/gi, "").trim().replace("CLAIMANT_", "").replace("_", " ").replace(/\b\w/g, (s) => s.toUpperCase()),
							field: key,
							checked: true
						}
						this.coldata1.push(mappedColumn);
					});
					this.columnDefs = this.coldata1;
					this.rowData = this.leaveData;
				});
			}, 1000)

		}

		if (this.dashboardName == 'Disability Detail') {

			setTimeout(() => {
				this.customerService.getCustomersLarge().then((res: any) => {
					this.disData = res;
					const keys = Object.keys(this.disData[0]);
					keys.forEach((key, index) => {
						let mappedColumn = {
							headerName: key.replace(/[^\w\s]/gi, "").trim().replace("CLAIMANT_", "").replace("_", " ").replace(/\b\w/g, (s) => s.toUpperCase()),
							field: key,
							checked: true
						}
						this.coldata.push(mappedColumn);
					});
					this.columnDefs = this.coldata;
					this.rowData = this.disData;
				});
			}, 1000)

		}
	}

	// get a dashboard func
	groupedBarChart(chartElement, widgetdetails) {

		if (this.value == 1) {
			this.wigetBackgroundColor = this.backColor;
		}
		if (this.value == 2) {
			this.wigetBackgroundColor = "#E1CCB6";
		}
		let options = {
			data: widgetdetails.data,
			dataForDrillDown: [],
			dataForTable: [],
			isDataDetailedForTable: false,
			keys: {
				label: widgetdetails.keyLabel,
				groupBars: widgetdetails.keyGroup,
			},
			chartTitle: widgetdetails.title,
			xAxisLable: widgetdetails.xLable,
			yAxisLable: widgetdetails.yLable,
			showTitle: true,
			colorSet: this.selectedChartsColorSet,
			BGcolor: this.wigetBackgroundColor,
			drillDownColumnName: "",
			showTable: false,
			showComments: false,
			comments: "",
			resizeButtonID: document.getElementById("resizeBtn").id
		}

		setTimeout(() => { acinfotech.groupedBarChart(chartElement, options) }, 1000)

	}
	columnChart(chartElement, widgetdetails) {
		if (this.value == 1) {
			this.wigetBackgroundColor = this.backColor;
		}
		if (this.value == 2) {
			this.wigetBackgroundColor = "#E1CCB6";
		}
		let options = {
			data: widgetdetails.data,
			dataForTable: [], // for table
			isDataDetailedForTable: false,
			keys: {
				label: widgetdetails.keyLabel,
				value: widgetdetails.keyGroup
			},
			chartTitle: widgetdetails.title,
			xAxisLable: widgetdetails.xLable,
			yAxisLable: widgetdetails.yLable,
			showTitle: true,
			colorSet: this.selectedChartsColorSet,
			BGcolor: this.wigetBackgroundColor, //one or two or three
			drillDownColumnName: "",
			showTable: false, //false - hide, true - show
			showComments: false,
			comments: "",
			resizeButtonID: document.getElementById("resizeBtn").id
		}

		setTimeout(() => {
			acinfotech.columnChart(chartElement, options)
			chartElement.selectAll('div.ui-resizable-handle').style("display", "none");
		}, 1000)
	}
	horizontalChart(chartElement, widgetdetails) {

		if (this.value == 1) {
			this.wigetBackgroundColor = this.backColor;
		}
		if (this.value == 2) {
			this.wigetBackgroundColor = "#ffd670";
		}
		let options = {
			data: widgetdetails.data,
			dataForTable: [],
			isDataDetailedForTable: false,
			keys: {
				label: widgetdetails.keyLabel,
				value: widgetdetails.keyGroup
			},
			chartTitle: widgetdetails.title,
			xAxisLable: widgetdetails.yLable,
			yAxisLable: widgetdetails.xLable,
			showTitle: true,
			colorSet: this.selectedChartsColorSet, //"one" or "two" or "three"
			BGcolor: this.wigetBackgroundColor,
			drillDownColumnName: "",
			showTable: false,
			showComments: false,
			comments: "",
			resizeButtonID: document.getElementById("resizeBtn").id
		}
		setTimeout(() => { acinfotech.horizontalChart(chartElement, options) }, 1000)
	}
	donutChart(chartElement, widgetdetails) {
		if (this.value == 1) {
			this.wigetBackgroundColor = this.backColor;
		}
		if (this.value == 2) {
			this.wigetBackgroundColor = "#ebb9b9";
		}

		let options = {
			data: widgetdetails.data, // for chart
			dataForTable: [], // for table if there is no data send empty array ([])
			isDataDetailedForTable: false,
			keys: {
				label: widgetdetails.keyLabel,
				value: widgetdetails.keyGroup
			},
			chartTitle: widgetdetails.title,
			showTitle: true,
			showLegend: true,
			legendSide: "right", //"right" or "left"
			colorSet: this.selectedChartsColorSet, //"one" or "two" or "three"
			BGcolor: this.wigetBackgroundColor,
			gradient: "none", //"none" or "smooth" or "elliptical" or "circular" or "radial"
			drillDownColumnName: widgetdetails.keyLabel,
			showTableIcon: true, //false - hide, true - show
			showTable: false, //false - hide, true - show
			showComments: false,
			comments: "",
			resizeButtonID: document.getElementById("resizeBtn").id
		}
		setTimeout(() => { acinfotech.donutChart(chartElement, options) }, 1000)
	}
	pieChart(chartElement, widgetdetails) {
		if (this.value == 1) {
			this.wigetBackgroundColor = this.backColor;
		}
		if (this.value == 2) {
			this.wigetBackgroundColor = "#CFE2F3";
		}


		let options = {
			data: widgetdetails.data, // for chart
			dataForTable: [], // for table if there is no data send empty array ([])
			isDataDetailedForTable: false,
			keys: {
				label: widgetdetails.keyLabel,
				value: widgetdetails.keyGroup
			},
			chartTitle: widgetdetails.title,
			showTitle: true,
			showLegend: true,
			legendSide: "right", //"right" or "left"
			colorSet: this.selectedChartsColorSet,
			BGcolor: this.wigetBackgroundColor, //"one" or "two" or "three"
			gradient: "none", //"none" or "smooth" or "elliptical" or "circular" or "radial"
			drillDownColumnName: widgetdetails.keyLabel,
			showTableIcon: true, //false - hide, true - show
			showTable: false, //false - hide, true - show
			showComments: false,
			comments: "",
			resizeButtonID: document.getElementById("resizeBtn").id
		}

		setTimeout(() => { acinfotech.pieChart(chartElement, options); }, 1000)
	}
	bubbleChart(chartElement, widgetdetails) {
		if (this.value == 1) {
			this.wigetBackgroundColor = this.backColor;
		}
		if (this.value == 2) {
			this.wigetBackgroundColor = "#C8E6C9";
		}

		let options = {
			data: widgetdetails.data,
			dataForTable: [],
			isDataDetailedForTable: false,
			keys: {
				label: widgetdetails.keyLabel,
				value: widgetdetails.keyGroup
			},
			chartTitle: widgetdetails.title,
			showTitle: true,
			colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
			BGcolor: this.wigetBackgroundColor,
			drillDownColumnName: "",
			showTable: false,
			showComments: false,
			comments: "",
			resizeButtonID: document.getElementById("resizeBtn").id
		}

		setTimeout(() => { acinfotech.bubbleChart(chartElement, options) }, 1000)
	}
	stackedBarChart(chartElement, widgetdetails) {
		if (this.value == 1) {
			this.wigetBackgroundColor = this.backColor;
		}
		if (this.value == 2) {
			this.wigetBackgroundColor = "#FFF9C4";
		}
		let options = {
			data: widgetdetails.data,
			dataForDrillDown: [],
			dataForTable: [],
			isDataDetailedForTable: false,
			keys: {
				label: widgetdetails.keyLabel, // X-Axis labels
				stackedBars: widgetdetails.keyGroup
			},
			chartTitle: widgetdetails.title,
			xAxisLable: widgetdetails.xLable,
			yAxisLable: widgetdetails.yLable,
			showTitle: true,
			colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
			BGcolor: this.wigetBackgroundColor,
			lineStyle: "cardinal", // "cardinal" or "linear" or "step"
			drillDownColumnName: "",
			showTable: false,
			showComments: false,
			comments: "",
			resizeButtonID: document.getElementById("resizeBtn").id
		}

		setTimeout(() => { acinfotech.stackedBarChart(chartElement, options) }, 1000)

	}
	horizontalGroupedBarChart(chartElement, widgetdetails) {
		if (this.value == 1) {
			this.wigetBackgroundColor = this.backColor;
		}

		let options = {
			data: widgetdetails.data,
			dataForDrillDown: [],
			dataForTable: [],
			isDataDetailedForTable: false,
			keys: {
				label: widgetdetails.keyLabel,
				groupBars: widgetdetails.keyGroup,
			},
			chartTitle: widgetdetails.title,
			xAxisLable: widgetdetails.xLable,
			yAxisLable: widgetdetails.yLable,
			showTitle: true,
			colorSet: this.selectedChartsColorSet,
			BGcolor: this.wigetBackgroundColor, //"one" or "two" or "three";
			drillDownColumnName: "",
			showTable: false,
			showComments: false,
			comments: "",
			resizeButtonID: document.getElementById("resizeBtn").id
		}

		setTimeout(() => { acinfotech.horizontalGroupedBarChart(chartElement, options) }, 1000)

	}
	map(chartElement, widgetdetails) {
		if (this.value == 1) {
			this.wigetBackgroundColor = this.backColor;
		}
		if (this.value == 2) {
			this.wigetBackgroundColor = "#e1d0e8";
		}
		let options = {
			data: widgetdetails.data,
			keys: {
				label: widgetdetails.keyLabel,
				value: widgetdetails.keyGroup
			},
			chartTitle: widgetdetails.title,
			showTitle: true,
			maximize: true,
			colorSet: this.selectedChartsColorSet,
			BGcolor: this.wigetBackgroundColor,
			drillDownColumnName: "",
			resizeButtonID: document.getElementById("resizeBtn").id
		}
		setTimeout(() => { acinfotech.map(chartElement, options) }, 1000)

	}
	numberGauge(chartElement, widgetdetails) {
		if (this.value == 1) {
			this.wigetBackgroundColor = this.backColor;

		}
		if (this.value == 2) {
			this.wigetBackgroundColor = "#ebb9b9";
		}

		let options = {
			data: widgetdetails.data[0].Number_of_employees,
			chartTitle: widgetdetails.title,
			showTitle: true,
			BGcolor: this.wigetBackgroundColor,
			resizeButtonID: document.getElementById("resizeBtn").id
		}
		setTimeout(() => { acinfotech.numberGauge(chartElement, options) }, 1000)

	}
	lineChart(chartElement, widgetdetails) {
		if (this.value == 1) {
			this.wigetBackgroundColor = this.backColor;
		}
		if (this.value == 2) {
			this.wigetBackgroundColor = "#B2DFDB";
		}
		let options = {
			data: widgetdetails.data,
			dataForTable: [],
			isDataDetailedForTable: false,
			keys: {
				label: widgetdetails.keyLabel, // X-Axis labels
				lines: widgetdetails.keyGroup
			},
			chartTitle: widgetdetails.title,
			xAxisLable: widgetdetails.xLable,
			yAxisLable: "",
			showTitle: true,
			colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
			BGcolor: this.wigetBackgroundColor,
			lineStyle: "linear", // "cardinal" or "linear" or "step"
			drillDownColumnName: "",
			showTable: false,
			showComments: false,
			comments: "",
			resizeButtonID: document.getElementById("resizeBtn").id
		}

		setTimeout(() => { acinfotech.lineChart(chartElement, options) }, 1000)
	}

	finalCall() {
		this.finalCallCounter++;

		if (this.finalCallCounter == this.totalWidgets.length) { // when all widgets service calling completes

			d3.select(".tibo-mydash-dash-spinner").style("display", "none") // hidding dashboard spinner

			d3.select(".tibo-data-dis-output-spinner").style("display", "none") // hiding report, dasbhoard or widget output container spinner

			d3.selectAll(".widget-class")[0].map(function (d) {
				if (d3.select(d).attr('empty') == "true") {
					d3.select(d).remove()
				}
				else {
					d3.select(d).style("visibility", "visible")
					d3.select(d).select("p-progressspinner").remove()
				}
			})
		}
	}

	addMessages() {
		this.alertMessage = [{ severity: 'error', summary: 'Error', detail: "Please fill all required fields." }];
	}

	clearMessages() { this.alertMessage = []; }

	//---------------------------------------------------------------------------------------------



	onTabChange() {
		this.sec = true;
		this.first = false;
	}
	back() {

		this.resultsBool = true;
		this.first = true;
		this.resultsBool = false;
		this.topbar = true;
		this.widgetContainerBool = false;
		this.report = false;
		this.searchText = '';
		d3.select(".top").style("display", "block");
		//this.secondpanel = true;
		//this.firstpanel = true;
		// this.router.navigateByUrl('/my-reports');
		console.log('hi');


	}
	onSearch() {
		console.log('hji');

		// this.secondpanel = false;
		// this.resultsBool = false; 
		// this.firstpanel = true;

		// this.resultsBool = false; 
	}
	showLegend(event) {
		console.log(event);
		if (event.item.id == 2) {
			if (this.showOrHideSeriesBool) {
				d3.selectAll(".series_value").style("display", "block");

				event.item.label = "Hide series Values";
				event.item.icon = "pi pi-eye-slash"
				this.showOrHideSeriesBool = false;
			}
			else {

				d3.selectAll(".series_value").style("display", "none");
				this.showOrHideSeriesBool = true;
				event.item.label = "Show series Values";
				event.item.icon = "pi pi-eye"
			}
		}


		else if (event.item.id == 3) {
			if (this.showOrHideLegendBool) {
				this.showOrHideLegendBool = false;
				d3.selectAll(".legend_value").style("display", "block");

				event.item.label = "Hide Legend Values";
				event.item.icon = "pi pi-eye-slash"
			}
			else {
				this.showOrHideLegendBool = true;
				d3.selectAll(".legend_value").style("display", "none");
				event.item.label = "Show Legend Values"
				event.item.icon = "pi pi-eye"
			}
		}
	}
	changeChartColorsFunc(colorSet) {

		if (colorSet == "one") {

			this.selectedChartsColorSet = "one";
			this.reloadDashboardWithNewColorSetFunc();
		}
		else if (colorSet == "two") {
			console.log("calling")
			this.selectedChartsColorSet = "two";
			this.reloadDashboardWithNewColorSetFunc();
		}
		else if (colorSet == "three") {
			this.selectedChartsColorSet = "three";
			this.reloadDashboardWithNewColorSetFunc();
		}
	}
	reloadDashboardWithNewColorSetFunc() {
		//d3.selectAll("#chart1").remove();
		d3.selectAll("#chart1 > *").remove();
		d3.selectAll("#chart2 > *").remove();
		d3.selectAll("#chart3 > *").remove();
		d3.selectAll("#chart4 > *").remove();
		d3.selectAll("#chart5 > *").remove();
		d3.selectAll("#chart6 > *").remove();
		d3.selectAll("#chart7 > *").remove();
		d3.selectAll("#chart8 > *").remove();
		d3.selectAll("#chart9 > *").remove();
		d3.selectAll("#chart10 > *").remove();
		d3.selectAll("#chart11 > *").remove();
		d3.selectAll("#chart12 > *").remove();
		d3.selectAll("#chart12 > *").remove();
		d3.selectAll("#chart13 > *").remove();
		// this.chartElement1.selectAll("*").remove();;
		// this.chartElement2.empty();
		// this.chartElement11.empty();
		this.getDashboard("")
	}

	optionsFunc(event) {
		// if(event.item.id == 1){

		// 	let canvas = document.getElementsByClassName('widget-class') as HTMLCollectionOf<HTMLElement>;

		// 	if(this.moreItems[0].label == "Show widgets in full view"){
		// 		this.moreItems[0].label = "Show widgets in widget view"
		// 		this.moreItems[0].icon = "pi pi-th-large"

		// 		for(var i = 0; i < canvas.length; i++)
		// 		{
		// 			canvas[i].classList.add('full-view-widget-class');
		// 		}
		// 		window.dispatchEvent(new Event('resize'));
		// 	}
		// 	else {
		// 		this.moreItems[0].label = "Show widgets in full view"
		// 		this.moreItems[0].icon = "pi pi-external-link"

		// 		for(var i = 0; i < canvas.length; i++)
		// 		{
		// 			canvas[i].classList.remove('full-view-widget-class');
		// 		}
		// 		window.dispatchEvent(new Event('resize'));
		// 	}

		// 	//d3.selectAll(".widget-class").attr("class","widget-class-2 full-view-widget-class")
		// 	//window.dispatchEvent(new Event('resize'));

		// }
		//  if(event.item.id == 2){



		// 	if(this.moreItems[0].label == "Turn on page refresh"){

		// 		this.moreItems[0].label = "Turn off page refresh"
		// 		this.moreItems[0].icon = "pi pi-times"
		// 		this.refreshTimerFunc()
		// 	}
		// 	else {

		// 		this.moreItems[0].label = "Turn on page refresh"
		// 		this.moreItems[0].icon = "pi pi-refresh"

		// 		clearInterval(this.refreshInterval);
		// 		this.countDownLabel.innerHTML = "";

		// 	}

		// }
		if (event.item.id == 1) {
			this.shareDashDialBoxDisplayBool = true;
			console.log("calling1");
			// 
		}
	}
	refreshTimerFunc() {
		//this.refreshCountDownTimerStr = this.dashboardRefreshTime + ":" + "00";
		//let countDownLabel = this.countDownLabel;
		let thisClass = this; // assinging compoment class object to a variable
		let minutes = this.dashboardRefreshTime == undefined ? 0 : this.dashboardRefreshTime;
		let counter = 60;
		let strMin, strSec;

		this.refreshCountDownTimerStr = minutes + ":" + "00";
		this.countDownLabel.innerHTML = this.refreshCountDownTimerStr;

		minutes--;

		this.refreshInterval = setInterval(function () {

			counter = counter - 1;

			if (String(minutes).length == 1) { strMin = "0" + minutes; }
			else { strMin = minutes; }

			if (String(counter).length == 1) { strSec = "0" + counter; }
			else {
				if (counter == 60) { strSec = "00"; }
				else { strSec = counter; }
			}

			this.refreshCountDownTimerStr = minutes + ":" + strSec;
			thisClass.countDownLabel.innerHTML = this.refreshCountDownTimerStr;

			if (counter == 0) {
				minutes--;
				counter = 60;
			}

			if (minutes < 0) {
				minutes = this.dashboardRefreshTime;
				this.refreshCountDownTimerStr = minutes + ":" + "00";
				thisClass.countDownLabel.innerHTML = this.refreshCountDownTimerStr;
				minutes--;
				thisClass.getDashboard("");
			}

		}, 1000);
	}
	fullscreen() {


		let canvas = document.getElementsByClassName('widget-class') as HTMLCollectionOf<HTMLElement>;

		if (canvas[0].classList.contains("full-view-widget-class")) {
			//this.moreItems[0].label = "Turn on page refresh"


			for (var i = 0; i < canvas.length; i++) {
				canvas[i].classList.remove('full-view-widget-class');
				this.moreItems[0].icon = "pi pi-th-large"
			}
			window.dispatchEvent(new Event('resize'));

		}
		else {
			//this.moreItems[0].label = "Turn off page refresh"


			for (var i = 0; i < canvas.length; i++) {
				canvas[i].classList.add('full-view-widget-class');


			}
			window.dispatchEvent(new Event('resize'));

		}

		//d3.selectAll(".widget-class").attr("class","widget-class-2 full-view-widget-class")
		//window.dispatchEvent(new Event('resize'));




	}
	shareDashFunc(event) {
		console.log("calling");
		//this.shareDashUserNamesArr = [];
		let ids = [];

		if (this.shareDashTabIndex == 0) {

			if (this.shareDashUserNamesArr.length != 0) {

				this.shareDashDialBoxDisplayBool = false;

				this.shareDashUserNamesArr.forEach(user => {
					this.usersArray.forEach(d => {
						if (user == d.userName) {
							ids.push(Number(d.userId))
						}
					})
				})

				//console.log({"userId":ids,"emailId":[],"dashboardId":[Number(this.selectedDashboard.code)]})

				this.shareDashSubscrip = this.dashService.shareDashboardService({ "userId": ids, "emailId": [], "dashboardId": [Number(this.selectedDashboard.code)] }).subscribe((resp: any) => {
					//resp.body?.records.map(x => this.usersArray.push({ userName: x.userName, userId: x.userId }));

					if (resp.status == 200) {
						this.shareDashSuccMsg = "Dashboard shared successfully!";
						this.successAlertMsgFunc()
					}

				}, error => {
					if (Number(error.status) == 401) { this.errorMsg = "Please try after some time!" }
					else if (Number(error.status) == 500) { this.errorMsg = "Internal error" }
					else { this.errorMsg = "Please try after some time." }

					this.addSingle()
				}, () => { }) // share the dashboard service
			}
			else {
				this.errorMsg = "Please enter User Name(s)!"
				this.addSingle()
			}

			this.shareDashUserNamesArr = [], this.shareDashSrchUserNamesArr = [], this.usersArray = [], this.shareDashEmailIdsArr = []; // clear all users name and email ids arrays

		}
		else if (this.shareDashTabIndex == 1) {

			if (this.shareDashUserNamesArr.length != 0) {

				this.shareDashDialBoxDisplayBool = false;

				this.shareDashSubscrip = this.dashService.shareDashboardService({ "userId": [], "emailId": this.shareDashEmailIdsArr, "dashboardId": [Number(this.selectedDashboard.code)] }).subscribe((resp: any) => {
					//resp.body?.records.map(x => this.usersArray.push({ userName: x.userName, userId: x.userId }));

					if (resp.status == 200) {
						this.shareDashSuccMsg = "Dashboard shared successfully!";
						this.successAlertMsgFunc()
					}

				}, error => {
					if (Number(error.status) == 401) { this.errorMsg = "Please try after some time!" }
					else if (Number(error.status) == 500) { this.errorMsg = "Internal error" }
					else { this.errorMsg = "Please try after some time." }

					this.addSingle()
				}, () => { }) // share the dashboard service

			}
			else {
				this.errorMsg = "Please enter Email Id(s)!"
				this.addSingle()
			}

			this.shareDashUserNamesArr = [], this.shareDashSrchUserNamesArr = [], this.usersArray = [], this.shareDashEmailIdsArr = []; // clear all users name and email ids arrays
		}

	}
	addSingle() {
		throw new Error('Method not implemented.');
	}
	successAlertMsgFunc() {
		throw new Error('Method not implemented.');
	}
	toggleListening(): void {
		console.log(this.isListening);
		this.isListening = !this.isListening;

		if (this.isListening) {
			this.startListening();
		} else {
			this.stopListening();
			this.spokenText = "";
		}
	}

	startListening(): void {
		this.filteredItems = [];
		this.spokenText = '';
		this.isWriting = false;
		// this.placeHolderValue = '';
		this.recognition.start();
		this.recognition.onresult = (event: any) => {
			const transcript = event.results[event.results.length - 1][0].transcript;
			this.spokenText = transcript.trim();
			this.searchText = this.spokenText;
			console.log(this.searchText);

			// Filter suggestions based on spoken text
			this.filteredItems = this.Suggestions.filter(item =>
				item.question.toLowerCase().includes(this.spokenText.toLowerCase())
			);
			// Trigger change detection
			this.detectChanges();
			this.searchText = this.spokenText;
		};

	}
	stopListening(): void {
		this.recognition.stop();

	}
	detectChanges(): void {
		this.cdRef.detectChanges();
	}
	showSuggestion(event) {
		this.isListening = false;
		this.stopListening();
		this.filteredItems = [];
		this.isWritingText = event.target.value;
		this.isWriting = true;
		this.spokenText = '';
		this.resultsBool = false;
		this.headerbtn = false;
		d3.select(".tibo-data-disco-res-output-cont").style({ "display": "none" })
		console.log(this.isWritingText)
		if (this.isWritingText.length > 0) {
			this.filteredItems = this.Suggestions.filter(item =>
				item.question.toLowerCase().includes(this.isWritingText.toLowerCase())
			);
		} else {
			this.filteredItems = [];
		}
	}

}
