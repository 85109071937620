import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class MyReportsService {
    
    constructor(private http: HttpClient) { }
	
	directoryService(param) {
        return this.http.get('api/v1/directory/'+param+'/directory', { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
    }
	
	recordsService(param,page,requestPayload) {
		return this.http.post('api/v1/directory/'+param+'/report/search/list-view?depth=0&limit=50&page='+page, requestPayload,
		{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	
	settingsGetService(){
		return this.http.get('api/v1/user/config', { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	
	settingsPostService(settingsReqPayLoad){
		return this.http.put('api/v1/user/config', settingsReqPayLoad, { headers: {'X-Auth-Token': localStorage.getItem("token")}, 
		observe: 'response' })
	}
	
	reportAddToFavoritesService(param){
		return this.http.post('api/v1/report/'+param+'/favourite', {},
		{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
}

