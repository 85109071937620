
<div class="tibo-mydash-wrapper tibo-right-container">

	<button id="resizeBtn" class="tibo-mydash-resize-btn" type="button">Resize All</button>
	
	<div class="tibo-mydash-dashlistbtn-dashoptsbtn-container" *ngIf="!showGrid">
		
		<button id="mydash-dashlist-toggle-btn" class="tibo-mydash-dashlist-btn" type="button" pButton icon="pi pi-chevron-down" pTooltip="Dashboards" (click)="dashboardListFunc()"></button>
		<div id="tibo-mydash-dashlist-container-id" class="tibo-mydash-dashlist-container">
			<div class="dashlist-search">
				<span>Dashboard List</span><input type="text"> <i class="fa fa-search"></i>
			</div>
			<p-panelMenu [model]="items" styleClass="tibo-mydash-dashlist-panel" [multiple]=false></p-panelMenu>
		</div>
		
		<div *ngIf="viewDashboardsList.length > 0" class="tibo-mydash-openeddashlist-chip-container">
			<p-chip styleClass="tibo-mydash-openeddashlist-chips" (click)="visitedDashFunc(selectedDashboard?.name, selectedDashboard?.code)">{{selectedDashboard?.name}}</p-chip>
			<!-- <img src="../../assets/images/history.png" alt="" height="20px" width="20px" type="button" class="tibo-mydash-openeddashlist-chips-dwn-arrow-btn" (click)="dashlistoverlaypanel.toggle($event)" > -->
		</div>
  
		<p-overlayPanel #dashlistoverlaypanel styleClass="tibo-mydash-openeddashlist-overlaypanel" [showTransitionOptions]="'100ms'" appendTo="body" [showCloseIcon]=false>
			<ng-template pTemplate="body">
				<div>
					<p-chip *ngFor="let dashboard of viewDashboardsList" styleClass="tibo-mydash-openeddashlist-overlaypanel-chips" (click)="visitedDashFunc(dashboard?.name,dashboard?.code); dashlistoverlaypanel.hide()">{{dashboard?.name}}</p-chip>
				</div>
			</ng-template>
		</p-overlayPanel>
		<div class="tibo-mydash-openeddashlist-chip-history">
			<i class="fa fa-history"  (click)="dashlistoverlaypanel.toggle($event)"></i>
		</div>
	
		<label id="mydash-countdown-timer-label" class="tibo-mydash-refreshtime-label"></label>
		
		<button class="tibo-mydash-more-btn-icon tibo-mydash-chartcoloroptions-btn" type="button" pButton icon="pi pi-ellipsis-v" pTooltip="Options" (click)="moreoptions.toggle($event)"></button>
		<p-tieredMenu #moreoptions [model]="moreItems" [popup]="true" appendTo="body"></p-tieredMenu>
		<button class="tibo-mydash-chartcoloroptions-btn" type="button" pButton icon="pi pi-download" pTooltip="Download"></button>
		<button class="tibo-mydash-chartcoloroptions-btn" type="button" pButton icon="pi pi-window-maximize" pTooltip="Full Screen"></button>
		<button class="tibo-mydash-chartcoloroptions-btn tibo-mydash-chartcoloroptions-btn-icon" type="button" pButton icon="pi pi-palette" pTooltip="Color Sets" (click)="chartscolorsetsoverlaypanel.toggle($event)"></button>
		<p-overlayPanel #chartscolorsetsoverlaypanel styleClass="" [showTransitionOptions]="'100ms'" appendTo="body" [showCloseIcon]=false>
			<div class="color-main">
				<div class="theme-color">
				<label class="p-grid p-ml-2 p-mb-2" style="font-size: 16px;">Theme Colors</label>
				</div>
				<div class="color-set">
				<div class="single-row-color-set">
					<div class="p-p-2" [ngStyle]="{'background-color': colorSetOne[i], cursor:'pointer', heigth:'30px',width:'50px'}" *ngFor='let row of colorSetOne let i = index' (click)="changeChartColorsFunc('one')"><span pTooltip="{{row}}"class="inside-color">{{row}}</span></div>
				</div>
				<div class="single-row-color-set">
					<div class="p-p-2" [ngStyle]="{'background-color': colorSetTwo[i], cursor:'pointer'}"  *ngFor='let row of colorSetTwo let i = index' (click)="changeChartColorsFunc('two')"><span pTooltip="{{row}}" class="inside-color">{{row}}</span></div>
				</div>
                
				<div class="single-row-color-set">
					<div class="p-p-2" [ngStyle]="{'background-color': colorSetThree[i], cursor:'pointer'}" *ngFor='let row of colorSetThree let i = index' (click)="changeChartColorsFunc('three')"><span pTooltip="{{row}}"  class="inside-color">{{row}}</span></div>
				</div>

				<div class="single-row-color-set">
					<div class="p-p-2" [ngStyle]="{'background-color': colorSetFour[i], cursor:'pointer'}" *ngFor='let row of colorSetFour let i = index' (click)="changeChartColorsFunc('four')"><span pTooltip="{{row}}"  class="inside-color">{{row}}</span></div>
				</div>
				
				<div class="single-row-color-set">
					<div class="p-p-2" [ngStyle]="{'background-color': colorSetFive[i], cursor:'pointer'}" *ngFor='let row of colorSetFive let i = index' (click)="changeChartColorsFunc('five')"><span pTooltip="{{row}}"  class="inside-color">{{row}}</span></div>
				</div>
				<div class="single-row-color-set">
					<div class="p-p-2" [ngStyle]="{'background-color': colorSetSix[i], cursor:'pointer'}" *ngFor='let row of colorSetSix let i = index' (click)="changeChartColorsFunc('six')"><span pTooltip="{{row}}"  class="inside-color">{{row}}</span></div>
				</div>
				<div class="single-row-color-set">
					<div class="p-p-2" [ngStyle]="{'background-color': colorSetSeven[i], cursor:'pointer'}" *ngFor='let row of colorSetSeven let i = index' (click)="changeChartColorsFunc('seven')"><span pTooltip="{{row}}"  class="inside-color">{{row}}</span></div>
				</div>
			</div>
		</div>
		</p-overlayPanel>
		
																																													   
																							   
		<!--<button #btn class="tibo-mydash-moreoptions-btn tibo-mydash-more-btn-icon" type="button" pButton icon="pi pi-fw pi-list" label="Show" (click)="menu.toggle($event)"></button>-->

		<!--<label id="mydash-countdown-timer-label" class="tibo-mydash-refreshtime-label"></label>
		<button class="tibo-mydash-moreoptions-btn tibo-mydash-more-btn-icon" type="button" pButton icon="pi pi-ellipsis-h" pTooltip="Options" (click)="moreoptions.toggle($event)"></button>
		<p-menu #moreoptions [model]="moreItems" [popup]="true" appendTo="body"></p-menu>-->

		<p-dialog  [(visible)]="shareDashDialBoxDisplayBool" styleClass="tibo-mydash-sharedash-box" [modal]="true" [draggable]="false" [resizable]="false" (onHide)="dialogBoxOnCloseFunc($event)">
			<ng-template pTemplate="header">
				<label class="tibo-mydash-sharedash-box-sharelabel" >Share {{selectedDashboard.name}}</label>
			</ng-template>
			<p-tabView [(activeIndex)]="shareDashTabIndex" (onChange)="shareDashTabChanFunc($event)" styleClass="share-tabview">
				<p-tabPanel header="Provide access to" class="tibo-mydash-sharedash-box-tabpanel">
					<div class="p-grid p-col-12 p-ai-center vertical-container">
						<label class="p-col-12">Enter User Name(s) <span class="tibo-mydash-paramdash-param-title-mand">*</span></label>
						<p-autoComplete [autofocus]="true" [(ngModel)]="shareDashUserNamesArr" [suggestions]="shareDashSrchUserNamesArr" (completeMethod)="search($event)" [multiple]="true" scrollHeight="155px"></p-autoComplete>
					</div>
				</p-tabPanel>
				<p-tabPanel header="One time share by Email" class="tibo-mydash-sharedash-box-tabpanel">
					<div class="p-grid p-col-12 p-ai-center vertical-container">
						<label class="p-col-12">Enter Email Id(s) <span class="tibo-mydash-paramdash-param-title-mand">* </span> 
						<span class="p-ml-2 tibo-mydash-sharedash-box-tabpanel-span">(To add multiple Email ids, enter an email id and press Enter)</span></label>
						<!--<input class="p-p-1 share-dash-inputtext" type="text" pInputText />-->
						<p-chips class="p-p-1" [(ngModel)]="shareDashEmailIdsArr" [allowDuplicate]="false"></p-chips>
					</div>
				</p-tabPanel>
			</p-tabView>
			<ng-template pTemplate="footer">
				<p-button icon="pi pi-share-alt" label="Share" (click)="shareDashFunc($event)" ></p-button>
			</ng-template>
		</p-dialog>
	
	</div>
	
	<div *ngIf="outlet.isActivated==false" class="tibo-mydash-charts-container" (click)="chartsDivContainerClick($event)">
		
		<p-toast styleClass="tibo-mydash-toast" position="center"></p-toast>
		
		<p-progressSpinner styleClass="tibo-mydash-dash-spinner custom-spinner" animationDuration="2s"></p-progressSpinner>

	
		
		<div class="tibo-mydash-dashboard-title">{{ dashboardName }}
			<div class="tibo-mydash-dashlistbtn-dashoptsbtn-container-2" >
			
	
				<span *ngFor="let row of parameterElementsArray let i = index" class="row" style="float:left; padding: 0px 10px 0px;">
						
					<div  *ngIf="parameterElementsArray[i].fetchedRecordsBool">
					<div style="text-align:left">
						<!-- <span class="tibo-mydash-paramdash-param-title">
							{{parameterElementsArray[i].parameterName.replace("_", " ") != "" ? parameterElementsArray[i].parameterName.replace("_", " ") : parameterElementsArray[i].parameterLabel.replace("_", " ")}}
							<span *ngIf="parameterElementsArray[i].isMandatory == 'Y'"></span>
						</span> -->
                    </div>
						<input type="text" 
							*ngIf="parameterElementsArray[i].displayType == 'Text Field'"
							[attr.selectInfoIndex]=i
							placeholder="{{'Enter ' + parameterElementsArray[i]?.parameterName.replace('_', ' ') }}"
							(keyup)="onChange($event)">
						
						<span  *ngIf="parameterElementsArray[i].displayType == 'Radio'">
							 <span  *ngFor="let radioItem of (parameterElementsArray[i].fetchedRecords?.records?.records ? parameterElementsArray[i].fetchedRecords.records.records : parameterElementsArray[i].defaultValue.split(','))">
								<input type="radio" name="radiobutton" 
										[value]="radioItem[parameterElementsArray[i].fetchedRecords.records.parameterValue] || radioItem"
										[attr.selectInfoIndex]=i
										(change)="onChange($event)">
								<label >
									{{ radioItem[parameterElementsArray[i].fetchedRecords.records.parameterLabel] || radioItem }}
								</label>
								
							</span>
						</span>
					
						<p-dropdown  styleClass="tibo-mydash-paramdash-param-dropdown"
                       
							*ngIf="parameterElementsArray[i].displayType == 'Select'"
							appendTo="body"
							[attr.selectInfoIndex]=i
							[options]="parameterElementsArray[i].fetchedRecords?.records?.records"
							[optionLabel]="parameterElementsArray[i].fetchedRecords?.records?.parameterLabel "
							[optionValue]="parameterElementsArray[i].fetchedRecords?.records?.parameterValue"
							placeholder="{{'Select ' + parameterElementsArray[i].fetchedRecords?.records?.parameterLabel.replace('_', ' ') }}"
							(onChange)="onChange($event,refEl, i)"
							#refEl>
                           
						 
						</p-dropdown>
						
	
						
						
						
						<p-multiSelect  styleClass="tibo-mydash-paramdash-param-multiselect"

							*ngIf="parameterElementsArray[i].displayType == 'Multi Select'"
							appendTo="body"
							[attr.selectInfoIndex]=i
							[options]="parameterElementsArray[i].fetchedRecords?.listEle"
							[optionLabel]="parameterElementsArray[i].fetchedRecords?.records?.parameterLabel"
							[optionValue]="parameterElementsArray[i].fetchedRecords?.records?.parameterValue"
							[defaultLabel]="'Please select '"
							placeholder="{{'Select ' + parameterElementsArray[i].fetchedRecords?.records?.parameterLabel.replace('_',' ')}}"
							(onChange)="onChange($event,refEl)"
							#refEl>
						</p-multiSelect>
						
						<p-calendar styleClass="tibo-mydash-paramdash-param-calendar"
							*ngIf="parameterElementsArray[i].displayType == 'Date'"
							readonlyInput="true" 
							dateFormat="mm/dd/yy" 
							appendTo="body"
							[monthNavigator]="true"
							[yearNavigator]="true" 
							yearRange="2000:2030"
							[attr.selectInfoIndex]=i
                            placeholder="Please Select Date"
							showIcon="true"
							(onSelect)="onChange($event,refEl)"
							#refEl>
						</p-calendar>
						<!-- <button pButton type="button" id="submitbutton-id" class="tibo-mydash-paramdash-param-submitbtn" label="Submit" (click)="onFilterSubmitClick()"></button> -->
					</div>
                    
				</span>
				
			</div>
		</div>
		

		
		<div id="dashboard-scroll-wrapper" class="tibo-mydash-nonparamdash-wrapper">
			
			<p-scrollPanel styleClass="tibo-mydash-nonparamdash-scrollpanel custombar2 ">
			
				<div class="tibo-mydash-nonparamdash-widget" *ngFor="let row of randomIdArray let i = index"> 
					<div class="widget-class"
						[ngClass]="{'widget-class-1': totalColumns.length == 1,'widget-class-2': totalColumns.length == 2,
									'widget-class-3': totalColumns.length == 3}"
						[attr.objectCordinates] = "(i+1)+','+(j+1)"  
						[attr.id] = randomIdArray[i][j]
						[attr.empty] = "true"
						*ngFor="let column of randomIdArray[i]; let j = index;">  
						
						<p-progressSpinner styleClass="tibo-mydash-widget-spinner custom-spinner" 
										   strokeWidth="4" 
										   fill="#FFFFFF" 
										   animationDuration="1.5s">
						</p-progressSpinner>

					</div>
				</div>

			</p-scrollPanel>
		</div> <!-- dashboard scroll wrapper -->

		<div id="dashboard-parameterized-scroll-wrapper" class="tibo-mydash-paramdash-wrapper">
	<div id="dashboard-parameterized-scroll-view" class="tibo-mydash-paramdash-scroll-wrapper">
				<p-scrollPanel styleClass="tibo-mydash-paramdash-scrollpanel custombar2">

					<div class="tibo-mydash-paramdash-widget" *ngFor="let row of parameterizedDashboardRandomArray let i = index"> 
						<div class="widget-class" 
							[ngClass]="{'widget-class-1': totalColumns.length == 1,'widget-class-2': totalColumns.length == 2,
										'widget-class-3': totalColumns.length == 3}"
							[attr.objectCordinates] = "(i+1)+','+(j+1)"  
							[attr.id] = parameterizedDashboardRandomArray[i][j]
							[attr.empty] = "true"
							*ngFor="let column of parameterizedDashboardRandomArray[i]; let j = index;">  
							
							<p-progressSpinner styleClass="tibo-mydash-widget-spinner custom-spinner" 
												strokeWidth="4" 
												fill="#FFFFFF" 
												animationDuration="1.5s">
							</p-progressSpinner>

						</div>
					</div>

				</p-scrollPanel>
			</div>
   
									   

		</div>
		 <!-- parameterized wrapper -->

	</div> <!-- charts container -->
	
	<router-outlet #outlet="outlet"></router-outlet>												
</div>


