import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DashboardInfo } from './dashboard-info';
import { ReportsInfo } from './reports-info';
import { ConfirmationService } from 'primeng/api';
import { ScheduleHistoryService } from '../schedule-history/schedule-history.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { AppComponent } from '../app.component';
declare var d3: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [
    MessageService,
    DialogService,
    DashboardInfo,
    ConfirmationService,
    ScheduleHistoryService,
  ],
})
export class HomeComponent implements OnInit, OnDestroy {
  accountForm = new FormGroup({
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    email: new FormControl(''),
    phone: new FormControl(''),
  });
  passwordForm = new FormGroup({
    oldPassword: new FormControl(''),
    newPassword: new FormControl(''),
    reEnter: new FormControl(''),
  });
  savedData: any = [];
  alertSeverity: string = 'error';
  alertMsg: string = '';

  ScheduledReports = [
    {
      ReportName: 'Leave Denial Report',
      ReportTime: '07/05/2023 5:00',
    },
    {
      ReportName: 'Intermittent Details',
      ReportTime: '07/05/2023 1:10',
    },
    {
      ReportName: 'Open PACS Tasks',
      ReportTime: '07/05/2023 2:00',
    },
    {
      ReportName: 'Salesforce Exempt Report',
      ReportTime: '07/05/2023 05:30',
    },
    {
      ReportName: 'Accommodation Duration Report',
      ReportTime: '07/05/2023 03:50',
    },
    {
      ReportName: 'DIS Build a Report',
      ReportTime: '07/05/2023 5:30',
    },
    {
      ReportName: 'Open Claims with no Open Tasks',
      ReportTime: '07/05/2023 10:00',
    },
    {
      ReportName: 'Absence Radar Share Status Notification',
      ReportTime: '07/05/2023 08:30',
    },
    {
      ReportName: 'WC Lost Time Make Claim Report',
      ReportTime: '07/05/2023 11:55',
    },
    {
      ReportName: 'Finscial Summary Report',
      ReportTime: '07/05/2023 3:25',
    },
    {
      ReportName: 'Sales Data ETL Process',
      ReportTime: '07/05/2023 4:05',
    },
    {
      ReportName: 'Abbott Daily Closure Report',
      ReportTime: '07/05/2023 6:45',
    },
    {
      ReportName: 'All Open Leaves',
      ReportTime: '07/05/2023 5:00',
    },
    {
      ReportName: 'Call Center Analysis',
      ReportTime: '07/05/2023 7:55',
    },
  ];
  Dashboard = [
    {
      DashboardName: 'Leave', dashboardPage: 'Leave'
    },
    {
      DashboardName: 'Disability-Client', dashboardPage: 'Disability-Client'
    },
    // {
    //   DashboardName: 'Disability - Client Version',
    // },
    // {
    //   DashboardName: 'Leave of Absence',
    // },
    // {
    //   DashboardName: 'Call Center Analysis',
    // },
    // {
    //   DashboardName: 'Disability Dashboard - V1',
    // },
  ];

  Report = [
    { ReportName: 'Absence Blueprint SSO Report', reportPage: 'absence-sso-report' },
    { ReportName: 'Absence Blueprint Open URL Report', reportPage: 'absence-url-report' },
    { ReportName: 'Leave Details', reportPage: 'leave-table' },
    { ReportName: 'Leave Summary', reportPage: 'leave-summary' },
    { ReportName: 'Disability Details', reportPage: 'disability-table' },
    { ReportName: 'Disability Summary', reportPage: 'disability-summary' }
  ]
  data: any = {};
  options: any;
  showProfileDialog: boolean = false;
  tabHeaderName = 'Account Information';
  userName: any = '';
  animationBoolean: boolean = false;
  tableSortColumn = 'startTime';
  dir = 'DESC';
  requestPayload = {
    sort: [{ property: this.tableSortColumn, dir: this.dir }],
    filters: [],
    filterType: 'and',
  };
  getTodayCompletedCount = 0;
  getTodayFailedCount = 0;
  getTodayRunningCount = 0;
  getTodayPendingCount = 0;
  getTotalTodayCount = 0;
  scheduleHistoryRecords: any = [];
  productInChild = [];
  newMessage: any;
  Temp: any;
  userDetail: any;
  chartStatus = '';
  resultUserRoleDetails: any = [];
  getTodayFailedCountSideBar = 0;
  storeScheduledReportCount: any = [];


  constructor(
    private router: Router,
    private messageService: MessageService,
    public dialogService: DialogService,
    private confirmationService: ConfirmationService,

    private appComp: AppComponent,

    private elementRef: ElementRef
  ) {
    this.productInChild = ['hi hello'];
    this.data = {
      labels: ['On Demand', 'Scheduled',],
      datasets: [
        {
          data: [10, 90],
          backgroundColor: ['#04aa6d99', '#4b5893'],
          // backgroundColor: ['#04aa6d99', '#d32f2fa8', '#add8e6', '#01204f'],
          hoverBackgroundColor: ['#04AA6D', '#4b5889'],
        }
      ]
    };


    this.options = {
      cutout: '60%',
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle' // Set legend markers as circles
        }
        // labels: {
        //     color: 
        // }
      }
    };
    // this.data = {
    //   labels: ['Completed', 'Failed', 'Running', 'Pending'],
    //   datasets: [
    //     {
    //       data: [ 100,
    //         100,10,100
    //       ],
    //       backgroundColor: ['#04aa6d99', '#d32f2fa8', '#1389c3', '#ffd966'],
    //       // backgroundColor: ['#04aa6d99', '#d32f2fa8', '#add8e6', '#01204f'],
    //       hoverBackgroundColor: ['#04AA6D', '#d32f2f', '#36A2EB', '#ffc61a'],
    //     },
    //   ],
    // };
    // this.options = {
    //   showAllTooltips: true,
    //   elements: {
    //     arc: {
    //       borderWidth: 0,
    //     },
    //   },
    //   legend: {
    //     display: true,
    //     position: 'bottom',
    //     labels: {
    //       boxWidth: 10,
    //       usePointStyle: true,
    //       pointStyle: 'circle',
    //       padding: 20,
    //     },
    //   },
    //   animation: {
    //     animateScale: true,
    //     animateRotate: true,
    //   },
    // };
  }

  ref: DynamicDialogRef;

  ngOnInit(): void {

    d3.select('.tibo-topbar-title').text('Home');

    this.animationBoolean = true;
    setTimeout(() => {
      this.animationBoolean = false;
    }, 2000);
  }

  ngOnDestroy() {
    if (this.ref) {
      this.ref.close();
    }
  }

  //   setScheduledReportData() {
  //     this.appCompService.currentSchduledReports.subscribe((res) => {
  //       this.newMessage = res;
  //       this.getTodayCompletedCount = this.newMessage[0];
  //       this.getTodayFailedCount = this.newMessage[1];
  //       this.getTodayRunningCount = this.newMessage[2];
  //       this.getTodayPendingCount = this.newMessage[3];
  //       // if (this.newMessage.length !== 0) {
  //         for (let i = 0; this.newMessage.length > i; i++) {
  //           if (
  //             this.newMessage[0] === 0 &&
  //             this.newMessage[1] === 0 &&
  //             this.newMessage[2] === 0 &&
  //             this.newMessage[3] === 0
  //           ) {
  //             this.chartStatus = 'No Status';
  //           } else {
  //             this.chartStatus = '';
  //           }
  //         }
  //       // } else {
  //         // this.chartStatus = 'No Status';
  //       // }
  //       this.data = {
  //         labels: ['Completed', 'Failed', 'Running', 'Pending'],
  //         datasets: [
  //           {
  //             data: [
  //               this.getTodayCompletedCount ? this.getTodayCompletedCount : 0,
  //               this.getTodayFailedCount ? this.getTodayFailedCount : 0,
  //               this.getTodayRunningCount ? this.getTodayRunningCount : 0,
  //               this.getTodayPendingCount ? this.getTodayPendingCount : 0,
  //             ],
  //             backgroundColor: ['#04aa6d99', '#d32f2fa8', '#1389c3', '#ffd966'],
  //             hoverBackgroundColor: ['#04AA6D', '#d32f2f', '#36A2EB', '#ffc61a'],
  //           },
  //         ],
  //       };
  //     });
  //     // if (this.newMessage.length !== 0) {
  //       for (let i = 0; this.newMessage.length > i; i++) {
  //         if (
  //           this.newMessage[0] === 0 &&
  //           this.newMessage[1] === 0 &&
  //           this.newMessage[2] === 0 &&
  //           this.newMessage[3] === 0
  //         ) {
  //           this.chartStatus = 'No Status';
  //         } else {
  //           this.chartStatus = '';
  //         }
  //       }
  //     // }

  //   }

  //   scheduledReportFun() {
  //     let count = 0;
  //     var getTodayCompletedCount = 0;
  //     var getTodayFailedCount = 0;
  //     var getTodayRunningCount = 0;
  //     var getTodayPendingCount = 0;
  //     this.requestPayload.filters = [];
  //     const date = new Date(new Date().setHours(0, 0, 0, 0));
  //     const obj = {
  //       property: '',
  //       operator: '',
  //       value: 0,
  //     };
  //     // daily
  //     obj.property = 'startTime';
  //     obj.operator = 'dton';
  //     obj.value = date.getTime() + 1000 * 60 * 60 * 24;
  //     this.requestPayload.filters.push(obj);
  //     this.homeService.recordsService(1, this.requestPayload).subscribe(
  //       (recordsResp: any) => {
  //         recordsResp.body?.records
  //           ? (this.scheduleHistoryRecords = recordsResp.body?.records)
  //           : (this.scheduleHistoryRecords = []);
  //         this.scheduleHistoryRecords.forEach((d) => {
  //           if (d?.startTime != null) {
  //             d.startTime = new Date(d?.startTime);
  //           }
  //           if (d?.endTime != null) {
  //             d.endTime = new Date(d?.endTime);
  //           }
  //           switch (Number(d?.status)) {
  //             case 15:
  //               d.status = 'Running';
  //               d['filterStatus'] = {
  //                 filterStatus: 'Running',
  //                 value: 'Running',
  //               };
  //               getTodayRunningCount++;
  //               this.getTodayRunningCount = getTodayRunningCount;
  //               break;
  //             case 16:
  //               d.status = 'Pending';
  //               d['filterStatus'] = {
  //                 filterStatus: 'Pending',
  //                 value: 'Pending',
  //               };
  //               getTodayPendingCount++;
  //               this.getTodayPendingCount = getTodayPendingCount;
  //               break;
  //             case 18:
  //               d.status = 'Failed';
  //               d['filterStatus'] = { filterStatus: 'Failed', value: 'Failed' };
  //               count++;
  //               this.getTodayFailedCountSideBar = count;
  //               getTodayFailedCount++;
  //               this.getTodayFailedCount = getTodayFailedCount;
  //               break;
  //             case 17:
  //               d.status = 'Completed';
  //               d['filterStatus'] = {
  //                 filterStatus: 'Completed',
  //                 value: 'Completed',
  //               };
  //               getTodayCompletedCount++;
  //               this.getTodayCompletedCount = getTodayCompletedCount;
  //               break;
  //           }
  //           this.data = {
  //             labels: ['Completed', 'Failed', 'Running', 'Pending'],
  //             datasets: [
  //               {
  //                 data: [
  //                   this.getTodayCompletedCount,
  //                   this.getTodayFailedCount,
  //                   this.getTodayRunningCount,
  //                   this.getTodayPendingCount,
  //                 ],
  //                 // backgroundColor: [
  //                 //   '#04aa6d99',
  //                 //   '#d32f2fa8',
  //                 //   '#add8e6',
  //                 //   '#01204f',
  //                 // ],
  //                 // hoverBackgroundColor: [
  //                 //   '#04AA6D',
  //                 //   '#d32f2f',
  //                 //   '#36A2EB',
  //                 //   '#36A2EB',
  //                 // ],
  //                 backgroundColor: [
  //                   '#04aa6d99',
  //                   '#d32f2fa8',
  //                   '#1389c3',
  //                   '#ffd966',
  //                 ],
  //                 hoverBackgroundColor: [
  //                   '#04AA6D',
  //                   '#d32f2f',
  //                   '#36A2EB',
  //                   '#ffc61a',
  //                 ],
  //               },
  //             ],
  //           };
  //         });
  //         this.storeScheduledReportCount[0] = this.getTodayCompletedCount;
  //         this.storeScheduledReportCount[1] = this.getTodayFailedCount;
  //         this.storeScheduledReportCount[2] = this.getTodayRunningCount;
  //         this.storeScheduledReportCount[3] = this.getTodayPendingCount;
  //         // this.appComponentService.currentSchduledReports.subscribe(
  //         //   (res) => (this.resultMenuList2 = res)
  //         // );
  //         // this.appComponentService.changesMessage(this.storeScheduledReportCount);
  //       },
  //       (error) => {
  //         if (Number(error.status) == 401) {
  //           // this.errorMsg = 'Please try after some time!';
  //         } else if (Number(error.status) == 500) {
  //           //   this.errorMsg = 'Internal error';
  //           this.messageService.add({
  //             severity: 'error',
  //             summary: 'Internal server error',
  //             detail: 'Please try again after sometime',
  //           });
  //         } else {
  //           // this.messageService.add({
  //           //   severity: 'error',
  //           //   summary: '',
  //           //   detail: 'Scheduled Reports api error',
  //           // });
  //         }
  //       }
  //     );
  //   }

  goToDashboard(data) {
    this.router.navigate(['/dashboard'], { queryParams: {Dashboard: data } });
  }
  goToReport(data) {
    this.router.navigate([`/${data}`]);
  }

  showDashboardInfoBtnClick() {
    this.ref = this.dialogService.open(DashboardInfo, {
      data: {},
      header: 'Dashboard',
      width: '75%',
      height: '80%',
      contentStyle: {
        'min-height': '350px',
        height: '100%',
        'background-color': '#f1f1f4',
        'overflow-y': 'auto',
      },
      baseZIndex: 10000,
    });
  }

  showReportsInfoBtnClick() {
    this.ref = this.dialogService.open(ReportsInfo, {
      data: {},
      header: 'Reports',
      width: '75%',
      height: '80%',
      contentStyle: {
        'min-height': '350px',
        height: '100%',
        'background-color': '#f1f1f4',
        'overflow-y': 'auto',
      },
      baseZIndex: 10000,
    });
  }
  adminProfile() {
    this.showProfileDialog = true;
    this.accountForm.controls['firstName'].setValue(
      this.userDetail['firstName']
    );
    this.accountForm.controls['lastName'].setValue(this.userDetail['lastName']);
    this.accountForm.controls['email'].setValue(this.userDetail['emailId']);
    this.accountForm.controls['phone'].setValue(this.userDetail['handPhoneNo']);
  }
  //   updateAccount() {
  //     let payload = {
  //       firstName: this.accountForm.controls.firstName.value,
  //       lastName: this.accountForm.controls.lastName.value,
  //       emailId: this.accountForm.controls.email.value,
  //       handPhoneNo: this.accountForm.controls.phone.value,
  //     };
  //     this.homeService.setChangeProfile(payload).subscribe(
  //       (res) => {
  //         if (res.status == 200) {
  //           this.alertSeverity = 'success';
  //           this.alertMsg = 'Account updated successfully!';
  //           this.addSingle();
  //         }
  //         this.showProfileDialog = false;
  //         alert('Account updated successfully');
  //         this.ngOnInit();
  //       },
  //       (error) => {
  //         this.alertSeverity = 'error';
  //         if (Number(error.status) == 401) {
  //           this.alertMsg = 'Please try after some time!';
  //         } else if (Number(error.status) == 500) {
  //           this.alertMsg = 'Internal error';
  //         } else {
  //           this.alertMsg = 'Please try after some time.';
  //         }
  //         this.addSingle();
  //       },
  //       () => {}
  //     );
  //   }
  //   updatePassword() {
  //     let payload = {
  //       oldPassword: this.passwordForm.controls.oldPassword.value,
  //       newPassword: this.passwordForm.controls.newPassword.value,
  //     };
  //     this.homeService.setChangePassword(payload).subscribe((res) => {
  //     });
  //     this.ngOnInit();
  //   }
  addSingle() {
    this.messageService.add({
      severity: this.alertSeverity,
      summary: '',
      detail: this.alertMsg,
    });
  }

  switchHeaders(event: any) {
    this.tabHeaderName = event.originalEvent.target.innerText;
  }
}
