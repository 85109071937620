
import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Subscription } from 'rxjs';
import { Message,MessageService } from 'primeng/api';
import { MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

import { DashboardOutput } from './dashboard.output';

import { OverlayPanelModule } from 'primeng/overlaypanel';
import { jsPDF } from "jspdf";
import { DashboardService } from './dashboard.service';


declare var d3:any;
declare var acinfotech: any;
let dashListPanelToggleBool: boolean = false;

interface DashboardInfo {
	name: string,
	code: string
}

@Component({
	selector: 'app-new-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.css'],
	providers: [DashboardService, MessageService, DialogService, DashboardOutput]
  })
  
  export class DashboardComponent implements OnInit, OnDestroy {
	
	//@ViewChild('op') overLay: OverlayPanelModule;

	scrollHeight: string = '300px';
	dashboardInfo: DashboardInfo[];
	selectedDashboard: DashboardInfo;
	errorMsg = "";
	shareDashSuccMsg = "";

	alertMessage: Message[];
	
	items: MenuItem[];
	moreItems: MenuItem[];

  

	//mMoreItems: MenuItem[];

	constructor(private messageService: MessageService, public dialogService: DialogService, private http: HttpClient, private dashService: DashboardService) {}

	// colorSetOne = ["#ff6e5f", "#d0d0aa", "#808080", "#ffeeb0", "#4ca1ff", "#f2c47c", "#51e597", "#f1f23d", "#5783e1", "#cfae73", "#00cc77", "#ebd689"];
	// colorSetTwo = ["#e2d387", "#795247", "#bfa349", "#e63131", "#82cc6f", "#CCCC99", "#fdab3a", "#3e859d", "#85c143", "#bd9457", "#82cec0", "#008d8d"];
	// colorSetThree = ["#cc719f", "#ecd9b6", "#797877", "#6bea98", "#fb8989", "#FBCC58", "#89acb3", "#a75858", "#e8d2d2", "#D4343F", "#7ACBD8", "#fdd496"];
 	
	// colorSetOne = ["#7D6608", "#9A7D0A", "#B7950B", "#D4AC0D", "#F1C40F", "#F4D03F", "#F7DC6F", "#F9E79F", "#FCF3CF", "#FEF9E7", "#fbf8ea", "#fdfaee"]; 
    // colorSetTwo = ["#012c66", "#86bedf", "#d8f3f4", "#cb2624", "#ea7904", "#efb544", "#229694", "#037a7d", "#005254", "#fdd720", "#fbdd34", "#f9d424"]; 
    // colorSetThree = ["#283747", "#F1C40F", "#FEF9E7", "#7FB3D5", "#E67E22", "#EC7063", "#5D6D7E", "#F9E79F", "#D7DBDD", "#FCF3CF", "#FAE5D3", "#D6DBDF"]; 

	colorSetOne = ["#E37C50", "#E9C246", "#808080", "#ffeeb0", "#4ca1ff", "#f2c47c", "#51e597", "#f1f23d", "#5783e1", "#cfae73", "#00cc77", "#ebd689"]; //Neon
    colorSetTwo = ["#012c66", "#86bedf", "#cfe8f7", "#cb2624", "#e34746", "#f47473", "#046365", "#13a1a4", "#68dddb", "#e7c414", "#ffe242", "#ffe87b"]; //third set
    colorSetThree = ["#6E2C00", "#873600", "#A04000", "#BA4A00", "#D35400", "#DC7633", "#E59866", "#EDBB99", "#F6DDCC", "#FBEEE6", "#fff2eb", "#fff7f2"]; //yellow
	colorSetFour = ["#f7e37a", "#fbdd38", "#ebc403", "#f8c32a", "#e2a83d", "#f3b064", "#f68933", "#f5636d", "#ff4e5b", "#e42f24", "#bc2025", "#c02024"]; //yellow to red	
    colorSetFive = ["#f7c600", "#4f90b6", "#dd373d", "#046365", "#fbe481", "#75b6de", "#e3575b", "#33b4b6", "#ffefa8", "#b2d9f0", "#fd7e82", "#72bbbd"]; //4 cube set
	colorSetSix = ["#ff9a42", "#6a6a6a", "#5491c1", "#e2575a", "#edd457", "#64ae78", "#ffc38e", "#d8d8d8", "#a4d7ff", "#ff7b7e", "#ffeb86", "#99e6ae"];// 6 cube set brown
	colorSetSeven = ["#b6a8a8", "#ead78b", "#e6e1cb", "#c1b495", "#d0b063", "#d6b591", "#dad3af", "#d7dbda", "#e4c8b7", "#fae5d4", "#e4bd5f", "#ffe87b"]; //Brown color set
	
	selected= {
		color: '#3580db'
	  }
	dashboardName: string = "";
	totalWidgets = [];
	eachWidgetRecords = [];
	randomIdArray = [];
	parameterizedDashboardRandomArray = [];
	tempArray = [];
	dashboardRecords = [];
	totalRows = []
	totalColumns = []
	rowIndex = [];
	finalCallCounter = 0;
	parameterElementsArray = [];
	parameterElementsDataArray = [];
	parameterizedFilterArray = [];
	filterPanelToggleBool: boolean = false;
	requestPayloadParametersArray = [];
	userParameterObject = {};
	requestPayloadParameterObj = {filterType: "and", filters: [], sort: [], parameters: []};
	defaultValuesOfParameterArray = [];
	cascadeParametersObject = {};
	dashboardsListSubscrip: Subscription;
	defaultDashboardSubscrip: Subscription;
	listOfDashboardsSubscrip: Subscription;
	getADashboardSubscrip: Subscription;
	getWidgetDataSubscrip: Subscription;
	getPrmDashItmRecsSubscrip: Subscription;
	getPrmDashCasRecsCalSubscrip: Subscription;
	getPrmDashCasRecsMultSubscrip: Subscription;
	getPrmDashCasRecsDropSubscrip: Subscription;
	getPrmDashCasRecsRadioSubscrip: Subscription;
	getPrmDashWidgetDataSubscrip: Subscription;
	usersNameSubscrip: Subscription;
	shareDashSubscrip: Subscription;
	viewDashboardsList = [];
	dashboardRefreshTime: any;
	dashboardInfoObject: any;
	refreshCountDownTimerStr: string = "";
	refreshInterval;
	countDownLabel: any;
	selectDate: Date;  
	shareDashDialBoxDisplayBool: boolean = false;
    displayBasic: boolean = false;
	discontainer: boolean = false;
	menu = false;
	shareDashUserNamesArr = [];
	shareDashSrchUserNamesArr: string[];
	usersArray = [];
	shareDashEmailIdsArr = [];
	shareDashTabIndex = 0; // setting default value as 0 (1st tab) for share dashboard modal window's tab
	selectedChartsColorSet = "one"; // used for changing of chart colors

	ref: DynamicDialogRef;
	
	ngAfterViewInit(): void {
		
		d3.select("div#tibo-mydash-dashlist-container-id").attr("non-hide","non-hide")
		d3.select("button#mydash-dashlist-toggle-btn").attr("non-hide","non-hide").selectAll("button#mydash-dashlist-toggle-btn > span").attr("non-hide","non-hide");
		d3.select("div#tibo-mydash-dashlist-container").attr("non-hide","non-hide")
		d3.select("button#mydash-dashlist-toggle-btn2").attr("non-hide","non-hide").selectAll("button#mydash-dashlist-toggle-btn2 > span").attr("non-hide","non-hide");
		
	}
	ngOnInit(): void {
		
		
		
		acinfotech.requestType(true); // Letting chart library know it's a dashboard module call

		this.countDownLabel = document.getElementById('mydash-countdown-timer-label') as HTMLElement;
		
		/*this.moreItems = [
            {
				label: 'Show widgets in full view',
				id: "1",
				command: e => this.optionsFunc(e),
				icon: "pi pi-external-link"
			},
			{
				label: 'Turn on page refresh',
				id: "2",
				command: e => this.optionsFunc(e),
				icon: "pi pi-refresh"
			},
			{
				label: 'Share',
				id: "3",
				command: e => this.optionsFunc(e),
				icon: "pi pi-share-alt"
			}
		];*/

		this.moreItems = [
            
            {
                label: 'Show widgets in full view',
				id: "1",
				command: e => this.optionsFunc(e),
				icon: "pi pi-external-link"
            },
			{
				label: 'Turn on page refresh',
				id: "2",
				command: e => this.optionsFunc(e),
				icon: "pi pi-refresh"
			},
			{
				label: 'Share',
				id: "3",
				command: e => this.optionsFunc(e),
				icon: "pi pi-share-alt"
			},
			// {
            //     label: 'Change chart colors',
			// 	icon: "pi pi-cog",
            //     items: [{
            //             label: 'Color Set 1',
			// 			id: "4",
			// 			command: e => this.optionsFunc(e),
            //             icon: 'pi pi-palette',
			// 		},
			// 		{
            //             label: 'Color Set 2',
			// 			id: "5",
			// 			command: e => this.optionsFunc(e),
            //             icon: 'pi pi-palette',
			// 		},
			// 		{
            //             label: 'Color Set 3',
			// 			id: "6",
			// 			command: e => this.optionsFunc(e),
            //             icon: 'pi pi-palette',
			// 		}
            //     ]
            // },
        ];
		
		d3.select("html").on("click",function(){
			if(!d3.event.target.hasAttribute("non-hide")){
				d3.select('#tibo-mydash-dashlist-container-id').style("display","none");
			}
		})
		d3.select("html").on("click",function(){
			if(!d3.event.target.hasAttribute("non-hide")){
				d3.select('#tibo-mydash-dashlist-container').style("display","none");
			}
		})
		
		d3.select(".tibo-topbar-title").text("Home/Dashboard"); // setting title
		
		if(Number(d3.select("#tibo-large-leftpanel-container").node().getBoundingClientRect().left) == 0){
			setTimeout(function(){
				d3.select(".tibo-right-container").transition().delay(0).duration(0).style("left","200px").ease("linear");
			},10)
		}
		
		this.initialServiceCalls()
		
		
		// setTimeout(() => {
		// this.onFilterSubmitClick();
			
		// }, 5000);
	} // ngOnInit

	ngOnDestroy() {
		if(this.dashboardsListSubscrip){
			this.dashboardsListSubscrip.unsubscribe();
		}
		if(this.defaultDashboardSubscrip){
			this.defaultDashboardSubscrip.unsubscribe();
		}
		if(this.listOfDashboardsSubscrip){
			this.listOfDashboardsSubscrip.unsubscribe();
		}
		if(this.getADashboardSubscrip){
			this.getADashboardSubscrip.unsubscribe();
		}
		if(this.getWidgetDataSubscrip){
			this.getWidgetDataSubscrip.unsubscribe();
		}
		if(this.getPrmDashItmRecsSubscrip){
			this.getPrmDashItmRecsSubscrip.unsubscribe();
		}
		if(this.getPrmDashCasRecsCalSubscrip){
			this.getPrmDashCasRecsCalSubscrip.unsubscribe();
		}
		if(this.getPrmDashCasRecsMultSubscrip){
			this.getPrmDashCasRecsMultSubscrip.unsubscribe();
		}
		if(this.getPrmDashCasRecsDropSubscrip){
			this.getPrmDashCasRecsDropSubscrip.unsubscribe();
		}
		if(this.getPrmDashCasRecsRadioSubscrip){
			this.getPrmDashCasRecsRadioSubscrip.unsubscribe();
		}
		if(this.getPrmDashWidgetDataSubscrip){
			this.getPrmDashWidgetDataSubscrip.unsubscribe();
		}
		if(this.usersNameSubscrip){
			this.usersNameSubscrip.unsubscribe();
		}
		if(this.shareDashSubscrip){
			this.shareDashSubscrip.unsubscribe();
		}
		if (this.ref) {
            this.ref.close();
        }
	}
	
	initialServiceCalls(){
   

		this.dashboardsListSubscrip = this.dashService.dashboardsListService().subscribe( (response: any) => { // dashboards list
   
      
    
			let dashTemp = {}, dashItemsTemp = {};
			response.body.forEach((element: any, index: any) => {
				this.tempArray.push({name: element.widgetName, code: element.widgetMasterId})
				dashTemp["label"] = element.domainName;
				dashTemp["items"] = [];
				
				element.dashboards.forEach((dashboard: any) => {
					dashItemsTemp["label"] = dashboard.widgetName;
					dashItemsTemp["id"] = dashboard.widgetMasterId;
					dashItemsTemp["command"] = e => this.changeDashboardFunc(e);
					
					dashTemp["items"].push(dashItemsTemp)
					dashItemsTemp = {};
				})
				
				this.dashboardRecords.push(dashTemp);
				dashTemp = {};
			});
			this.items = this.dashboardRecords;
			this.dashboardInfo = this.tempArray;
			
			// Additional dashboard domains filtering ---------------------
				let filteredDomains = [];
				this.items.forEach((domain: any) => {
					if(domain.items.length > 0){
						filteredDomains.push(domain)
					}
				})
				this.items = filteredDomains;
			//-------------------------------------------------------------

		}, error => {

			d3.select(".tibo-mydash-dash-spinner").style("display","none") // hidding dashboard spinner

			if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
			else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
			else { this.errorMsg = "Please try after some time." }
			
			this.addSingle()
		}, () => {}) // dashboardService
		
		this.defaultDashboardSubscrip = this.dashService.getDefaultDashboard().subscribe( (response: any) => { // get defaul dashboard
   
			this.dashboardRefreshTime = response.body.dashboardRefreshTime;
			
			if(response.body.defaultDashboard.toString() != 'null'){
				this.selectedDashboard = {name: "", code: response.body.defaultDashboard }
			
				this.getDashboard("");
			}
			else { // if there is no default dashboard set

				this.listOfDashboardsSubscrip = this.dashService.dashboardsList().subscribe( (dashboardsListResp: any) => { // get list of dashboards
					this.selectedDashboard = {name: "", code: dashboardsListResp.body[0].widgetMasterId }
				
					this.getDashboard("");
				}, error => { 
       
					d3.select(".tibo-mydash-dash-spinner").style("display","none") // hidding dashboard spinner

					if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
					else { this.errorMsg = "Please try after some time." }
					
					this.addSingle()
				}, () => {}) // get list of dashboards
			}
			
			if(response.body?.dashboardRefreshOnOROff.toString().toLowerCase() == "y"){ // checking whether dashboard auto refresh is turned on in settings page
				this.moreItems[1].label = "Turn off page refresh"
				//clearInterval(this.refreshInterval);
				this.countDownLabel.innerHTML = "";
				this.refreshTimerFunc()
			}
			
		}, error => {

			d3.select(".tibo-mydash-dash-spinner").style("display","none") // hidding dashboard spinner

			if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
			else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
			else { this.errorMsg = "Please try after some time." }
			
			this.addSingle()
		}, () => {}) // get default dashboard service

	}


	datasData:any;

	getDashboard(event: any){
	
		acinfotech.requestType(true); // Letting chart library know it's a dashboard module call and empty all stored divs id and chart div elements

		d3.select(document).selectAll(".d3-tip").remove() // hiding d3-tip tool tip on dashboard change
		d3.select(".tibo-mydash-dash-spinner").style("display","block") //  showing dashboard spinner
		
		this.moreItems[0].label = "Show widgets in full view" // changing label text in options dropdown (full, widget view)
		this.moreItems[0].icon = "pi pi-external-link" // changing icon in options dropdown (full, widget view)
		
		if(this.moreItems[1].label == "Turn off page refresh"){ // to check whether page refresh is on
			clearInterval(this.refreshInterval);
			this.countDownLabel.innerHTML = "";
			this.refreshTimerFunc()
		}
				
		this.getADashboardSubscrip = this.dashService.getADashboard(this.selectedDashboard.code).subscribe( (data: any) => { // a dashboard info   
			
			this.viewDashboardsList = this.viewDashboardsList.filter(obje => Number(obje.code) !== Number(this.selectedDashboard.code));
			
			this.selectedDashboard.name = data.body.widgetName;
			this.viewDashboardsList.push(this.selectedDashboard) // storing opened dashboards
			
			this.totalRows = Array(Number(data.body.row)).fill(0);
			this.totalColumns = Array(Number(data.body.column)).fill(0);
			
			this.dashboardName = data.body.widgetName;
			this.totalWidgets = data.body.widgetControls;
			this.randomIdArray = [], this.parameterizedDashboardRandomArray = [], this.rowIndex = [], this.finalCallCounter = 0;
			this.parameterizedFilterArray = [], this.parameterElementsDataArray = [], this.parameterElementsArray = [];
			this.requestPayloadParametersArray = [];
			this.requestPayloadParameterObj = {filterType: "and", filters: [], sort: [], parameters: []}
			this.defaultValuesOfParameterArray = [];
			
			if(data.body.dashboardRequestorParameters.length == 0){  

				this.totalRows.forEach((rowEle,rowIndex) => {
					this.totalColumns.forEach((colEle,columnIndex) => {
						this.rowIndex.push("chartid-" + Math.floor(Math.random() * 10000) + 1)
					})
					this.randomIdArray.push(this.rowIndex)
					this.rowIndex = []
				})
				
				d3.select("#dashboard-scroll-wrapper").style("display","block")
				d3.select("#dashboard-parameterized-scroll-wrapper").style("display","none")

				setTimeout(() => { // removing unused chart divs and adjusting their width
					
					var totalRows = this.totalRows;
					var totalColumns = this.totalColumns;
					var totalObjCoords = [];
					var usedObjCoords = [];

					totalRows.map(function(row,rowInd){
						
						totalColumns.map(function(column,columnInd){
							totalObjCoords.push((rowInd+1) +","+ (columnInd+1))
						})

					})
					
					totalObjCoords.map(function(coord){

						data.body.widgetControls.map(function(d){
							
							if((Number(d.controlMasterId) == 7) || (Number(d.controlMasterId) == 22) || (Number(d.controlMasterId) == 23) 
								|| (Number(d.controlMasterId) == 25) || (Number(d.controlMasterId) == 26) || (Number(d.controlMasterId) == 27)){
							}
							else {
								if(String(coord) == String(d.objectCordinates)){
									usedObjCoords.push(coord)
								}
							}
							
						})

					})

					var eachWidget = d3.selectAll(".widget-class")[0]
					for(var widi = 0; widi < totalObjCoords.length; widi++){
						
						if(usedObjCoords.indexOf(d3.select(eachWidget[widi]).attr("objectCordinates")) == -1){
							d3.select(eachWidget[widi]).remove();
						}
					}

					d3.selectAll(".tibo-mydash-nonparamdash-widget")[0].forEach(function(row){
						if(d3.select(row).selectAll("div.widget-class")[0].length == 1){
							d3.select(row).selectAll("div.widget-class").attr("class","widget-class expand-widget-class-1");
						}
						else if(d3.select(row).selectAll("div.widget-class")[0].length == 2){
							d3.select(row).selectAll("div.widget-class").attr("class","widget-class expand-widget-class-2");
						}
						else if(d3.select(row).selectAll("div.widget-class")[0].length == 3){
							d3.select(row).selectAll("div.widget-class").attr("class","widget-class expand-widget-class-3");
						}
					})

				}, 1000); // removing unused chart divs and adjusting their width
				
				
				data.body.widgetControls.forEach((widget,index) => {
					
					if(widget.controlMasterId == 3){
						
						this.getWidgetDataSubscrip = this.dashService.getWidgetData(widget.widgetControlId,"limit=&isWebservice=N").subscribe( (widgetdetails: any) => {
							
							data.body.widgetControls[index]["eachWidgetResponse"] = widgetdetails; // Storing each widget information for changing chart colors usage

							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)
								
							this.table(chartElement,widget,widgetdetails)
							
						}, error => console.log(error),
						() => this.finalCall() ) // getWidgetData
						
					}
					else {
						
						this.getWidgetDataSubscrip = this.dashService.getWidgetData(widget.widgetControlId,"limit=10").subscribe( (widgetdetails: any) => {
							
							data.body.widgetControls[index]["eachWidgetResponse"] = widgetdetails; // Storing each widget information for changing chart colors usage

						//-------------------------------------------------------------------------------------------------
						
							if(widget.controlMasterId == 1){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)

								this.pieChart(chartElement,widget,widgetdetails)
							}
							else if(widget.controlMasterId == 2){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)

								this.columnChart(chartElement,widget,widgetdetails)
							}
							/*else if(widget.controlMasterId == 3){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)
								
								this.table(chartElement,widget,widgetdetails)
							}*/
							else if(widget.controlMasterId == 5){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)
								
								this.donutChart(chartElement,widget,widgetdetails)
							}
							else if(widget.controlMasterId == 6){
			
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)
								
								this.horizontalChart(chartElement,widget,widgetdetails)
							}
							else if(widget.controlMasterId == 7){ } // Waffle chart
							else if(widget.controlMasterId == 8){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)
								
								this.perGauge(chartElement,widget,widgetdetails)
							}
							else if(widget.controlMasterId == 9){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)
								
								this.numberGauge(chartElement,widget,widgetdetails)
							}
							else if(widget.controlMasterId == 10){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)
								
								this.map(chartElement,widget,widgetdetails)
							}
							else if(widget.controlMasterId == 11){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)
								
								this.columnAndLineChart(chartElement,widget,widgetdetails)
							}
							else if(widget.controlMasterId == 12){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)
								
								this.lineChart(chartElement,widget,widgetdetails)
							}
							else if(widget.controlMasterId == 13){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)
								
								this.bubbleChart(chartElement,widget,widgetdetails)
							}
							else if(widget.controlMasterId == 14){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)
								
								this.stackedHorizontalBarChart(chartElement,widget,widgetdetails)
							}
							else if(widget.controlMasterId == 15){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)
								
								this.stackedBarChart(chartElement,widget,widgetdetails)
							}
							else if(widget.controlMasterId == 16){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)
								
								this.perGaugeWithFlip(chartElement,widget,widgetdetails)
							}
							else if(widget.controlMasterId == 17){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)
								
								this.squareGauge(chartElement,widget,widgetdetails)
							}
							else if(widget.controlMasterId == 18){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)
								
								this.bellCurveChart(chartElement,widget,widgetdetails)
							}
							else if(widget.controlMasterId == 19){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)
								
								this.areaChart(chartElement,widget,widgetdetails)
							}
							else if(widget.controlMasterId == 20){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)

								this.groupedBarChart(chartElement,widget,widgetdetails)
							}
							else if(widget.controlMasterId == 21){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)

								this.horizontalGroupedBarChart(chartElement,widget,widgetdetails)
							}
							else if(widget.controlMasterId == 22){ } //Horizontal opposite bar Chart 
							else if(widget.controlMasterId == 23){ } //Vertical opposite bar Chart 
							else if(widget.controlMasterId == 24){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)

								this.dotGauge(chartElement,widget,widgetdetails)
							}
							else if(widget.controlMasterId == 25){ }//Square bubble chart
							else if(widget.controlMasterId == 26){ }//Magic quadrant
							else if(widget.controlMasterId == 27){ }//Funnel chart
							else if(widget.controlMasterId == 28){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)

								this.columnAndLinesChart(chartElement,widget,widgetdetails)
							}
							else if(widget.controlMasterId == 29){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)

								this.threeXAxisesGroupedBarChart(chartElement,widget,widgetdetails)
							}
							else if(widget.controlMasterId == 30){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)

								this.twoXAxisesGroupedBarChart(chartElement,widget,widgetdetails)
							}
							else if(widget.controlMasterId == 31){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)

								this.bellCurveChartWithMinAndMaxRange(chartElement,widget,widgetdetails)
							}
							else if(widget.controlMasterId == 32){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)

								this.stackedGroupedBarChart(chartElement,widget,widgetdetails)
							}
							else if(widget.controlMasterId == 33){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)

								this.stackedGroupedBarsWithLineChart(chartElement,widget,widgetdetails)
							}
							else if(widget.controlMasterId == 34){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)

								this.stackedBarWithLinesChart(chartElement,widget,widgetdetails)
							}
							else if(widget.controlMasterId == 35){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)

								this.trafficLightsChart(chartElement,widget,widgetdetails)
							}
							else if(widget.controlMasterId == 36){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)

								this.columnChartWithNegativeValues(chartElement,widget,widgetdetails)
							}
							else if(widget.controlMasterId == 37){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)

								this.groupedBarsWithLineChart(chartElement,widget,widgetdetails)
							}
							else if(widget.controlMasterId == 38){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)

								this.USMapWithPins(chartElement,widget,widgetdetails)
							}
							
							//-------------------------------------------------------------------------------------------------
							
						}, error => console.log(error),
						() => this.finalCall() ) // getWidgetData
						
					} // else
				}) // forEach

				this.dashboardInfoObject = data?.body; // Storing entire dashboard information for changing chart colors usage
		
			} // data.body.dashboardRequestorParameters.length if block
			else {
			
				d3.select(".tibo-mydash-dash-spinner").style("display","none") // hidding dashboard spinner
				d3.select("#dashboard-parameterized-scroll-wrapper").style("display","block")
				d3.select("#dashboard-scroll-wrapper").style("display","none")
				
				d3.select("#parameterized-dashboard-filter-panel-scroll-div").transition().delay(300).duration(400).style("display","block")
				// d3.select("#submitbutton-id").transition().delay(500).duration(400).style("display","block")
				d3.select("#dashboard-parameterized-filter").transition().delay(200).duration(500).style("width","275px")
				// d3.select("#dashboard-parameterized-scroll-view").transition().delay(200).duration(500).style("left","360px")//.style("width","calc(100% - 360px)")
				d3.select("#dashboard-parameterized-filter").select("button").style("transform","rotate(0deg)")
				d3.select("#filter-id").transition().delay(250).duration(100)
				.style({'left':'10px','top':'10px'}).style("transform","rotate(0deg)")
				this.filterPanelToggleBool = false;
				
				data.body.dashboardRequestorParameters.forEach((parameter) => {
					if(parameter.parameterName !== "User"){
						this.parameterElementsArray.push(parameter)
					}
					else if(parameter.parameterName == "User"){
						this.requestPayloadParametersArray.push({id: parameter.dashboardParameterId, isVisible: 'Y', name: 'User', questionId: 0, value: localStorage.userEmail})
						this.userParameterObject = {id: parameter.dashboardParameterId, isVisible: 'Y', name: 'User', questionId: 0, value: localStorage.userEmail}
						
					}
				})

				let defaultTempReqPayload = [];
				defaultTempReqPayload.push(this.userParameterObject);
				let paramTempObj = {};
				
				data.body.dashboardRequestorParameters.forEach(function(param){
					if(param.parameterName !== "User"){
						paramTempObj = {
							id: param.dashboardParameterId,
							isVisible: param.isVisible,
							name: param.parameterName,
							questionId: param.questionOrderBy,
							value: param.defaultValue
						}
						
						defaultTempReqPayload.push(paramTempObj)
					}
				});
						
					this.requestPayloadParameterObj.parameters=defaultTempReqPayload;
					this.datasData=defaultTempReqPayload;
					this.dashboard();

					console.log(this.requestPayloadParameterObj);
				
				this.getPrmDashItmRecsSubscrip = this.dashService.getParameterizedDashboardItemRecords(this.selectedDashboard.code).subscribe( (data: any) => {
					this.parameterElementsDataArray = data.body.parameterResponse; // 0 service response
          
      //     if(this.parameterElementsDataArray[2].parameterLabel === "Agent_Name"){
      //     let filterAll=   this.parameterElementsDataArray[2].records.filter((res:any) =>{
      //  return  res.Agent_Name != 'All'
      //  })
      
      //  console.log('filterval', data);  
       
       

      //  this.parameterElementsDataArray[2].records=filterAll

      //  console.log('filterval', filterAll);
      // }
				}, error => console.log(error),
				() => {
          
					
					this.parameterElementsArray.forEach((ele) => {
						
						this.defaultValuesOfParameterArray = []
						
						if(ele.displayType == "Multi Select"){
							ele["fetchedRecords"] = {paramElement: ele, records: null, questionOrderBy: ele.questionOrderBy, listEle: null}
						}
						else {
							ele["fetchedRecords"] = {paramElement: ele, records: null, questionOrderBy: ele.questionOrderBy}
						}
						ele["fetchedRecordsBool"] = false;
						
						ele["paramElementFilledBool"] = false;
						
						ele["cascadeChildParameters"] = [];
          
            
						
						this.parameterElementsDataArray.forEach((rec) => { // assigning records from 0'th service
								
							if(ele.dashboardParameterId == rec.dashboardParameterId){
							  
								if(ele.displayType == "Multi Select"){
									
									// if(ele.defaultValue != ""){
						
									// 	ele.defaultValue.split(',').forEach((defaulVal) => {
									// 		this.defaultValuesOfParameterArray.push({[ele.parameterLabel]:defaulVal, [ele.parameterValue]:defaulVal})
									// 	})
										
									// 	this.parameterizedFilterArray.push({paramElement: ele, records: {parameterLabel: ele.parameterLabel, parameterValue: ele.parameterValue, records: this.defaultValuesOfParameterArray}, questionOrderBy: ele.questionOrderBy, listEle: this.defaultValuesOfParameterArray})
						  
									// 	ele["fetchedRecords"] = {paramElement: ele, records: {parameterLabel: ele.parameterLabel, parameterValue: ele.parameterValue, records: this.defaultValuesOfParameterArray}, questionOrderBy: ele.questionOrderBy, listEle: this.defaultValuesOfParameterArray}
									// }
									 
										
										this.parameterizedFilterArray.push({paramElement: ele, records: rec, questionOrderBy: ele.questionOrderBy, listEle: rec.records})
								  
										ele["fetchedRecords"] = {paramElement: ele, records: rec, questionOrderBy: ele.questionOrderBy, listEle: rec.records}
									
								}
								else {
									
									if(ele.defaultValue != ""){
						
										ele.defaultValue.split(',').forEach((defaulVal) => {
											this.defaultValuesOfParameterArray.push({[ele.parameterLabel]:defaulVal, [ele.parameterValue]:defaulVal})
										})
										
										this.parameterizedFilterArray.push({paramElement: ele, records: {parameterLabel: ele.parameterLabel, parameterValue: ele.parameterValue, records: this.defaultValuesOfParameterArray}, questionOrderBy: ele.questionOrderBy, listEle: this.defaultValuesOfParameterArray})
						  
										ele["fetchedRecords"] = {paramElement: ele, records: {parameterLabel: ele.parameterLabel, parameterValue: ele.parameterValue, records: this.defaultValuesOfParameterArray}, questionOrderBy: ele.questionOrderBy, listEle: this.defaultValuesOfParameterArray}
										
									}
									else {
										
										this.parameterizedFilterArray.push({paramElement: ele, records: rec, questionOrderBy: ele.questionOrderBy})
								  
										ele["fetchedRecords"] = {paramElement: ele, records: rec, questionOrderBy: ele.questionOrderBy}
										
									}
									
								}
								
								ele["fetchedRecordsBool"] = true;
							}
							  
						}) //rec
						
					}) // ele
				  
					this.parameterElementsArray = this.parameterElementsArray.sort((n1,n2) => {
						if (Number(n1.questionOrderBy) > Number(n2.questionOrderBy)) { return 1; }
						if (Number(n1.questionOrderBy) < Number(n2.questionOrderBy)) { return -1; }
						return 0;
					});

				}) // getParameterizedDashboardItemRecords final block
				
				this.dashboardInfoObject = data?.body; // Storing entire dashboard information for changing chart colors usage
                
				   
			} // data.body.dashboardRequestorParameters.length else block
		  
		}, error => { 

			d3.select(".tibo-mydash-dash-spinner").style("display","none") // hidding dashboard spinner

			if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
			else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
			else { this.errorMsg = "Please try after some time." }
			
			this.addSingle()
		}, () => {}) // getADashboard service
    
	} // get a dashboard func

	finalCall(){
		this.finalCallCounter++;
		
		if(this.finalCallCounter == this.totalWidgets.length){ // when all widgets service calling completes
			
			d3.select(".tibo-mydash-dash-spinner").style("display","none") // hidding dashboard spinner
			
			d3.selectAll(".widget-class")[0].map(function(d, ind){ 
				if(d3.select(d).attr('empty') == "true"){
				    //d3.select(d).remove()
				}
				else {
					d3.select(d).style("visibility","visible")
					d3.select(d).select("p-progressspinner").remove();
				}
			})

		}
	}

	
    selectcolor: boolean = false;

	onChange(event,refEl,j:any) {
		
	

		//this.clearMessages()
		//this.getDashboard("");
		
		if(refEl != undefined){
			
			if(refEl.el.nativeElement.nodeName == "P-CALENDAR"){
      
           
			// this.selectcolor = false;
				var value = ("0" + (event.getMonth() + 1)).slice(-2) + "/" + ("0" + event.getDate()).slice(-2) + "/" + event.getFullYear();
			
		

				var obj = this.parameterElementsArray[Number(refEl.el.nativeElement.attributes.selectInfoIndex.value)]
			
				
				obj.paramElementFilledBool = true;
        // this.onFilterSubmitClick();
		this.dashboard();
		
		
				
				this.requestPayloadParametersArray = this.requestPayloadParametersArray.filter(obje => obje.id !== obj.dashboardParameterId);

				var parameterArrayObj = {id: obj.dashboardParameterId, isVisible: obj.isVisible,
							name: obj.parameterName, questionId: obj.questionOrderBy, value: value}									
				this.requestPayloadParametersArray.push(parameterArrayObj)

				//this.requestPayloadParametersArray[j].value=value
				this.requestPayloadParameterObj.parameters = this.datasData.map((obj) => this.requestPayloadParametersArray.find((o) => o.id === obj.id) || obj);

				//this.requestPayloadParameterObj.parameters = this.requestPayloadParametersArray;
              
        
        
				
				this.getPrmDashCasRecsCalSubscrip = this.dashService.getParameterizedDashboardCascadingRecords(this.selectedDashboard.code,
				obj.dashboardParameterId,
				{"parameters":[this.userParameterObject,parameterArrayObj]}).subscribe( (data: any) => {
					
					//--------------------------------------------
					data.body.parameterResponse.forEach((rec) => {
						this.parameterElementsArray.forEach((ele) => {
							if(Number(ele.dashboardParameterId) == Number(rec.dashboardParameterId)){
								this.parameterElementsArray.forEach((ele1) => {
									if(ele1.parameterName == ele.cascadeParameters){
										ele1.cascadeChildParameters.push(ele.dashboardParameterId)
									}
								})
							}
						})
					})
					
					var array = obj.cascadeChildParameters, newArray = [], arrayCopy = []; 
					
					if(array != []){
						array = array.filter(function(item, pos) {
							return array.indexOf(item) == pos;
						})
					}
					
					arrayCopy = array;
					
					do {
						newArray = [];
						
						array.forEach((item,i) => { 
							this.parameterElementsArray.forEach((ele) => {
								if(Number(ele.dashboardParameterId) == Number(item)){
									if(ele.cascadeChildParameters != []){
										for(let ii = 0; ii < ele.cascadeChildParameters.length;  ii++){
											newArray.push(ele.cascadeChildParameters[ii])
										}
									}
									else if(ele.cascadeChildParameters == []){
										
									}
								}
							})
						})
						
						array = []
						array = newArray;
						arrayCopy = [...arrayCopy, ...newArray]
						
					} while(newArray.length > 0)
					
					var uniqueArray = arrayCopy.filter(function(item, pos) {
						return arrayCopy.indexOf(item) == pos;
					})
					
					uniqueArray.forEach((item) => {
						this.parameterElementsArray.forEach((ele) => {
							if(Number(item) == Number(ele.dashboardParameterId)){
								ele.fetchedRecordsBool = false;
								ele.fetchedRecords.records = null
								ele.paramElementFilledBool = false;
							}
						})
					})
					//--------------------------------------------
					
					this.parameterElementsArray.forEach((ele) => {
						data.body.parameterResponse.forEach((rec) => {
							if(Number(ele.dashboardParameterId) == Number(rec.dashboardParameterId)){
								setTimeout(function(){ ele.fetchedRecordsBool = true; }, 150)
								ele.fetchedRecords.records = rec
								ele.fetchedRecords.listEle = rec.records;
							}
						})
					})
					
				}, error => console.log(error),
				() => console.log())
				
			 }
			else if(refEl.el.nativeElement.nodeName == "P-MULTISELECT"){
     
       
		// this.selectcolor = false;
				if(event.value.length == 1){ 

					var valueArray = event.value[0].toString();
				}
				else if(event.value.length > 1){
					var valueArray = event.value.map(x => "'" + x + "'").toString()
				}
				
				var obj = this.parameterElementsArray[Number(refEl.el.nativeElement.attributes.selectInfoIndex.value)]
				
				if(event.value.length == 0){ obj.paramElementFilledBool = false; }
				else { obj.paramElementFilledBool = true; }
	

				this.requestPayloadParametersArray = this.requestPayloadParametersArray.filter(obje => obje.id !== obj.dashboardParameterId);
												  
				var parameterArrayObj1 = {id: obj.dashboardParameterId, isVisible: obj.isVisible,
										name: obj.parameterName, questionId: obj.questionOrderBy, value: valueArray}

				this.requestPayloadParametersArray.push(parameterArrayObj1);
				this.requestPayloadParameterObj.parameters = this.datasData.map((obj) => this.requestPayloadParametersArray.find((o) => o.id === obj.id) || obj);

				//this.requestPayloadParameterObj.parameters = this.requestPayloadParametersArray;
         
        // this.onFilterSubmitClick();
		this.dashboard();

				this.getPrmDashCasRecsMultSubscrip = this.dashService.getParameterizedDashboardCascadingRecords(this.selectedDashboard.code,
				obj.dashboardParameterId,
				{"parameters":[this.userParameterObject,parameterArrayObj1]}).subscribe( (data: any) => {
        
					
					//--------------------------------------------
					data.body.parameterResponse.forEach((rec) => {
						this.parameterElementsArray.forEach((ele) => {
							if(Number(ele.dashboardParameterId) == Number(rec.dashboardParameterId)){
								this.parameterElementsArray.forEach((ele1) => {
									if(ele1.parameterName == ele.cascadeParameters){
										ele1.cascadeChildParameters.push(ele.dashboardParameterId)
									}
								})
							}
						})
					})
					
					var array = obj.cascadeChildParameters, newArray = [], arrayCopy = []; 
					
					if(array != []){
						array = array.filter(function(item, pos) {
							return array.indexOf(item) == pos;
						})
					}
					
					arrayCopy = array;
					
					do {
						newArray = [];
						
						array.forEach((item,i) => { 
							this.parameterElementsArray.forEach((ele) => {
								if(Number(ele.dashboardParameterId) == Number(item)){
									if(ele.cascadeChildParameters != []){
										for(let ii = 0; ii < ele.cascadeChildParameters.length;  ii++){
											newArray.push(ele.cascadeChildParameters[ii])
										}
									}
									else if(ele.cascadeChildParameters == []){
										
									}
								}
							})
						})
						
						array = []
						array = newArray;
						arrayCopy = [...arrayCopy, ...newArray]
						
					} while(newArray.length > 0)
					
					var uniqueArray = arrayCopy.filter(function(item, pos) {
						return arrayCopy.indexOf(item) == pos;
					})
					
					uniqueArray.forEach((item) => {
						this.parameterElementsArray.forEach((ele) => {
							if(Number(item) == Number(ele.dashboardParameterId)){
								ele.fetchedRecordsBool = false;
								ele.fetchedRecords.records = null
								ele.paramElementFilledBool = false;
							}
						})
					})
					//--------------------------------------------
					
					this.parameterElementsArray.forEach((ele) => {
						data.body.parameterResponse.forEach((rec) => {
							if(Number(ele.dashboardParameterId) == Number(rec.dashboardParameterId)){
								setTimeout(function(){  ele.fetchedRecordsBool = true; })
								ele.fetchedRecords.records = rec;
								ele.fetchedRecords.listEle = rec.records;
							}
						})
					})
					
				}, error => console.log(error),
				() => console.log())
				
			}
			else if(refEl.el.nativeElement.nodeName == "P-DROPDOWN"){
     
				this.selectcolor = true;
				
				var value1 = event.value
				var obj = this.parameterElementsArray[Number(refEl.el.nativeElement.attributes.selectInfoIndex.value)]
				
				this.requestPayloadParametersArray = this.requestPayloadParametersArray.filter(obje => obje.id !== obj.dashboardParameterId);
				
				var parameterArrayObj2 = {id: obj.dashboardParameterId, isVisible: obj.isVisible, 
											name: obj.parameterName, questionId: obj.questionOrderBy, value: value1}

				this.requestPayloadParametersArray.push(parameterArrayObj2)
				this.requestPayloadParameterObj.parameters = this.datasData.map((obj) => this.requestPayloadParametersArray.find((o) => o.id === obj.id) || obj);
			//	this.requestPayloadParameterObj.parameters = this.requestPayloadParametersArray;
				
				obj.paramElementFilledBool = true;

       
        
        //  this.onFilterSubmitClick();
		this.dashboard();

	
				
				this.getPrmDashCasRecsDropSubscrip = this.dashService.getParameterizedDashboardCascadingRecords(this.selectedDashboard.code,
				obj.dashboardParameterId,
				{"parameters":[this.userParameterObject,parameterArrayObj2]}).subscribe( (data: any) => {

          
					
					//--------------------------------------------
					data.body.parameterResponse.forEach((rec) => {
						this.parameterElementsArray.forEach((ele) => {
							if(Number(ele.dashboardParameterId) == Number(rec.dashboardParameterId)){
								this.parameterElementsArray.forEach((ele1) => {
									if(ele1.parameterName == ele.cascadeParameters){
										ele1.cascadeChildParameters.push(ele.dashboardParameterId)
									}
								})
							}
						})
					})
					
					var array = obj.cascadeChildParameters, newArray = [], arrayCopy = []; 
					
					if(array != []){
						array = array.filter(function(item, pos) {
							return array.indexOf(item) == pos;
						})
					}
					
					arrayCopy = array;
					
					do {
						newArray = [];
						
						array.forEach((item,i) => { 
							this.parameterElementsArray.forEach((ele) => {
								if(Number(ele.dashboardParameterId) == Number(item)){
									if(ele.cascadeChildParameters != []){
										for(let ii = 0; ii < ele.cascadeChildParameters.length;  ii++){
											newArray.push(ele.cascadeChildParameters[ii])
										}
									}
									else if(ele.cascadeChildParameters == []){
										
									}
								}
							})
						})
						
						array = []
						array = newArray;
						arrayCopy = [...arrayCopy, ...newArray]
						
					} while(newArray.length > 0)
					
					var uniqueArray = arrayCopy.filter(function(item, pos) {
						return arrayCopy.indexOf(item) == pos;
					})
					
					uniqueArray.forEach((item) => {
						this.parameterElementsArray.forEach((ele) => {
							if(Number(item) == Number(ele.dashboardParameterId)){
								ele.fetchedRecordsBool = false;
								ele.fetchedRecords.records = null
								ele.paramElementFilledBool = false;
							}
						})
					})
					//-------------------------------------------- 
					
					this.parameterElementsArray.forEach((ele) => {
						data.body.parameterResponse.forEach((rec) => {
							if(Number(ele.dashboardParameterId) == Number(rec.dashboardParameterId)){
								setTimeout(function(){ ele.fetchedRecordsBool = true; },150)
								ele.fetchedRecords.records = rec
								ele.fetchedRecords.listEle = rec.records;
							}
						})
					})

					
				}, error => console.log(error),
				() => console.log())
			}
			
		}
		else if(refEl == undefined){
			
			var value1 = event.target.value;
			var obj = this.parameterElementsArray[Number(event.target.attributes.selectInfoIndex.value)]
			
			this.requestPayloadParametersArray = this.requestPayloadParametersArray.filter(obje => obje.id !== obj.dashboardParameterId);
			
			var parameterArrayObj2 = {id: obj.dashboardParameterId, isVisible: obj.isVisible, 
										name: obj.parameterName, questionId: obj.questionOrderBy, value: value1}

			this.requestPayloadParametersArray.push(parameterArrayObj2)
			
			this.requestPayloadParameterObj.parameters = this.requestPayloadParametersArray;
			
			
			//if(event.target.nodeName != "INPUT"){
			if(event.target.type != "text"){ // radio button
				
				obj.paramElementFilledBool = true;
				
				this.getPrmDashCasRecsRadioSubscrip = this.dashService.getParameterizedDashboardCascadingRecords(this.selectedDashboard.code,
				obj.dashboardParameterId,
				{"parameters":[this.userParameterObject,parameterArrayObj2]}).subscribe( (data: any) => {
					
					//--------------------------------------------
					data.body.parameterResponse.forEach((rec) => {
						this.parameterElementsArray.forEach((ele) => {
							if(Number(ele.dashboardParameterId) == Number(rec.dashboardParameterId)){
								this.parameterElementsArray.forEach((ele1) => {
									if(ele1.parameterName == ele.cascadeParameters){
										ele1.cascadeChildParameters.push(ele.dashboardParameterId)
									}
								})
							}
						})
					})
					
					var array = obj.cascadeChildParameters, newArray = [], arrayCopy = []; 
					
					if(array != []){
						array = array.filter(function(item, pos) {
							return array.indexOf(item) == pos;
						})
					}
					
					arrayCopy = array;
					
					do {
						newArray = [];
						
						array.forEach((item,i) => { 
							this.parameterElementsArray.forEach((ele) => {
								if(Number(ele.dashboardParameterId) == Number(item)){
									if(ele.cascadeChildParameters != []){
										for(let ii = 0; ii < ele.cascadeChildParameters.length;  ii++){
											newArray.push(ele.cascadeChildParameters[ii])
										}
									}
									else if(ele.cascadeChildParameters == []){
										
									}
								}
							})
						})
						
						array = []
						array = newArray;
						arrayCopy = [...arrayCopy, ...newArray]
						
					} while(newArray.length > 0)
					
					var uniqueArray = arrayCopy.filter(function(item, pos) {
						return arrayCopy.indexOf(item) == pos;
					})
					
					uniqueArray.forEach((item) => {
						this.parameterElementsArray.forEach((ele) => {
							if(Number(item) == Number(ele.dashboardParameterId)){
								ele.fetchedRecordsBool = false;
								ele.fetchedRecords.records = null
								ele.paramElementFilledBool = false;
							}
						})
					})
					//--------------------------------------------
					
					this.parameterElementsArray.forEach((ele) => {
						data.body.parameterResponse.forEach((rec) => {
							if(Number(ele.dashboardParameterId) == Number(rec.dashboardParameterId)){
								setTimeout(function(){  ele.fetchedRecordsBool = true; })
								ele.fetchedRecords.records = rec
								ele.fetchedRecords.listEle = rec.records;
							}
						})
					})
					
				}, error => console.log(error),
				() => console.log())
				
			}
			else if(event.target.type == "text"){
				if(event.target.value.length == 0) { obj.paramElementFilledBool = false;}
				else{ obj.paramElementFilledBool = true; }
			}
			//}
		}
		
    } // onChange func

	dashboard(){
	//	this.clearMessages();
	

			acinfotech.requestType(true); // Letting chart library know it's a dashboard module call and empty all stored divs id and chart div elements
			
			d3.select(".tibo-mydash-dash-spinner").style("display","block"); // showing dashboard spinner
			
			//  d3.selectAll("#parameterized-dashboard-filter-panel-scroll-div, #submitbutton-id").transition().delay(200).duration(400).style("display","none")
			// d3.select("#dashboard-parameterized-filter").transition().delay(200).duration(500).style("width","40px")
			// d3.select("#dashboard-parameterized-scroll-view").transition().delay(200).duration(500).style("left","50px")//.style("width","calc(100% - 50px)")
			// d3.select("#dashboard-parameterized-filter").select("button").style("transform","rotate(180deg)")
			// d3.select("#filter-id").transition().delay(100).duration(100)
			// .style({'left':'3px','top':'50px'}).style("transform","rotate(-90deg)")
			this.filterPanelToggleBool = true;
			
			this.parameterizedDashboardRandomArray = [];
			this.finalCallCounter = 0;
			this.totalRows.forEach((rowEle,rowIndex) => {
				this.totalColumns.forEach((colEle,columnIndex) => {
					this.rowIndex.push("chartid-" + Math.floor(Math.random() * 10000) + 1)
				})
				this.parameterizedDashboardRandomArray.push(this.rowIndex)
				this.rowIndex = []
			})
			
			setTimeout(() => { // removing unused chart divs and adjusting their width
					
				var totalRows = this.totalRows;
				var totalColumns = this.totalColumns;
				var totalObjCoords = [];
				var usedObjCoords = [];
				var totalWidgets = this.totalWidgets;

				totalRows.map(function(row,rowInd){
					
					totalColumns.map(function(column,columnInd){
						totalObjCoords.push((rowInd+1) +","+ (columnInd+1))
					})

				})
				
				totalObjCoords.map(function(coord){

					totalWidgets.map(function(d){
						
						if((Number(d.controlMasterId) == 7) || (Number(d.controlMasterId) == 22) || (Number(d.controlMasterId) == 23) 
							|| (Number(d.controlMasterId) == 25) || (Number(d.controlMasterId) == 26) || (Number(d.controlMasterId) == 27)){
						}
						else {
							if(String(coord) == String(d.objectCordinates)){
								usedObjCoords.push(coord)
							}
						}
						
					})

				})

				var eachWidget = d3.selectAll(".widget-class")[0]
				for(var widi = 0; widi < totalObjCoords.length; widi++){
					
					if(usedObjCoords.indexOf(d3.select(eachWidget[widi]).attr("objectCordinates")) == -1){
						d3.select(eachWidget[widi]).remove();
					}
				}

				d3.selectAll(".tibo-mydash-paramdash-widget")[0].forEach(function(row){
					if(d3.select(row).selectAll("div.widget-class")[0].length == 1){
						d3.select(row).selectAll("div.widget-class").attr("class","widget-class expand-widget-class-1");
					}
					else if(d3.select(row).selectAll("div.widget-class")[0].length == 2){
						d3.select(row).selectAll("div.widget-class").attr("class","widget-class expand-widget-class-2");
					}
					else if(d3.select(row).selectAll("div.widget-class")[0].length == 3){
						d3.select(row).selectAll("div.widget-class").attr("class","widget-class expand-widget-class-3");
					}
				})

			}, 1000); // removing unused chart divs and adjusting their width
			
			this.totalWidgets.forEach((widget,index) => {
      
				
				if(widget.controlMasterId == 3){

        //   this.requestPayloadParameterObj ={
        //     filterType: "and",
        //     filters: [],
        //     parameters:[
        //     {id: 971, isVisible: 'Y', name: 'User', questionId: 0, value: 'anand@acinfotech.com'},

        //     {id: 965, isVisible: 'Y', name: 'Client', questionId: 1, value: 'ABC Company'},

        //     {id: 966, isVisible: 'Y', name: 'Gender', questionId: 2, value: ''},
 
        //     {id: 967, isVisible: 'Y', name: 'Type', questionId: 3, value: ''},

        //     {id: 968, isVisible: 'Y', name: 'State', questionId: 4, value: null},

        //     {id: 969, isVisible: 'Y', name: 'From_Date', questionId: 6, value: null},

        //     {id: 970, isVisible: 'Y', name: 'End_Date', questionId: 7, value: null}],

        //     sort: []
        //   } 
						
					this.getPrmDashWidgetDataSubscrip = this.dashService.getParameterizedDashboardWidgetData(widget.widgetControlId, "limit=&isWebservice=N", this.requestPayloadParameterObj).subscribe( (widgetdetails: any) => {
						
						this.dashboardInfoObject.widgetControls[index]["eachWidgetResponse"] = widgetdetails; // Storing each widget information for changing chart colors usage

						var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
						.attr("empty", false)
							
						this.table(chartElement,widget,widgetdetails)
						
					}, error => console.log(error),
					() => this.finalCall() ) // getParameterizedDashboardWidgetData
					
				}
				else {
         
          
			
					this.getPrmDashWidgetDataSubscrip = this.dashService.getParameterizedDashboardWidgetData(widget.widgetControlId, "limit=10", this.requestPayloadParameterObj).subscribe( (widgetdetails: any) => {

         
            
					
						this.dashboardInfoObject.widgetControls[index]["eachWidgetResponse"] = widgetdetails; // Storing each widget information for changing chart colors usage

					//-------------------------------------------------------------------------------------------------
					
						if(widget.controlMasterId == 1){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)

							this.pieChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 2){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)

							this.columnChart(chartElement,widget,widgetdetails)
						}
						/*else if(widget.controlMasterId == 3){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)
							
							this.table(chartElement,widget,widgetdetails)
						}*/
						else if(widget.controlMasterId == 5){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)
							
							this.donutChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 6){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)
							
							this.horizontalChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 7){ } // Waffle chart
						else if(widget.controlMasterId == 8){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)
							
							this.perGauge(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 9){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)
							
							this.numberGauge(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 10){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)
							
							this.map(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 11){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)
							
							this.columnAndLineChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 12){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)
							
							this.lineChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 13){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)
							
							this.bubbleChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 14){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)
							
							this.stackedHorizontalBarChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 15){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)
							
							this.stackedBarChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 16){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)
							
							this.perGaugeWithFlip(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 17){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)
							
							this.squareGauge(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 18){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)
							
							this.bellCurveChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 19){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)
							
							this.areaChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 20){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)

							this.groupedBarChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 21){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)

							this.horizontalGroupedBarChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 22){ } //Horizontal opposite bar Chart 
						else if(widget.controlMasterId == 23){ } //Vertical opposite bar Chart 
						else if(widget.controlMasterId == 24){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)

							this.dotGauge(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 25){ }//Square bubble chart
						else if(widget.controlMasterId == 26){ }//Magic quadrant
						else if(widget.controlMasterId == 27){ }//Funnel chart
						else if(widget.controlMasterId == 28){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)

							this.columnAndLinesChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 29){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)

							this.threeXAxisesGroupedBarChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 30){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)

							this.twoXAxisesGroupedBarChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 31){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)

							this.bellCurveChartWithMinAndMaxRange(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 32){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)

							this.stackedGroupedBarChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 33){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)

							this.stackedGroupedBarsWithLineChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 34){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)

							this.stackedBarWithLinesChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 35){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)

							this.trafficLightsChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 36){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)

							this.columnChartWithNegativeValues(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 37){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)

							this.groupedBarsWithLineChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 38){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)

							this.USMapWithPins(chartElement,widget,widgetdetails)
						}
						
						//-------------------------------------------------------------------------------------------------
						
					}, error => console.log(error),
					() => this.finalCall() ) // getParameterizedDashboardWidgetData
				}
				
			}) // forEach

	}
  
	onFilterSubmitClick(){
   
	
		var showAlertBool:Boolean;
		
		// this.parameterElementsArray.forEach((ele) => {
			
		// 	if(ele.isMandatory == 'Y' && ele.paramElementFilledBool != true){
		// 		showAlertBool = true;
		// 	}
		// })
		
		if(showAlertBool == true){
			this.addMessages();
		}
		else {
     
			this.clearMessages();

			acinfotech.requestType(true); // Letting chart library know it's a dashboard module call and empty all stored divs id and chart div elements
			
			d3.select(".tibo-mydash-dash-spinner").style("display","block"); // showing dashboard spinner
			
			//  d3.selectAll("#parameterized-dashboard-filter-panel-scroll-div, #submitbutton-id").transition().delay(200).duration(400).style("display","none")
			// d3.select("#dashboard-parameterized-filter").transition().delay(200).duration(500).style("width","40px")
			// d3.select("#dashboard-parameterized-scroll-view").transition().delay(200).duration(500).style("left","50px")//.style("width","calc(100% - 50px)")
			// d3.select("#dashboard-parameterized-filter").select("button").style("transform","rotate(180deg)")
			// d3.select("#filter-id").transition().delay(100).duration(100)
			// .style({'left':'3px','top':'50px'}).style("transform","rotate(-90deg)")
			this.filterPanelToggleBool = true;
			
			this.parameterizedDashboardRandomArray = [];
			this.finalCallCounter = 0;
			this.totalRows.forEach((rowEle,rowIndex) => {
				this.totalColumns.forEach((colEle,columnIndex) => {
					this.rowIndex.push("chartid-" + Math.floor(Math.random() * 10000) + 1)
				})
				this.parameterizedDashboardRandomArray.push(this.rowIndex)
				this.rowIndex = []
			})
			
			setTimeout(() => { // removing unused chart divs and adjusting their width
					
				var totalRows = this.totalRows;
				var totalColumns = this.totalColumns;
				var totalObjCoords = [];
				var usedObjCoords = [];
				var totalWidgets = this.totalWidgets;

				totalRows.map(function(row,rowInd){
					
					totalColumns.map(function(column,columnInd){
						totalObjCoords.push((rowInd+1) +","+ (columnInd+1))
					})

				})
				
				totalObjCoords.map(function(coord){

					totalWidgets.map(function(d){
						
						if((Number(d.controlMasterId) == 7) || (Number(d.controlMasterId) == 22) || (Number(d.controlMasterId) == 23) 
							|| (Number(d.controlMasterId) == 25) || (Number(d.controlMasterId) == 26) || (Number(d.controlMasterId) == 27)){
						}
						else {
							if(String(coord) == String(d.objectCordinates)){
								usedObjCoords.push(coord)
							}
						}
						
					})

				})

				var eachWidget = d3.selectAll(".widget-class")[0]
				for(var widi = 0; widi < totalObjCoords.length; widi++){
					
					if(usedObjCoords.indexOf(d3.select(eachWidget[widi]).attr("objectCordinates")) == -1){
						d3.select(eachWidget[widi]).remove();
					}
				}

				d3.selectAll(".tibo-mydash-paramdash-widget")[0].forEach(function(row){
					if(d3.select(row).selectAll("div.widget-class")[0].length == 1){
						d3.select(row).selectAll("div.widget-class").attr("class","widget-class expand-widget-class-1");
					}
					else if(d3.select(row).selectAll("div.widget-class")[0].length == 2){
						d3.select(row).selectAll("div.widget-class").attr("class","widget-class expand-widget-class-2");
					}
					else if(d3.select(row).selectAll("div.widget-class")[0].length == 3){
						d3.select(row).selectAll("div.widget-class").attr("class","widget-class expand-widget-class-3");
					}
				})

			}, 1000); // removing unused chart divs and adjusting their width
			
			this.totalWidgets.forEach((widget,index) => {
       
				
				if(widget.controlMasterId == 3){

        //   this.requestPayloadParameterObj ={
        //     filterType: "and",
        //     filters: [],
        //     parameters:[
        //     {id: 971, isVisible: 'Y', name: 'User', questionId: 0, value: 'anand@acinfotech.com'},

        //     {id: 965, isVisible: 'Y', name: 'Client', questionId: 1, value: 'ABC Company'},

        //     {id: 966, isVisible: 'Y', name: 'Gender', questionId: 2, value: ''},
 
        //     {id: 967, isVisible: 'Y', name: 'Type', questionId: 3, value: ''},

        //     {id: 968, isVisible: 'Y', name: 'State', questionId: 4, value: null},

        //     {id: 969, isVisible: 'Y', name: 'From_Date', questionId: 6, value: null},

        //     {id: 970, isVisible: 'Y', name: 'End_Date', questionId: 7, value: null}],

        //     sort: []
        //   } 
						
					this.getPrmDashWidgetDataSubscrip = this.dashService.getParameterizedDashboardWidgetData(widget.widgetControlId, "limit=&isWebservice=N", this.requestPayloadParameterObj).subscribe( (widgetdetails: any) => {
						
						this.dashboardInfoObject.widgetControls[index]["eachWidgetResponse"] = widgetdetails; // Storing each widget information for changing chart colors usage

						var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
						.attr("empty", false)
							
						this.table(chartElement,widget,widgetdetails)
						
					}, error => console.log(error),
					() => this.finalCall() ) // getParameterizedDashboardWidgetData
					
				}
				else {
          
          
			
					this.getPrmDashWidgetDataSubscrip = this.dashService.getParameterizedDashboardWidgetData(widget.widgetControlId, "limit=10", this.requestPayloadParameterObj).subscribe( (widgetdetails: any) => {

         
            
					
						this.dashboardInfoObject.widgetControls[index]["eachWidgetResponse"] = widgetdetails; // Storing each widget information for changing chart colors usage

					//-------------------------------------------------------------------------------------------------
					
						if(widget.controlMasterId == 1){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)

							this.pieChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 2){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)

							this.columnChart(chartElement,widget,widgetdetails)
						}
						/*else if(widget.controlMasterId == 3){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)
							
							this.table(chartElement,widget,widgetdetails)
						}*/
						else if(widget.controlMasterId == 5){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)
							
							this.donutChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 6){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)
							
							this.horizontalChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 7){ } // Waffle chart
						else if(widget.controlMasterId == 8){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)
							
							this.perGauge(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 9){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)
							
							this.numberGauge(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 10){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)
							
							this.map(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 11){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)
							
							this.columnAndLineChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 12){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)
							
							this.lineChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 13){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)
							
							this.bubbleChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 14){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)
							
							this.stackedHorizontalBarChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 15){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)
							
							this.stackedBarChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 16){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)
							
							this.perGaugeWithFlip(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 17){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)
							
							this.squareGauge(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 18){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)
							
							this.bellCurveChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 19){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)
							
							this.areaChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 20){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)

							this.groupedBarChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 21){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)

							this.horizontalGroupedBarChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 22){ } //Horizontal opposite bar Chart 
						else if(widget.controlMasterId == 23){ } //Vertical opposite bar Chart 
						else if(widget.controlMasterId == 24){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)

							this.dotGauge(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 25){ }//Square bubble chart
						else if(widget.controlMasterId == 26){ }//Magic quadrant
						else if(widget.controlMasterId == 27){ }//Funnel chart
						else if(widget.controlMasterId == 28){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)

							this.columnAndLinesChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 29){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)

							this.threeXAxisesGroupedBarChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 30){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)

							this.twoXAxisesGroupedBarChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 31){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)

							this.bellCurveChartWithMinAndMaxRange(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 32){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)

							this.stackedGroupedBarChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 33){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)

							this.stackedGroupedBarsWithLineChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 34){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)

							this.stackedBarWithLinesChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 35){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)

							this.trafficLightsChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 36){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)

							this.columnChartWithNegativeValues(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 37){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)

							this.groupedBarsWithLineChart(chartElement,widget,widgetdetails)
						}
						else if(widget.controlMasterId == 38){
							var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
							.attr("empty", false)

							this.USMapWithPins(chartElement,widget,widgetdetails)
						}
						
						//-------------------------------------------------------------------------------------------------
						
					}, error => console.log(error),
					() => this.finalCall() ) // getParameterizedDashboardWidgetData
				}
				
			}) // forEach
		}

		this.selectDate=null;
	} //onFilterSubmitClick func

	filterPanelToggle() {
    
		if(this.filterPanelToggleBool == false){
			d3.selectAll("#parameterized-dashboard-filter-panel-scroll-div, #submitbutton-id").transition().delay(200).duration(400).style("display","none")
			d3.select("#dashboard-parameterized-filter").transition().delay(200).duration(500).style("width","40px")
			 d3.select("#dashboard-parameterized-scroll-view").transition().delay(200).duration(500).style({"left":"0px",'width':'96%','height':'100%','float':'right'})//.style("width","calc(100% - 50px)")
			d3.select("#dashboard-parameterized-filter").select("button").style("transform","rotate(180deg)")
			d3.select("#filter-id").transition().delay(100).duration(100)
			.style({'left':'3px','top':'50px'}).style("transform","rotate(-90deg)")
			this.filterPanelToggleBool = true;
		}
		else {
			d3.select("#parameterized-dashboard-filter-panel-scroll-div").transition().delay(300).duration(400).style("display","block")
			 d3.select("#submitbutton-id").transition().delay(500).duration(400).style("display","block")
			d3.select("#dashboard-parameterized-filter").transition().delay(200).duration(500).style("width","275px")
			 d3.select("#dashboard-parameterized-scroll-view").transition().delay(200).duration(500).style({"left":"0px",'width':'80%','height':'100%'})//.style("width","calc(100% - 360px)")
			d3.select("#dashboard-parameterized-filter").select("button").style("transform","rotate(0deg)")
			d3.select("#filter-id").transition().delay(250).duration(100)
			.style({'left':'10px','top':'10px'}).style("transform","rotate(0deg)")
			this.filterPanelToggleBool = false;
		}
	}

	// filterPanelToggle() {
    
	// 	if(this.filterPanelToggleBool == false){
	// 		d3.selectAll("#parameterized-dashboard-filter-panel-scroll-div, #submitbutton-id").transition().delay(200).duration(400).style("display","none")
	// 		d3.select("#dashboard-parameterized-filter").transition().delay(200).duration(500).style("width","40px")
	// 		d3.select("#dashboard-parameterized-scroll-view").transition().delay(200).duration(500).style("left","50px")//.style("width","calc(100% - 50px)")
	// 		d3.select("#dashboard-parameterized-filter").select("button").style("transform","rotate(180deg)")
	// 		d3.select("#filter-id").transition().delay(100).duration(100)
	// 		.style({'left':'3px','top':'50px'}).style("transform","rotate(-90deg)")
	// 		this.filterPanelToggleBool = true;
	// 	}
	// 	else {
	// 		d3.select("#parameterized-dashboard-filter-panel-scroll-div").transition().delay(300).duration(400).style("display","block")
	// 		d3.select("#submitbutton-id").transition().delay(500).duration(400).style("display","block")
	// 		d3.select("#dashboard-parameterized-filter").transition().delay(200).duration(500).style("width","0px")
	// 		d3.select("#dashboard-parameterized-scroll-view").transition().delay(200).duration(500).style("left","360px")//.style("width","calc(100% - 360px)")
	// 		d3.select("#dashboard-parameterized-filter").select("button").style("transform","rotate(0deg)")
	// 		d3.select("#filter-id").transition().delay(250).duration(100)
	// 		.style({'left':'10px','top':'10px'}).style("transform","rotate(0deg)")
	// 		this.filterPanelToggleBool = false;
	// 	}
	// }
	
	addMessages() {
        this.alertMessage = [ {severity:'error', summary:'Error', detail:"Please fill all required fields."} ];
    }

    clearMessages() { this.alertMessage = []; }
	
	//---------------------------------------------------------------------------------------------
	
	pieChart(chartElement,widget,widgetdetails){
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widget.chartTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if(widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]"){
				xAxisFormatted = JSON.parse(widget.yAxis)[0]
			}
			else { xAxisFormatted = widget.yAxis }
			
			let yAxisFormatted = "";
			if(widget.xAxis.charAt(0) == "[" && widget.xAxis.charAt(widget.xAxis.length - 1) == "]"){
				yAxisFormatted = JSON.parse(widget.xAxis)[0]
			}
			else { yAxisFormatted = widget.xAxis }
			
			let options = {
				data: widgetdetails.body.records, // for chart
				dataForTable: [], // for table if there is no data send empty array ([])
				isDataDetailedForTable: false, 
				keys: {
					label: xAxisFormatted,
					value: yAxisFormatted
				},
				chartTitle: widget.chartTitle,
				showTitle: true,
				showLegend: true,
				legendSide: "right", //"right" or "left"
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three"
				gradient: "none", //"none" or "smooth" or "elliptical" or "circular" or "radial"
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				showTableIcon: true, //false - hide, true - show
				showTable: false, //false - hide, true - show
				showComments: false,
				comments: widget.comments,
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			
			setTimeout(() => { acinfotech.pieChart(chartElement, options);
				/*let html = document.getElementById(chartElement[0][0].id).innerHTML;
				//let dataURI = 'data:text/html,' + encodeURIComponent(html);
				
				console.log(chartElement.selectAll("svg")[0][1])
				
				let canvas = document.createElement("CANVAS") as HTMLCanvasElement;
				let img = document.createElement("img");
				console.log(canvas)
				var xml = new XMLSerializer().serializeToString(chartElement.selectAll("svg")[0][1]);

				// make it base64
				var svg64 = btoa(xml);
				var b64Start = "data:image/svg+xml;base64," + svg64;
				
				img.src = b64Start;
				
				canvas.getContext('2d').drawImage(img, 0, 0);
				
				console.log(canvas)
				
				let pngUrl = canvas.toDataURL();
				
				console.log(pngUrl)
				
				let ob = {"image":[pngUrl]}
				console.log(ob);*/
				
				/*let xml = new XMLSerializer().serializeToString(document.querySelector('svg'));
				let img = document.createElement("img");
				let canvas = document.getElementById("myCanvas") as HTMLCanvasElement;
				
				let svg64 = btoa(xml);
				let b64Start = "data:image/svg+xml;base64," + svg64;
				img.src = b64Start;
				canvas.style.color = "white"
				canvas.getContext('2d').drawImage(img, 0, 0);
				let png = canvas.toDataURL("image/png");
				console.log(png)*/
				
				/*var pdf = new jsPDF("p","mm","a4");
				pdf.addImage(png, 'PNG', 0, 0, 1350, 750);
				pdf.save('download.pdf');*/
				
				/*var doc = new jsPDF();
				var image = "data:image/png;base64,iVBORw0KGgoAA..";
				doc.addImage(png, 'JPEG', 15, 40, 180, 160);
				doc.save('title');*/
				
				
	/*var img1 = new Image();
    img1.src = b64Start;
    img1.addEventListener('load',()=>{
            var canvas = document.createElement('canvas');
            canvas.width = 250;
            canvas.height = 250;
			canvas.getContext('2d').drawImage(img1, 0, 0);
			canvas.style.backgroundColor = "white"

            var dataURL = canvas.toDataURL('image/jpeg');
			var fullQuality = canvas.toDataURL('image/jpeg', 1.0);
			
			console.log(img1.src)
			console.log(dataURL)
			
            var pdf = new jsPDF();
			pdf.addImage(dataURL, 'jpeg', 15, 40, 180, 160);
			pdf.text('Hello world!',30, 20);
			pdf.save('MCR.pdf');
    });*/
			
			/* -------------------------------------------------
			console.log(chartElement.selectAll("svg")[0][5])
			
			let xml = new XMLSerializer().serializeToString(chartElement.selectAll("svg")[0][5]);
			let img = document.createElement("img");
			//let canvas = document.getElementById("myCanvas") as HTMLCanvasElement;
			
			let canvas = document.createElement("canvas");
			
			let svg64 = btoa(xml);
			let b64Start = "data:image/svg+xml;base64," + svg64;
			img.src = b64Start;
			canvas.getContext('2d').drawImage(img, 0, 0);
			------------------------------------------------- */
			
			
			//console.log(b64Start)
			/*let svg = new Blob([xml],{type:'image/svg+sml;charset=utf-8'});
			
			let png = canvas.toDataURL("image/png");
			document.querySelector("#container").innerHTML = '<img src="'+png+'"/>';
			
			let DOMURL = self.URL || self.webkitURL || self;
			let url = DOMURL.createObjectURL(svg)
			DOMURL.revokeObjectURL(png);*/
			
			
			/* -----------------------------------------------
			let png = canvas.toDataURL("image/png");
			console.log(png)

			var img1 = new Image();
			img1.src = b64Start;
			img1.addEventListener('load',()=>{
				var canvas = document.createElement('canvas');
				canvas.getContext('2d').drawImage(img1,10,10,200,25);
				//canvas.style.backgroundColor = "white"
				canvas.style.width = "350px"
				canvas.style.height = "350px"
				canvas.style.border = "1px solid orange"

				var dataURL = canvas.toDataURL('image/png');
				console.log(dataURL)
				
				var pdf = new jsPDF();
				pdf.addImage(dataURL, 'png', 0, 0, 150, 300);
				pdf.text('Hello world!',30, 20);
				pdf.save('MCR.pdf');
					
			});
			----------------------------------------------- */
	
				
				
				/*let ob = {"image":[png]}
				console.log(ob);
				
				this.dashService.PPTXDashboardDownloadService(ob).subscribe( (resp: any) => {
					console.log(resp)*/
					
					
					/*let blob = new Blob([resp.body],{type:'application/vnd.openxmlformats-officedocument.presentationml.presentation'});
					console.log(blob);
						
					var a = document.createElement("a");
					a.href = URL.createObjectURL(blob);
					a.download = "abc" + ".pptx";
					a.click();*/
					
				/*}, error => console.log(error),
				() => console.log("final"))*/
				
			},1000)
		}
	}
	columnChart(chartElement,widget,widgetdetails){
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widget.chartTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if(widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]"){
				xAxisFormatted = JSON.parse(widget.yAxis)[0]
			}
			else { xAxisFormatted = widget.yAxis }
			
			let yAxisFormatted = "";
			if(widget.xAxis.charAt(0) == "[" && widget.xAxis.charAt(widget.xAxis.length - 1) == "]"){
				yAxisFormatted = JSON.parse(widget.xAxis)[0]
			}
			else { yAxisFormatted = widget.xAxis }
			
			let options = {
				data: widgetdetails.body.records,
				dataForTable: [], // for table
				isDataDetailedForTable: false,
				keys: {
					label: xAxisFormatted,
					value: yAxisFormatted
				},
				chartTitle: widget.chartTitle,
				xAxisLable: xAxisFormatted,
				yAxisLable: yAxisFormatted,
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //one or two or three
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				showTable: false, //false - hide, true - show
				showComments: false,
				comments: widget.comments,
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			setTimeout(() => { acinfotech.columnChart(chartElement, options) },1000)
		}
	}
	table(chartElement,widget,widgetdetails){
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widget.chartTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let options = {
				data: widgetdetails.body.records,
				chartTitle: widget.chartTitle,
				showTitle: true,
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				fontStyle: "Normal", // Normal, Italic
				fontWeight: "Bold", // Normal, Bold
				fontFamily: "Roboto", // Roboto, Century Gothic, Candara, Verdana, Trebuchet MS, Segoe UI
				resizeButtonID: document.getElementById("resizeBtn").id 
			}
			setTimeout(() => { acinfotech.table(chartElement, options) },1000)
		}
	}
	donutChart(chartElement,widget,widgetdetails){
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widget.chartTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if(widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]"){
				xAxisFormatted = JSON.parse(widget.yAxis)[0]
			}
			else { xAxisFormatted = widget.yAxis }
			
			let yAxisFormatted = "";
			if(widget.xAxis.charAt(0) == "[" && widget.xAxis.charAt(widget.xAxis.length - 1) == "]"){
				yAxisFormatted = JSON.parse(widget.xAxis)[0]
			}
			else { yAxisFormatted = widget.xAxis }
			
			let options = {
				data: widgetdetails.body.records, // for chart
				dataForTable: [], // for table if there is no data send empty array ([])
				isDataDetailedForTable: false, 
				keys: {
					label: xAxisFormatted,
					value: yAxisFormatted
				},
				chartTitle: widget.chartTitle,
				showTitle: true,
				showLegend: true,
				legendSide: "right", //"right" or "left"
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three"
				gradient: "none", //"none" or "smooth" or "elliptical" or "circular" or "radial"
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				showTableIcon: true, //false - hide, true - show
				showTable: false, //false - hide, true - show
				showComments: false,
				comments: widget.comments,
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			setTimeout(() => { acinfotech.donutChart(chartElement, options) },1000)
		}
	}
	horizontalChart(chartElement,widget,widgetdetails){
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widget.chartTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if(widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]"){
				xAxisFormatted = JSON.parse(widget.yAxis)[0]
			}
			else { xAxisFormatted = widget.yAxis }
			
			let yAxisFormatted = "";
			if(widget.xAxis.charAt(0) == "[" && widget.xAxis.charAt(widget.xAxis.length - 1) == "]"){
				yAxisFormatted = JSON.parse(widget.xAxis)[0]
			}
			else { yAxisFormatted = widget.xAxis }
			
			let options = {
				data: widgetdetails.body.records,
				dataForTable: [],
				isDataDetailedForTable: false,
				keys: {
					label: xAxisFormatted,
					value: yAxisFormatted
				},
				chartTitle: widget.chartTitle,
				xAxisLable: yAxisFormatted,
				yAxisLable: xAxisFormatted,
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three"
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				showTable: false,
				showComments: false,
				comments: widget.comments,
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			setTimeout(() => { acinfotech.horizontalChart(chartElement, options) },1000)
		}
	}
	//waffleChart(chartElement,widget,widgetdetails){}
	perGauge(chartElement,widget,widgetdetails){
		let fetchedData = widgetdetails.body.records == null ? widget.chartPlot : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records[0][widget.yAxis] : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widget.chartTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let options = {
				data: fetchedData,
				chartTitle: widget.chartTitle,
				showTitle: true,
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			setTimeout(() => { acinfotech.perGauge(chartElement, options) },1000)
		}
	}
	numberGauge(chartElement,widget,widgetdetails){
		let fetchedData = widgetdetails.body.records == null ? widget.chartPlot : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records[0][widget.yAxis] : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widget.chartTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let options = {
				data: fetchedData,
				chartTitle: widget.chartTitle,
				showTitle: true,
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			setTimeout(() => { acinfotech.numberGauge(chartElement, options) },1000)
		}
	}
	map(chartElement,widget,widgetdetails){
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widget.chartTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if(widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]"){
				xAxisFormatted = JSON.parse(widget.yAxis)[0]
			}
			else { xAxisFormatted = widget.yAxis }
			
			let yAxisFormatted = "";
			if(widget.xAxis.charAt(0) == "[" && widget.xAxis.charAt(widget.xAxis.length - 1) == "]"){
				yAxisFormatted = JSON.parse(widget.xAxis)[0]
			}
			else { yAxisFormatted = widget.xAxis }
			
			let options = {
				data: widgetdetails.body.records,
				keys: {
				  label: xAxisFormatted,
				  value: yAxisFormatted
				},
				chartTitle: widget.chartTitle,
				showTitle: true,
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			setTimeout(() => { acinfotech.map(chartElement, options) },1000)
		}
	}
	columnAndLineChart(chartElement,widget,widgetdetails){
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widget.chartTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxis = "";
			if(widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]"){
				xAxis = JSON.parse(widget.yAxis)[0]
			}
			else { xAxis = widget.yAxis }
			
			let options = {
				data: widgetdetails.body.records,
				dataForTable: [],
				isDataDetailedForTable: false,
				keys: {
				  label: xAxis,
				  valueForColumn: JSON.parse(widget.xAxis)[0], // left axis
				  valueForLine: JSON.parse(widget.xAxis)[1] // right axis
				},
				chartTitle: widget.chartTitle,
				xAxisLable: xAxis,
				yLeftAxisLable: JSON.parse(widget.xAxis)[0],
				yRightAxisLable: JSON.parse(widget.xAxis)[1],
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
				showTable: false,
				showComments: false,
				comments: widget.comments,
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			setTimeout(() => { acinfotech.columnAndLineChart(chartElement, options) },1000)
		}
	}
	lineChart(chartElement,widget,widgetdetails){
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widget.chartTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxis = "", xAxisArr = [];
			if(widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]"){
				xAxis = JSON.parse(widget.yAxis)
				if(xAxis.length > 1) { xAxisArr = xAxis as any }
				else { xAxis = JSON.parse(widget.yAxis)[0] }
			}
			else { xAxis = widget.yAxis }
			
			if(xAxisArr.length > 0){
				let yAxis = "";
				if(widget.xAxis.charAt(0) == "[" && widget.xAxis.charAt(widget.xAxis.length - 1) == "]"){
					yAxis = JSON.parse(widget.xAxis)[0];
				}
				else { 
					yAxis = widget.xAxis 
				}
				let options = {
					data: widgetdetails.body.records,
					dataForTable: [],
					isDataDetailedForTable: false,
					keys: {
						label: xAxisArr, // X-Axis labels
						lines: yAxis
					},
					chartTitle: widget.chartTitle,
					xAxisLable: yAxis,
					yAxisLable: "",
					showTitle: true,
					colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
					lineStyle: "cardinal", // "cardinal" or "linear" or "step"
					drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
					showTable: false,
					showComments: false,
					comments: widget.comments,
					resizeButtonID: document.getElementById("resizeBtn").id
				}
					
				setTimeout(() => { acinfotech.lineChart2(chartElement, options) },1000)
			}
			else {
				let options = {
					data: widgetdetails.body.records,
					dataForTable: [],
					isDataDetailedForTable: false,
					keys: {
						label: xAxis, // X-Axis labels
						lines: JSON.parse(widget.xAxis)
					},
					chartTitle: widget.chartTitle,
					xAxisLable: xAxis,
					yAxisLable: "",
					showTitle: true,
					colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
					lineStyle: "cardinal", // "cardinal" or "linear" or "step"
					drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
					showTable: false,
					showComments: false,
					comments: widget.comments,
					resizeButtonID: document.getElementById("resizeBtn").id
				}
				
				setTimeout(() => { acinfotech.lineChart(chartElement, options) },1000)
			}
		}
	}
	bubbleChart(chartElement,widget,widgetdetails){
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widget.chartTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if(widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]"){
				xAxisFormatted = JSON.parse(widget.yAxis)[0]
			}
			else { xAxisFormatted = widget.yAxis }

			let yAxisFormatted = "";
			if(widget.xAxis.charAt(0) == "[" && widget.xAxis.charAt(widget.xAxis.length - 1) == "]"){
				yAxisFormatted = JSON.parse(widget.xAxis)[0]
			}
			else { yAxisFormatted = widget.xAxis }
			
			let options = {
				data: widgetdetails.body.records,
				dataForTable: [],
				isDataDetailedForTable: false,
				keys: {
				label: xAxisFormatted,
				value: yAxisFormatted
				},
				chartTitle: widget.chartTitle,
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				showTable: false,
				showComments: false,
				comments: widget.comments,
				resizeButtonID: document.getElementById("resizeBtn").id
			}

			setTimeout(() => { acinfotech.bubbleChart(chartElement, options) },1000)
		}
	}
	stackedHorizontalBarChart(chartElement,widget,widgetdetails){
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widget.chartTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxis = "", xAxisArr = [];
			if(widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]"){
				xAxis = JSON.parse(widget.yAxis)
				if(xAxis.length > 1) { xAxisArr = xAxis as any }
				else { xAxis = JSON.parse(widget.yAxis)[0] }
			}
			else { xAxis = widget.yAxis }
			
			if(xAxisArr.length > 0){
				let yAxis = "";
				if(widget.xAxis.charAt(0) == "[" && widget.xAxis.charAt(widget.xAxis.length - 1) == "]"){
					yAxis = JSON.parse(widget.xAxis)[0];
				}
				else { 
					yAxis = widget.xAxis 
				}
				let options = {
					data: widgetdetails.body.records,
					dataForDrillDown: [],
					dataForTable: [],
					isDataDetailedForTable: false,
					keys: {
						label: xAxisArr, // X-Axis labels
						stackedBars: yAxis
					},
					chartTitle: widget.chartTitle,
					xAxisLable: "",
					yAxisLable: yAxis,
					showTitle: true,
					colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
					lineStyle: "cardinal", // "cardinal" or "linear" or "step"
					drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
					showTable: false,
					showComments: false,
					comments: widget.comments,
					resizeButtonID: document.getElementById("resizeBtn").id
				}
					
				setTimeout(() => { acinfotech.stackedHorizontalBarChart2(chartElement, options) },1000)
			}
			else {
				let options = {
					data: widgetdetails.body.records,
					dataForDrillDown: [],
					dataForTable: [],
					isDataDetailedForTable: false,
					keys: {
						label: xAxis, // X-Axis labels
						stackedBars: JSON.parse(widget.xAxis)
					},
					chartTitle: widget.chartTitle,
					xAxisLable: "",
					yAxisLable: xAxis,
					showTitle: true,
					colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
					lineStyle: "cardinal", // "cardinal" or "linear" or "step"
					drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
					showTable: false,
					showComments: false,
					comments: widget.comments,
					resizeButtonID: document.getElementById("resizeBtn").id
				}
				
				setTimeout(() => { acinfotech.stackedHorizontalBarChart(chartElement, options) },1000)
			}
		}
	}
	stackedBarChart(chartElement,widget,widgetdetails){
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widget.chartTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxis = "", xAxisArr = [];
			if(widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]"){
				xAxis = JSON.parse(widget.yAxis)
				if(xAxis.length > 1) { xAxisArr = xAxis as any }
				else { xAxis = JSON.parse(widget.yAxis)[0] }
			}
			else { xAxis = widget.yAxis }
			
			if(xAxisArr.length > 0){
				let yAxis = "";
				if(widget.xAxis.charAt(0) == "[" && widget.xAxis.charAt(widget.xAxis.length - 1) == "]"){
					yAxis = JSON.parse(widget.xAxis)[0];
				}
				else { 
					yAxis = widget.xAxis 
				}
				let options = {
					data: widgetdetails.body.records,
					dataForDrillDown: [],
					dataForTable: [],
					isDataDetailedForTable: false,
					keys: {
						label: xAxisArr, // X-Axis labels
						stackedBars: yAxis
					},
					chartTitle: widget.chartTitle,
					xAxisLable: yAxis,
					yAxisLable: "",
					showTitle: true,
					colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
					lineStyle: "cardinal", // "cardinal" or "linear" or "step"
					drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
					showTable: false,
					showComments: false,
					comments: widget.comments,
					resizeButtonID: document.getElementById("resizeBtn").id
				}
					
				setTimeout(() => { acinfotech.stackedBarChart2(chartElement, options) },1000)
			}
			else {
				let options = {
					data: widgetdetails.body.records,
					dataForDrillDown: [],
					dataForTable: [],
					isDataDetailedForTable: false,
					keys: {
						label: xAxis, // X-Axis labels
						stackedBars: JSON.parse(widget.xAxis)
					},
					chartTitle: widget.chartTitle,
					xAxisLable: xAxis,
					yAxisLable: "",
					showTitle: true,
					colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
					lineStyle: "cardinal", // "cardinal" or "linear" or "step"
					drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
					showTable: false,
					showComments: false,
					comments: widget.comments,
					resizeButtonID: document.getElementById("resizeBtn").id
				}
				
				setTimeout(() => { acinfotech.stackedBarChart(chartElement, options) },1000)
			}
		}
	}
	perGaugeWithFlip(chartElement,widget,widgetdetails){
		let fetchedData = widgetdetails.body.records == null ? widget.chartPlot : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records[0][widget.yAxis] : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widget.chartTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let options = {
				data: widgetdetails.body.records == null ? widget.chartPlot : widgetdetails.body.records[0][widget.yAxis],
				chartTitle: widget.chartTitle,
				coveredCircularAreaText: "",
				unCoveredCircularAreaText: "",
				showTitle: true,
				resizeButtonID: document.getElementById("resizeBtn").id
			}

			setTimeout(() => { acinfotech.perGaugeWithFlip(chartElement, options) },1000)
		}
	}
	squareGauge(chartElement,widget,widgetdetails){
		let fetchedData = widgetdetails.body.records == null ? widget.chartPlot : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records[0][widget.yAxis] : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widget.chartTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let options = {
				text: widgetdetails.body.records == null ? widget.chartPlot : widgetdetails.body.records[0][widget.yAxis],
				widgetTitle: widget.chartTitle,
				showTitle: true,
				resizeButtonID: document.getElementById("resizeBtn").id
			}

			setTimeout(() => { acinfotech.squareGauge(chartElement, options) },1000)
		}
	}
	bellCurveChart(chartElement,widget,widgetdetails){
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widget.chartTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if(widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]"){
				xAxisFormatted = JSON.parse(widget.yAxis)[0]
			}
			else { xAxisFormatted = widget.yAxis }

			let yAxisFormatted = "";
			if(widget.xAxis.charAt(0) == "[" && widget.xAxis.charAt(widget.xAxis.length - 1) == "]"){
				yAxisFormatted = JSON.parse(widget.xAxis)[0]
			}
			else { yAxisFormatted = widget.xAxis }
			
			let options = {
				data: widgetdetails.body.records,
				keys: {
					label: xAxisFormatted,
					value: yAxisFormatted,
					toolTipLabel: (widget.chartPlot.split(",") > 0) ? widget.chartPlot.split(",") : []
				},
				chartTitle: widget.chartTitle,
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
				resizeButtonID: document.getElementById("resizeBtn").id 
			}   
			setTimeout(() => { acinfotech.bellCurveChart(chartElement, options) },1000)
		}
	}
	areaChart(chartElement,widget,widgetdetails){
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widget.chartTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxis = "", xAxisArr = [];
			if(widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]"){
				xAxis = JSON.parse(widget.yAxis)
				if(xAxis.length > 1) { xAxisArr = xAxis as any }
				else { xAxis = JSON.parse(widget.yAxis)[0] }
			}
			else { xAxis = widget.yAxis }
			
			if(xAxisArr.length > 0){
				let yAxis = "";
				if(widget.xAxis.charAt(0) == "[" && widget.xAxis.charAt(widget.xAxis.length - 1) == "]"){
					yAxis = JSON.parse(widget.xAxis)[0];
				}
				else { 
					yAxis = widget.xAxis 
				}
				
				let options = {
					data: widgetdetails.body.records,
					dataForTable: [],
					isDataDetailedForTable: false,
					keys: {
						label: xAxisArr, // X-Axis labels
						areas: yAxis
					},
					chartTitle: widget.chartTitle,
					xAxisLable: yAxis,
					yAxisLable: "",
					showTitle: true,
					colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
					lineStyle: "cardinal", // "cardinal" or "linear" or "step"
					drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
					showTable: false,
					showComments: false,
					comments: widget.comments,
					resizeButtonID: document.getElementById("resizeBtn").id
				}
					
				setTimeout(() => { acinfotech.areaChart2(chartElement, options) },1000)
			}
			else {
				let options = {
					data: widgetdetails.body.records,
					dataForTable: [],
					isDataDetailedForTable: false,
					keys: {
						label: xAxis, // X-Axis labels
						areas: JSON.parse(widget.xAxis)
					},
					chartTitle: widget.chartTitle,
					xAxisLable: xAxis,
					yAxisLable: "",
					showTitle: true,
					colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
					lineStyle: "cardinal", // "cardinal" or "linear" or "step"
					drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
					showTable: false,
					showComments: false,
					comments: widget.comments,
					resizeButtonID: document.getElementById("resizeBtn").id
				}
				
				setTimeout(() => { acinfotech.areaChart(chartElement, options) },1000)
			}
		}
	}
	groupedBarChart(chartElement,widget,widgetdetails){
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widget.chartTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if(widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]"){
				xAxisFormatted = JSON.parse(widget.yAxis)[0]
			}
			else { xAxisFormatted = widget.yAxis }

			let options = {
				data: widgetdetails.body.records,
				dataForDrillDown: [],
				dataForTable: [],
				isDataDetailedForTable: false,
				keys: {
					label: xAxisFormatted,
					groupBars: JSON.parse(widget.xAxis),
				},
				chartTitle: widget.chartTitle,
				xAxisLable: xAxisFormatted,
				yAxisLable: "",
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				showTable: false,
				showComments: false,
				comments: widget.comments,
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			
			setTimeout(() => { acinfotech.groupedBarChart(chartElement, options) },1000)
		}
	}
	horizontalGroupedBarChart(chartElement,widget,widgetdetails){
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widget.chartTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if(widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]"){
				xAxisFormatted = JSON.parse(widget.yAxis)[0]
			}
			else { xAxisFormatted = widget.yAxis }

			let options = {
				data: widgetdetails.body.records,
				dataForDrillDown: [],
				dataForTable: [],
				isDataDetailedForTable: false,
				keys: {
					label: xAxisFormatted,
					groupBars: JSON.parse(widget.xAxis),
				},
				chartTitle: widget.chartTitle,
				xAxisLable: "",
				yAxisLable: xAxisFormatted,
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				showTable: false,
				showComments: false,
				comments: widget.comments,
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			
			setTimeout(() => { acinfotech.horizontalGroupedBarChart(chartElement, options) },1000)
		}
	}
	//horizontalOppositeBarChart(chartElement,widget,widgetdetails){}
	//verticalOppositeBarChart(chartElement,widget,widgetdetails){}
	dotGauge(chartElement,widget,widgetdetails){
		let options = {
			data: widgetdetails.body.records == null ? widget.chartPlot : widgetdetails.body.records[0][widget.yAxis],
			chartTitle: widget.chartTitle,
			showTitle: true,
			resizeButtonID: document.getElementById("resizeBtn").id
		}
		setTimeout(() => { acinfotech.dotGauge(chartElement, options) },1000)
	}
	//squareBubbleChart(chartElement,widget,widgetdetails){}
	//magicQuadrant(chartElement,widget,widgetdetails){}
	//funnelChart(chartElement,widget,widgetdetails){}
	columnAndLinesChart(chartElement,widget,widgetdetails){
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widget.chartTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if(widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]"){
				xAxisFormatted = JSON.parse(widget.yAxis)[0]
			}
			else { xAxisFormatted = widget.yAxis }
			
			let options = {
				data: widgetdetails.body.records,
				dataForTable: [],
				isDataDetailedForTable: false,
				keys: {
					label: xAxisFormatted, // X-Axis labels
					valueForColumn: JSON.parse(widget.xAxis)[0], // Single Value - left y axis
					valueForLines: JSON.parse(widget.xAxis).slice(1),  // multiple value (array) - right y axis
					toolTipLabel: (widget.chartPlot.split(",") > 0) ? widget.chartPlot.split(",") : []
				},
				chartTitle: widget.chartTitle,
				xAxisLable: xAxisFormatted,
				//yAxisLable: "",
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
				lineStyle: "cardinal", // "cardinal" or "linear" or "step"
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				showTable: false,
				showComments: false,
				comments: widget.comments,
				resizeButtonID: document.getElementById("resizeBtn").id
			}
		
			setTimeout(() => { acinfotech.columnAndLinesChart(chartElement, options) },1000)
		}
	}
	threeXAxisesGroupedBarChart(chartElement,widget,widgetdetails){
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widget.chartTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let options = {
				data: widgetdetails.body.records,
				keys: {
					labels: JSON.parse(widget.yAxis),
					groupBars: JSON.parse(widget.xAxis),
					toolTipLabel: (widget.chartPlot.split(",") > 0) ? widget.chartPlot.split(",") : []
				},
				chartTitle: widget.chartTitle,
				xAxisLables: JSON.parse(widget.yAxis),
				yAxisLable: "",
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
				showComments: false,
				comments: widget.comments,
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			setTimeout(() => { acinfotech.threeXAxisesGroupedBarChart(chartElement, options) },1000)
		}
	}
	twoXAxisesGroupedBarChart(chartElement,widget,widgetdetails){
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widget.chartTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let options = {
				data: widgetdetails.body.records,
				dataForDrillDown: [],
				keys: {
					labels: JSON.parse(widget.yAxis),
					groupBars: JSON.parse(widget.xAxis),
					toolTipLabel: (widget.chartPlot.split(",") > 0) ? widget.chartPlot.split(",") : []

				},
				chartTitle: widget.chartTitle,
				xAxisLables: JSON.parse(widget.yAxis),
				//yAxisLable: "",
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				showComments: false,
				comments: widget.comments,
				resizeButtonID: document.getElementById("resizeBtn").id
				
			}
			setTimeout(() => { acinfotech.twoXAxisesGroupedBarChart(chartElement, options) },1000)
		}
	}
	bellCurveChartWithMinAndMaxRange(chartElement,widget,widgetdetails){
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widget.chartTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let options = {
				data: widgetdetails.body.records,
				keys: {
					label: JSON.parse(widget.yAxis[0]),
					value: JSON.parse(widget.xAxis[0]),
					minValue: JSON.parse(widget.xAxis[1]),
					maxValue: JSON.parse(widget.xAxis[2]),
					minAndMaxLines: JSON.parse(widget.yAxis[0]),
					toolTipLabel: (widget.chartPlot.split(",") > 0) ? widget.chartPlot.split(",") : []
				},
				chartTitle: widget.chartTitle,
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
				resizeButtonID: document.getElementById("resizeBtn").id
			}
		
			setTimeout(() => { acinfotech.bellCurveChartWithMinAndMaxRange(chartElement, options) },1000)
		}
	}
	stackedGroupedBarChart(chartElement,widget,widgetdetails){
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widget.chartTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let options = {
				data: widgetdetails.body.records,
				keys: {
					labels: JSON.parse(widget.yAxis),
					groupBars: JSON.parse(widget.xAxis),
					toolTipLabel: (widget.chartPlot.split(",") > 0) ? widget.chartPlot.split(",") : []
					
				},
				chartTitle: widget.chartTitle,
				xAxisLables: JSON.parse(widget.yAxis),
				//yAxisLable: "",
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				showTable: false,
				showComments: false,
				comments: widget.comments,
				resizeButtonID: document.getElementById("resizeBtn").id
			}

			setTimeout(() => { acinfotech.stackedGroupedBarChart(chartElement, options) },1000)
		}
	}
	stackedGroupedBarsWithLineChart(chartElement,widget,widgetdetails){
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widget.chartTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let options = {
				data: widgetdetails.body.records,
				keys: {
					labels: JSON.parse(widget.yAxis),
					groupBars: JSON.parse(widget.xAxis),
					lines: JSON.parse(widget.secondaryYaxis),
					toolTipLabel: (widget.chartPlot.split(",") > 0) ? widget.chartPlot.split(",") : []
					
				},
				chartTitle: widget.chartTitle,
				xAxisLables: JSON.parse(widget.yAxis),
				//yAxisLable: "",
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				showTable: false,
				showComments: false,
				comments: widget.comments,
				resizeButtonID: document.getElementById("resizeBtn").id
			}
		
			setTimeout(() => { acinfotech.stackedGroupedBarsWithLineChart(chartElement, options) },1000)
		}
	}
	stackedBarWithLinesChart(chartElement,widget,widgetdetails){
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widget.chartTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if(widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]"){
				xAxisFormatted = JSON.parse(widget.yAxis)[0]
			}
			else { xAxisFormatted = widget.yAxis }
			
			let options = {
				data: widgetdetails.body.records,
				dataForTable: [],
				isDataDetailedForTable: false,
				keys: {
					label: xAxisFormatted,
					stackedBars: JSON.parse(widget.xAxis),
					lines: JSON.parse(widget.secondaryYaxis),
					toolTipLabel: (widget.chartPlot.split(",") > 0) ? widget.chartPlot.split(",") : []
				},
				chartTitle: widget.chartTitle,
				xAxisLable: xAxisFormatted,
				yAxisLable: "",
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				showTable: false,
				showComments: false,
				comments: widget.comments,
				resizeButtonID: document.getElementById("resizeBtn").id
			}
		
			setTimeout(() => { acinfotech.stackedBarWithLinesChart(chartElement, options) },1000)
		}
	}
	trafficLightsChart(chartElement,widget,widgetdetails){
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widget.chartTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if(widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]"){
				xAxisFormatted = JSON.parse(widget.yAxis)[0]
			}
			else { xAxisFormatted = widget.yAxis }

			let yAxisFormatted = "";
			if(widget.xAxis.charAt(0) == "[" && widget.xAxis.charAt(widget.xAxis.length - 1) == "]"){
				yAxisFormatted = JSON.parse(widget.xAxis)[0]
			}
			else { yAxisFormatted = widget.xAxis }
			
			let options = {
				data: widgetdetails.body.records,
				dataForTable: [],
				isDataDetailedForTable: false,
				keys: {
					label: xAxisFormatted,
					value: yAxisFormatted,
					toolTipLabel: (widget.chartPlot.split(",") > 0) ? widget.chartPlot.split(",") : []
				},
				chartTitle: widget.chartTitle,
				showTitle: true,
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				showTable: false,
				showComments: false,
				comments: widget.comments,
				resizeButtonID: document.getElementById("resizeBtn").id
			}

			setTimeout(() => { acinfotech.trafficLightsChart(chartElement, options) },1000)
		}
	}
	columnChartWithNegativeValues(chartElement,widget,widgetdetails){
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widget.chartTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if(widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]"){
				xAxisFormatted = JSON.parse(widget.yAxis)[0]
			}
			else { xAxisFormatted = widget.yAxis }

			let yAxisFormatted = "";
			if(widget.xAxis.charAt(0) == "[" && widget.xAxis.charAt(widget.xAxis.length - 1) == "]"){
				yAxisFormatted = JSON.parse(widget.xAxis)[0]
			}
			else { yAxisFormatted = widget.xAxis }
			
			let options = {
				data: widgetdetails.body.records,
				dataForTable: [], // for table
				isDataDetailedForTable: false,
				keys: {
					label: xAxisFormatted, 
					value: yAxisFormatted
				},
				chartTitle: widget.chartTitle,
				xAxisLable: xAxisFormatted,
				yAxisLable: yAxisFormatted,
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //one or two or three
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				showTable: false, //false - hide, true - show
				showComments: false,
				comments: widget.comments,
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			
			setTimeout(() => { acinfotech.columnChartWithNegativeValues(chartElement, options) },1000)
		}
	}
	groupedBarsWithLineChart(chartElement,widget,widgetdetails){
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widget.chartTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if(widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]"){
				xAxisFormatted = JSON.parse(widget.yAxis)[0]
			}
			else { xAxisFormatted = widget.yAxis }
			
			let options = {
				data: widgetdetails.body.records,
				dataForTable: [],
				isDataDetailedForTable: false,
				keys: {
					label: xAxisFormatted,
					groupBars: JSON.parse(widget.xAxis),
					lines: JSON.parse(widget.secondaryYaxis)
				},
				chartTitle: widget.chartTitle,
				xAxisLable: xAxisFormatted,
				yAxisLable: "",
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				showTable: false,
				showComments: true,
				comments: widget.comments,
				resizeButtonID: document.getElementById("resizeBtn").id
			}
				
			setTimeout(() => { acinfotech.groupedBarsWithLineChart(chartElement, options) },1000)
		}
	}
	USMapWithPins(chartElement,widget,widgetdetails){
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widget.chartTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if(widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]"){
				xAxisFormatted = JSON.parse(widget.yAxis)[0]
			}
			else { xAxisFormatted = widget.yAxis }

			let yAxisFormatted = "";
			if(widget.xAxis.charAt(0) == "[" && widget.xAxis.charAt(widget.xAxis.length - 1) == "]"){
				yAxisFormatted = JSON.parse(widget.xAxis)[0]
			}
			else { yAxisFormatted = widget.xAxis }
			
			let options = {
				data: widgetdetails.body.records,
				dataForTable: [],
				keys: {
					stateCode: xAxisFormatted,
					latitude: "LATITUDE",
					longitude: "LONGITUDE",
					state: widget.chartPlot,
					place: yAxisFormatted,
					value: JSON.parse(widget.secondaryYaxis)[0]
				},
				chartTitle: widget.chartTitle,
				showTable: false,
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				resizeButtonID: document.getElementById("resizeBtn").id
			}

			setTimeout(() => { acinfotech.USMapWithPins(chartElement, options) },1000)
		}
	}
	
	//---------------------------------------------------------------------------------------------
	
	addSingle() { this.messageService.add({severity:'error', summary:'', detail:this.errorMsg}); }

    clear() { this.messageService.clear(); }
	
	changeDashboardFunc(event){
        this.selectedDashboard = {name: event.item.label, code: event.item.id }
		this.getDashboard("")
		
		d3.select("#tibo-mydash-dashlist-container-id").style("display","none")
		d3.select("#tibo-mydash-dashlist-container").style("display","none")
		//dashListPanelToggleBool = false;
		
    }
	
	optionsFunc(event){
		if(event.item.id == 1){
			
			let canvas = document.getElementsByClassName('widget-class') as HTMLCollectionOf<HTMLElement>;
			
			if(this.moreItems[0].label == "Show widgets in full view"){
				this.moreItems[0].label = "Show widgets in widget view"
				this.moreItems[0].icon = "pi pi-th-large"
				
				for(var i = 0; i < canvas.length; i++)
				{
					canvas[i].classList.add('full-view-widget-class');
				}
				window.dispatchEvent(new Event('resize'));
			}
			else {
				this.moreItems[0].label = "Show widgets in full view"
				this.moreItems[0].icon = "pi pi-external-link"
				
				for(var i = 0; i < canvas.length; i++)
				{
					canvas[i].classList.remove('full-view-widget-class');
				}
				window.dispatchEvent(new Event('resize'));
			}
			
			//d3.selectAll(".widget-class").attr("class","widget-class-2 full-view-widget-class")
			//window.dispatchEvent(new Event('resize'));
			
		}
		else if(event.item.id == 2){
			
			if(this.moreItems[1].label == "Turn on page refresh"){
				
				this.moreItems[1].label = "Turn off page refresh"
				this.moreItems[1].icon = "pi pi-times"
				this.refreshTimerFunc()
			}
			else {
				
				this.moreItems[1].label = "Turn on page refresh"
				this.moreItems[1].icon = "pi pi-refresh"
				
				clearInterval(this.refreshInterval);
				this.countDownLabel.innerHTML = "";
				
			}
			
		}
		else if(event.item.id == 3){
			this.shareDashDialBoxDisplayBool = true;
		}
		else if(event.item.id == 4){
			this.selectedChartsColorSet = "one";
			//this.getDashboard("");
			this.reloadDashboardWithNewColorSetFunc();
		}
		else if(event.item.id == 5){
			this.selectedChartsColorSet = "two";
			//this.getDashboard("");
			this.reloadDashboardWithNewColorSetFunc();
		}
		else if(event.item.id == 6){
			this.selectedChartsColorSet = "three";
			//this.getDashboard("");
			this.reloadDashboardWithNewColorSetFunc();
		}
	}

	changeChartColorsFunc(colorSet){
		
		if(colorSet == "one"){
			this.selectedChartsColorSet = "one";
			this.reloadDashboardWithNewColorSetFunc();
		}
		else if(colorSet == "two"){
			this.selectedChartsColorSet = "two";
			this.reloadDashboardWithNewColorSetFunc();
		}
		else if(colorSet == "three"){
			this.selectedChartsColorSet = "three";
			this.reloadDashboardWithNewColorSetFunc();
		}
	}

	reloadDashboardWithNewColorSetFunc(){
	

		this.moreItems[0].label = "Show widgets in full view"; // Setting the widgets full view mode option
		this.moreItems[0].icon = "pi pi-external-link";
		
		var dashboardInfoObject = this.dashboardInfoObject; // Creating local variable for use in .map, .forEach method
		
		if((this.randomIdArray.length > 0) || (this.parameterizedDashboardRandomArray.length > 0)){ // checking whether a dashboard is loaded or not

			acinfotech.requestType(true); // Letting chart library know it's a dashboard module call and empty all stored divs id and chart div elements

			d3.select(".tibo-mydash-dash-spinner").style("display","block") //  showing dashboard spinner

			this.totalRows = Array(Number(dashboardInfoObject.row)).fill(0);
			this.totalColumns = Array(Number(dashboardInfoObject.column)).fill(0);
			
			this.randomIdArray = [], this.parameterizedDashboardRandomArray = [], this.rowIndex = [], this.finalCallCounter = 0;
			
			if(dashboardInfoObject.dashboardRequestorParameters.length == 0){  

				this.totalRows.forEach((rowEle,rowIndex) => {
					this.totalColumns.forEach((colEle,columnIndex) => {
						this.rowIndex.push("chartid-" + Math.floor(Math.random() * 10000) + 1)
					})
					this.randomIdArray.push(this.rowIndex)
					this.rowIndex = []
				})
				
				//d3.select("#dashboard-scroll-wrapper").style("display","block")
				//d3.select("#dashboard-parameterized-scroll-wrapper").style("display","none")

				setTimeout(() => { // removing unused chart divs and adjusting their width
					
					var totalRows = this.totalRows;
					var totalColumns = this.totalColumns;
					var totalObjCoords = [];
					var usedObjCoords = [];

					totalRows.map(function(row,rowInd){
						
						totalColumns.map(function(column,columnInd){
							totalObjCoords.push((rowInd+1) +","+ (columnInd+1))
						})

					})
					
					totalObjCoords.map(function(coord){
						
						dashboardInfoObject.widgetControls.map(function(d){
							
							if((Number(d.controlMasterId) == 7) || (Number(d.controlMasterId) == 22) || (Number(d.controlMasterId) == 23) 
								|| (Number(d.controlMasterId) == 25) || (Number(d.controlMasterId) == 26) || (Number(d.controlMasterId) == 27)){
							}
							else {
								if(String(coord) == String(d.objectCordinates)){
									usedObjCoords.push(coord)
								}
							}
							
						})

					})

					var eachWidget = d3.selectAll(".widget-class")[0]
					for(var widi = 0; widi < totalObjCoords.length; widi++){
						
						if(usedObjCoords.indexOf(d3.select(eachWidget[widi]).attr("objectCordinates")) == -1){
							d3.select(eachWidget[widi]).remove();
						}
					}

					d3.selectAll(".tibo-mydash-nonparamdash-widget")[0].forEach(function(row){
						if(d3.select(row).selectAll("div.widget-class")[0].length == 1){
							d3.select(row).selectAll("div.widget-class").attr("class","widget-class expand-widget-class-1");
						}
						else if(d3.select(row).selectAll("div.widget-class")[0].length == 2){
							d3.select(row).selectAll("div.widget-class").attr("class","widget-class expand-widget-class-2");
						}
						else if(d3.select(row).selectAll("div.widget-class")[0].length == 3){
							d3.select(row).selectAll("div.widget-class").attr("class","widget-class expand-widget-class-3");
						}
					})

				}, 1000); // removing unused chart divs and adjusting their width
			
			} // dashboardInfoObject.dashboardRequestorParameters.length == 0
			else {

				this.totalRows.forEach((rowEle,rowIndex) => {
					this.totalColumns.forEach((colEle,columnIndex) => {
						this.rowIndex.push("chartid-" + Math.floor(Math.random() * 10000) + 1)
					})
					this.parameterizedDashboardRandomArray.push(this.rowIndex)
					this.rowIndex = []
				})
				
				//d3.select("#dashboard-scroll-wrapper").style("display","block")
				//d3.select("#dashboard-parameterized-scroll-wrapper").style("display","none")

				setTimeout(() => { // removing unused chart divs and adjusting their width
					
					var totalRows = this.totalRows;
					var totalColumns = this.totalColumns;
					var totalObjCoords = [];
					var usedObjCoords = [];

					totalRows.map(function(row,rowInd){
						
						totalColumns.map(function(column,columnInd){
							totalObjCoords.push((rowInd+1) +","+ (columnInd+1))
						})

					})
					
					totalObjCoords.map(function(coord){
						
						dashboardInfoObject.widgetControls.map(function(d){
							
							if((Number(d.controlMasterId) == 7) || (Number(d.controlMasterId) == 22) || (Number(d.controlMasterId) == 23) 
								|| (Number(d.controlMasterId) == 25) || (Number(d.controlMasterId) == 26) || (Number(d.controlMasterId) == 27)){
							}
							else {
								if(String(coord) == String(d.objectCordinates)){
									usedObjCoords.push(coord)
								}
							}
							
						})

					})

					var eachWidget = d3.selectAll(".widget-class")[0]
					for(var widi = 0; widi < totalObjCoords.length; widi++){
						
						if(usedObjCoords.indexOf(d3.select(eachWidget[widi]).attr("objectCordinates")) == -1){
							d3.select(eachWidget[widi]).remove();
						}
					}

					d3.selectAll(".tibo-mydash-paramdash-widget")[0].forEach(function(row){
						if(d3.select(row).selectAll("div.widget-class")[0].length == 1){
							d3.select(row).selectAll("div.widget-class").attr("class","widget-class expand-widget-class-1");
						}
						else if(d3.select(row).selectAll("div.widget-class")[0].length == 2){
							d3.select(row).selectAll("div.widget-class").attr("class","widget-class expand-widget-class-2");
						}
						else if(d3.select(row).selectAll("div.widget-class")[0].length == 3){
							d3.select(row).selectAll("div.widget-class").attr("class","widget-class expand-widget-class-3");
						}
					})

				}, 1000); // removing unused chart divs and adjusting their width

			}

			setTimeout(() => { 

				dashboardInfoObject.widgetControls.forEach((widget,index) => {
					
					if(widget.controlMasterId == 3){
						
						var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
						.attr("empty", false)

						this.table(chartElement,widget,dashboardInfoObject.widgetControls[index].eachWidgetResponse)
							
						this.finalCall();
					}
					else {
						
						//-------------------------------------------------------------------------------------------------
						
							if(widget.controlMasterId == 1){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)
								

								this.pieChart(chartElement,widget,dashboardInfoObject.widgetControls[index].eachWidgetResponse)
							}
							else if(widget.controlMasterId == 2){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)

								this.columnChart(chartElement,widget,dashboardInfoObject.widgetControls[index].eachWidgetResponse)
							}
							/*else if(widget.controlMasterId == 3){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)
								
								this.table(chartElement,widget,dashboardInfoObject.widgetControls[index].eachWidgetResponse)
							}*/
							else if(widget.controlMasterId == 5){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)
								
								this.donutChart(chartElement,widget,dashboardInfoObject.widgetControls[index].eachWidgetResponse)
							}
							else if(widget.controlMasterId == 6){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)
								
								this.horizontalChart(chartElement,widget,dashboardInfoObject.widgetControls[index].eachWidgetResponse)
							}
							else if(widget.controlMasterId == 7){ } // Waffle chart
							else if(widget.controlMasterId == 8){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)
								
								this.perGauge(chartElement,widget,dashboardInfoObject.widgetControls[index].eachWidgetResponse)
							}
							else if(widget.controlMasterId == 9){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)
								
								this.numberGauge(chartElement,widget,dashboardInfoObject.widgetControls[index].eachWidgetResponse)
							}
							else if(widget.controlMasterId == 10){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)
								
								this.map(chartElement,widget,dashboardInfoObject.widgetControls[index].eachWidgetResponse)
							}
							else if(widget.controlMasterId == 11){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)
								
								this.columnAndLineChart(chartElement,widget,dashboardInfoObject.widgetControls[index].eachWidgetResponse)
							}
							else if(widget.controlMasterId == 12){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)
								
								this.lineChart(chartElement,widget,dashboardInfoObject.widgetControls[index].eachWidgetResponse)
							}
							else if(widget.controlMasterId == 13){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)
								
								this.bubbleChart(chartElement,widget,dashboardInfoObject.widgetControls[index].eachWidgetResponse)
							}
							else if(widget.controlMasterId == 14){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)
								
								this.stackedHorizontalBarChart(chartElement,widget,dashboardInfoObject.widgetControls[index].eachWidgetResponse)
							}
							else if(widget.controlMasterId == 15){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)
								
								this.stackedBarChart(chartElement,widget,dashboardInfoObject.widgetControls[index].eachWidgetResponse)
							}
							else if(widget.controlMasterId == 16){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)
								
								this.perGaugeWithFlip(chartElement,widget,dashboardInfoObject.widgetControls[index].eachWidgetResponse)
							}
							else if(widget.controlMasterId == 17){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)
								
								this.squareGauge(chartElement,widget,dashboardInfoObject.widgetControls[index].eachWidgetResponse)
							}
							else if(widget.controlMasterId == 18){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)
								
								this.bellCurveChart(chartElement,widget,dashboardInfoObject.widgetControls[index].eachWidgetResponse)
							}
							else if(widget.controlMasterId == 19){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)
								
								this.areaChart(chartElement,widget,dashboardInfoObject.widgetControls[index].eachWidgetResponse)
							}
							else if(widget.controlMasterId == 20){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)

								this.groupedBarChart(chartElement,widget,dashboardInfoObject.widgetControls[index].eachWidgetResponse)
							}
							else if(widget.controlMasterId == 21){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)

								this.horizontalGroupedBarChart(chartElement,widget,dashboardInfoObject.widgetControls[index].eachWidgetResponse)
							}
							else if(widget.controlMasterId == 22){ } //Horizontal opposite bar Chart 
							else if(widget.controlMasterId == 23){ } //Vertical opposite bar Chart 
							else if(widget.controlMasterId == 24){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)

								this.dotGauge(chartElement,widget,dashboardInfoObject.widgetControls[index].eachWidgetResponse)
							}
							else if(widget.controlMasterId == 25){ }//Square bubble chart
							else if(widget.controlMasterId == 26){ }//Magic quadrant
							else if(widget.controlMasterId == 27){ }//Funnel chart
							else if(widget.controlMasterId == 28){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)

								this.columnAndLinesChart(chartElement,widget,dashboardInfoObject.widgetControls[index].eachWidgetResponse)
							}
							else if(widget.controlMasterId == 29){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)

								this.threeXAxisesGroupedBarChart(chartElement,widget,dashboardInfoObject.widgetControls[index].eachWidgetResponse)
							}
							else if(widget.controlMasterId == 30){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)

								this.twoXAxisesGroupedBarChart(chartElement,widget,dashboardInfoObject.widgetControls[index].eachWidgetResponse)
							}
							else if(widget.controlMasterId == 31){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)

								this.bellCurveChartWithMinAndMaxRange(chartElement,widget,dashboardInfoObject.widgetControls[index].eachWidgetResponse)
							}
							else if(widget.controlMasterId == 32){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)

								this.stackedGroupedBarChart(chartElement,widget,dashboardInfoObject.widgetControls[index].eachWidgetResponse)
							}
							else if(widget.controlMasterId == 33){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)

								this.stackedGroupedBarsWithLineChart(chartElement,widget,dashboardInfoObject.widgetControls[index].eachWidgetResponse)
							}
							else if(widget.controlMasterId == 34){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)

								this.stackedBarWithLinesChart(chartElement,widget,dashboardInfoObject.widgetControls[index].eachWidgetResponse)
							}
							else if(widget.controlMasterId == 35){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)

								this.trafficLightsChart(chartElement,widget,dashboardInfoObject.widgetControls[index].eachWidgetResponse)
							}
							else if(widget.controlMasterId == 36){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)

								this.columnChartWithNegativeValues(chartElement,widget,dashboardInfoObject.widgetControls[index].eachWidgetResponse)
							}
							else if(widget.controlMasterId == 37){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)

								this.groupedBarsWithLineChart(chartElement,widget,dashboardInfoObject.widgetControls[index].eachWidgetResponse)
							}
							else if(widget.controlMasterId == 38){
								var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
								.attr("empty", false)

								this.USMapWithPins(chartElement,widget,dashboardInfoObject.widgetControls[index].eachWidgetResponse)
							}
							
						//-------------------------------------------------------------------------------------------------

							this.finalCall();
						
					} // else
				}) // forEach

				d3.select(".tibo-mydash-dash-spinner").style("display","none") //  hidding dashboard spinner

			},1100)
			
		} // if((this.randomIdArray.length > 0) || (this.parameterizedDashboardRandomArray.length > 0))
	}
	
	shareDashTabChanFunc(e){
		//this.shareDashUserNamesArr = [], this.shareDashSrchUserNamesArr = [], this.usersArray = [], this.shareDashEmailIdsArr = [];
		e.index == 0 ? this.shareDashTabIndex = 0 : this.shareDashTabIndex = 1;
	}
	
	search(event) {
        
		this.usersArray = [];
		
		this.usersNameSubscrip = this.dashService.usersNameService(event.query).subscribe( (resp: any) => {
			resp.body?.records.map(x => this.usersArray.push({ userName: x.userName, userId: x.userId }));
			this.showSuggestions(this.usersArray)
		}, error => { 
			if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
			else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
			else { this.errorMsg = "Please try after some time." }
			
			this.addSingle()
		}, () => {}) // get users name service
		
	}
	
	showSuggestions(usersArray){
		this.shareDashSrchUserNamesArr = [];
		usersArray.map(x => this.shareDashSrchUserNamesArr.push(x.userName));
	}
	
	shareDashFunc(event){
		
		//this.shareDashUserNamesArr = [];
		let ids = [];
		
		if(this.shareDashTabIndex == 0){

			if(this.shareDashUserNamesArr.length != 0){
				
				this.shareDashDialBoxDisplayBool = false;

				this.shareDashUserNamesArr.forEach(user => {
					this.usersArray.forEach(d => {
						if(user == d.userName){
							ids.push(Number(d.userId))
						}
					})
				})
				
				//console.log({"userId":ids,"emailId":[],"dashboardId":[Number(this.selectedDashboard.code)]})
				
				this.shareDashSubscrip = this.dashService.shareDashboardService({"userId":ids,"emailId":[],"dashboardId":[Number(this.selectedDashboard.code)]}).subscribe( (resp: any) => {
					//resp.body?.records.map(x => this.usersArray.push({ userName: x.userName, userId: x.userId }));
					
					if(resp.status == 200){
						this.shareDashSuccMsg = "Dashboard shared successfully!";
						this.successAlertMsgFunc()
					}
					
				}, error => { 
					if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
					else { this.errorMsg = "Please try after some time." }
					
					this.addSingle()
				}, () => {}) // share the dashboard service
			}
			else {
				this.errorMsg = "Please enter User Name(s)!"
				this.addSingle()
			}
			
			this.shareDashUserNamesArr = [], this.shareDashSrchUserNamesArr = [], this.usersArray = [], this.shareDashEmailIdsArr = []; // clear all users name and email ids arrays

		}
		else if(this.shareDashTabIndex == 1){
			
			if(this.shareDashUserNamesArr.length != 0){

				this.shareDashDialBoxDisplayBool = false;
			
				this.shareDashSubscrip = this.dashService.shareDashboardService({"userId":[],"emailId":this.shareDashEmailIdsArr,"dashboardId":[Number(this.selectedDashboard.code)]}).subscribe( (resp: any) => {
					//resp.body?.records.map(x => this.usersArray.push({ userName: x.userName, userId: x.userId }));
					
					if(resp.status == 200){
						this.shareDashSuccMsg = "Dashboard shared successfully!";
						this.successAlertMsgFunc()
					}
					
				}, error => { 
					if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
					else { this.errorMsg = "Please try after some time." }
					
					this.addSingle()
				}, () => {}) // share the dashboard service

			}
			else {
				this.errorMsg = "Please enter Email Id(s)!"
				this.addSingle()
			}
			
			this.shareDashUserNamesArr = [], this.shareDashSrchUserNamesArr = [], this.usersArray = [], this.shareDashEmailIdsArr = []; // clear all users name and email ids arrays
		}
		
	}
	
	dialogBoxOnCloseFunc(event){
		this.shareDashTabIndex = 0;
		this.shareDashUserNamesArr = [], this.shareDashSrchUserNamesArr = [], this.usersArray = [], this.shareDashEmailIdsArr = []; // clear all users name and email ids arrays
	}
	
	successAlertMsgFunc(){
		this.messageService.add({severity:'success', summary:'', detail:this.shareDashSuccMsg });
	}
	
	dashboardListFunc(){
		
		
		
		d3.select("#tibo-mydash-dashlist-container-id").style("display") == "none" ? d3.select("#tibo-mydash-dashlist-container-id").style("display","block") : d3.select("#tibo-mydash-dashlist-container-id").style("display","none")
		
		d3.select("#tibo-mydash-dashlist-container-id").select("p-panelmenu").selectAll("div > div.p-panelmenu-header > a.p-panelmenu-header-link").attr("non-hide","non-hide");
		d3.select("#tibo-mydash-dashlist-container-id").select("p-panelmenu").selectAll("div > div.p-panelmenu-header > a.p-panelmenu-header-link > span").attr("non-hide","non-hide");
		
	}
	dashboardListFuncs(){
	
		d3.select("#tibo-mydash-dashlist-container").style("display") == "none" ? d3.select("#tibo-mydash-dashlist-container").style("display","block") : d3.select("#tibo-mydash-dashlist-container").style("display","none")
		
		d3.select("#tibo-mydash-dashlist-container").select("p-panelmenu").selectAll("div > div.p-panelmenu-header > a.p-panelmenu-header-link").attr("non-hide","non-hide");
		d3.select("#tibo-mydash-dashlist-container").select("p-panelmenu").selectAll("div > div.p-panelmenu-header > a.p-panelmenu-header-link > span").attr("non-hide","non-hide");
	}
	visitedDashFunc(dashboardName,dashboardId){
		this.selectedDashboard = {name: dashboardName, code: dashboardId }
		this.getDashboard("")
	}
	
	refreshTimerFunc(){
		//this.refreshCountDownTimerStr = this.dashboardRefreshTime + ":" + "00";
		//let countDownLabel = this.countDownLabel;
		let thisClass = this; // assinging compoment class object to a variable
		let minutes = this.dashboardRefreshTime == undefined ? 0 : this.dashboardRefreshTime;
		let counter = 60;
		let strMin, strSec;
		
		this.refreshCountDownTimerStr = minutes + ":" + "00";
		this.countDownLabel.innerHTML = this.refreshCountDownTimerStr;
		
		minutes--;

		this.refreshInterval = setInterval(function() {
			
			counter = counter - 1;
			
			if(String(minutes).length == 1){ strMin = "0" + minutes; }
			else { strMin = minutes; }
			
			if(String(counter).length == 1){ strSec = "0" + counter; }
			else{
				if(counter == 60){ strSec = "00"; }
				else { strSec = counter; }
			}
			
			this.refreshCountDownTimerStr = minutes + ":" + strSec;
			thisClass.countDownLabel.innerHTML = this.refreshCountDownTimerStr;
			
			if (counter == 0) {
				minutes--;
				counter = 60;
			}
		  
			if (minutes < 0) {
				minutes = this.dashboardRefreshTime;
				this.refreshCountDownTimerStr = minutes + ":" + "00";
				thisClass.countDownLabel.innerHTML = this.refreshCountDownTimerStr;
				minutes--;
				thisClass.getDashboard("");
			}
			
		}, 1000);
	}

	chartsDivContainerClick(event){ // drill thru
		//console.log(document.querySelector(event.target.nodeName).closest("div.widget-class"))
		//console.log(Object.values(event.path))
		
		//console.log(event.target.querySelector(event.target.nodeName))
		//console.log(event.path || (event.composedPath && event.composedPath()))
		//console.log(event.target.attributes["data-drilldownkey"])
		

		var chartDivObjCoor = "", clickedWidget = {};

		if((event.target.nodeName.toLowerCase() == "rect") || (event.target.nodeName.toLowerCase() == "path") || 
			(event.target.nodeName.toLowerCase() == "circle")){
				if(event.target.attributes["data-drilldownkey"] != undefined){
					//console.log(d3.select(event.target).attr("data-drilldownkey").split(","))
					var drillThruKeyValuesArray = [], dataDrillDownKeyArray = d3.select(event.target).attr("data-drilldownkey").split(",");
					for(var i = 0; i < dataDrillDownKeyArray.length; i++){
						//console.log(dataDrillDownKeyArray[i].substr(dataDrillDownKeyArray[i].indexOf("|"),dataDrillDownKeyArray[i].length))
						drillThruKeyValuesArray.push({"drillThruColumn":dataDrillDownKeyArray[i].substr(0,dataDrillDownKeyArray[i].indexOf("|")),
						"value":dataDrillDownKeyArray[i].substr((dataDrillDownKeyArray[i].indexOf("|")+1),dataDrillDownKeyArray[i].length)})
					}
					//console.log(dataDrillDownKeyArray)
					var nodes = [];
					var element = event.target
					nodes.push(element);
					while(element.parentNode) {
						nodes.push(element.parentNode);
						element = element.parentNode;
					}
					
					//console.log(drillThruKeyValuesArray)
					for(var i = 0; i < nodes.length; i++){
						if(nodes[i].nodeName != undefined){
							if(nodes[i].nodeName.toLowerCase() == "div"){
								if(nodes[i].attributes["class"].value.includes("widget-class")){
									//console.log(nodes[i].attributes["objectCordinates"].value)
									chartDivObjCoor = nodes[i].attributes["objectCordinates"].value;
								}
								
							}
						}
					}
					
					this.dashboardInfoObject?.widgetControls.forEach(widget => {
						if(chartDivObjCoor == widget.objectCordinates){
							//console.log(widget)
							clickedWidget = widget;
							this.showDrillThruOutputBtnClick(drillThruKeyValuesArray,clickedWidget)
						}
					});
					
				}
		}
		else if(event.target.nodeName.toLowerCase() == "td"){
			if(event.target.style["textDecoration"].toLowerCase() == "underline"){
				var drillThruKeyValuesArray = [];
				//console.log(event.target.offsetParent)
				//console.log(event.target.offsetParent.firstChild.children)
				for(var i = 0; i < event.target.offsetParent.firstChild.children.length; i++){
					//console.log(event.target.offsetParent.firstChild.children)
					//console.log(event.target.parentNode.children[i].innerText)
					//drillThruTempObj["drillThruColumn"] = event.target.offsetParent.firstChild.children[i].innerText;
					//drillThruTempObj["value"] = event.target.parentNode.children[i].innerText;
					drillThruKeyValuesArray.push({"drillThruColumn":event.target.offsetParent.firstChild.children[i].textContent,
					"value":event.target.parentNode.children[i].textContent});
				}
				
				//console.log(event.target.parentElement.rowIndex)
				//console.log(event.target.parentNode.children)
				
				var nodes = [];
				var element = event.target
				nodes.push(element);
				while(element.parentNode) {
					nodes.push(element.parentNode);
					element = element.parentNode;
				}

				//console.log(drillThruKeyValuesArray)
				for(var i = 0; i < nodes.length; i++){
					if(nodes[i].nodeName != undefined){
						if(nodes[i].nodeName.toLowerCase() == "div"){
							if(nodes[i].attributes["class"].value.includes("widget-class")){
								//console.log(nodes[i].attributes["objectCordinates"].value)
								chartDivObjCoor = nodes[i].attributes["objectCordinates"].value;
							}
							
						}
					}
				}
				
				this.dashboardInfoObject?.widgetControls.forEach(widget => {
					if(chartDivObjCoor == widget.objectCordinates){
						//console.log(widget)
						clickedWidget = widget;
						this.showDrillThruOutputBtnClick(drillThruKeyValuesArray,clickedWidget)
					}
				});
			} 
		} // td
	}

	showDrillThruOutputBtnClick(drillThruKeyValuesArray,clickedWidget) {
		
        this.ref = this.dialogService.open(DashboardOutput, {
			data: {
				drillThruKeyValuesArray: drillThruKeyValuesArray,
				clickedWidget: clickedWidget,
				selectedChartsColorSet: this.selectedChartsColorSet
			},
            header: clickedWidget?.chartTitle + " - Detail View",
            width: '85%',
			height: '85%',
            contentStyle: {"min-width": "380px", "min-height": "350px", "height":"100%", "background-color":"#f1f1f4", "overflow": "hidden"},
            baseZIndex: 10000
        });

		this.ref.onClose.subscribe(() => {
			d3.select(".tibo-mydash-drill-thru-widget").select("*").remove();
			console.log("Close")
		});

	}

	showBasicDialog() {
        this.displayBasic = true;
    }
	
	toggle(){
	
	
	}
}
