import { Directive, ElementRef, EventEmitter, Output, HostListener } from '@angular/core';

@Directive({
  selector: '[appClickOutside]'
})
export class ClickOutsideDirective {

  @Output() appClickOutside = new EventEmitter<void>();
  @Output() appClickOutsideDownload = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) { }

  @HostListener('document:click', ['$event'])
  public onClick(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    const isToggleButton = targetElement.classList.contains('hideSearch') ||
      targetElement.parentElement.classList.contains('hideSearch');
    const isToggleButtonDownload = targetElement.classList.contains('hideDownload') ||
      targetElement.parentElement.classList.contains('hideDownload');

    if (!clickedInside && !isToggleButton) {
      this.appClickOutside.emit();
    }
    if (!clickedInside && !isToggleButtonDownload) {
      this.appClickOutsideDownload.emit();
    }
  }

}
