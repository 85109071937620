import { Component, OnInit, OnDestroy } from '@angular/core';
import { Message,MessageService } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { Table } from 'primeng/table';
import { SortEvent } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { ViewChild } from '@angular/core'

declare var d3:any;
declare var acinfotech: any;

@Component({
    template: `

	<p-toast styleClass="tibo-myrepo-toast" position="center"></p-toast>
	<p-progressSpinner styleClass="tibo-mydash-drillthru-spinner custom-spinner" animationDuration="2s"></p-progressSpinner>

	<div class="tibo-mydash-drill-thru-widget" style="position:relative; left:0px; top:5%; width:100%; height:95%; background-color:white;" (click)="chartsDivContainerClick($event)"></div>
	
	<p-paginator #pagenoindicator styleClass="tibo-drillthru-model-table-pagin" [rows]="250" [totalRecords]="totalRecords" (onPageChange)="paginate($event)"></p-paginator>
	`,
	styleUrls: ['./dashboard.component.css'],
	providers: [MessageService]
})


export class DashboardOutput implements OnInit, OnDestroy {

	@ViewChild('pagenoindicator', {static: true}) paginator: Paginator;
	
	//@ViewChild('reportoutputfilestable', {static: true}) table: Table;
	
	errorMsg = "";
	paginatorPageNo = 1;
	totalRecords = 0;
	myReportRecords = [];
	hisOrWeklyBoolVal = "History";
	customSortBool:boolean = false;
	tableEmptyMessage:string = "";
	
	constructor(private messageService: MessageService, public config: DynamicDialogConfig, private http: HttpClient) { }
	
	requestPayload = {"sort":[],"filters":[],"filterType":"and",drillDownValue:"",drillDown:[]};
	selectedChartsColorSet = "one";
	drillThruClickCount = 0;

	tableSortColumn = "createdDate";
	tableSortDir = -1;
	
	drillThruSubscrip: Subscription;
	//outputFilesDownLoadSubscrip: Subscription;

    ngOnInit() {
		
		//console.log(this.config.data?.clickedWidget.drillThru[0].widgetControlDrillThruId)

		this.requestPayload.drillDown = this.config.data?.drillThruKeyValuesArray;
		this.selectedChartsColorSet = this.config.data?.selectedChartsColorSet;
		
		this.drillThruSubscrip = this.http.post('api/v1/dashboard/controls/'+this.config.data?.clickedWidget.drillThru[0].widgetControlDrillThruId+'/drill/run?&limit=250&page=1', this.requestPayload,
		{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' }).subscribe( (recordsResp: any) => {
			
			d3.selectAll(".tibo-mydash-drillthru-spinner").style("display","none") // hidding reports container spinner
			
			//console.log(recordsResp)

			if(this.config.data?.clickedWidget.drillThru[0].tbiControlsMasterId == 3){ // Storing the total records length for paginator if the chart is Table (grid)
				this.totalRecords = recordsResp.body?.totalRecords;
			}
			
			this.renderChart(this.config.data?.clickedWidget.drillThru[0],recordsResp.body?.records)
			
		}, error => { 
			d3.selectAll(".tibo-mydash-drillthru-spinner").style("display","none") // hidding reports container spinner
			if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
			else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
			else { this.errorMsg = "Please try after some time." }
			
			this.addSingle();
		})
    }
	
	ngOnDestroy() {
		if(this.drillThruSubscrip){
			this.drillThruSubscrip.unsubscribe();
		}
		/*if(this.outputFilesDownLoadSubscrip){
			this.outputFilesDownLoadSubscrip.unsubscribe();
		}*/
	}
	
	renderChart(widgetDetails,records){

		let chartId = "chartid-" + Math.floor(Math.random() * 10000) + 1;
		d3.select(".tibo-mydash-drill-thru-widget").selectAll("*").style("display","none"); // Hiding previous rendered chart
		d3.select(".tibo-mydash-drill-thru-widget").style("height","95%"); // Setting the chart container height
		d3.select(".tibo-drillthru-model-table-pagin").style("visibility","hidden"); // Hiding the Table paginator

		var chartElement = d3.select(".tibo-mydash-drill-thru-widget")
		.append("div").attr("id",chartId).style({"position":"relative","left":"0","top":"0","with":"100%","height":"100%","background-color":"white"});

		setTimeout(() => {
			
			if(widgetDetails.tbiControlsMasterId == 1){
				this.pieChart(chartElement,widgetDetails,records)
			}
			else if(widgetDetails.tbiControlsMasterId == 2){
				this.columnChart(chartElement,widgetDetails,records)
			}
			else if(widgetDetails.tbiControlsMasterId == 3){
				d3.select(".tibo-mydash-drill-thru-widget").style("height","calc(95% - 40px)");
				d3.select(".tibo-drillthru-model-table-pagin").style("visibility","visible");
				this.table(chartElement,widgetDetails,records)
			}
			else if(widgetDetails.tbiControlsMasterId == 5){
				this.donutChart(chartElement,widgetDetails,records)
			}
			else if(widgetDetails.tbiControlsMasterId == 6){
				this.horizontalChart(chartElement,widgetDetails,records)
			}
			/*else if(widgetDetails.tbiControlsMasterId == 7){ } // Waffle chart
			else if(widgetDetails.tbiControlsMasterId == 8){
				this.perGauge(chartElement,widgetDetails,records)
			}
			else if(widgetDetails.tbiControlsMasterId == 9){
				this.numberGauge(chartElement,widgetDetails,records)
			}
			else if(widgetDetails.tbiControlsMasterId == 10){
				this.map(chartElement,widgetDetails,records)
			}
			else if(widgetDetails.tbiControlsMasterId == 11){
				this.columnAndLineChart(chartElement,widgetDetails,records)
			}*/
			else if(widgetDetails.tbiControlsMasterId == 12){
				this.lineChart(chartElement,widgetDetails,records)
			}
			else if(widgetDetails.tbiControlsMasterId == 13){
				this.bubbleChart(chartElement,widgetDetails,records)
			}
			else if(widgetDetails.tbiControlsMasterId == 14){
				this.stackedHorizontalBarChart(chartElement,widgetDetails,records)
			}
			else if(widgetDetails.tbiControlsMasterId == 15){
				this.stackedBarChart(chartElement,widgetDetails,records)
			}
			/*else if(widgetDetails.tbiControlsMasterId == 16){
				this.perGaugeWithFlip(chartElement,widgetDetails,records)
			}
			else if(widgetDetails.tbiControlsMasterId == 17){
				this.squareGauge(chartElement,widgetDetails,records)
			}
			else if(widgetDetails.tbiControlsMasterId == 18){
				this.bellCurveChart(chartElement,widgetDetails,records)
			}*/
			else if(widgetDetails.tbiControlsMasterId == 19){
				this.areaChart(chartElement,widgetDetails,records)
			}
			else if(widgetDetails.tbiControlsMasterId == 20){
				this.groupedBarChart(chartElement,widgetDetails,records)
			}
			/*else if(widgetDetails.tbiControlsMasterId == 21){
				this.horizontalGroupedBarChart(chartElement,widgetDetails,records)
			}
			else if(widgetDetails.tbiControlsMasterId == 22){ } //Horizontal opposite bar Chart 
			else if(widgetDetails.tbiControlsMasterId == 23){ } //Vertical opposite bar Chart 
			else if(widgetDetails.tbiControlsMasterId == 24){
				this.dotGauge(chartElement,widgetDetails,records)
			}
			else if(widgetDetails.tbiControlsMasterId == 25){ }//Square bubble chart
			else if(widgetDetails.tbiControlsMasterId == 26){ }//Magic quadrant
			else if(widgetDetails.tbiControlsMasterId == 27){ }//Funnel chart*/
			else if(widgetDetails.tbiControlsMasterId == 28){
				this.columnAndLinesChart(chartElement,widgetDetails,records)
			}
			/*else if(widgetDetails.tbiControlsMasterId == 29){
				this.threeXAxisesGroupedBarChart(chartElement,widgetDetails,records)
			}
			else if(widgetDetails.tbiControlsMasterId == 30){
				this.twoXAxisesGroupedBarChart(chartElement,widgetDetails,records)
			}
			else if(widgetDetails.tbiControlsMasterId == 31){
				this.bellCurveChartWithMinAndMaxRange(chartElement,widgetDetails,records)
			}*/
			else if(widgetDetails.tbiControlsMasterId == 32){
				this.stackedGroupedBarChart(chartElement,widgetDetails,records)
			}
			else if(widgetDetails.tbiControlsMasterId == 33){
				this.stackedGroupedBarsWithLineChart(chartElement,widgetDetails,records)
			}
			else if(widgetDetails.tbiControlsMasterId == 34){
				this.stackedBarWithLinesChart(chartElement,widgetDetails,records)
			}
			else if(widgetDetails.tbiControlsMasterId == 35){
				this.trafficLightsChart(chartElement,widgetDetails,records)
			}
			else if(widgetDetails.tbiControlsMasterId == 36){
				this.columnChartWithNegativeValues(chartElement,widgetDetails,records)
			}
			else if(widgetDetails.tbiControlsMasterId == 37){
				this.groupedBarsWithLineChart(chartElement,widgetDetails,records)
			}
			else if(widgetDetails.tbiControlsMasterId == 38){
				this.USMapWithPins(chartElement,widgetDetails,records)
			}

		}, 100);
		//-------------------------------------------------------------------------------------------------
		//console.log(records,widgetDetails)
	}

	pieChart(chartElement,widgetdetails,records){
		let fetchedData = records == null ? "No records!" : (records.length > 0 ? records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widgetdetails.drillThruTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if(widgetdetails.drillThruXAxis.charAt(0) == "[" && widgetdetails.drillThruXAxis.charAt(widgetdetails.drillThruXAxis.length - 1) == "]"){
				xAxisFormatted = JSON.parse(widgetdetails.drillThruXAxis)[0]
			}
			else { xAxisFormatted = widgetdetails.drillThruXAxis }
			
			let yAxisFormatted = "";
			if(widgetdetails.drillThruYAxis.charAt(0) == "[" && widgetdetails.drillThruYAxis.charAt(widgetdetails.drillThruYAxis.length - 1) == "]"){
				yAxisFormatted = JSON.parse(widgetdetails.drillThruYAxis)[0]
			}
			else { yAxisFormatted = widgetdetails.drillThruYAxis }
			
			let options = {
				data: records, // for chart
				dataForTable: [], // for table if there is no data send empty array ([])
				isDataDetailedForTable: false, 
				keys: {
					label: xAxisFormatted,
					value: yAxisFormatted
				},
				chartTitle: widgetdetails.drillThruTitle,
				showTitle: true,
				showLegend: true,
				legendSide: "right", //"right" or "left"
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three"
				gradient: "none", //"none" or "smooth" or "elliptical" or "circular" or "radial"
				drillDownColumnName: widgetdetails?.drillDownColumn ?  widgetdetails.drillDownColumn : "",
				showTableIcon: true, //false - hide, true - show
				showTable: false, //false - hide, true - show
				showComments: false,
				comments: "",
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			
			setTimeout(() => { 
				acinfotech.pieChart(chartElement, options)
				chartElement.selectAll('div.ui-resizable-handle').style("display","none");
			},1000)
		}
	}
	columnChart(chartElement,widgetdetails,records){
		let fetchedData = records == null ? "No records!" : (records.length > 0 ? records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widgetdetails.drillThruTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if(widgetdetails.drillThruXAxis.charAt(0) == "[" && widgetdetails.drillThruXAxis.charAt(widgetdetails.drillThruXAxis.length - 1) == "]"){
				xAxisFormatted = JSON.parse(widgetdetails.drillThruXAxis)[0]
			}
			else { xAxisFormatted = widgetdetails.drillThruXAxis }
			
			let yAxisFormatted = "";
			if(widgetdetails.drillThruYAxis.charAt(0) == "[" && widgetdetails.drillThruYAxis.charAt(widgetdetails.drillThruYAxis.length - 1) == "]"){
				yAxisFormatted = JSON.parse(widgetdetails.drillThruYAxis)[0]
			}
			else { yAxisFormatted = widgetdetails.drillThruYAxis }
			
			let options = {
				data: records,
				dataForTable: [], // for table
				isDataDetailedForTable: false,
				keys: {
					label: xAxisFormatted,
					value: yAxisFormatted
				},
				chartTitle: widgetdetails.drillThruTitle,
				xAxisLable: xAxisFormatted,
				yAxisLable: yAxisFormatted,
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //one or two or three
				drillDownColumnName: widgetdetails?.drillDownColumn ?  widgetdetails.drillDownColumn : "",
				showTable: false, //false - hide, true - show
				showComments: false,
				comments: "",
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			setTimeout(() => { 
				acinfotech.columnChart(chartElement, options)
				chartElement.selectAll('div.ui-resizable-handle').style("display","none");
			},1000)
		}
	}
	table(chartElement,widgetdetails,records){
		let fetchedData = records == null ? "No records!" : (records.length > 0 ? records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widgetdetails.drillThruTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let options = {
				data: records,
				chartTitle: widgetdetails.drillThruTitle,
				showTitle: true,
				drillDownColumnName: widgetdetails?.drillDownColumn ?  widgetdetails.drillDownColumn : "",
				fontStyle: "Normal", // Normal, Italic
				fontWeight: "Bold", // Normal, Bold
				fontFamily: "Roboto", // Roboto, Century Gothic, Candara, Verdana, Trebuchet MS, Segoe UI
				resizeButtonID: document.getElementById("resizeBtn").id 
			}
			setTimeout(() => { 
				acinfotech.table(chartElement, options) 
				chartElement.selectAll('div.ui-resizable-handle').style("display","none");
			},1000)
			
		}
	}
	donutChart(chartElement,widgetdetails,records){
		let fetchedData = records == null ? "No records!" : (records.length > 0 ? records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widgetdetails.drillThruTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if(widgetdetails.drillThruXAxis.charAt(0) == "[" && widgetdetails.drillThruXAxis.charAt(widgetdetails.drillThruXAxis.length - 1) == "]"){
				xAxisFormatted = JSON.parse(widgetdetails.drillThruXAxis)[0]
			}
			else { xAxisFormatted = widgetdetails.drillThruXAxis }
			
			let yAxisFormatted = "";
			if(widgetdetails.drillThruYAxis.charAt(0) == "[" && widgetdetails.drillThruYAxis.charAt(widgetdetails.drillThruYAxis.length - 1) == "]"){
				yAxisFormatted = JSON.parse(widgetdetails.drillThruYAxis)[0]
			}
			else { yAxisFormatted = widgetdetails.drillThruYAxis }
			
			let options = {
				data: records, // for chart
				dataForTable: [], // for table if there is no data send empty array ([])
				isDataDetailedForTable: false, 
				keys: {
					label: xAxisFormatted,
					value: yAxisFormatted
				},
				chartTitle: widgetdetails.drillThruTitle,
				showTitle: true,
				showLegend: true,
				legendSide: "right", //"right" or "left"
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three"
				gradient: "none", //"none" or "smooth" or "elliptical" or "circular" or "radial"
				drillDownColumnName: widgetdetails?.drillDownColumn ?  widgetdetails.drillDownColumn : "",
				showTableIcon: true, //false - hide, true - show
				showTable: false, //false - hide, true - show
				showComments: false,
				comments: "",
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			setTimeout(() => { 
				acinfotech.donutChart(chartElement, options)
				chartElement.selectAll('div.ui-resizable-handle').style("display","none");
			 },1000)
		}
	}
	horizontalChart(chartElement,widgetdetails,records){
		let fetchedData = records == null ? "No records!" : (records.length > 0 ? records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widgetdetails.drillThruTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if(widgetdetails.drillThruXAxis.charAt(0) == "[" && widgetdetails.drillThruXAxis.charAt(widgetdetails.drillThruXAxis.length - 1) == "]"){
				xAxisFormatted = JSON.parse(widgetdetails.drillThruXAxis)[0]
			}
			else { xAxisFormatted = widgetdetails.drillThruXAxis }
			
			let yAxisFormatted = "";
			if(widgetdetails.drillThruYAxis.charAt(0) == "[" && widgetdetails.drillThruYAxis.charAt(widgetdetails.drillThruYAxis.length - 1) == "]"){
				yAxisFormatted = JSON.parse(widgetdetails.drillThruYAxis)[0]
			}
			else { yAxisFormatted = widgetdetails.drillThruYAxis }
			
			let options = {
				data: records,
				dataForTable: [],
				isDataDetailedForTable: false,
				keys: {
					label: xAxisFormatted,
					value: yAxisFormatted
				},
				chartTitle: widgetdetails.drillThruTitle,
				xAxisLable: yAxisFormatted,
				yAxisLable: xAxisFormatted,
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three"
				drillDownColumnName: widgetdetails?.drillDownColumn ?  widgetdetails.drillDownColumn : "",
				showTable: false,
				showComments: false,
				comments: "",
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			setTimeout(() => { 
				acinfotech.horizontalChart(chartElement, options)
				chartElement.selectAll('div.ui-resizable-handle').style("display","none");
			},1000)
		}
	}
	//waffleChart(chartElement,widgetdetails,records){}
	/*perGauge(chartElement,widgetdetails,records){
		let fetchedData = records == null ? widgetdetails.chartPlot : (records.length > 0 ? records[0][widgetdetails.drillThruXAxis] : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widgetdetails.drillThruTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let options = {
				data: fetchedData,
				chartTitle: widgetdetails.drillThruTitle,
				showTitle: true,
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			setTimeout(() => { 
				acinfotech.perGauge(chartElement, options)
				chartElement.selectAll('div.ui-resizable-handle').style("display","none");
			},1000)
		}
	}
	numberGauge(chartElement,widgetdetails,records){
		let fetchedData = records == null ? widgetdetails.chartPlot : (records.length > 0 ? records[0][widgetdetails.drillThruXAxis] : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widgetdetails.drillThruTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let options = {
				data: fetchedData,
				chartTitle: widgetdetails.drillThruTitle,
				showTitle: true,
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			setTimeout(() => { 
				acinfotech.numberGauge(chartElement, options)
				chartElement.selectAll('div.ui-resizable-handle').style("display","none");
			},1000)
		}
	}
	map(chartElement,widgetdetails,records){
		let fetchedData = records == null ? "No records!" : (records.length > 0 ? records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widgetdetails.drillThruTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if(widgetdetails.drillThruXAxis.charAt(0) == "[" && widgetdetails.drillThruXAxis.charAt(widgetdetails.drillThruXAxis.length - 1) == "]"){
				xAxisFormatted = JSON.parse(widgetdetails.drillThruXAxis)[0]
			}
			else { xAxisFormatted = widgetdetails.drillThruXAxis }
			
			let yAxisFormatted = "";
			if(widgetdetails.drillThruYAxis.charAt(0) == "[" && widgetdetails.drillThruYAxis.charAt(widgetdetails.drillThruYAxis.length - 1) == "]"){
				yAxisFormatted = JSON.parse(widgetdetails.drillThruYAxis)[0]
			}
			else { yAxisFormatted = widgetdetails.drillThruYAxis }
			
			let options = {
				data: records,
				keys: {
				  label: xAxisFormatted,
				  value: yAxisFormatted
				},
				chartTitle: widgetdetails.drillThruTitle,
				showTitle: true,
				drillDownColumnName: widgetdetails?.drillDownColumn ?  widgetdetails.drillDownColumn : "",
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			setTimeout(() => { 
				acinfotech.map(chartElement, options)
				chartElement.selectAll('div.ui-resizable-handle').style("display","none"); 
			},1000)
		}
	}
	columnAndLineChart(chartElement,widgetdetails,records){
		let fetchedData = records == null ? "No records!" : (records.length > 0 ? records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widgetdetails.drillThruTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxis = "";
			if(widgetdetails.drillThruXAxis.charAt(0) == "[" && widgetdetails.drillThruXAxis.charAt(widgetdetails.drillThruXAxis.length - 1) == "]"){
				xAxis = JSON.parse(widgetdetails.drillThruXAxis)[0]
			}
			else { xAxis = widgetdetails.drillThruXAxis }
			
			let options = {
				data: records,
				dataForTable: [],
				isDataDetailedForTable: false,
				keys: {
				  label: xAxis,
				  valueForColumn: JSON.parse(widgetdetails.drillThruYAxis)[0], // left axis
				  valueForLine: JSON.parse(widgetdetails.drillThruYAxis)[1] // right axis
				},
				chartTitle: widgetdetails.drillThruTitle,
				xAxisLable: xAxis,
				yLeftAxisLable: JSON.parse(widgetdetails.drillThruYAxis)[0],
				yRightAxisLable: JSON.parse(widgetdetails.drillThruYAxis)[1],
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
				showTable: false,
				showComments: false,
				comments: "",
				drillDownColumnName: widgetdetails?.drillDownColumn ?  widgetdetails.drillDownColumn : "",
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			setTimeout(() => { 
				acinfotech.columnAndLineChart(chartElement, options)
				chartElement.selectAll('div.ui-resizable-handle').style("display","none");
			},1000)
		}
	}*/
	lineChart(chartElement,widgetdetails,records){
		let fetchedData = records == null ? "No records!" : (records.length > 0 ? records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widgetdetails.drillThruTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxis = "", xAxisArr = [];
			if(widgetdetails.drillThruXAxis.charAt(0) == "[" && widgetdetails.drillThruXAxis.charAt(widgetdetails.drillThruXAxis.length - 1) == "]"){
				xAxis = JSON.parse(widgetdetails.drillThruXAxis)
				if(xAxis.length > 1) { xAxisArr = xAxis as any }
				else { xAxis = JSON.parse(widgetdetails.drillThruXAxis)[0] }
			}
			else { xAxis = widgetdetails.drillThruXAxis }
			
			if(xAxisArr.length > 0){
				let yAxis = "";
				if(widgetdetails.drillThruYAxis.charAt(0) == "[" && widgetdetails.drillThruYAxis.charAt(widgetdetails.drillThruYAxis.length - 1) == "]"){
					yAxis = JSON.parse(widgetdetails.drillThruYAxis)[0];
				}
				else { 
					yAxis = widgetdetails.drillThruYAxis 
				}
				let options = {
					data: records,
					dataForTable: [],
					isDataDetailedForTable: false,
					keys: {
						label: xAxisArr, // X-Axis labels
						lines: yAxis
					},
					chartTitle: widgetdetails.drillThruTitle,
					xAxisLable: yAxis,
					yAxisLable: "",
					showTitle: true,
					colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
					lineStyle: "cardinal", // "cardinal" or "linear" or "step"
					drillDownColumnName: widgetdetails?.drillDownColumn ?  widgetdetails.drillDownColumn : "",
					showTable: false,
					showComments: false,
					comments: "",
					resizeButtonID: document.getElementById("resizeBtn").id
				}
					
				setTimeout(() => { 
					acinfotech.lineChart2(chartElement, options)
					chartElement.selectAll('div.ui-resizable-handle').style("display","none");
				},1000)
			}
			else {
				let options = {
					data: records,
					dataForTable: [],
					isDataDetailedForTable: false,
					keys: {
						label: xAxis, // X-Axis labels
						lines: widgetdetails.drillThruYAxis.split(",")
					},
					chartTitle: widgetdetails.drillThruTitle,
					xAxisLable: xAxis,
					yAxisLable: "",
					showTitle: true,
					colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
					lineStyle: "cardinal", // "cardinal" or "linear" or "step"
					drillDownColumnName: widgetdetails?.drillDownColumn ?  widgetdetails.drillDownColumn : "",
					showTable: false,
					showComments: false,
					comments: "",
					resizeButtonID: document.getElementById("resizeBtn").id
				}
				
				setTimeout(() => { 
					acinfotech.lineChart(chartElement, options)
					chartElement.selectAll('div.ui-resizable-handle').style("display","none");
				},1000)
			}
		}
	}
	bubbleChart(chartElement,widgetdetails,records){
		let fetchedData = records == null ? "No records!" : (records.length > 0 ? records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widgetdetails.drillThruTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if(widgetdetails.drillThruXAxis.charAt(0) == "[" && widgetdetails.drillThruXAxis.charAt(widgetdetails.drillThruXAxis.length - 1) == "]"){
				xAxisFormatted = JSON.parse(widgetdetails.drillThruXAxis)[0]
			}
			else { xAxisFormatted = widgetdetails.drillThruXAxis }

			let yAxisFormatted = "";
			if(widgetdetails.drillThruYAxis.charAt(0) == "[" && widgetdetails.drillThruYAxis.charAt(widgetdetails.drillThruYAxis.length - 1) == "]"){
				yAxisFormatted = JSON.parse(widgetdetails.drillThruYAxis)[0]
			}
			else { yAxisFormatted = widgetdetails.drillThruYAxis }
			
			let options = {
				data: records,
				dataForTable: [],
				isDataDetailedForTable: false,
				keys: {
				label: xAxisFormatted,
				value: yAxisFormatted
				},
				chartTitle: widgetdetails.drillThruTitle,
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
				drillDownColumnName: widgetdetails?.drillDownColumn ?  widgetdetails.drillDownColumn : "",
				showTable: false,
				showComments: false,
				comments: "",
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			
			setTimeout(() => { 
				acinfotech.bubbleChart(chartElement, options)
				chartElement.selectAll('div.ui-resizable-handle').style("display","none");
			},1000)
		}
	}
	stackedHorizontalBarChart(chartElement,widgetdetails,records){
		let fetchedData = records == null ? "No records!" : (records.length > 0 ? records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widgetdetails.drillThruTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxis = "", xAxisArr = [];
			if(widgetdetails.drillThruXAxis.charAt(0) == "[" && widgetdetails.drillThruXAxis.charAt(widgetdetails.drillThruXAxis.length - 1) == "]"){
				xAxis = JSON.parse(widgetdetails.drillThruXAxis)
				if(xAxis.length > 1) { xAxisArr = xAxis as any }
				else { xAxis = JSON.parse(widgetdetails.drillThruXAxis)[0] }
			}
			else { xAxis = widgetdetails.drillThruXAxis }
			
			if(xAxisArr.length > 0){
				let yAxis = "";
				if(widgetdetails.drillThruYAxis.charAt(0) == "[" && widgetdetails.drillThruYAxis.charAt(widgetdetails.drillThruYAxis.length - 1) == "]"){
					yAxis = JSON.parse(widgetdetails.drillThruYAxis)[0];
				}
				else { 
					yAxis = widgetdetails.drillThruYAxis 
				}
				let options = {
					data: records,
					dataForDrillDown: [],
					dataForTable: [],
					isDataDetailedForTable: false,
					keys: {
						label: xAxisArr, // X-Axis labels
						stackedBars: yAxis
					},
					chartTitle: widgetdetails.drillThruTitle,
					xAxisLable: "",
					yAxisLable: yAxis,
					showTitle: true,
					colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
					lineStyle: "cardinal", // "cardinal" or "linear" or "step"
					drillDownColumnName: widgetdetails?.drillDownColumn ?  widgetdetails.drillDownColumn : "",
					showTable: false,
					showComments: false,
					comments: "",
					resizeButtonID: document.getElementById("resizeBtn").id
				}
					
				setTimeout(() => { 
					acinfotech.stackedHorizontalBarChart2(chartElement, options)
					chartElement.selectAll('div.ui-resizable-handle').style("display","none");
				},1000)
			}
			else {
				let options = {
					data: records,
					dataForDrillDown: [],
					dataForTable: [],
					isDataDetailedForTable: false,
					keys: {
						label: xAxis, // X-Axis labels
						stackedBars: widgetdetails.drillThruYAxis.split(",")
					},
					chartTitle: widgetdetails.drillThruTitle,
					xAxisLable: "",
					yAxisLable: xAxis,
					showTitle: true,
					colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
					lineStyle: "cardinal", // "cardinal" or "linear" or "step"
					drillDownColumnName: widgetdetails?.drillDownColumn ?  widgetdetails.drillDownColumn : "",
					showTable: false,
					showComments: false,
					comments: "",
					resizeButtonID: document.getElementById("resizeBtn").id
				}
				
				setTimeout(() => { 
					acinfotech.stackedHorizontalBarChart(chartElement, options)
					chartElement.selectAll('div.ui-resizable-handle').style("display","none");
				},1000)
			}
		}
	}
	stackedBarChart(chartElement,widgetdetails,records){
		let fetchedData = records == null ? "No records!" : (records.length > 0 ? records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widgetdetails.drillThruTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxis = "", xAxisArr = [];
			if(widgetdetails.drillThruXAxis.charAt(0) == "[" && widgetdetails.drillThruXAxis.charAt(widgetdetails.drillThruXAxis.length - 1) == "]"){
				xAxis = JSON.parse(widgetdetails.drillThruXAxis)
				if(xAxis.length > 1) { xAxisArr = xAxis as any }
				else { xAxis = JSON.parse(widgetdetails.drillThruXAxis)[0] }
			}
			else { xAxis = widgetdetails.drillThruXAxis }
			
			if(xAxisArr.length > 0){
				let yAxis = "";
				if(widgetdetails.drillThruYAxis.charAt(0) == "[" && widgetdetails.drillThruYAxis.charAt(widgetdetails.drillThruYAxis.length - 1) == "]"){
					yAxis = JSON.parse(widgetdetails.drillThruYAxis)[0];
				}
				else { 
					yAxis = widgetdetails.drillThruYAxis 
				}
				let options = {
					data: records,
					dataForDrillDown: [],
					dataForTable: [],
					isDataDetailedForTable: false,
					keys: {
						label: xAxisArr, // X-Axis labels
						stackedBars: yAxis
					},
					chartTitle: widgetdetails.drillThruTitle,
					xAxisLable: yAxis,
					yAxisLable: "",
					showTitle: true,
					colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
					lineStyle: "cardinal", // "cardinal" or "linear" or "step"
					drillDownColumnName: widgetdetails?.drillDownColumn ?  widgetdetails.drillDownColumn : "",
					showTable: false,
					showComments: false,
					comments: "",
					resizeButtonID: document.getElementById("resizeBtn").id
				}
				
				setTimeout(() => { 
					acinfotech.stackedBarChart2(chartElement, options)
					chartElement.selectAll('div.ui-resizable-handle').style("display","none");
				},1000)
			}
			else {
				let options = {
					data: records,
					dataForDrillDown: [],
					dataForTable: [],
					isDataDetailedForTable: false,
					keys: {
						label: xAxis, // X-Axis labels
						stackedBars: widgetdetails.drillThruYAxis.split(",")
					},
					chartTitle: widgetdetails.drillThruTitle,
					xAxisLable: xAxis,
					yAxisLable: "",
					showTitle: true,
					colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
					lineStyle: "cardinal", // "cardinal" or "linear" or "step"
					drillDownColumnName: widgetdetails?.drillDownColumn ?  widgetdetails.drillDownColumn : "",
					showTable: false,
					showComments: false,
					comments: "",
					resizeButtonID: document.getElementById("resizeBtn").id
				}
				
				setTimeout(() => { 
					acinfotech.stackedBarChart(chartElement, options)
					chartElement.selectAll('div.ui-resizable-handle').style("display","none");
				},1000)
			}
		}
	}
	/*perGaugeWithFlip(chartElement,widgetdetails,records){
		let fetchedData = records == null ? widgetdetails.chartPlot : (records.length > 0 ? records[0][widgetdetails.drillThruXAxis] : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widgetdetails.drillThruTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let options = {
				data: records == null ? widgetdetails.chartPlot : records[0][widgetdetails.drillThruXAxis],
				chartTitle: widgetdetails.drillThruTitle,
				coveredCircularAreaText: "",
				unCoveredCircularAreaText: "",
				showTitle: true,
				resizeButtonID: document.getElementById("resizeBtn").id
			}

			setTimeout(() => { 
				acinfotech.perGaugeWithFlip(chartElement, options)
				chartElement.selectAll('div.ui-resizable-handle').style("display","none");
			},1000)
		}
	}
	squareGauge(chartElement,widgetdetails,records){
		let fetchedData = records == null ? widgetdetails.chartPlot : (records.length > 0 ? records[0][widgetdetails.drillThruXAxis] : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widgetdetails.drillThruTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let options = {
				text: records == null ? widgetdetails.chartPlot : records[0][widgetdetails.drillThruXAxis],
				widgetTitle: widgetdetails.drillThruTitle,
				showTitle: true,
				resizeButtonID: document.getElementById("resizeBtn").id
			}

			setTimeout(() => { 
				acinfotech.squareGauge(chartElement, options)
				chartElement.selectAll('div.ui-resizable-handle').style("display","none");
			},1000)
		}
	}
	bellCurveChart(chartElement,widgetdetails,records){
		let fetchedData = records == null ? "No records!" : (records.length > 0 ? records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widgetdetails.drillThruTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if(widgetdetails.drillThruXAxis.charAt(0) == "[" && widgetdetails.drillThruXAxis.charAt(widgetdetails.drillThruXAxis.length - 1) == "]"){
				xAxisFormatted = JSON.parse(widgetdetails.drillThruXAxis)[0]
			}
			else { xAxisFormatted = widgetdetails.drillThruXAxis }

			let yAxisFormatted = "";
			if(widgetdetails.drillThruYAxis.charAt(0) == "[" && widgetdetails.drillThruYAxis.charAt(widgetdetails.drillThruYAxis.length - 1) == "]"){
				yAxisFormatted = JSON.parse(widgetdetails.drillThruYAxis)[0]
			}
			else { yAxisFormatted = widgetdetails.drillThruYAxis }
			
			let options = {
				data: records,
				keys: {
					label: xAxisFormatted,
					value: yAxisFormatted,
					toolTipLabel: (widgetdetails.chartPlot.split(",") > 0) ? widgetdetails.chartPlot.split(",") : []
				},
				chartTitle: widgetdetails.drillThruTitle,
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
				resizeButtonID: document.getElementById("resizeBtn").id 
			}   
			setTimeout(() => { 
				acinfotech.bellCurveChart(chartElement, options)
				chartElement.selectAll('div.ui-resizable-handle').style("display","none");
			},1000)
		}
	}*/
	areaChart(chartElement,widgetdetails,records){
		let fetchedData = records == null ? "No records!" : (records.length > 0 ? records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widgetdetails.drillThruTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxis = "", xAxisArr = [];
			if(widgetdetails.drillThruXAxis.charAt(0) == "[" && widgetdetails.drillThruXAxis.charAt(widgetdetails.drillThruXAxis.length - 1) == "]"){
				xAxis = JSON.parse(widgetdetails.drillThruXAxis)
				if(xAxis.length > 1) { xAxisArr = xAxis as any }
				else { xAxis = JSON.parse(widgetdetails.drillThruXAxis)[0] }
			}
			else { xAxis = widgetdetails.drillThruXAxis }
			
			if(xAxisArr.length > 0){
				let yAxis = "";
				if(widgetdetails.drillThruYAxis.charAt(0) == "[" && widgetdetails.drillThruYAxis.charAt(widgetdetails.drillThruYAxis.length - 1) == "]"){
					yAxis = JSON.parse(widgetdetails.drillThruYAxis)[0];
				}
				else { 
					yAxis = widgetdetails.drillThruYAxis 
				}
				
				let options = {
					data: records,
					dataForTable: [],
					isDataDetailedForTable: false,
					keys: {
						label: xAxisArr, // X-Axis labels
						areas: yAxis
					},
					chartTitle: widgetdetails.drillThruTitle,
					xAxisLable: yAxis,
					yAxisLable: "",
					showTitle: true,
					colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
					lineStyle: "cardinal", // "cardinal" or "linear" or "step"
					drillDownColumnName: widgetdetails?.drillDownColumn ?  widgetdetails.drillDownColumn : "",
					showTable: false,
					showComments: false,
					comments: "",
					resizeButtonID: document.getElementById("resizeBtn").id
				}
					
				setTimeout(() => { 
					acinfotech.areaChart2(chartElement, options)
					chartElement.selectAll('div.ui-resizable-handle').style("display","none");
				},1000)
			}
			else {
				let options = {
					data: records,
					dataForTable: [],
					isDataDetailedForTable: false,
					keys: {
						label: xAxis, // X-Axis labels
						areas: widgetdetails.drillThruYAxis.split(",")
					},
					chartTitle: widgetdetails.drillThruTitle,
					xAxisLable: xAxis,
					yAxisLable: "",
					showTitle: true,
					colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
					lineStyle: "cardinal", // "cardinal" or "linear" or "step"
					drillDownColumnName: widgetdetails?.drillDownColumn ?  widgetdetails.drillDownColumn : "",
					showTable: false,
					showComments: false,
					comments: "",
					resizeButtonID: document.getElementById("resizeBtn").id
				}
				
				setTimeout(() => { 
					acinfotech.areaChart(chartElement, options)
					chartElement.selectAll('div.ui-resizable-handle').style("display","none");
				},1000)
			}
		}
	}
	groupedBarChart(chartElement,widgetdetails,records){
		let fetchedData = records == null ? "No records!" : (records.length > 0 ? records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widgetdetails.drillThruTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if(widgetdetails.drillThruXAxis.charAt(0) == "[" && widgetdetails.drillThruXAxis.charAt(widgetdetails.drillThruXAxis.length - 1) == "]"){
				xAxisFormatted = JSON.parse(widgetdetails.drillThruXAxis)[0]
			}
			else { xAxisFormatted = widgetdetails.drillThruXAxis }
			
			let options = {
				data: records,
				dataForDrillDown: [],
				dataForTable: [],
				isDataDetailedForTable: false,
				keys: {
					label: xAxisFormatted,
					groupBars: widgetdetails.drillThruYAxis.split(","),
				},
				chartTitle: widgetdetails.drillThruTitle,
				xAxisLable: xAxisFormatted,
				yAxisLable: "",
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
				drillDownColumnName: widgetdetails?.drillDownColumn ?  widgetdetails.drillDownColumn : "",
				showTable: false,
				showComments: false,
				comments: "",
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			
			setTimeout(() => { 
				acinfotech.groupedBarChart(chartElement, options)
				chartElement.selectAll('div.ui-resizable-handle').style("display","none");
			},1000)
		}
	}
	/*horizontalGroupedBarChart(chartElement,widgetdetails,records){
		let fetchedData = records == null ? "No records!" : (records.length > 0 ? records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widgetdetails.drillThruTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if(widgetdetails.drillThruXAxis.charAt(0) == "[" && widgetdetails.drillThruXAxis.charAt(widgetdetails.drillThruXAxis.length - 1) == "]"){
				xAxisFormatted = JSON.parse(widgetdetails.drillThruXAxis)[0]
			}
			else { xAxisFormatted = widgetdetails.drillThruXAxis }

			let options = {
				data: records,
				dataForDrillDown: [],
				dataForTable: [],
				isDataDetailedForTable: false,
				keys: {
					label: xAxisFormatted,
					groupBars: JSON.parse(widgetdetails.drillThruYAxis),
				},
				chartTitle: widgetdetails.drillThruTitle,
				xAxisLable: "",
				yAxisLable: xAxisFormatted,
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
				drillDownColumnName: widgetdetails?.drillDownColumn ?  widgetdetails.drillDownColumn : "",
				showTable: false,
				showComments: false,
				comments: "",
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			
			setTimeout(() => { 
				acinfotech.horizontalGroupedBarChart(chartElement, options)
				chartElement.selectAll('div.ui-resizable-handle').style("display","none");
			},1000)
		}
	}
	//horizontalOppositeBarChart(chartElement,widgetdetails,records){}
	//verticalOppositeBarChart(chartElement,widgetdetails,records){}
	dotGauge(chartElement,widgetdetails,records){
		let options = {
			data: records == null ? widgetdetails.chartPlot : records[0][widgetdetails.drillThruXAxis],
			chartTitle: widgetdetails.drillThruTitle,
			showTitle: true,
			resizeButtonID: document.getElementById("resizeBtn").id
		}
		setTimeout(() => { 
			acinfotech.dotGauge(chartElement, options)
			chartElement.selectAll('div.ui-resizable-handle').style("display","none");
		},1000)
	}*/
	//squareBubbleChart(chartElement,widgetdetails,records){}
	//magicQuadrant(chartElement,widgetdetails,records){}
	//funnelChart(chartElement,widgetdetails,records){}
	columnAndLinesChart(chartElement,widgetdetails,records){
		let fetchedData = records == null ? "No records!" : (records.length > 0 ? records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widgetdetails.drillThruTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if(widgetdetails.drillThruXAxis.charAt(0) == "[" && widgetdetails.drillThruXAxis.charAt(widgetdetails.drillThruXAxis.length - 1) == "]"){
				xAxisFormatted = JSON.parse(widgetdetails.drillThruXAxis)[0]
			}
			else { xAxisFormatted = widgetdetails.drillThruXAxis }
			
			let options = {
				data: records,
				dataForTable: [],
				isDataDetailedForTable: false,
				keys: {
					label: xAxisFormatted, // X-Axis labels
					valueForColumn: widgetdetails.drillThruYAxis.split(",")[0], // Single Value - left y axis
					valueForLines: widgetdetails.drillThruYAxis.split(",").slice(1),  // multiple value (array) - right y axis
					toolTipLabel: (widgetdetails?.chartPlot.split(",") > 0) ? widgetdetails.chartPlot.split(",") : []
				},
				chartTitle: widgetdetails.drillThruTitle,
				xAxisLable: xAxisFormatted,
				//yAxisLable: "",
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
				lineStyle: "cardinal", // "cardinal" or "linear" or "step"
				drillDownColumnName: widgetdetails?.drillDownColumn ?  widgetdetails.drillDownColumn : "",
				showTable: false,
				showComments: false,
				comments: "",
				resizeButtonID: document.getElementById("resizeBtn").id
			}
		
			setTimeout(() => { 
				acinfotech.columnAndLinesChart(chartElement, options)
				chartElement.selectAll('div.ui-resizable-handle').style("display","none");
			},1000)
		}
	}
	/*threeXAxisesGroupedBarChart(chartElement,widgetdetails,records){
		let fetchedData = records == null ? "No records!" : (records.length > 0 ? records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widgetdetails.drillThruTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let options = {
				data: records,
				keys: {
					labels: JSON.parse(widgetdetails.drillThruXAxis),
					groupBars: JSON.parse(widgetdetails.drillThruYAxis),
					toolTipLabel: (widgetdetails.chartPlot.split(",") > 0) ? widgetdetails.chartPlot.split(",") : []
				},
				chartTitle: widgetdetails.drillThruTitle,
				xAxisLables: JSON.parse(widgetdetails.drillThruXAxis),
				yAxisLable: "",
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
				showComments: false,
				comments: "",
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			setTimeout(() => { 
				acinfotech.threeXAxisesGroupedBarChart(chartElement, options)
				chartElement.selectAll('div.ui-resizable-handle').style("display","none");
			},1000)
		}
	}
	twoXAxisesGroupedBarChart(chartElement,widgetdetails,records){
		let fetchedData = records == null ? "No records!" : (records.length > 0 ? records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widgetdetails.drillThruTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let options = {
				data: records,
				dataForDrillDown: [],
				keys: {
					labels: JSON.parse(widgetdetails.drillThruXAxis),
					groupBars: JSON.parse(widgetdetails.drillThruYAxis),
					toolTipLabel: (widgetdetails.chartPlot.split(",") > 0) ? widgetdetails.chartPlot.split(",") : []

				},
				chartTitle: widgetdetails.drillThruTitle,
				xAxisLables: JSON.parse(widgetdetails.drillThruXAxis),
				//yAxisLable: "",
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
				drillDownColumnName: widgetdetails?.drillDownColumn ?  widgetdetails.drillDownColumn : "",
				showComments: false,
				comments: "",
				resizeButtonID: document.getElementById("resizeBtn").id
				
			}
			setTimeout(() => { 
				acinfotech.twoXAxisesGroupedBarChart(chartElement, options)
				chartElement.selectAll('div.ui-resizable-handle').style("display","none");
			},1000)
		}
	}
	bellCurveChartWithMinAndMaxRange(chartElement,widgetdetails,records){
		let fetchedData = records == null ? "No records!" : (records.length > 0 ? records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widgetdetails.drillThruTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let options = {
				data: records,
				keys: {
					label: JSON.parse(widgetdetails.drillThruXAxis[0]),
					value: JSON.parse(widgetdetails.drillThruYAxis[0]),
					minValue: JSON.parse(widgetdetails.drillThruYAxis[1]),
					maxValue: JSON.parse(widgetdetails.drillThruYAxis[2]),
					minAndMaxLines: JSON.parse(widgetdetails.drillThruXAxis[0]),
					toolTipLabel: (widgetdetails.chartPlot.split(",") > 0) ? widgetdetails.chartPlot.split(",") : []
				},
				chartTitle: widgetdetails.drillThruTitle,
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
				resizeButtonID: document.getElementById("resizeBtn").id
			}
		
			setTimeout(() => { 
				acinfotech.bellCurveChartWithMinAndMaxRange(chartElement, options)
				chartElement.selectAll('div.ui-resizable-handle').style("display","none");
			},1000)
		}
	}*/
	stackedGroupedBarChart(chartElement,widgetdetails,records){
		let fetchedData = records == null ? "No records!" : (records.length > 0 ? records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widgetdetails.drillThruTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let options = {
				data: records,
				keys: {
					labels: widgetdetails.drillThruXAxis.split(","),
					groupBars: widgetdetails.drillThruYAxis.split(","),
					toolTipLabel: (widgetdetails.chartPlot.split(",") > 0) ? widgetdetails.chartPlot.split(",") : []
					
				},
				chartTitle: widgetdetails.drillThruTitle,
				xAxisLables: JSON.parse(widgetdetails.drillThruXAxis),
				//yAxisLable: "",
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
				drillDownColumnName: widgetdetails?.drillDownColumn ?  widgetdetails.drillDownColumn : "",
				showTable: false,
				showComments: false,
				comments: "",
				resizeButtonID: document.getElementById("resizeBtn").id
			}

			setTimeout(() => { 
				acinfotech.stackedGroupedBarChart(chartElement, options)
				chartElement.selectAll('div.ui-resizable-handle').style("display","none");
			},1000)
		}
	}
	stackedGroupedBarsWithLineChart(chartElement,widgetdetails,records){
		let fetchedData = records == null ? "No records!" : (records.length > 0 ? records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widgetdetails.drillThruTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let options = {
				data: records,
				keys: {
					labels: JSON.parse(widgetdetails.drillThruXAxis),
					groupBars: JSON.parse(widgetdetails.drillThruYAxis),
					lines: JSON.parse(widgetdetails.secondaryYaxis),
					toolTipLabel: (widgetdetails.chartPlot.split(",") > 0) ? widgetdetails.chartPlot.split(",") : []
					
				},
				chartTitle: widgetdetails.drillThruTitle,
				xAxisLables: JSON.parse(widgetdetails.drillThruXAxis),
				//yAxisLable: "",
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
				drillDownColumnName: widgetdetails?.drillDownColumn ?  widgetdetails.drillDownColumn : "",
				showTable: false,
				showComments: false,
				comments: "",
				resizeButtonID: document.getElementById("resizeBtn").id
			}
		
			setTimeout(() => { 
				acinfotech.stackedGroupedBarsWithLineChart(chartElement, options)
				chartElement.selectAll('div.ui-resizable-handle').style("display","none");
			},1000)
		}
	}
	stackedBarWithLinesChart(chartElement,widgetdetails,records){
		let fetchedData = records == null ? "No records!" : (records.length > 0 ? records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widgetdetails.drillThruTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if(widgetdetails.drillThruXAxis.charAt(0) == "[" && widgetdetails.drillThruXAxis.charAt(widgetdetails.drillThruXAxis.length - 1) == "]"){
				xAxisFormatted = JSON.parse(widgetdetails.drillThruXAxis)[0]
			}
			else { xAxisFormatted = widgetdetails.drillThruXAxis }
			
			let options = {
				data: records,
				dataForTable: [],
				isDataDetailedForTable: false,
				keys: {
					label: xAxisFormatted,
					stackedBars: JSON.parse(widgetdetails.drillThruYAxis),
					lines: JSON.parse(widgetdetails.secondaryYaxis),
					toolTipLabel: (widgetdetails.chartPlot.split(",") > 0) ? widgetdetails.chartPlot.split(",") : []
				},
				chartTitle: widgetdetails.drillThruTitle,
				xAxisLable: xAxisFormatted,
				yAxisLable: "",
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
				drillDownColumnName: widgetdetails?.drillDownColumn ?  widgetdetails.drillDownColumn : "",
				showTable: false,
				showComments: false,
				comments: "",
				resizeButtonID: document.getElementById("resizeBtn").id
			}
		
			setTimeout(() => { 
				acinfotech.stackedBarWithLinesChart(chartElement, options)
				chartElement.selectAll('div.ui-resizable-handle').style("display","none");
			},1000)
		}
	}
	trafficLightsChart(chartElement,widgetdetails,records){
		let fetchedData = records == null ? "No records!" : (records.length > 0 ? records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widgetdetails.drillThruTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if(widgetdetails.drillThruXAxis.charAt(0) == "[" && widgetdetails.drillThruXAxis.charAt(widgetdetails.drillThruXAxis.length - 1) == "]"){
				xAxisFormatted = JSON.parse(widgetdetails.drillThruXAxis)[0]
			}
			else { xAxisFormatted = widgetdetails.drillThruXAxis }

			let yAxisFormatted = "";
			if(widgetdetails.drillThruYAxis.charAt(0) == "[" && widgetdetails.drillThruYAxis.charAt(widgetdetails.drillThruYAxis.length - 1) == "]"){
				yAxisFormatted = JSON.parse(widgetdetails.drillThruYAxis)[0]
			}
			else { yAxisFormatted = widgetdetails.drillThruYAxis }
			
			let options = {
				data: records,
				dataForTable: [],
				isDataDetailedForTable: false,
				keys: {
					label: xAxisFormatted,
					value: yAxisFormatted,
					toolTipLabel: (widgetdetails.chartPlot.split(",") > 0) ? widgetdetails.chartPlot.split(",") : []
				},
				chartTitle: widgetdetails.drillThruTitle,
				showTitle: true,
				drillDownColumnName: widgetdetails?.drillDownColumn ?  widgetdetails.drillDownColumn : "",
				showTable: false,
				showComments: false,
				comments: "",
				resizeButtonID: document.getElementById("resizeBtn").id
			}

			setTimeout(() => { 
				acinfotech.trafficLightsChart(chartElement, options)
				chartElement.selectAll('div.ui-resizable-handle').style("display","none");
			},1000)
		}
	}
	columnChartWithNegativeValues(chartElement,widgetdetails,records){
		let fetchedData = records == null ? "No records!" : (records.length > 0 ? records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widgetdetails.drillThruTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if(widgetdetails.drillThruXAxis.charAt(0) == "[" && widgetdetails.drillThruXAxis.charAt(widgetdetails.drillThruXAxis.length - 1) == "]"){
				xAxisFormatted = JSON.parse(widgetdetails.drillThruXAxis)[0]
			}
			else { xAxisFormatted = widgetdetails.drillThruXAxis }

			let yAxisFormatted = "";
			if(widgetdetails.drillThruYAxis.charAt(0) == "[" && widgetdetails.drillThruYAxis.charAt(widgetdetails.drillThruYAxis.length - 1) == "]"){
				yAxisFormatted = JSON.parse(widgetdetails.drillThruYAxis)[0]
			}
			else { yAxisFormatted = widgetdetails.drillThruYAxis }
			
			let options = {
				data: records,
				dataForTable: [], // for table
				isDataDetailedForTable: false,
				keys: {
					label: xAxisFormatted, 
					value: yAxisFormatted
				},
				chartTitle: widgetdetails.drillThruTitle,
				xAxisLable: xAxisFormatted,
				yAxisLable: yAxisFormatted,
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //one or two or three
				drillDownColumnName: widgetdetails?.drillDownColumn ?  widgetdetails.drillDownColumn : "",
				showTable: false, //false - hide, true - show
				showComments: false,
				comments: "",
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			
			setTimeout(() => { 
				acinfotech.columnChartWithNegativeValues(chartElement, options)
				chartElement.selectAll('div.ui-resizable-handle').style("display","none");
			},1000)
		}
	}
	groupedBarsWithLineChart(chartElement,widgetdetails,records){
		let fetchedData = records == null ? "No records!" : (records.length > 0 ? records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widgetdetails.drillThruTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if(widgetdetails.drillThruXAxis.charAt(0) == "[" && widgetdetails.drillThruXAxis.charAt(widgetdetails.drillThruXAxis.length - 1) == "]"){
				xAxisFormatted = JSON.parse(widgetdetails.drillThruXAxis)[0]
			}
			else { xAxisFormatted = widgetdetails.drillThruXAxis }
			
			let options = {
				data: records,
				dataForTable: [],
				isDataDetailedForTable: false,
				keys: {
					label: xAxisFormatted,
					groupBars: JSON.parse(widgetdetails.drillThruYAxis),
					lines: JSON.parse(widgetdetails.secondaryYaxis)
				},
				chartTitle: widgetdetails.drillThruTitle,
				xAxisLable: xAxisFormatted,
				yAxisLable: "",
				showTitle: true,
				colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
				drillDownColumnName: widgetdetails?.drillDownColumn ?  widgetdetails.drillDownColumn : "",
				showTable: false,
				showComments: true,
				comments: "",
				resizeButtonID: document.getElementById("resizeBtn").id
			}
				
			setTimeout(() => { 
				acinfotech.groupedBarsWithLineChart(chartElement, options)
				chartElement.selectAll('div.ui-resizable-handle').style("display","none");
			},1000)
		}
	}
	USMapWithPins(chartElement,widgetdetails,records){
		let fetchedData = records == null ? "No records!" : (records.length > 0 ? records : "No records!")
		
		if(fetchedData == "No records!"){
			chartElement.append("div").style({"position":"absolute","left":"0%", "top":"0%", "width":"100%", "height":"8%", "background-color":"white", "text-indent":"6px"})
            .html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">'+ widgetdetails.drillThruTitle + '</div>')
			
			chartElement.append("label").style({"position":"absolute","left":"0%", "top":"45%", "width":"100%", "font-family":"Roboto", "font-size":"30px", "color":"#f16464","text-align":"center"})
			.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if(widgetdetails.drillThruXAxis.charAt(0) == "[" && widgetdetails.drillThruXAxis.charAt(widgetdetails.drillThruXAxis.length - 1) == "]"){
				xAxisFormatted = JSON.parse(widgetdetails.drillThruXAxis)[0]
			}
			else { xAxisFormatted = widgetdetails.drillThruXAxis }

			let yAxisFormatted = "";
			if(widgetdetails.drillThruYAxis.charAt(0) == "[" && widgetdetails.drillThruYAxis.charAt(widgetdetails.drillThruYAxis.length - 1) == "]"){
				yAxisFormatted = JSON.parse(widgetdetails.drillThruYAxis)[0]
			}
			else { yAxisFormatted = widgetdetails.drillThruYAxis }
			
			let options = {
				data: records,
				dataForTable: [],
				keys: {
					stateCode: xAxisFormatted,
					latitude: "LATITUDE",
					longitude: "LONGITUDE",
					state: widgetdetails.chartPlot,
					place: yAxisFormatted,
					value: JSON.parse(widgetdetails.secondaryYaxis)[0]
				},
				chartTitle: widgetdetails.drillThruTitle,
				showTable: false,
				drillDownColumnName: widgetdetails?.drillDownColumn ?  widgetdetails.drillDownColumn : "",
				resizeButtonID: document.getElementById("resizeBtn").id
			}

			setTimeout(() => { 
				acinfotech.USMapWithPins(chartElement, options)
				chartElement.selectAll('div.ui-resizable-handle').style("display","none");
			},1000)
		}
	}

	chartsDivContainerClick(event){ // drill down

		if((event.target.nodeName.toLowerCase() == "rect") || (event.target.nodeName.toLowerCase() == "path") || 
			(event.target.nodeName.toLowerCase() == "circle")){
				if(event.target.attributes["data-drilldownkey"] != undefined){
					
					var drillThruKeyValuesArray = [], dataDrillDownKeyArray = d3.select(event.target).attr("data-drilldownkey").split(",");
					
					for(var i = 0; i < dataDrillDownKeyArray.length; i++){
						drillThruKeyValuesArray.push({"drillThruColumn":dataDrillDownKeyArray[i].substr(0,dataDrillDownKeyArray[i].indexOf("|")),
						"value":dataDrillDownKeyArray[i].substr((dataDrillDownKeyArray[i].indexOf("|")+1),dataDrillDownKeyArray[i].length)})
					}
					
					this.requestPayload.drillDown = drillThruKeyValuesArray;

					this.drillThruClickCount++;
					
					//console.log(drillThruKeyValuesArray,this.drillThruClickCount,this.config.data?.clickedWidget.drillThru[this.drillThruClickCount])
					//console.log(this.config.data?.clickedWidget.drillThru[this.drillThruClickCount]?.widgetControlDrillThruId)
					
					if(this.drillThruClickCount < this.config.data?.clickedWidget.drillThru.length){

						d3.selectAll(".tibo-mydash-drillthru-spinner").style("display","block") // showing reports container spinner

						this.drillThruSubscrip = this.http.post('api/v1/dashboard/controls/'+this.config.data?.clickedWidget.drillThru[this.drillThruClickCount]?.widgetControlDrillThruId
						+'/drill/run?&limit=250&page=1', this.requestPayload,
						{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' }).subscribe( (recordsResp: any) => {
							
							d3.selectAll(".tibo-mydash-drillthru-spinner").style("display","none") // hidding reports container spinner
							
							//console.log(this.config.data?.clickedWidget.drillThru[this.drillThruClickCount].drillDownColumn)

							if(this.config.data?.clickedWidget.drillThru[this.drillThruClickCount].tbiControlsMasterId == 3){ // Storing the total records length for paginator if the chart is Table (grid)
								this.totalRecords = recordsResp.body?.totalRecords;
							}
							
							this.renderChart(this.config.data?.clickedWidget.drillThru[this.drillThruClickCount],recordsResp.body?.records)
							
						}, error => { 
							
							this.drillThruClickCount--; // decreasing the drill thru click count if statu code is not 200

							d3.selectAll(".tibo-mydash-drillthru-spinner").style("display","none") // hidding reports container spinner
							if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
							else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
							else { this.errorMsg = "Please try after some time." }
							
							this.addSingle();
						})

					}
					
				}
		}
		else if(event.target.nodeName.toLowerCase() == "td"){
			if(event.target.style["textDecoration"].toLowerCase() == "underline"){

				var drillThruKeyValuesArray = [];
				for(var i = 0; i < event.target.offsetParent.firstChild.children.length; i++){
					drillThruKeyValuesArray.push({"drillThruColumn":event.target.offsetParent.firstChild.children[i].textContent,
					"value":event.target.parentNode.children[i].textContent});
				}

				this.requestPayload.drillDown = drillThruKeyValuesArray;

				this.drillThruClickCount++;

				//console.log(drillThruKeyValuesArray,this.drillThruClickCount,this.config.data?.clickedWidget.drillThru[this.drillThruClickCount])
				//console.log(this.config.data?.clickedWidget.drillThru[this.drillThruClickCount]?.widgetControlDrillThruId)

				if(this.drillThruClickCount < this.config.data?.clickedWidget.drillThru.length){

					d3.selectAll(".tibo-mydash-drillthru-spinner").style("display","block") // showing reports container spinner

					this.drillThruSubscrip = this.http.post('api/v1/dashboard/controls/'+this.config.data?.clickedWidget.drillThru[this.drillThruClickCount]?.widgetControlDrillThruId
					+'/drill/run?&limit=250&page=1', this.requestPayload,
					{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' }).subscribe( (recordsResp: any) => {
						
						d3.selectAll(".tibo-mydash-drillthru-spinner").style("display","none") // hidding reports container spinner
						
						//console.log(recordsResp)

						if(this.config.data?.clickedWidget.drillThru[this.drillThruClickCount].tbiControlsMasterId == 3){ // Storing the total records length for paginator if the chart is Table (grid)
							this.totalRecords = recordsResp.body?.totalRecords;
						}

						this.renderChart(this.config.data?.clickedWidget.drillThru[this.drillThruClickCount],recordsResp.body?.records)
						
					}, error => { 

						this.drillThruClickCount--; // decreasing the drill thru click count if statu code is not 200

						d3.selectAll(".tibo-mydash-drillthru-spinner").style("display","none") // hidding reports container spinner
						if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
						else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
						else { this.errorMsg = "Please try after some time." }
						
						this.addSingle();
					})
				
				}
				
			} 
		} // td

	}

	paginate(event) {

		this.paginatorPageNo = event.page + 1;
		//console.log(this.paginatorPageNo)

		d3.selectAll(".tibo-mydash-drillthru-spinner").style("display","block") // showing reports container spinner

		this.drillThruSubscrip = this.http.post('api/v1/dashboard/controls/'+this.config.data?.clickedWidget.drillThru[this.drillThruClickCount]?.widgetControlDrillThruId
			+'/drill/run?&limit=250&page='+this.paginatorPageNo, this.requestPayload,
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' }).subscribe( (recordsResp: any) => {
				
				d3.selectAll(".tibo-mydash-drillthru-spinner").style("display","none") // hidding reports container spinner
				
				//console.log(recordsResp)

				/*if(this.config.data?.clickedWidget.drillThru[this.drillThruClickCount].tbiControlsMasterId == 3){ // Storing the total records length for paginator if the chart is Table (grid)
					this.totalRecords = recordsResp.body?.totalRecords;
				}*/

				this.renderChart(this.config.data?.clickedWidget.drillThru[this.drillThruClickCount],recordsResp.body?.records)
				
		}, error => { 
			d3.selectAll(".tibo-mydash-drillthru-spinner").style("display","none") // hidding reports container spinner
			if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
			else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
			else { this.errorMsg = "Please try after some time." }
			
			this.addSingle();
		})

	}

	/*theadClick(){
		this.customSortBool = true;
	}

    customSort(event: SortEvent){
		
		this.tableSortColumn = event.field;
		this.tableSortDir = event.order;
		
		this.table.reset();
		this.table.sortField = this.tableSortColumn;
		this.table._sortOrder = this.tableSortDir;
		
		if(this.customSortBool){
			
			this.customSortBool = false;
			
			this.requestPayload.sort = [];
			
			if(event.order == 1){
				this.requestPayload.sort.push({ property: event.field, dir: "ASC"})
			}
			else if(event.order == -1){
				this.requestPayload.sort.push({ property: event.field, dir: "DESC"})
			}
			
			d3.selectAll(".tibo-mydash-drillthru-spinner").style("display","block") // showing reports container spinner
			
			if(this.hisOrWeklyBoolVal == "History"){
			
				this.drillThruSubscrip = this.http.post('api/v1/report/'+this.config.data?.reportId+'/output/search?depth=0&limit=100&page='+this.paginatorPageNo, this.requestPayload,
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' }).subscribe( (recordsResp: any) => {
					
					d3.selectAll(".tibo-mydash-drillthru-spinner").style("display","none") // hidding reports container spinner
					
					this.totalRecords = recordsResp.body.totalRecords;
					let sortedData = [];
					recordsResp.body?.records ? sortedData = recordsResp.body.records : sortedData = [];
					
					sortedData.forEach(d => {
						if(d?.createdDate != null){
							d.createdDate = new Date(d?.createdDate);
						}
					});
					
					event.data.splice(0, event.data.length)
					
					sortedData.forEach(d => { event.data.push(d) })

					sortedData.length > 0 ? this.tableEmptyMessage = "" : this.tableEmptyMessage = "No records found";
					
				}, error => { 
					d3.selectAll(".tibo-mydash-drillthru-spinner").style("display","none") // hidding reports container spinner
					if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
					else { this.errorMsg = "Please try after some time." }
					
					this.addSingle();
				})
				
			}
			else {
			
				this.drillThruSubscrip = this.http.post('api/v1/report/'+this.config.data?.reportId+'/output/history/search?depth=0&limit=100&page='+this.paginatorPageNo, this.requestPayload,
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' }).subscribe( (recordsResp: any) => {
					
					d3.selectAll(".tibo-mydash-drillthru-spinner").style("display","none") // hidding reports container spinner
					
					this.totalRecords = recordsResp.body.totalRecords;
					let sortedData = [];
					recordsResp.body?.records ? sortedData = recordsResp.body.records : sortedData = [];
					
					sortedData.forEach(d => {
						if(d?.createdDate != null){
							d.createdDate = new Date(d?.createdDate);
						}
					});
					
					event.data.splice(0, event.data.length)
					
					sortedData.forEach(d => { event.data.push(d) })

					sortedData.length > 0 ? this.tableEmptyMessage = "" : this.tableEmptyMessage = "No records found";

				}, error => { 
					d3.selectAll(".tibo-mydash-drillthru-spinner").style("display","none") // hidding reports container spinner
					if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
					else { this.errorMsg = "Please try after some time." }
					
					this.addSingle();
				})
				
			}
		}
	}
	
	paginate(event) {
		
		this.paginatorPageNo = event.page + 1;
		this.myReportRecords = [];
		
		d3.selectAll(".tibo-mydash-drillthru-spinner").style("display","block") // showing reports container spinner
		
		if(this.hisOrWeklyBoolVal == "History"){
		
			this.drillThruSubscrip = this.http.post('api/v1/report/'+this.config.data?.reportId+'/output/search?depth=0&limit=100&page='+this.paginatorPageNo, this.requestPayload,
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' }).subscribe( (recordsResp: any) => {
				
				d3.selectAll(".tibo-mydash-drillthru-spinner").style("display","none") // hidding reports container spinner
				
				//this.totalRecords = recordsResp.body.totalRecords;
				recordsResp.body?.records ? this.myReportRecords = recordsResp.body.records : this.myReportRecords = [];
				
				this.myReportRecords.forEach(d => {
					if(d?.createdDate != null){
						d.createdDate = new Date(d?.createdDate);
					}
				});
				
			}, error => { 
				d3.selectAll(".tibo-mydash-drillthru-spinner").style("display","none") // hidding reports container spinner
				if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
				else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
				else { this.errorMsg = "Please try after some time." }
				
				this.addSingle();
			})
		
		}
		else {
			
			this.drillThruSubscrip = this.http.post('api/v1/report/'+this.config.data?.reportId+'/output/history/search?depth=0&limit=100&page='+this.paginatorPageNo, this.requestPayload,
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' }).subscribe( (recordsResp: any) => {
				
				d3.selectAll(".tibo-mydash-drillthru-spinner").style("display","none") // hidding reports container spinner
				
				//this.totalRecords = recordsResp.body.totalRecords;
				recordsResp.body?.records ? this.myReportRecords = recordsResp.body.records : this.myReportRecords = [];
				
				this.myReportRecords.forEach(d => {
					if(d?.createdDate != null){
						d.createdDate = new Date(d?.createdDate);
					}
				});
				
			}, error => { 
				d3.selectAll(".tibo-mydash-drillthru-spinner").style("display","none") // hidding reports container spinner
				if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
				else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
				else { this.errorMsg = "Please try after some time." }
				
				this.addSingle();
			})
			
		}
		
	}
	
	historyOrWeeklyBtnClick(){
		
		this.tableSortColumn = "createdDate";
		this.tableSortDir = -1;
		
		//this.table.reset();
		//this.table.sortField = this.tableSortColumn;
		//this.table._sortOrder = this.tableSortDir;
		
		this.tableEmptyMessage = "";

		this.requestPayload = {"sort":[{"property":"createdDate","dir":"DESC"}],"filters":[],"filterType":"and"};
		this.paginatorPageNo = 1;
		this.myReportRecords = [];
		this.totalRecords = 0;
		
		d3.selectAll(".tibo-mydash-drillthru-spinner").style("display","block") // showing reports container spinner
		
		if(this.hisOrWeklyBoolVal == "History"){
		
			this.hisOrWeklyBoolVal = "Weekly";
			
			this.drillThruSubscrip = this.http.post('api/v1/report/'+this.config.data?.reportId+'/output/history/search?depth=0&limit=100&page='+this.paginatorPageNo, this.requestPayload,
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' }).subscribe( (recordsResp: any) => {
				
				d3.selectAll(".tibo-mydash-drillthru-spinner").style("display","none") // hidding reports container spinner
				
				this.totalRecords = recordsResp.body.totalRecords;
				recordsResp.body?.records ? this.myReportRecords = recordsResp.body.records : this.myReportRecords = [];
				
				this.myReportRecords.forEach(d => {
					if(d?.createdDate != null){
						d.createdDate = new Date(d?.createdDate);
					}
				});
				
				this.myReportRecords.length > 0 ? this.tableEmptyMessage = "" : this.tableEmptyMessage = "No records found";
				
				this.paginator.changePage(0);
				
			}, error => { 
				d3.selectAll(".tibo-mydash-drillthru-spinner").style("display","none") // hidding reports container spinner
				if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
				else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
				else { this.errorMsg = "Please try after some time." }
				
				this.addSingle();
			})
			
		}
		else {
		
			this.hisOrWeklyBoolVal = "History";
			
			this.drillThruSubscrip = this.http.post('api/v1/report/'+this.config.data?.reportId+'/output/search?depth=0&limit=100&page='+this.paginatorPageNo, this.requestPayload,
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' }).subscribe( (recordsResp: any) => {
				
				d3.selectAll(".tibo-mydash-drillthru-spinner").style("display","none") // hidding reports container spinner
				
				this.totalRecords = recordsResp.body.totalRecords;
				recordsResp.body?.records ? this.myReportRecords = recordsResp.body.records : this.myReportRecords = [];
				
				this.myReportRecords.forEach(d => {
					if(d?.createdDate != null){
						d.createdDate = new Date(d?.createdDate);
					}
				});
				
				this.myReportRecords.length > 0 ? this.tableEmptyMessage = "" : this.tableEmptyMessage = "No records found";
				
				this.paginator.changePage(0);
				
			}, error => { 
				d3.selectAll(".tibo-mydash-drillthru-spinner").style("display","none") // hidding reports container spinner
				if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
				else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
				else { this.errorMsg = "Please try after some time." }
				
				this.addSingle();
			})
			
		}
		
	}
	
	downloadOutputFileBtnClick(fileId,fileName){
		
		if(fileName.split('.').pop().toLowerCase() == "xls"){
		
			this.drillThruSubscrip = this.http.get('api/v1/file/'+fileId+'/export', 
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, 'responseType': 'blob' as 'json', observe: 'response' })
				.subscribe( (response: any) => {
		
					let blob = new Blob([response.body],{type:'application/vnd.ms-excel'});
						
					var a = document.createElement("a");
					a.href = URL.createObjectURL(blob);
					a.download = fileName;
					a.click();
			
				}, error => { 
					if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
					else { this.errorMsg = "Please try after some time." }
					
					this.addSingle();
				})
			
		}
		else if(fileName.split('.').pop().toLowerCase() == "xlsx"){
		
			this.drillThruSubscrip = this.http.get('api/v1/file/'+fileId+'/export',
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, 'responseType': 'blob' as 'json', observe: 'response' })
				.subscribe( (response: any) => {
				
					let blob = new Blob([response.body],{type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
							
					var a = document.createElement("a");
					a.href = URL.createObjectURL(blob);
					a.download = fileName;
					a.click();
					
				}, error => { 
					if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
					else { this.errorMsg = "Please try after some time." }
					
					this.addSingle();
				})
		}
		else if(fileName.split('.').pop().toLowerCase() == "csv"){
			
			this.drillThruSubscrip = this.http.get('api/v1/file/'+fileId+'/export', 
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, 'responseType': 'text', observe: 'response' })
				.subscribe( (response: any) => {
				
					let blob = new Blob([response.body],{type:'text/csv;charset=utf-8'});
						
					var a = document.createElement("a");
					a.href = URL.createObjectURL(blob);
					a.download = fileName;
					a.click();
					
				}, error => { 
					if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
					else { this.errorMsg = "Please try after some time." }
					
					this.addSingle();
				})
			
		}
		else if(fileName.split('.').pop().toLowerCase() == "txt"){
		
			this.drillThruSubscrip = this.http.get('api/v1/file/'+fileId+'/export',
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, 'responseType': 'text', observe: 'response' })
				.subscribe( (response: any) => {
					
					let blob = new Blob([response.body],{type:'text/text'})
						
					var a = document.createElement("a");
					a.href = URL.createObjectURL(blob);
					a.download = fileName;
					a.click();
					
				}, error => { 
					if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
					else { this.errorMsg = "Please try after some time." }
					
					this.addSingle();
				})
		
		}
		else if(fileName.split('.').pop().toLowerCase() == "pdf"){
			
			this.drillThruSubscrip = this.http.get('api/v1/file/'+fileId+'/export',
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, 'responseType': 'blob' as 'json', observe: 'response' })
				.subscribe( (response: any) => {
					
					let blob = new Blob([response.body],{type:'application/pdf'})
						
					var a = document.createElement("a");
					a.href = URL.createObjectURL(blob);
					a.download = fileName;
					a.click();
					
				}, error => { 
					if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
					else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
					else { this.errorMsg = "Please try after some time." }
					
					this.addSingle();
				})
			
		}
		else if(fileName.split('.').pop().toLowerCase() == "doc"){
			
			this.drillThruSubscrip = this.http.get('api/v1/file/'+fileId+'/export',
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, 'responseType': 'blob' as 'json', observe: 'response' })
			.subscribe( (response: any) => {
			
				let blob = new Blob([response.body],{type:'application/msword'});
					
				var a = document.createElement("a");
				a.href = URL.createObjectURL(blob);
				a.download = fileName;
				a.click();
			
			}, error => { 
				if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
				else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
				else { this.errorMsg = "Please try after some time." }
				
				this.addSingle();
			})
			
		}
		else if(fileName.split('.').pop().toLowerCase() == "pptx"){
		
			this.drillThruSubscrip =  this.http.get('api/v1/file/'+fileId+'/export',
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, 'responseType': 'blob' as 'json', observe: 'response' })
			.subscribe( (response: any) => {
				
				let blob = new Blob([response.body],{type:'application/vnd.openxmlformats-officedocument.presentationml.presentation'});
					
				var a = document.createElement("a");
				a.href = URL.createObjectURL(blob);
				a.download = fileName;
				a.click();
				
			}, error => { 
				if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
				else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
				else { this.errorMsg = "Please try after some time." }
				
				this.addSingle();
			})
		
		}
	}*/
	
	addSingle() { this.messageService.add({severity:'error', summary:'', detail:this.errorMsg}); }

    clear() { this.messageService.clear(); }
}