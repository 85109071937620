import {Component} from '@angular/core';
import { Table } from 'primeng/table';
import { ModelWindowService } from './model-window.service'
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';


@Component({
    template: `<div style="font-family:Roboto; font-size:14px;">
				<p>Client Code: <span style="font-weight:bold;">{{this.reportInfo?.clientCode}}</span></p>
				<p>Client Name: <span style="font-weight:bold;">{{this.reportInfo?.clientName}}</span></p>
				<p>Report Name: <span style="font-weight:bold;">{{this.reportInfo?.authorizationName}}</span></p>
				<p>Report Description: <span style="font-weight:bold;">{{this.reportInfo?.authorizationDesc}}</span></p>
				<p>Report Type: <span style="font-weight:bold;">{{this.reportInfo?.reportType}}</span></p>
				<p>Report Parameters: <span style="font-weight:bold;">{{this.reportInfo?.parameters}}</span></p>
				<p>Report Criteria: <span style="font-weight:bold;">{{this.reportInfo?.reportCriteria}}</span></p>
				<p>Field Mapping: <span style="font-weight:bold;">{{this.reportInfo?.fieldMapping}}</span></p>
				<p>Location: <span style="font-weight:bold;">{{this.reportInfo?.location}}</span></p>
			</div>`,
	providers: [ModelWindowService]
})
export class ReportCatalogueModelWindow {

    reportInfo: any;
    
    constructor(private modelWindowService: ModelWindowService, public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }

    ngOnInit() {
		console.log(this.config.data)
        this.modelWindowService.reportInfoService(this.config.data).subscribe(data => {
			//this.reportInfo = data.body
			this.reportInfo = data.body;
			console.log(this.reportInfo)
		})
    }

    /*selectProduct(product: Product) {
        this.ref.close(product);
    }*/
}