import { Component } from '@angular/core';

import { Router } from "@angular/router";

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
	
export class AppComponent {
	constructor(private router: Router) { }
	show = true;
	sidebarExpanded = false;
	ngOnInit(): void {
		this.router.navigate(['./home']);
		localStorage.setItem('userName', 'Admin')
		//if(location.pathname == "/"){ this.show = false; }
		//else { this.show = true;}
		
		// if(localStorage.getItem("token") != null){
		// 	this.show = true;
		// 	if(location.pathname == "/"){ this.router.navigate(['./home']); }
		// }
		
	}
	
	enterAppFunc(receivedBoolVal: any) { this.show = receivedBoolVal; }
	
}
