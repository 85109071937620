<div id="schedrepo-scroll-wrapper" class="tibo-schedrepo-scroll-wrapper">
    <div class="top" *ngIf="first">
        <div *ngIf="topbar" class="search-bar">
            <div class="p-md-6 p-sm-9 p-px-0">

                <div class="search-div">
                    <input type="text" [(ngModel)]="searchText" (keyup.enter)="onSearchBtnClick()" class="search-input"
                        [placeholder]="placeHolderValue" (input)="showSuggestion($event)" pInputText />
                    <span class="microphone-icon" tooltip="Search by Voice" (click)="toggleListening()"
                        *ngIf="!isListening">
                        <i class="fa fa-microphone-slash"></i>
                    </span>

                    <!-- Add slash microphone icon when listening -->
                    <span class="microphone-icon" tooltip="Search by Voice" (click)="toggleListening()"
                        *ngIf="isListening">
                        <i class="fa fa-microphone" [ngClass]="{'listening': isListening}"></i>
                    </span>
                    <!-- <span class="microphone-icon" tooltip="Search by Voice" (click)="toggleListening()"><i class="fa fa-microphone"></i></span> -->
                    <button pButton type="button" icon="pi pi-search" iconPos="center" class="tibo-data-disco-submitbtn"
                        (click)="onSearchBtnClick()" (keyup.enter)="onSearchBtnClick()"></button>

                </div>
                <div *ngIf="isListening" class="iswriting">
                    <p class="speak-now-text" *ngIf="!spokenText">Speak now...</p>
                    <div *ngIf="filteredItems.length > 0 &&isListening">
                        <ul class="suggestionUl">
                            <li *ngFor="let item of filteredItems" (click)="onOptionSelect(item)">{{
                                item.question
                                }}</li>
                        </ul>
                    </div>
                </div>
                <div *ngIf="isWriting" class="iswriting">
                    <div *ngIf="filteredItems.length > 0 && isWriting">
                        <ul class="suggestionUl">
                            <li *ngFor="let item of filteredItems" (click)="onOptionSelect(item)">{{
                                item.question
                                }}</li>
                        </ul>
                    </div>
                    <!-- <ul class="suggestionUl">
  <li *ngIf="filteredItems.length === 0">No Result found</li>
  </ul> -->
                </div>
                <!-- <div class="check-parent">
                    <div *ngFor="let category of categories" class="checkboxes">
                        <div class="single-check">
                            <p-checkbox name="group2" value="category" [(ngModel)]="selectedCategories"
                                [value]="category" [inputId]="category.key" (onChange)="checkboxClick()">
                            </p-checkbox>&nbsp;
                            <label [for]="category.key" class="category-name">{{ category.name }}</label>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <!-- <div class="bottom" *ngIf="widget">
        <div class="bottom-content">
            <i class="fa fa-search icon"></i>
            <h1>What are you looking for?</h1>
        </div>
    </div> -->
    <div class="abbc" *ngIf="report">
        <div *ngIf="widgetContainerBool" class="tibo-data-disco-res-output-cont">
            <!-- <p-progressSpinner styleClass="tibo-data-dis-output-spinner custom-spinner"
                class="tibo-data-dis-result-spinner" strokeWidth="2" fill="#FFFFFF" animationDuration="1.5s"
                style="z-index:1000;">
            </p-progressSpinner> -->

            <div *ngIf="headerbtn" class="tibo-mydash-dashboard-title" id="full-screen-mode-btn">
                <!-- {{
                dashboardName }} -->
                <!-- <button class="tibo-mydash-chartcoloroptions-btn" type="button" pButton icon="pi pi-angle-left" (click)="back()" pTooltip="Back to Search"></button>
    </div> -->

                <div *ngIf="showDashboard" class="chartContainer" id="chartscontainerid">
                    <div *ngIf="dashboardName == 'Disability'">
                        <!-- <div class="chartTitle" id="dashboardtitle">{{ dashboardName }}</div> -->
                        <div class="chartDiv" id="chart1">
                        </div>
                        <div class="chartDiv" id="chart2">
                        </div>
                        <div class="chartDiv" id="chart3">
                        </div>
                        <div class="chartDiv" id="chart4">
                        </div>
                        <div class="chartDiv" id="chart5">
                        </div>
                        <div class="chartDiv" id="chart6">
                        </div>
                        <div class="chartDiv" id="chart7">
                        </div>
                        <div class="chartDiv" id="chart8">
                        </div>
                        <div class="chartDiv" id="chart11">
                        </div>
                        <div class="chartDiv" id="chart10">
                        </div>

                    </div>
                    <button class="chartResiz" id="resizeBtn" type="button" onclick="btnClickedFunc(this.id)">Resize
                        All</button>

                    <div *ngIf="dashboardName == 'Leave'">
                        <!-- <div class="chartTitle" id="dashboardtitle">{{ dashboardName }}</div> -->
                        <div class="chartDiv" id="chart12">
                        </div>
                        <div class="chartDiv" id="chart13">
                        </div>
                        <div class="chartDiv1" id="chart9">
                        </div>
                        <!-- <div class="chartDiv" id="chart10">
                </div> -->

                    </div>
                    <button class="chartResiz" id="resizeBtn" type="button" onclick="btnClickedFunc(this.id)">Resize
                        All</button>
                </div>

                <div *ngIf="showWidgets" class="chartContainer" id="chartscontainerid">
                    <div class="chartDiv3" [id]="this.chartId">
                    </div>
                    <button class="chartResiz" id="resizeBtn" type="button" onclick="btnClickedFunc(this.id)">Resize
                        All</button>
                </div>
                <div *ngIf="showReport " class="chartContainer" id="chartscontainerid">
                    <div class="container1">
                        <div class="aggriid">
                            <ag-grid-angular style="width: 100%; height:579px;clear: both;" class="ag-theme-alpine"
                                [rowData]="rowData" [columnDefs]="columnDefs " [defaultColDef]="defaultColDef"
                                [enableFilter]="true" [pagination]="true" [gridOptions]="gridOptions"
                                [setQuickFilter]="quickFilterText">
                            </ag-grid-angular>
                        </div>
                    </div>
                </div>

            </div>


        </div>
    </div>
</div>