import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataDiscoveryService } from './data-discovery.service'
import { Subscription } from 'rxjs';
import { Message,MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { ViewChild } from '@angular/core'


import {MenuItem} from 'primeng/api';

import { Router } from "@angular/router";

declare var d3:any;

interface City {
    name: string,
    code: string
}

@Component({
	selector: 'app-data-discovery',
	templateUrl: './data-discovery.component.html',
	styleUrls: ['./data-discovery.component.css'],
	providers: [DataDiscoveryService, MessageService]
})

export class DataDiscoveryComponent implements OnInit {
	
	cities: City[];
	selectedCity: City;
	domainsArray = [];
	selectedDomain: any;
	dashWidgetsArray = [];
	tempObj = {};
	repomatricAndWidgetsArray = [];
	totalRecords = 0;
	businessMetricText: any;
	
	searchFromPanelToggleBool: boolean = false;

	constructor(private messageService: MessageService, private http: HttpClient, private dataDiscoveryService: DataDiscoveryService, private router: Router) { 
		this.cities = [
            {name: 'New York', code: 'NY'},
            {name: 'Rome', code: 'RM'},
            {name: 'London', code: 'LDN'},
            {name: 'Istanbul', code: 'IST'},
            {name: 'Paris', code: 'PRS'}
        ];
	}
	
	items: MenuItem[];
	
	dataDiscoverySubscrip: Subscription;
	reportsAndMatricsSubscrip: Subscription;
	dashboardWidgetsSubscrip: Subscription;
	
	ngOnInit(): void {
		
		this.items = [
            {
                label: 'Today',
                
            },
            {
                label: 'This Week',
                
            }
        ];

		d3.select(".tibo-topbar-title").text("Home/Data Discovery"); // setting title
		
		if(Number(d3.select("#tibo-large-leftpanel-container").node().getBoundingClientRect().width) == 64){
			setTimeout(function(){
				d3.select(".tibo-right-container").transition().delay(0).duration(0).style("left","64px").ease("linear");
			},10)
		}
		
		this.dataDiscoverySubscrip = this.dataDiscoveryService.domainsService().subscribe( (response: any) => { // domains list
			
			this.domainsArray = response.body?.records;
			// console.log(this.domainsArray);
			
		}, error => { 
			/*if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
			else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
			else { this.errorMsg = "Please try after some time." }
			
			this.addSingle()*/
		}, () => {}) // directoryService

	}
	
	ngOnDestroy() {
		if(this.dataDiscoverySubscrip){
			this.dataDiscoverySubscrip.unsubscribe();
		}
		if(this.reportsAndMatricsSubscrip){
			this.reportsAndMatricsSubscrip.unsubscribe();
		}
		if(this.dashboardWidgetsSubscrip){
			this.dashboardWidgetsSubscrip.unsubscribe();
		}
	}
	
	searchFromPanelToggleFunc() {
		if(this.searchFromPanelToggleBool == false){
			d3.selectAll("#data-discovery-search-from-panel-params-container, #data-discovery-submitbutton-id").transition().delay(100).duration(400).style("display","none")
			d3.select("#data-discovery-search-from-panel").transition().delay(200).duration(500).style("width","40px")
			d3.select("#data-disco-list-scroll-view").transition().delay(200).duration(500).style("left","50px")//.style("width","calc(100% - 50px)")
			d3.select("#data-discovery-search-from-panel").select("button").style("transform","rotate(180deg)")
			d3.select("#search-from-id").transition().delay(100).duration(100)
			.style({'left':'0px','top':'60px'}).style("transform","rotate(-90deg)")
			this.searchFromPanelToggleBool = true;
		}
		else {
			d3.select("#data-discovery-search-from-panel-params-container").transition().delay(300).duration(400).style("display","block")
			d3.select("#data-discovery-submitbutton-id").transition().delay(500).duration(400).style("display","block")
			d3.select("#data-discovery-search-from-panel").transition().delay(200).duration(500).style("width","360px")
			d3.select("#data-disco-list-scroll-view").transition().delay(200).duration(500).style("left","360px")//.style("width","calc(100% - 360px)")
			d3.select("#data-discovery-search-from-panel").select("button").style("transform","rotate(0deg)")
			d3.select("#search-from-id").transition().delay(250).duration(100)
			.style({'left':'10px','top':'10px'}).style("transform","rotate(0deg)")
			this.searchFromPanelToggleBool = false;
		}
	}
	
	onChange(event){
		this.selectedDomain = event.value;
		// console.log(this.selectedDomain)
	}
	
	onSubmitClick(){
		
		d3.selectAll("#data-discovery-search-from-panel-params-container, #data-discovery-submitbutton-id").transition().delay(100).duration(400).style("display","none")
		d3.select("#data-discovery-search-from-panel").transition().delay(200).duration(500).style("width","40px")
		d3.select("#data-disco-list-scroll-view").transition().delay(200).duration(500).style("left","50px")//.style("width","calc(100% - 50px)")
		d3.select("#data-discovery-search-from-panel").select("button").style("transform","rotate(180deg)")
		d3.select("#search-from-id").transition().delay(100).duration(100)
		.style({'left':'0px','top':'60px'}).style("transform","rotate(-90deg)")
		this.searchFromPanelToggleBool = true;
		
		this.businessMetricText = document.getElementById("data-disco-business-metric-text-id") as HTMLInputElement;
		
		this.reportsAndMatricsSubscrip = this.dataDiscoveryService.reportAndMatricsGetService(this.selectedDomain,this.businessMetricText.value,1).subscribe( (response: any) => { // matrics record
			
			//this.dashWidgetsArray = response.body?.records;
			//console.log(response.body?.records);
			
			this.totalRecords = response.body?.totalRecords;
			
			this.repomatricAndWidgetsArray = [];
			
			response.body?.records.forEach(d => {
				this.tempObj = {name:d.ddName, type:d.ddType, chart:"", matricOrDashboarName:d.metricName, discOrDomaId: d.discoveryId}
				this.repomatricAndWidgetsArray.push(this.tempObj)
				this.tempObj = {};
			})
			
			this.dashboardWidgetsSubscrip = this.dataDiscoveryService.dashWidgetsGetService(this.businessMetricText.value).subscribe( (response: any) => { // widgets record
			
				//this.dashWidgetsArray = response.body?.records;
				//console.log(this.dashWidgetsArray);
				
				this.totalRecords = this.totalRecords + response.body?.records.length;
				
				response.body?.records.forEach(d => {
					this.tempObj = {name:d.widgetControls[0].chartTitle, type:"Widget", chart:d.widgetControls[0].controlMasterId, 
									matricOrDashboarName:d.widgetName, discOrDomaId: d.widgetControls[0].widgetControlId,
									xAxis:d.widgetControls[0].yAxis, yAxis:d.widgetControls[0].xAxis,}
					this.repomatricAndWidgetsArray.push(this.tempObj)
					this.tempObj = {};
				})
				// console.log(this.repomatricAndWidgetsArray)
				// console.log(this.totalRecords)
			}, error => { 
			}, () => {})
			
			
		}, error => { 
		}, () => {})
		
	}
	
	onRunButtonClick(id,type,name){
		if(type == "dashboard"){
			this.router.navigate(['/dashboard'], { queryParams: { id: id} })
		}
		else if(type == "widget"){
			this.router.navigate(['/widget'], { queryParams: { id: id} })
		}
		else if(type == "report"){
			this.router.navigate(['/view'], { queryParams: { id: id, type: "report", name: name} });
		}
	}
	
	paginate(event) {
		
		// console.log(event.page + 1)
		
		this.repomatricAndWidgetsArray = [];
		//this.totalRecords = 0;
		
		this.reportsAndMatricsSubscrip = this.dataDiscoveryService.reportAndMatricsGetService(this.selectedDomain,this.businessMetricText.value,(event.page + 1)).subscribe( (response: any) => { // matrics record
			
			//this.totalRecords = response.body?.totalRecords;
			
			response.body?.records.forEach(d => {
				this.tempObj = {name:d.ddName, type:d.ddType, chart:"", matricOrDashboarName:d.metricName}
				this.repomatricAndWidgetsArray.push(this.tempObj)
				this.tempObj = {};
			})
			
			this.dashboardWidgetsSubscrip = this.dataDiscoveryService.dashWidgetsGetService(this.businessMetricText.value).subscribe( (response: any) => { // widgets record
				
					//this.totalRecords = this.totalRecords + response.body?.records.length;
					
					response.body?.records.forEach(d => {
						this.tempObj = {name:d.widgetControls[0].chartTitle, type:"Widget", chart:d.widgetControls[0].controlMasterId, matricOrDashboarName:d.widgetName}
						this.repomatricAndWidgetsArray.push(this.tempObj)
						this.tempObj = {};
					})
					// console.log(this.repomatricAndWidgetsArray)
					// console.log(this.totalRecords)
				}, error => { 
			}, () => {})
			
			}, error => { 
		}, () => {})
		
		
	}

}
