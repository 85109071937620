import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

declare var d3:any;

@Component({
  selector: 'app-data-analytics',
  templateUrl: './data-analytics.component.html',
  styleUrls: ['./data-analytics.component.css']
})
export class DataAnalyticsComponent implements OnInit {

  constructor(private fb: FormBuilder) {

    this.employeeForm = this.fb.group({
      name: ['', Validators.required],
      Startdate: ['', Validators.required],
      enddate: ['', Validators.required],

    })
    this.absenceForm = this.fb.group({
      range: ['', Validators.required],
      fromdate: ['', Validators.required],
      todate: ['', Validators.required],

    })
   }

  dateRangeArray = [];
  showStories: boolean = true;
  showBackBtn: boolean = false;
  showDateRange: boolean = false;
  showReport: boolean = false;
  story: string;
  folderPanelToggleBool: boolean = false;
  panel = false;
  Absence = false;
  showStory = true;
  employeeForm: any;
  absenceForm: any;

  ngOnInit(): void {
    
    d3.select(".tibo-topbar-title").text("Home/Data Analytics"); // setting title
		
		// if(Number(d3.select("#tibo-large-leftpanel-container").node().getBoundingClientRect().left) == 0){
		// 	setTimeout(function(){
		// 		d3.select(".tibo-right-container").transition().delay(0).duration(0).style("left","200px").ease("linear");
		// 	},10)
		// }

    this.dateRangeArray = [{label: "This Month", value: "This Month"},{label: "Last 3 Months", value: "Last 3 Months"},
    {label: "Last 6 Months", value: "Last 6 Months"},{label: "Last Quarter", value: "Last Quarter"},
    {label: "Last Year", value: "Last Year"}]

  }

  storyClickFunc(story: any){
    this.panel = true;
    this.showStories = false;
    this.showBackBtn = true;
    this.showDateRange = true;
    this.showReport = false;

    this.story = story;

  }
  storyClickFun(story: any){
    this.panel = false;
    this.Absence = true;
    this.showStories = false;
    this.showBackBtn = true;
    this.showDateRange = true;
    this.showReport = false;

    this.story = story;
  }

  backButtonFunc(){
    this.panel = false;
    this.Absence = false;
    
    if(this.showDateRange){
      this.showStories = true;
      this.showBackBtn = false;
      this.showDateRange = false;
      this.showReport = false;
    }
    if(this.showReport){
      this.showStories = true;
      this.showBackBtn = true;
      this.showDateRange = true;
      this.showReport = false;
    }
  }

  submitButtonFunc(){
    if (this.folderPanelToggleBool == false) {
      d3.selectAll("#buildreport-folder-panel-scroll-div").transition().delay(200).duration(400).style("display", "none")
      d3.select("#buildreports-folderview-panel").transition().delay(200).duration(500).style("width", "40px")
       d3.select("#buildreports-tile-list-scroll-view").transition().delay(200).duration(500).style({"left":"50px",'width':'96%','height':'100%','animation-name':'reducetime','float':'right'})//.style("width","calc(100% - 50px)")
      d3.select("#buildreports-folderview-panel").select("button").style("transform", "rotate(180deg)")
      d3.select("#all-reports-id").transition().delay(100).duration(100)
        .style({ 'left': '-10px', 'top': '70px' }).style("transform", "rotate(-90deg)")
      this.folderPanelToggleBool = true;
    }
    else {
      d3.select("#buildreport-folder-panel-scroll-div").transition().delay(300).duration(400).style("display", "block")
      //d3.select("#submitbutton-id").transition().delay(500).duration(400).style("display","block")
      d3.select("#buildreports-folderview-panel").transition().delay(200).duration(500).style("width", "350px")
       d3.select("#buildreports-tile-list-scroll-view").transition().delay(200).duration(500).style({"left":"360px",'width':'75%','height':'100%'})//.style("width","calc(100% - 360px)")
      d3.select("#buildreports-folderview-panel").select("button").style("transform", "rotate(0deg)")
      d3.select("#all-reports-id").transition().delay(250).duration(100)
        .style({ 'left': '10px', 'top': '10px' }).style("transform", "rotate(0deg)")
      this.folderPanelToggleBool = false;
    }
    
    this.showStories = false;
    this.showBackBtn = true;
    this.showDateRange = false;
    this.showReport = true;

    if(this.story == "Employee"){
      setTimeout(() => {
        d3.select("#data-analytics-iframe").attr("src","../../assets/Employee_Experience/Employee_Experience.html")
      }, 200);
      
    }
    else if(this.story == "Absence"){
      setTimeout(() => {
        d3.select("#data-analytics-iframe").attr("src","../../assets/Absence_Management/Absence_Management.html")
      }, 200);
    }

  }

  folderPanelToggle() {
    //this.files = this.directoryArray;
    if (this.folderPanelToggleBool == false) {
      d3.selectAll("#buildreport-folder-panel-scroll-div").transition().delay(200).duration(400).style("display", "none")
      d3.select("#buildreports-folderview-panel").transition().delay(200).duration(500).style("width", "40px")
       d3.select("#buildreports-tile-list-scroll-view").transition().delay(200).duration(500).style({"left":"50px",'width':'96%','height':'100%','animation-name':'reducetime','float':'right'})//.style("width","calc(100% - 50px)")
      d3.select("#buildreports-folderview-panel").select("button").style("transform", "rotate(180deg)")
      d3.select("#all-reports-id").transition().delay(100).duration(100)
        .style({ 'left': '-10px', 'top': '70px' }).style("transform", "rotate(-90deg)")
      this.folderPanelToggleBool = true;
    }
    else {
      d3.select("#buildreport-folder-panel-scroll-div").transition().delay(300).duration(400).style("display", "block")
      //d3.select("#submitbutton-id").transition().delay(500).duration(400).style("display","block")
      d3.select("#buildreports-folderview-panel").transition().delay(200).duration(500).style("width", "350px")
       d3.select("#buildreports-tile-list-scroll-view").transition().delay(200).duration(500).style({"left":"360px",'width':'75%','height':'100%'})//.style("width","calc(100% - 360px)")
      d3.select("#buildreports-folderview-panel").select("button").style("transform", "rotate(0deg)")
      d3.select("#all-reports-id").transition().delay(250).duration(100)
        .style({ 'left': '10px', 'top': '10px' }).style("transform", "rotate(0deg)")
      this.folderPanelToggleBool = false;
    }
  }

  resetForm() {

    this.employeeForm.reset();
    this.absenceForm.reset();
   
  }

}


