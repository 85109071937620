import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


//import { LoginComponent } from './login/login.component'
import { HomeComponent } from './home/home.component'
import { MyDashboardComponent } from './my-dashboard/my-dashboard.component';
import { CreateDashboardComponent } from './create-dashboard/create-dashboard.component';
import { DataDiscoveryComponent } from './data-discovery/data-discovery.component';
import { DataAnalyticsComponent } from './data-analytics/data-analytics.component';
import { MyReportsComponent } from './my-reports/my-reports.component'
import { MyReportsOutputComponent } from './my-reports-output/my-reports-output.component'
import { SchedulerComponent } from './scheduler/scheduler.component'
import { ScheduledReportsComponent } from './scheduled-reports/scheduled-reports.component'
import { ScheduleHistoryComponent } from './schedule-history/schedule-history.component'
import { ReportCatalogueComponent } from './report-catalogue/report-catalogue.component'
import { MyReportInboxComponent } from './my-report-inbox/my-report-inbox.component'
import { MyFavoritesComponent } from './my-favorites/my-favorites.component'
import { BuildReportComponent } from './build-report/build-report.component'
import { DashboardOutputComponent } from './dashboard-output/dashboard-output.component'
import { WidgetOutputComponent } from './widget-output/widget-output.component'
import { DiscoveryComponent } from './discovery/discovery.component'
import { SettingsComponent } from './settings/settings.component'

import { AuthGuard } from './auth.guard'

import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OldDashboardComponent } from './old-dashboard/old-dashboard.component';
import { DashboardV3Component } from './dashboard-v3/dashboard-v3.component';
import { DashboardChartComponent } from './dashboard-v3/dashboard-chart/dashboard-chart.component';
import { LeaveDetailsTableComponent } from './leave-details-table/leave-details-table.component';
import { DisabilityDetailsComponent } from './disability-details/disability-details.component';
import { DisabilitySummaryComponent } from './disability-summary/disability-summary.component';
import { LeaveSummaryComponent } from './leave-summary/leave-summary.component';
import { AbsenceSsoReportComponent } from './absence-sso-report/absence-sso-report.component';
import { AbsenceUrlReportComponent } from './absence-url-report/absence-url-report.component';
import { CustomerExperienceComponent } from './customer-experience/customer-experience.component';

const routes: Routes = [
	//{ path: 'login', component: LoginComponent },
	{ path: 'home', component: HomeComponent },
	// { path: 'my-dashboard', component: MyDashboardComponent },
	// { path: 'new-dashboard', component: DashboardComponent },
	// {
	// 	path: 'dashboard-v3', component: DashboardV3Component,
	// 	children: [
	// 		{
	// 			path: 'dashboard-out', // child route path
	// 			component: DashboardChartComponent, // child route component that the router renders
	// 		},]
	// },
	{ path: 'dashboard', component: OldDashboardComponent },
	{ path: 'create-dashboard', component: CreateDashboardComponent },
	// { path: 'data-discovery', component: DataDiscoveryComponent, data: { shouldDetach: true } },
	// { path: 'data-analytics', component: DataAnalyticsComponent, data: { shouldDetach: true } },
	{ path: 'my-reports', component: MyReportsComponent, data: { shouldDetach: true } },
	{ path: 'view', component: MyReportsOutputComponent },
	{ path: 'scheduler', component: SchedulerComponent },
	{ path: 'scheduled-reports', component: ScheduledReportsComponent },
	{ path: 'schedule-history', component: ScheduleHistoryComponent },
	{ path: 'report-catalogue', component: ReportCatalogueComponent },
	{ path: 'my-report-inbox', component: MyReportInboxComponent },
	{ path: 'my-favorites', component: MyFavoritesComponent },
	{ path: 'build-report', component: BuildReportComponent },
	{ path: 'dashboard', component: DashboardOutputComponent },
	{ path: 'widget', component: WidgetOutputComponent },
	{ path: 'discovery', component: DiscoveryComponent },
	{ path: 'settings', component: SettingsComponent },
	{ path: 'leave-table', component: LeaveDetailsTableComponent },
	{ path: 'disability-table', component: DisabilityDetailsComponent },
	{ path: 'disability-summary', component: DisabilitySummaryComponent },
	{ path: 'leave-summary', component: LeaveSummaryComponent },
	{ path: 'absence-sso-report', component: AbsenceSsoReportComponent },
	{ path: 'absence-url-report', component: AbsenceUrlReportComponent },
	{path:'customer',component:CustomerExperienceComponent}
	//{ path: '', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true })], //{ enableTracing: true, useHash:true } { useHash:true }

	exports: [RouterModule]
})

export class AppRoutingModule { }
