import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ScheduledReportsService } from './scheduled-reports.service'
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { SortEvent } from 'primeng/api';
import { ViewChild } from '@angular/core'

import { Router } from "@angular/router";

declare var d3:any;

interface Status {
  isActive?: string;
  value?: string;
}

@Component({
	selector: 'app-scheduled-reports',
	templateUrl: './scheduled-reports.component.html',
	styleUrls: ['./scheduled-reports.component.css'],
	providers: [ScheduledReportsService, MessageService]
})

export class ScheduledReportsComponent implements OnInit, OnDestroy {
	
	@ViewChild('scheduledreportstable', {static: true}) table: Table;
	
	statuses: Status[];
	
	constructor(private messageService: MessageService, private http: HttpClient, private scheduledReportsService: ScheduledReportsService, private router: Router) { }
	
	errorMsg = "";
	totalRecords = 0;
	scheduledReportRecords = [];
	requestPayload = { sort: [{property: "jobCreatedDate", dir: "DESC"}], filters: [], filterType: "and"};
	tableSortColumn = "jobCreatedDate";
	tableSortDir = -1;
	paginatorPageNo = 1;
	customSortBool:boolean = false;
	tableEmptyMessage:string = "";
	
	recordsSubscrip: Subscription;
	
	ngOnInit(): void {
		
		d3.select(".tibo-topbar-title").text("Home/Scheduled Reports"); // setting title
		
		if(Number(d3.select("#tibo-large-leftpanel-container").node().getBoundingClientRect().left) == 0){
			setTimeout(function(){
				d3.select(".tibo-right-container").transition().delay(0).duration(0).style("left","200px").ease("linear");
			},10)
		}
		
		this.statuses = [
			{ isActive: "Active", value: "Active" },
			{ isActive: "InActive", value: "InActive" },
		];
		
		this.initialCall();
	}
	
	ngOnDestroy() {
		if(this.recordsSubscrip){
			this.recordsSubscrip.unsubscribe();
		}
	}
	
	initialCall(){

		this.recordsSubscrip = this.scheduledReportsService.recordsService(1,this.requestPayload).subscribe( (recordsResp: any) => { // records
			//console.loglog(recordsResp)
			
			d3.select(".tibo-schedrepo-reports-spinner").style("display","none") // hidding scheduled reports container spinner
			
			this.totalRecords = recordsResp.body?.totalRecords;
			recordsResp.body?.records ? this.scheduledReportRecords = recordsResp.body?.records : this.scheduledReportRecords = [];

			this.scheduledReportRecords.forEach(d => {
				if(d?.jobCreatedDate != null){
					d.jobCreatedDate = new Date(d?.jobCreatedDate);
				}
				if(d?.lastRun != null){
					d.lastRun = new Date(d?.lastRun);
				}
				if(d.nextRun != null){
					d.nextRun = new Date(d?.nextRun);
				}
				
				if(d.isActive == "Y"){		// Adding an object to use it in status column filtering
					d["status"] = {isActive: "Active", value: "Active"}
				}
				else if(d.isActive == "N"){
					d["status"] = {isActive: "InActive", value: "InActive"}
				}
				
			});
			
			this.scheduledReportRecords.length > 0 ? this.tableEmptyMessage = "" : this.tableEmptyMessage = "No records found";

			/*d3.select(".tibo-schedrepo-reports-spinner").style("display","none") // hidding reports container spinner
			this.totalRecords = recordsResp.body.totalRecords;
			recordsResp.body?.records ? this.myReportRecords = recordsResp.body.records : this.myReportRecords = [];*/
			
		}, error => { 
			//console.loglog("error")
			d3.select(".tibo-schedrepo-reports-spinner").style("display","none") // hidding scheduled reports container spinner
			if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
			else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
			else { this.errorMsg = "Please try after some time." }
			
			this.addSingle();
		}, () => {})
	}

	theadClick(event){
		event.target.attributes.class.value.includes("pi-filter-icon") ? this.customSortBool = false : this.customSortBool = true;
	}

	customSort(event: SortEvent){
		
		this.tableSortColumn = event.field;
		this.tableSortDir = event.order;
		
		this.table.reset();
		this.table.sortField = this.tableSortColumn;
		this.table._sortOrder = this.tableSortDir;

		if(this.customSortBool){

			this.customSortBool = false;
			
			d3.select(".tibo-schedrepo-reports-spinner").style("display","block") // showing scheduled reports container spinner
			
			//console.loglog(event.field)
			
			this.requestPayload.sort = [];
			
			if(event.order == 1){
				this.requestPayload.sort.push({ property: event.field, dir: "ASC"})
			}
			else if(event.order == -1){
				this.requestPayload.sort.push({ property: event.field, dir: "DESC"})
			}
			
			this.recordsSubscrip = this.scheduledReportsService.recordsService(this.paginatorPageNo,this.requestPayload).subscribe( (recordsResp: any) => { // records
				//console.loglog(recordsResp)
				/*this.totalRecords = recordsResp.body?.totalRecords;
				recordsResp.body?.records ? this.scheduledReportRecords = recordsResp.body?.records : this.scheduledReportRecords = [];
				
				this.scheduledReportRecords.forEach(d => d.jobCreatedDate = new Date(d.jobCreatedDate));*/
				
				d3.select(".tibo-schedrepo-reports-spinner").style("display","none") // hidding scheduled reports container spinner
				
				let sortedData = [];
				recordsResp.body?.records ? sortedData = recordsResp.body.records : sortedData = [];
				
				sortedData.forEach(d => {
					if(d?.jobCreatedDate != null){
						d.jobCreatedDate = new Date(d?.jobCreatedDate);
					}
					if(d?.lastRun != null){
						d.lastRun = new Date(d?.lastRun);
					}
					if(d.nextRun != null){
						d.nextRun = new Date(d?.nextRun);
					}
					
					if(d.isActive == "Y"){
						d["status"] = {isActive: "Active", value: "Active"}
					}
					else if(d.isActive == "N"){
						d["status"] = {isActive: "InActive", value: "InActive"}
					}
				});
				
				event.data.splice(0, event.data.length)
				
				sortedData.forEach(d => { event.data.push(d) })
				
				//console.log(sortedData)
				
				/*d3.select(".tibo-schedrepo-reports-spinner").style("display","none") // hidding reports container spinner
				this.totalRecords = recordsResp.body.totalRecords;
				recordsResp.body?.records ? this.myReportRecords = recordsResp.body.records : this.myReportRecords = [];*/
					
			}, error => { 
				//console.loglog("error")
				d3.select(".tibo-schedrepo-reports-spinner").style("display","none") // hidding scheduled reports container spinner
				if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
				else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
				else { this.errorMsg = "Please try after some time." }
				
				this.addSingle();
			}, () => {})
		}	
	}
	
	paginate(event) {
		/*//console.loglog(this.table)
		
		this.table.clear();
		this.myReportRecords = [];
		
		//d3.select(".tibo-schedrepo-reports-spinner").style("display","block") // showing reports container spinner
		
		this.recordsSubscrip = this.myRepoService.recordsService(this.authorizationId,(event.page + 1)).subscribe( (recordsResp: any) => { // records
			//console.loglog(recordsResp.body)
			
			d3.select(".tibo-schedrepo-reports-spinner").style("display","none") // hidding reports container spinner
			this.totalRecords = recordsResp.body.totalRecords;
			recordsResp.body?.records ? this.myReportRecords = recordsResp.body.records : this.myReportRecords = [];
			
		}, error => { 
			//console.loglog("error")
			if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
			else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
			else { this.errorMsg = "Please try after some time." }
			
			this.addSingle()
		}, () => {})*/

		this.customSortBool = false;

		d3.select(".tibo-schedrepo-reports-spinner").style("display","block") // showing scheduled reports container spinner
		
		this.paginatorPageNo = event.page + 1;
		
		this.scheduledReportRecords = [];
		
		this.recordsSubscrip = this.scheduledReportsService.recordsService(this.paginatorPageNo,this.requestPayload).subscribe( (recordsResp: any) => { // records
			//console.loglog(recordsResp)
			
			d3.select(".tibo-schedrepo-reports-spinner").style("display","none") // hidding scheduled reports container spinner
			
			recordsResp.body?.records ? this.scheduledReportRecords = recordsResp.body?.records : this.scheduledReportRecords = [];
				
			this.scheduledReportRecords.forEach(d => {
				if(d?.jobCreatedDate != null){
					d.jobCreatedDate = new Date(d?.jobCreatedDate);
				}
				if(d?.lastRun != null){
					d.lastRun = new Date(d?.lastRun);
				}
				if(d.nextRun != null){
					d.nextRun = new Date(d?.nextRun);
				}
				
				if(d.isActive == "Y"){
					d["status"] = {isActive: "Active", value: "Active"}
				}
				else if(d.isActive == "N"){
					d["status"] = {isActive: "InActive", value: "InActive"}
				}
			});
			
			/*d3.select(".tibo-schedrepo-reports-spinner").style("display","none") // hidding reports container spinner
			this.totalRecords = recordsResp.body.totalRecords;
			recordsResp.body?.records ? this.myReportRecords = recordsResp.body.records : this.myReportRecords = [];*/
				
		}, error => { 
			//console.loglog("error")
			d3.select(".tibo-schedrepo-reports-spinner").style("display","none") // hidding scheduled reports container spinner
			//d3.select(".tibo-schedrepo-reports-spinner").style("display","none") // hidding reports container spinner
			if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
			else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
			else { this.errorMsg = "Please try after some time." }
			
			this.addSingle()
		}, () => {})
			
	}
	
	filter(event){
		
		console.log(status, this.scheduledReportRecords)
		
		/*this.requestPayload.filters = []; // clearing the request payload filters array before filling
		
		if(status.length > 0){ // if the user has selected at least one status
		
			let tempArray = [];
		
			status.forEach( d => {
				tempArray.push(d.value)
			})
		
			this.requestPayload.filters.push({property:"isActive", operator:"in", value:tempArray}) // forming request payload according to status selected
			
			console.log(this.requestPayload)
			
			this.scheduledReportRecords = [];
			
			d3.select(".tibo-schedrepo-reports-spinner").style("display","block") // showing scheduled reports container spinner
			
			this.recordsSubscrip = this.scheduledReportsService.recordsService(this.paginatorPageNo,this.requestPayload).subscribe( (recordsResp: any) => { // records
				//console.loglog(recordsResp)
				
				d3.select(".tibo-schedrepo-reports-spinner").style("display","none") // hidding scheduled reports container spinner
				
				this.totalRecords = recordsResp.body?.totalRecords;
				recordsResp.body?.records ? this.scheduledReportRecords = recordsResp.body?.records : this.scheduledReportRecords = [];
				
				this.scheduledReportRecords.forEach(d => {
					if(d?.jobCreatedDate != null){
						d.jobCreatedDate = new Date(d?.jobCreatedDate);
					}
					if(d?.lastRun != null){
						d.lastRun = new Date(d?.lastRun);
					}
					if(d.nextRun != null){
						d.nextRun = new Date(d?.nextRun);
					}
				});
				
				
			}, error => { 
				//console.loglog("error")
				d3.select(".tibo-schedrepo-reports-spinner").style("display","none") // hidding scheduled reports container spinner
				if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
				else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
				else { this.errorMsg = "Please try after some time." }
				
				this.addSingle();
			}, () => {})
			
		}
		else {
		
			this.scheduledReportRecords = [];
			
			d3.select(".tibo-schedrepo-reports-spinner").style("display","block") // showing scheduled reports container spinner
			
			this.recordsSubscrip = this.scheduledReportsService.recordsService(this.paginatorPageNo,this.requestPayload).subscribe( (recordsResp: any) => { // records
				//console.loglog(recordsResp)
				
				d3.select(".tibo-schedrepo-reports-spinner").style("display","none") // hidding scheduled reports container spinner
				
				this.totalRecords = recordsResp.body?.totalRecords;
				recordsResp.body?.records ? this.scheduledReportRecords = recordsResp.body?.records : this.scheduledReportRecords = [];
				
				this.scheduledReportRecords.forEach(d => {
					if(d?.jobCreatedDate != null){
						d.jobCreatedDate = new Date(d?.jobCreatedDate);
					}
					if(d?.lastRun != null){
						d.lastRun = new Date(d?.lastRun);
					}
					if(d.nextRun != null){
						d.nextRun = new Date(d?.nextRun);
					}
				});
				
				
			}, error => { 
				//console.loglog("error")
				d3.select(".tibo-schedrepo-reports-spinner").style("display","none") // hidding scheduled reports container spinner
				if(Number(error.status) == 401){ this.errorMsg = "Please try after some time!" }
				else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
				else { this.errorMsg = "Please try after some time." }
				
				this.addSingle();
			}, () => {})
			
		}*/
		console.log(event)
	}

	filterBtnClick(){
		d3.selectAll(".tibo-sche-repos-fltr-multiSelect").selectAll("div.p-component").style("padding","2px 0px 4px 0px")
		d3.selectAll(".tibo-sche-repos-fltr-multiSelect").selectAll("div.p-multiselect-trigger").style("padding","3px 0px")
	}
	
	addSingle() { this.messageService.add({severity:'error', summary:'', detail:this.errorMsg}); }

    clear() { this.messageService.clear(); }
	
}
