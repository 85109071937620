<div class="tibo-myrepoinbox-container-wrapper tibo-right-container">
	<div id="schedrepo-scroll-wrapper" class="tibo-myrepoinbox-scroll-wrapper">
		<div class="tibo-myrepoinbox-tile-list-scrolview-wrapper">
		
			<label>{{selectedOption}}</label> <!--Styling available in .css file-->
			
			<button class="tibo-myrepoinbox-more-btn-icon" type="button" pButton icon="pi pi-ellipsis-h" pTooltip="Options" (click)="moreOptions.toggle($event)"></button>
			<p-menu #moreOptions class="tibo-schedrepo-menu" [model]="moreItems"  [popup]="true" appendTo="body"></p-menu>
			
			<p-toast styleClass="tibo-myrepoinbox-hist-toast" position="center"></p-toast>

			<p-progressSpinner styleClass="tibo-myrepoinbox-reports-spinner custom-spinner" animationDuration="2s"></p-progressSpinner>
			
			<div class="tibo-myrepoinbox-listview-cont"> <!--Children styling available in .css file-->
				<p-table #reportsinboxtable [value]="reportInboxRecords" [sortField]="tableSortColumn" [sortOrder]="tableSortDir" [scrollable]="true" scrollHeight="flex" 
					[globalFilterFields]="['authorizationName','authorizationDesc','source','createdDate','sharedBy']" (sortFunction)="customSort($event)" [customSort]="true">
					
					<ng-template pTemplate="caption">
						<div class="p-d-flex p-jc-between p-ai-center">
							<span class="p-input-icon-left p-ml-auto">
								<i class="pi pi-search"></i>
								<input pInputText type="text" (input)="reportsinboxtable.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" />
							</span>
							<label class="p-mx-2">Showing {{reportInboxRecords?.length}} of {{totalRecords}}</label>
						</div>
					</ng-template>
						
					<ng-template pTemplate="header">
						<tr>
							<th pSortableColumn="authorizationName" (click)="theadClick($event)">
								<div class="p-d-flex p-jc-between p-ai-center">
									File Name
									<p-sortIcon field="authorizationName"></p-sortIcon>
									<p-columnFilter type="text" field="authorizationName" display="menu" class="p-ml-auto"></p-columnFilter>
								</div>
							</th>
							<th pSortableColumn="authorizationDesc" (click)="theadClick($event)">
								<div class="p-d-flex p-jc-between p-ai-center">
									Description
									<p-sortIcon field="authorizationDesc"></p-sortIcon>
									<p-columnFilter type="text" field="authorizationDesc" display="menu" class="p-ml-auto"></p-columnFilter>
								</div>
							</th>
							<th>
								<!--<p-columnFilter type="text" field="source" display="menu" class="p-ml-auto"></p-columnFilter>-->
								<div class="p-d-flex p-jc-between p-ai-center">
									Source
									<p-columnFilter field="filterSource" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" [showApplyButton]="false" [showClearButton]="false" class="p-ml-auto" (click)="filterBtnClick()">
										<ng-template pTemplate="filter" let-value let-filter="filterCallback">
											<p-multiSelect class="tibo-repo-inbox-fltr-multiSelect" styleClass="prime-slider-override" [ngModel]="value" [options]="sources" placeholder="Select Status" optionLabel="filterSource" [filter]="false" (onChange)="filter($event.value)">
												<ng-template let-option>
													<div>
														<span class="p-ml-1">{{option.filterSource}}</span>
													</div>
												</ng-template>
											</p-multiSelect>
										</ng-template>
									</p-columnFilter>
								</div>
							</th>
							<th pSortableColumn="createdDate" (click)="theadClick($event)">
								<div class="p-d-flex p-jc-between p-ai-center">
									Received Date/Time
									<p-sortIcon field="createdDate"></p-sortIcon>
									<p-columnFilter type="date" field="createdDate" display="menu" class="p-ml-auto"></p-columnFilter>
								</div>
							</th>
							<th *ngIf="selectedOption == 'Shared With Me'" pSortableColumn="sharedBy" (click)="theadClick($event)">
								<div class="p-d-flex p-jc-between p-ai-center">
									Shared By
									<p-sortIcon field="sharedBy"></p-sortIcon>
									<p-columnFilter type="text" field="sharedBy" display="menu" class="p-ml-auto"></p-columnFilter>
								</div>
							</th>
							<th>
								<div class="p-d-flex p-jc-between p-ai-center">
									Action
								</div>
							</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-car>
						<tr>
							<td>{{car?.authorizationName}}</td>
							<td>{{car?.authorizationDesc}}</td>
							<td>{{car?.source}}</td>
							<td>{{car?.createdDate | date:'MM/dd/YYYY h:mm a'}}</td>
							<td *ngIf="selectedOption == 'Shared With Me'">{{car?.sharedBy}}</td>
							<td>
								<!--<div class=""  style="width:max-content; min-width:106px; padding:3px 6px 3px 12px; background-color:#e7f0f9; border-radius:20px; box-sizing:border-box;">
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-download" pTooltip="Download" tooltipPosition="top" style="background-color:#5ea7e0; width:30px; height:24px; border:0; border-radius:3px; border-color:#398dce;"></button>
									<button *ngIf="userServiceResp?.canShareFiles.toLowerCase() == 'y'" id="" class="p-mr-2" type="button" pButton icon="pi pi-share-alt" pTooltip="Share" tooltipPosition="top" style="background-color:#5ea7e0; width:26px; height:24px; border:0; border-radius:3px; border-color:#398dce;"></button>
									<button *ngIf="userServiceResp?.canShareFiles.toLowerCase() == 'y'" id="" class="p-mr-2" type="button" pButton icon="pi pi-envelope" pTooltip="Email" tooltipPosition="top" style="background-color:#5ea7e0; width:26px; height:24px; border:0; border-radius:3px; border-color:#398dce;"></button>
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-file" pTooltip="Show all output files" tooltipPosition="top" style="background-color:#5ea7e0; width:26px; height:24px; border:0; border-radius:3px; border-color:#398dce;"></button>
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-trash" pTooltip="Delete" tooltipPosition="top" style="background-color:#5ea7e0; width:26px; height:24px; border:0; border-radius:3px; border-color:#398dce;"></button>
								</div>-->
							</td>
						</tr>
					</ng-template>
					<ng-template pTemplate="emptymessage">
						<tr>
							<td>{{ tableEmptyMessage }}</td>
						</tr>
					</ng-template>
				</p-table>
			</div>
			
			<p-paginator styleClass="tibo-myrepoinbox-listView-p-paginator" [rows]="250" [totalRecords]="totalRecords" (onPageChange)="paginate($event)"></p-paginator>
		
		</div>
	</div>
</div>
