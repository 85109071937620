import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';
import { MenuItem } from 'primeng/api';
import domToImage from 'dom-to-image';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { OverlayPanel } from 'primeng/overlaypanel';
import PptxGenJS from 'pptxgenjs';
declare var d3: any;
declare var acinfotech: any;
let dashListPanelToggleBool: boolean = false;
interface Temp {
  name: string;
  code: string;
}
@Component({
  selector: 'app-customer-experience',
  templateUrl: './customer-experience.component.html',
  styleUrls: ['./customer-experience.component.css']
})
export class CustomerExperienceComponent implements OnInit {
  @ViewChild('op') overlayPanel: OverlayPanel;
  @ViewChild('op1') overlayPanel1: OverlayPanel;
  @ViewChild('op2') overlayPanel2: OverlayPanel;
  @ViewChild('op3') overlayPanel3: OverlayPanel;
  @ViewChild('op4') overlayPanel4: OverlayPanel;
  @ViewChild('op5') overlayPanel5: OverlayPanel;
  @ViewChild('op6') overlayPanel6: OverlayPanel;
  @ViewChild('op7') overlayPanel7: OverlayPanel;
  @ViewChild('op8') overlayPanel8: OverlayPanel;
  @ViewChild('op9') overlayPanel9: OverlayPanel;
  @ViewChild('op10') overlayPanel10: OverlayPanel;
  @ViewChild('op11') overlayPanel11: OverlayPanel;
  @ViewChild('op12') overlayPanel12: OverlayPanel;
  @ViewChild('op13') overlayPanel13: OverlayPanel;
  @ViewChild('op14') overlayPanel14: OverlayPanel;
  @ViewChild('op15') overlayPanel15: OverlayPanel;
  @ViewChild('op16') overlayPanel16: OverlayPanel;
  @ViewChild('op17') overlayPanel17: OverlayPanel;
  wigetBackgroundColor: any = "#d9d9d9";
  @ViewChild('chartContainerDiv', { static: false }) chartContainer: ElementRef;
  @ViewChild('contentToConvert') contentToConvert!: ElementRef;
  chartElement1: any;
  detail1: { data: { YEAR: string; STD: number; VP: number; }[]; title: string; keyLabel: string; keyGroup: string[]; xLable: string; yLable: string; };
  selectedDashboard: any;
  chartElement2: any;
  detail2: { data: { CLIENT: number; YEAR_VALUE: string; AVERAGE_DURATION: number; }[]; title: string; keyLabel: string; keyGroup: string; xLable: string; yLable: string; };
  value: number;
  backColor: any;
  chartElement36: any;
  detail36: any;
  data: { labels: string[]; datasets: { label: string; backgroundColor: string; borderColor: string; data: number[]; }[]; };
  options: { maintainAspectRatio: boolean; aspectRatio: number; plugins: { legend: { labels: { color: string; }; }; }; scales: { x: { ticks: { color: string; font: { weight: number; }; }; grid: { color: string; drawBorder: boolean; }; }; y: { ticks: { color: string; }; grid: { color: string; drawBorder: boolean; }; }; }; };
  basicData: any;
  basicOptions: any;
  inputText: string = "Employee Eservice"
  inputText2: string = " Employee Service Management facilitates seamless digital workflows, including automated onboarding processes, to empower new hires right from day one.to help improve workforce productivity, identify ways to engage and retain talent, and alleviate administrative burdens for HR professionals."
  inputText3: string = "Document Uploaded"
  inputText4: string = "A Document Portal is a really useful platform for you to store, access, send, exchange, sign and collaborate online without any of the documents needing to leave the portal. It can be branded with your logo and integrated directly into your website so it looks great and your clients get a seamless experience.Your customers can log in to review and sign documents, fill in registration forms and upload their own documents. Its a secure and easy way to share information with everything in one place."
  inputText5: string = "Text Sign Up Vs Sent"
  fmlaiInputHeader: string = "FMLA Incidence"
  inputText6: string = "Customer experience encompasses every aspect of a company’s offering—the quality of customer care, of course, but also advertising, packaging, product and service features, ease of use, and reliability. Yet few of the people responsible for those things have given sustained thought to how their separate decisions shape customer experience. To the extent they do think about it, they all have different ideas of what customer experience means, and no one more senior oversees everyone’s efforts."
  customerInputText1: string = "The interactions and experiences your customer has with your business throughout the entire customer journey, from first contact to becoming a happy and loyal customer."
  customerInputHeader: string = " Customer Experience"
  fmlaiInputHeader2 = "FMLA And All Policy Count"
  fmlaiInputText2 = "Customer Experience dashboards are a visual representation of the data,which show employee ,and the experience of the customer .."
  fmlaiInputText1: string = "Customer Experience dashboards are a visual representation of the data,which show employee ,and the experience of the customer .."
  fmlaiInputPara = "Include data like percentages and average.It helps the reader get insight about the topic";
  fmlaiInputHeader3 = "FTE";
  fteInputText = "Management and HR can evaluate projects, job descriptions or really any employment need and assess the number of FTE needed to get the job done..."
  fmlaiInputHeader4 = "Standalone Continuous";
  fmlaiInputText4 = "Customer Experience dashboards are a visual representation of the data,which show employee ,and the experience of the customer .."
  fmlaiReasonHeader = "FMLA Reason";
  fmlaDurationHeader = "FMLA Duration";
  fmladurationInputPara="Eligible employees have the right to use up to 12 workweeks of FMLA leave in a 12-month period, and up to 26 workweeks of leave in a single 12-month period."
  fmlaApprovalRate = "FMLA Approval Rate";
  fmlaInputText5 = "FMLA REASON Current Previous Year Count";
  fmlaInputText55 = "FMLA REASON Previous Year Count";
  leaveInputPara = "60% of all leaves are requested by females";
  leaveinputText2 = "X% both FMLA and All policy Utilization increased over the prior year and has exceeded the incidence cap(be Aware of any)"
  LeaveInputHeader = "Leave of Absence";
  leaveInputText5 = "Why are Employees requesting FMLA?";
  leaveInputText56 = "Why are Employees requesting Leave?";
  leaveInputParaa = "FMLA time used equals 518 full-time employees per day, but all other leaves policy types increase FTE to 938. 85% of FMLA requests are approved and most those that are denied are for ineligibility or failure to provide medical certification. 86% of Non-FML requests are approved. Denial reasons are similar to those for FML. The majority of FML and Non-FML leaves are for an employee's own condition, followed by care of newborn and pregnancy."
  leaverelateInputPara = "The Family and Medical Leave Act (FMLA) provides eligible employees up to 12 workweeks of unpaid leave a year, and requires group health benefits to be maintained during the leave as if employees continued to work instead of taking leave."
  leaverelateInputPara1 = "If an employee does not provide either a complete and sufficient certification or an authorization allowing the health care provider to provide a complete and sufficient certification to the employer, the employee's request for FMLA leave may be denied."
  leaverelateInputPara2 = "A leave of absence (LOA) is unpaid leave that allows an employee to be off work for an extended period of time and return to his/her former position when the leave ends."
  leaverelateInputPara3 = "One of the most common reasons for rejected leave requests is the delicate balance between staffing needs and employee time off. Imagine a department already running on a tight ship; granting another leave request might leave the remaining team members swamped with an overwhelming workload."
  clientAllfmlaiInputText1="This fact sheet explains the four options that employers may use to establish the 12-month period for taking FMLA leave for most leave reasons. "
  clientAllfmlaiInputText2="Employers may select any one of the four methods to establish the 12-month period, so long as they use the same 12-month period for all employees."
  clientAllfmlaiInputText0="Some States have their own family and medical leave laws. Nothing in the FMLA prevents employees from receiving protections under other laws."
  fmlaincidenceInputHeader="Family and Medical Leave Act (FMLA) allows eligible employees working for covered employers to take up to 12 work weeks of unpaid, job-protected leave for certain family and medical reasons."
  fmlareasonInputText4="an employee would be entitled to 12 workweeks of leave during the 12-months beginning on the first date FMLA leave is taken. "
  fmlareasonInputPara="Private-sector employers who employ 50 or more employees in 20 or more workweeks in either the current calendar year or previous calendar year,"
  fmlareasonInputParasec=" Parents may use FMLA leave when their child is born and to bond with their child during the 12-month period beginning on the date of birth."
  l=" A period of 12 consecutive months determined on a rolling basis with a new 12-month period beginning on the first day of each calendar month."
  chartInputText1="How Many requests per 100 employees?";
  chartInputPara="add all FMLA time the employee has used during the previous 12 months and subtract that total from the employee's 12-week leave allotment.";
  chartInputPara1="As per the Act, women working at recognised organisations and factories can take maternity leave for up to 6 months.";
  chartInputPara2="Employees are eligible for leave if they have worked for their employer at least 12 months, at least 1,250 hours over the past 12 months.";
  chartInputPara3="The Family and Medical Leave Act (FMLA) is a labor law requiring employers of a certain size to provide employees with unpaid time off for serious family health issues";
  fmlaiInputText3="FTE stands for full-time equivalent, and it measures how many total full-time employees or part-time employees."
  fteInputText1="This information allows them to forecast a budget for the upcoming year or for a specific project.";
  fteInputText2="FTE is also useful for headcount analysis. Budget analysts can easily compare headcounts to outputs or profits using FTE.";
  fteInputText3="FTE is used to allocate employees to departments, depending on each department’s workload.";
  standaloneInputText1="typically when an employee is absent for three consecutive business days or longer and has been treated by a doctor.";
  chartInputText2="Who is requesting leave?"
  chartInputText3="What is the absence time expressed as a full time equivalency?"
  chartInputText4="What are the FML claim decisions?"
  chartInputText5="Why are FML requests denied?"
  chartInputText6="What are the claim decisions for All Leaves?"
  chartInputText7="Why are employees requesting FML?"
  chartInputText8="Why are employees requesting FML?"
  chartInputText9="Why are employees requesting Leave?";
  chartInputText90="Both FML and All Policy Utilization increased over the prior Year and has Exceeded the incident cap.(Be Aware of any carveouts)";
  chartInputText10="of all leaves requested by females.";
  chartInputHeader1="How Many leave Requests were made?";
  chartInputHeader2="What Was the Average Leave Duration?"
  chartInputHeader3="How Many Continuous leaves did not have STD?"
  chartInputHeader4="Are There any day of the week intermittent absence trends?";
  chartInputText11="The Majority of absences, whether for FML or Non-FML policies are continuous";
  chartInputText12="40% of continuous FML leaves and 49% of continuous Non-FML leaves don not run concurrently with disability benefits." ;
  chartInputText13="an average intermittent absence are slightly higher on Monday and Tuesday than remaining workdays.";
  chartInputText14="FML Time used equals 518 full time employess per day.but all other leaves policy types increase";
  basicdata2: any = {};
  basicdata3: any = {};
  chartOptions2: any;
  basicData4: any = {};
  basicOptions3: any;
  items: MenuItem[];
  moreItems: MenuItem[];
  visibleLeave: boolean = false;
  chartsPage: boolean = false;
  searchText;
  items1: {};
  allColorButton: boolean = false;
  colorOne = ["#FFFF", "#CFE2F3", "#E1CCB6", "#ffd670", "#d9d9d9", "#ebb9b9", "#e1d0e8", "#E3F2FD", "#B2DFDB", "#C8E6C9", "#FFF9C4"];

  colorSetOne = ["#00A9E9", "#BC851C", "#004990", "#87189D", "#C5003E", "#28724F", "#92C1E9", "#CEB888", "#678787", "#B884CB", "#EF6079", "#85B09A"]
  colorSetTwo = ["#E95044", "#F28A4C", "#F0D444", "#A2DF9C", "#93DBE4", "#B091D8", "#DD1D24", "#E35724", "#EFE60C", "#39A654", "#0C9FDC", "#812888"] //third set
  colorSetThree = ["#01204F", "#006A8D", "#03848C", "#64A79D", "#E9CFA1", "#F2A465", "#EF6727", "#F03113", "#E6DCAB", "#dbcb7c", "#DDBD36", "#926A00"]; //yellow
  colorSetFour = ["#f7e37a", "#fbdd38", "#ebc403", "#f8c32a", "#e2a83d", "#f3b064", "#f68933", "#f5636d", "#ff4e5b", "#e42f24", "#bc2025", "#c02024"]; //yellow to red	
  colorSetFive = ["#012c66", "#86bedf", "#cfe8f7", "#cb2624", "#e34746", "#f47473", "#046365", "#13a1a4", "#68dddb", "#e7c414", "#ffe242", "#ffe87b"]; //Neon
  colorSetSix = ["#6E2C00", "#873600", "#A04000", "#BA4A00", "#D35400", "#DC7633", "#E59866", "#EDBB99", "#F6DDCC", "#FBEEE6", "#fff2eb", "#fff7f2"];// 6 cube set brown
  colorSetSeven = ["#002E55", "#014C7C", "#005B7C", "#008098", "#0E89B5", "#0695AA", "#35B5C1", "#397C82", "#87C9CF", "#ACCBC6", "#C0E0DB", "#E4EBEA"]; //Brown color set
  colorSetEight = ["#3d818c", "#63adb3", "#95cdcf", "#b9d0d0", "#cfe6e6", "#e5eeee", "#eeebe3", "#c3bca1", "#c3bca1", "#c4ad91", "#b89f87", "#a08d76"]; //4 cube set

  selected = {
    color: '#3580db'
  }


  chartOptions: any;
  recentNavBool = false;
  dashboardName: any;
  customerExperience: boolean = false;
  fmlaPage: boolean = false;
  fmlachartOptions: any;
  fmlabasicdata: any;
  horizontalOptions: any;
  horizontalBasicData: any;
  fmlaDurationbasicData: any;
  fmlaDurationbasicOptions: any;
  visible: boolean = false;
  iconsArray = ["pi pi-android", "pi pi-apple", "pi pi-amazon", "pi pi-calendar", "pi pi-bars", "pi pi-percentage", "pi pi-microsoft", "pi pi-compass", "pi pi-cog", "pi pi-chart-line", "pi pi-thumbs-up", "pi pi-thumbs-down", "pi pi-table", "pi pi-facebook",
    "pi pi-clock", "pi pi-desktop", "pi pi-file", "pi pi-th-large", "pi pi-lock", "pi pi-wifi"
  ]
  iconsArrayLeave = ["fa fa-male", "fa fa-female", "pi pi-android", "pi pi-apple", "pi pi-amazon", "pi pi-calendar", "pi pi-bars", "pi pi-percentage", "pi pi-microsoft", "pi pi-compass", "pi pi-cog", "pi pi-chart-line", "pi pi-thumbs-up", "pi pi-thumbs-down", "pi pi-table", "pi pi-facebook",
    "pi pi-clock", "pi pi-desktop", "pi pi-file", "pi pi-th-large", "pi pi-lock", "pi pi-wifi"
  ]
  iconsArray2 = ["pi pi-mobile", "pi pi-apple", "pi pi-amazon", "pi pi-calendar", "pi pi-bars", "pi pi-percentage", "pi pi-microsoft", "pi pi-compass", "pi pi-cog", "pi pi-chart-line", "pi pi-thumbs-up", "pi pi-thumbs-down", "pi pi-table", "pi pi-facebook",
    "pi pi-clock", "pi pi-desktop", "pi pi-file", "pi pi-th-large", "pi pi-lock", "pi pi-wifi"
  ]
  selectedIcon: string = 'pi pi-android';
  selectedIcon1: string;
  activeString: string = 'pi pi-android';
  activeString2: string = 'pi pi-mobile';
  activeString3: any;
  selectedIcon2: string = 'pi pi-mobile';
  selectedIconLeave1: string = 'fa fa-male';
  selectedIconLeave2: string = 'fa fa-female';
  selectedIconLeave3: string = 'fa fa-male';
  selectedIconLeave4: string = 'fa fa-female';
  visible2: boolean = false;
  menuVisible: boolean = false;
  typeOfGraph: string = 'bar';
  basicDataa: any;
  piedata: any;
  piechartOptions: any;
  leavePage: boolean = true;
  leavebasicData: any;
  leavebasicOptions: any
  leavebasicDataset: any;
  leavehorizontalOptions: any
  leavedonutbasicdata: any;
  leavedonutchartOptions: any
  basicData45: any;
  orderOfIconLeave: any;
  selectedIconForLeave: any;
  selectedIconForLeave1: any;
  selectedIconForLeave2: any;
  selectedIconForLeave3: any;
  selectedIconForLeave4: any;
  menuVisible1: boolean = false;
  leaveLinebasicdata: any;
  typeOfGraph1: string = "horizontalBar";
  leavePolarAreachartOptions: any;
  leavePolarAreadata: any;
  Leavepiedata: any;
  leavepiechartOptions: any;
  basicData11: any;
  DownloadItems: MenuItem[];
  templates: Temp[] | undefined;
  selectTemplate: any = '';
  templ3basicData: any;
  templ3basicOptions: any;
  public barChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
    { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
  ];
  templ3basicData1: any;
  templ3basicOptions1: any;
  multiAxisOptions: any;
  temp3groupedbasicData: any;
  temp3groupedbasicOptions: any;
  temp3piedata: any;
  temp3piechartOptions: any;
  temp3piedata1: any;
  temp3piechartOptions1: any;
  temp3piedata2: any;
  temp3piechartOptions2: any;
  temp3basicData: any;
  temp3basicOptions: any;
  chartpage2basicData: any;
  chartpage2basicOptions: any;
  templ3basicData4: any;
  templ3basicOptions4: any;
  templ3basicData3: any;
  templ3basicOptions3: any;
  chartLinebasicDataa: any;
  chartLinebasicOptions: any;
  chartpage2basicOptions2: any;
  chartpage2basicData2: any
  showEditor: boolean=false;
  data1: any='';
  constructor() { }

  ngOnInit(): void {
    this.initialServiceCalls()
    d3.select('.tibo-topbar-title').text('Home / Infograph'); // setting title


    this.DownloadItems = [
      {
        label: 'PDF View',
        icon: 'pi pi-file-pdf',
        id: '',
        command: () => {
          this.downloadCharts();
        },
      },
      {
        label: 'PNG Image',
        icon: 'pi pi-image',
        id: '',
        command: () => {
          this.downloadPNG();
        },
      },
      {
        label: 'JPG Image',
        icon: 'pi pi-image',
        id: '',
        command: () => {
          this.downloadJPEG();
        },
      },
      {
        label: 'PPTX',
        icon: 'pi pi-image',
        id: '',
        command: () => {
          this.downloadPpt();
        },
      },
    ];
    this.templates = [
      { name: 'Template1', code: 'NY' },
      // { name: 'Template2', code: 'RM' },
      { name: 'Template2', code: 'LDN' }
    ];
    this.basicData = {
      labels: ['Jan', 'Feb', 'Mar', 'Apr',
        'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [
        {
          label: 'sales',
          backgroundColor: '#01204f',
          data: [49, 66, 81, 71, 75, 65, 60, 81, 71, 58, 65, 60]
        }
      ]
    };
    this.basicData11 = {
      labels: ['Jan', 'Feb', 'Mar', 'Apr',
        'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [
        {
          label: 'sales',
          backgroundColor: '#01204f',
          data: [49, 66, 81, 71, 75, 65, 60, 81, 71, 58, 65, 60]
        }
      ]
    };
    this.basicOptions = {
      legend: { display: false },
      plugins: {
        legend: {
          labels: {
            display: false
          }

        }
      },
      scales: {
        x: {
          ticks: {
            color: '#black'
          },
          grid: {
            display: false
          }
        },
        xAxes: [{
          gridLines: {
            display: false
          }
        }],
        // yAxes:[{
        //   gridLines:{
        //     display:false
        //   }
        // }],
        y: {
          ticks: {
            color: '#black'
          },
          grid: {
            display: false
          }
        }
      }
    }

    this.multiAxisOptions = {
      legend: { display: false },
      plugins: {
        legend: {
          labels: {
            display: false
          }

        }
      },
      scales: {
        x: {
          ticks: {
            color: '#black'
          },
          grid: {
            display: false
          }
        },
        xAxes: [{
          gridLines: {
            display: false
          }
        }],
        // yAxes:[{
        //   gridLines:{
        //     display:false
        //   }
        // }],
        y: {
          ticks: {
            color: '#black'
          },
          grid: {
            display: false
          }
        }
      }
    }

    this.basicdata2 = {
      labels: ['Mobile', 'Phone', 'IVR'],
      datasets: [
        {
          data: [10, 70, 20],
          backgroundColor: ['blue', '#01204f', '#4996a2'],
          hoverBackgroundColor: ['#04AA6D', '#4b5889'],
        }
      ]
    };
    this.chartOptions = {
      cutout: '60%',
      responsive: false,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle' // Set legend markers as circles
        }
      }
    };

    this.fmlabasicdata = {
      labels: ['Mobile', 'Phone'],
      datasets: [
        {
          data: [10, 60],
          backgroundColor: ['#4996a2', '#01204f'],
          hoverBackgroundColor: ['#04AA6D', '#4b5889'],
        }
      ]
    };
    this.fmlachartOptions = {
      cutout: '60%',
      responsive: false,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        display: false,
        labels: {
          usePointStyle: true,
          pointStyle: 'circle' // Set legend markers as circles
        }
      }
    };
    this.basicdata3 = {
      labels: ['Jan', 'Feb', 'Mar', 'Apr',
        'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [
        {
          label: '',
          data: [65, 59, 80, 81, 56, 55, 40, 59, 80, 81, 56, 55, 40],
          fill: false,
          tension: .1,
          borderColor: '#01204f'
        },
      ]
    };
    this.basicDataa = {
      labels: ['Jan', 'Feb', 'Mar', 'Apr',
        'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [
        {
          label: 'sales',
          backgroundColor: '#01204f',
          data: [49, 66, 81, 71, 75, 65, 60, 81, 71, 58, 65, 60],
          fill: false,
          tension: .1,
          borderColor: '#01204f'
        }
      ]
    };

    this.chartOptions2 = {
      legend: { display: false },
      plugins: {
        legend: {
          labels: {
            color: '#000'
          }
        }
      },
      scales: {
        xAxes: [{
          gridLines: {
            display: false
          }
        }],

      }
    }
    this.basicData4 = {
      labels: ['Jan', 'Feb', 'Mar', 'Apr',
        'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [
        {
          label: '',
          backgroundColor: '#01204f',
          data: [15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15,]

        },
        {
          label: '',
          backgroundColor: '#4996a2',
          data: [66, 49, 81, 71, 26, 65, 60, 49, 81, 71, 26, 65, 60]
        }
      ]
    };

    this.basicOptions3 = {
      legend: { display: false },
      plugins: {
        legend: {
          labels: {
            color: '#black'
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#black'
          },

          grid: {
            color: 'rgba(255,255,255,0.2)',
            display: false
          }
        },
        xAxes: [{
          gridLines: {
            display: false
          }
        }],
        y: {
          ticks: {
            color: '#black'
          },
          grid: {
            color: 'rgba(255,255,255,0.2)',
            display: false
          }
        }
      }
    }
    this.horizontalBasicData = {
      labels:
        ['Standalone Continuous All policy', 'Standalone Continuous FMLA'],
      datasets: [
        {
          label: 'Booked',
          backgroundColor: '#01204f',
          data: [750, 175]
        },

      ]
    };
    this.horizontalOptions = {
      indexAxis: 'y',
      legend: { display: false },
      plugins: {
        legend: {
          labels: {
            color: '#black'
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#black'
          },
          grid: {
            display: false
          }
        },
        // xAxes:[{
        //   gridLines:{
        //     display:false
        //   }
        // }], 
        y: {
          ticks: {
            color: '#black'
          },
          grid: {
            display: false
          }
        },
        yAxes: [{
          gridLines: {
            display: false
          },
          barPercentage: 1
        }],
      }
    };
    this.fmlaDurationbasicData = {
      labels: ['2023', '2024'],
      datasets: [
        {
          label: '',
          backgroundColor: '#01204f',
          data: [32, 15],
        },
        {
          label: '',
          backgroundColor: '#4996a2',
          data: [30, 8]
        }
      ]
    };

    this.fmlaDurationbasicOptions = {
      legend: { display: false },
      plugins: {
        legend: {
          labels: {
            color: '#black'
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#black'
          },

          grid: {
            color: 'rgba(255,255,255,0.2)',
            display: false,
          }
        },
        xAxes: [{
          gridLines: {
            display: false
          },
        }],
        y: {
          ticks: {
            color: '#black'
          },
          grid: {
            color: 'rgba(255,255,255,0.2)',
            display: false
          }
        }
      },
    }
    this.piedata = {
      labels: ['Jan', 'Feb', 'Mar', 'Apr',
        'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [
        {
          data: [49, 66, 81, 71, 75, 65, 60, 81, 71, 58, 65, 60],
          backgroundColor: [
            "#01204F", "#006A8D", "#03848C", "#64A79D", "#E9CFA1", "#F2A465", "#EF6727", "#F03113", "#E6DCAB", "#dbcb7c", "#DDBD36", "#926A00"

          ],
          hoverBackgroundColor: [
            "#64B5F6",
            "#81C784",
            "#FFB74D",
            'pink'
          ]

        }
      ]
    };

    this.piechartOptions = {
      legend: { display: false },
      plugins: {
        legend: {
          labels: {
            color: '#495057'
          }
        }
      }
    }
    this.leavebasicData = {
      labels: ['FMLA Prior Year', 'FMLA Current Year', 'All Policy Prior Year', 'All Policy Current Year',
        'BOB(SIC)', 'IB(SIC)'],
      datasets: [
        {
          label: 'sales',
          backgroundColor: '#01204f',
          data: [9, 20, 30, 40, 30, 50, 40]
        }
      ]
    };

    this.leavebasicOptions = {
      legend: { display: false },
      plugins: {
        legend: {
          labels: {
            display: false
          }

        }
      },
      scales: {
        x: {
          ticks: {
            color: '#black'
          },
          grid: {
            display: false
          }
        },
        xAxes: [{
          // gridLines:{
          //   display:false
          // }
        }],
        yAxes: [{
          gridLines: {
            display: false
          }
        }],
        y: {
          ticks: {
            color: '#black'
          },
          grid: {
            display: false
          }
        }
      }
    }
    this.leavebasicDataset = {
      labels: ['FMLA', 'All Policy'],
      datasets: [
        {
          backgroundColor: '#01204f',
          data: [28, 49]
        },
        {
          backgroundColor: '#4996a2',
          data: [36, 69]
        },

      ]
    };
    this.leavehorizontalOptions = {
      legend: { display: false },
      indexAxis: 'y',
      plugins: {
        legend: {
          labels: {
            color: '#black'
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#black'
          },
          grid: {
            color: 'rgba(255,255,255,0.2)'
          }
        },
        yAxes: [{
          gridLines: {
            display: false
          }
        }],
        y: {
          ticks: {
            color: '#black'
          },
          grid: {
            color: 'rgba(255,255,255,0.2)'
          }
        }
      }
    };
    this.leavedonutbasicdata = {
      labels: ['Mobile', 'Phone', 'android', 'tab', 'iPad'],
      datasets: [
        {
          data: [10, 60, 15, 20, 25],
          backgroundColor: ["#01204F", "#006A8D", "#03848C", "#64A79D", "#E9CFA1"],
          hoverBackgroundColor: ['#04AA6D', '#4b5889'],
        }
      ]
    };
    this.leavedonutchartOptions = {
      cutout: '60%',
      responsive: false,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        display: false,
        labels: {
          usePointStyle: true,
          pointStyle: 'circle' // Set legend markers as circles
        }
      }
    };
    this.basicData45 = {
      labels: ['New Born', 'Own', 'Pregnancy', 'Parent', 'Other'],
      datasets: [
        {
          label: '',
          backgroundColor: '#01204f',
          data: [16, 15, 18, 20, 15]

        },
        {
          label: '',
          backgroundColor: '#4996a2',
          data: [66, 49, 81, 71, 26]
        }
      ]
    };
    this.leaveLinebasicdata = {
      labels: ['FMLA Prior Year', 'FMLA Current Year', 'All Policy Prior Year', 'All Policy Current Year',
        'BOB(SIC)', 'IB(SIC)'],
      datasets: [
        {
          label: '',
          data: [9, 20, 30, 40, 30, 50, 40],
          fill: false,
          tension: .1,
          borderColor: '#01204f'
        },
      ]
    };
    this.leavePolarAreadata = {
      datasets: [{
        data: [9, 20, 30, 40, 30, 50],
        backgroundColor: [
          "#01204F", "#006A8D", "#03848C", "#64A79D", "#E9CFA1", "#F2A465"
        ],
      },

      ],
      labels: ['FMLA Prior Year', 'FMLA Current Year', 'All Policy Prior Year', 'All Policy Current Year',
        'BOB(SIC)', 'IB(SIC)'],
    };

    this.leavePolarAreachartOptions = {
      legend: { display: false },
      plugins: {
        legend: {
          labels: {
            color: '#495057'
          }
        }
      },
      scales: {
        r: {
          grid: {
            color: '#ebedef'
          }
        }
      }
    }
    this.Leavepiedata = {
      labels: ['FMLA Prior Year', 'FMLA Current Year', 'All Policy Prior Year', 'All Policy Current Year',
        'BOB(SIC)', 'IB(SIC)'],
      datasets: [
        {
          data: [9, 20, 30, 40, 30, 50],
          backgroundColor: [
            "#01204F", "#006A8D", "#03848C", "#64A79D", "#E9CFA1", "#F2A465"
          ],
          hoverBackgroundColor: [
            "#64B5F6",
            "#81C784",
            "#FFB74D",
            'pink'
          ]

        }
      ]
    };

    this.leavepiechartOptions = {
      legend: { display: false },
      plugins: {
        legend: {
          labels: {
            color: '#495057'
          }
        }
      }
    }
    this.templ3basicData = { 
      labels: ['FMLA Prior', 'FMLA Current ','BOB(SIC)', 'IB(SIC)', 'All Policy Prior', 'All Policy Current ','incidence cap'
      ], 
      datasets: [ 
          { 
              label: 'Booked', 
              backgroundColor: ['#00a9e9','#004890','#bc851c','#87179c','#00a9e9','#004890','#c4003d'],
              data: [8, 6, 9, 8, 7, 5, 7] 
          }, 

      ] 
  }; 
  this.templ3basicOptions = { 
    legend: { display: false },
      plugins: {
        legend: {
          labels: {
            display: false
          }

        }
      },
      
      
    scales: {  
      xAxes: [{
        align: 'start', // Adjust alignment of y-axis labels ('start' aligns them at the beginning)
        font: {
            size: 10 // Adjust font size of y-axis labels
        },
        gridLines: {
          display: false
        },
        ticks: {
            minRotation: 0
          }
      }],
      yAxes: [{
        display:false,
        gridLines: {
          display: false
        },
        ticks: {
          beginAtZero: true,  // Start y-axis at zero
          stepSize: 5,        // Increment y-axis by 5
          suggestedMin: 0,    // Minimum value for y-axis
          suggestedMax: 15    // Maximum value for y-axis (adjust based on your data range)
        }
      }],    
        x: { 
            ticks: { 
                color: '#black',
            }, 
            grid: { 
                color: 'rgba(255,255,255,0.2)'
            } 
        }, 
        y: { 
            ticks: { 
                color: '#black',
            }, 
            grid: { 
                color: 'rgba(255,255,255,0.2)'
            } 
        } 
    } 
};
this.templ3basicData1 = { 
  labels: [ 
      "FML Utilization", 
      "Headcount", 
  ], 
  datasets: [ 
      { 
          label: "Male", 
          backgroundColor: "#00a9e9", 
          data: [66, 66],
          barThickness: 26,
          barPercentage: 0.5,
          categoryPercentage: 0.2,
      }, 
      { 
          label: "Female", 
          backgroundColor: "#bc851c", 
          data: [49, 49],
          barThickness: 26,
          barPercentage: 0.5,
          categoryPercentage: 0.2, 
      }, 
  ] 
}; 
this.templ3basicOptions1 = { 
  legend: { position: 'bottom'},
  usePointStyle:true,
  indexAxis: "y", 
  plugins: { 
      legend: { 
        position: 'bottom',
          labels: { 
              color: "#black"
          } 
      } 
  }, 
  scales: {
    xAxes: [{
      stacked: true,
      display:false,
      gridLines: {
        display: false
      }
    }],
    yAxes: [{
      stacked: true,
      gridLines: {
        display: false
      }
    }],
  } 
}; 
this.temp3groupedbasicData = {
  labels: ['FML', 'All Policy'],
  datasets: [
    {
      label: 'Prior Year',
      backgroundColor: '#00a9e9',
      data: [49, 55],
      barThickness: 36,
      barPercentage: 1.5, 

    },
    {
      label: 'Current Year',
      backgroundColor: '#004890',
      data: [66, 76],
      barThickness: 36,
      barPercentage: 1.5, 
    }
  ]
};
this.temp3groupedbasicOptions = {
  legend: { position: 'bottom'},
  usePointStyle:true,
  plugins: {
    legend: {
      labels: {
        color: '#black'
      }
    }
  },
  scales: {  
    xAxes: [{
      align: 'start', // Adjust alignment of y-axis labels ('start' aligns them at the beginning)
      font: {
          size: 10 // Adjust font size of y-axis labels
      },
      gridLines: {
        display: false
      }
    }],
    yAxes: [{
      display:false,
      gridLines: {
        display: false
      },
      ticks: {
        beginAtZero: true,  // Start y-axis at zero
        stepSize: 5,        // Increment y-axis by 5
        suggestedMin: 0,    // Minimum value for y-axis
        suggestedMax: 15    // Maximum value for y-axis (adjust based on your data range)
      }
    }],    
      x: { 
          ticks: { 
              color: '#black',
          }, 
          grid: { 
              color: 'rgba(255,255,255,0.2)'
          } 
      }, 
      y: { 
          ticks: { 
              color: '#black',
          }, 
          grid: { 
              color: 'rgba(255,255,255,0.2)'
          } 
      } 
  } 
}
this.temp3piedata = {
  labels: ['Denied', 'Approved'],
  datasets: [
    {
      data: [17, 79],
      backgroundColor: [
        "#004890","#00a9e9"],
      hoverBackgroundColor: [
        "#64B5F6",
        "#81C784",
        "#FFB74D",
        'pink'
      ]

    }
  ]
};

this.temp3piechartOptions = {
  cutout: '60%',
  responsive: false,
  maintainAspectRatio: false,
  legend: {
    position: 'bottom',
    labels: {
      usePointStyle: true,
      pointStyle: 'circle' // Set legend markers as circles
    }
  }
}
this.temp3piedata1 = {
  labels: ['Ineligible', 'No Cert Returned','Exhausted','Reason not Covered','Other'],
  datasets: [
    {
      data: [5,6,6,21,62],
      backgroundColor: ['#c4003d','#004890','#bc851c','#87179c','#00a9e9'],
      hoverBackgroundColor: [
        "#64B5F6",
        "#81C784",
        "#FFB74D",
        'pink'
      ]

    }
  ]
};

this.temp3piechartOptions1 = {
  cutout: '60%',
  responsive: false,
  maintainAspectRatio: false,
  legend: {
    position: 'right',
    labels: {
      usePointStyle: true,
      pointStyle: 'circle' // Set legend markers as circles
    }
  }
}
this.temp3piedata2 = {
  labels: ['Denied', 'Approved'],
  datasets: [
    {
      data: [18,77],
      backgroundColor: [
        "#004890","#00a9e9"],
      hoverBackgroundColor: [
        "#64B5F6",
        "#81C784",
        "#FFB74D",
        'pink'
      ]

    }
  ]
};

this.temp3piechartOptions2 = {
  cutout: '60%',
  responsive: false,
  maintainAspectRatio: false,
  legend: {
    position: 'bottom',
    labels: {
      usePointStyle: true,
      pointStyle: 'rect' // Set legend markers as circles
    }
  }
}
this.temp3basicData = {
  labels: ['New Born', 'Own', 'Pregnancy', 'Parent', 'Other'],
  datasets: [
    {
      label: 'Prior Year',
      backgroundColor: '#00a9e9',
      data: [18, 13, 9, 4, 6],
      barThickness: 36,
      barPercentage: 0.5,

    },
    {
      label: 'Current Year',
      backgroundColor: '#004890',
      data: [19, 14, 4, 6, 7],
      barThickness: 36,
      barPercentage: 0.5,
    }
  ]
};
this.temp3basicOptions = {
  legend: { position: 'bottom'},
  usePointStyle:true,
  plugins: {
    legend: {
      labels: {
        color: '#black'
      }
    }
  },
  scales: {  
    xAxes: [{
      align: 'start', // Adjust alignment of y-axis labels ('start' aligns them at the beginning)
      font: {
          size: 10 // Adjust font size of y-axis labels
      },
      gridLines: {
        display: false
      },
      ticks: {
          minRotation: 0
        }
    }],
    yAxes: [{
      display:false,
      gridLines: {
        display: false
      },
      ticks: {
        // beginAtZero: true,  // Start y-axis at zero
        stepSize: 10,        // Increment y-axis by 5
        suggestedMin: 0,    // Minimum value for y-axis
        suggestedMax: 15    // Maximum value for y-axis (adjust based on your data range)
      }
    }],    
      x: { 
          ticks: { 
              color: '#black',
          }, 
          grid: { 
              color: 'rgba(255,255,255,0.2)'
          } 
      }, 
      y: { 
          ticks: { 
              color: '#black',
          }, 
          grid: { 
              color: 'rgba(255,255,255,0.2)'
          } 
      } 
  } 
}
this.templ3basicData4 = { 
  labels: ['SUNDAY', 'MONDAY ','TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY ','SATURADY'
  ], 
  datasets: [ 
      { 
          label: 'Booked', 
          backgroundColor: ['#00a9e9','#004890','#bc851c','#87179c','#00a9e9','#004890','#c4003d'],
          data: [8, 6, 9, 8, 7, 5, 7] 
      }, 

  ] 
}; 
this.templ3basicOptions4 = { 
legend: { display: false },
  plugins: {
    legend: {
      labels: {
        display: false
      }

    }
  },
  
  
scales: {  
  xAxes: [{
    align: 'start', // Adjust alignment of y-axis labels ('start' aligns them at the beginning)
    font: {
        size: 10 // Adjust font size of y-axis labels
    },
    gridLines: {
      display: false
    },
    ticks: {
        minRotation: 0
      }
  }],
  yAxes: [{
    display:false,
    gridLines: {
      display: false
    },
    ticks: {
      beginAtZero: true,  // Start y-axis at zero
      stepSize: 5,        // Increment y-axis by 5
      suggestedMin: 0,    // Minimum value for y-axis
      suggestedMax: 20    // Maximum value for y-axis (adjust based on your data range)
    }
  }],    
    x: { 
        ticks: { 
            color: '#black',
        }, 
        grid: { 
            color: 'rgba(255,255,255,0.2)'
        } 
    }, 
    y: { 
        ticks: { 
            color: '#black',
        }, 
        grid: { 
            color: 'rgba(255,255,255,0.2)'
        } 
    } 
} 
};
this.templ3basicData3 = { 
  labels: ['FML', 'All Policy '], 
  datasets: [ 
      { 
          label: 'Booked', 
          backgroundColor: ['#00a9e9','#004890'],
          data: [200,164],
          barThickness:50,
          barPercentage: .5,  
      }, 

  ] 
}; 
this.templ3basicOptions3 = { 
legend: { display: false },
  plugins: {
    legend: {
      labels: {
        display: false
      }

    }
  },
  
  
scales: {  
  xAxes: [{
    align: 'start', // Adjust alignment of y-axis labels ('start' aligns them at the beginning)
    font: {
        size: 10 // Adjust font size of y-axis labels
    },
    gridLines: {
      display: false
    },
    ticks: {
        minRotation: 0
      }
  }],
  yAxes: [{
    display:false,
    gridLines: {
      display: false
    },
    ticks: {
      beginAtZero: true,  // Start y-axis at zero
      stepSize: 5,        // Increment y-axis by 5
      suggestedMin: 0,    // Minimum value for y-axis
      suggestedMax: 20   // Maximum value for y-axis (adjust based on your data range)
    }
  }],    
    x: { 
        ticks: { 
            color: '#black',
        }, 
        grid: { 
            color: 'rgba(255,255,255,0.2)'
        } 
    }, 
    y: { 
        ticks: { 
            color: '#black',
        }, 
        grid: { 
            color: 'rgba(255,255,255,0.2)'
        } 
    } 
} 
};
this.chartLinebasicDataa = {
  labels: ['FMLA Prior', 'FMLA Current ','BOB(SIC)', 'IB(SIC)', 'All Policy Prior', 'All Policy Current ','incidence cap'
],
  datasets: [
    {
      label: 'sales',
      backgroundColor: ['#00a9e9','#004890','#bc851c','#87179c','#00a9e9','#004890','#c4003d'],
      data: [8, 6, 9, 8, 7, 5, 7] ,
      fill: false,
      tension: .1,
      borderColor: '#01204f'
    }
  ]
};
this.chartLinebasicOptions = {
  legend: { display: false },
  plugins: {
    legend: {
      labels: {
        display: false
      }

    }
  },
  scales: {
    x: {
      ticks: {
        color: '#black'
      },
      grid: {
        display: false
      }
    },
    xAxes: [{
      gridLines: {
        display: false
      }
    }],
    // yAxes:[{
    //   gridLines:{
    //     display:false
    //   }
    // }],
    y: {
      ticks: {
        color: '#black'
      },
      grid: {
        display: false
      }
    }
  }
}
this.chartpage2basicData = {
  labels: ['FML', 'All Policy'],
  datasets: [
    {
      label: 'Prior Year',
      backgroundColor: '#00a9e9',
      data: [49, 55],
      // barThickness: 35,
      // barPercentage: .5, 

    },
    {
      label: 'Current Year',
      backgroundColor: '#004890',
      data: [66, 76],
      // barThickness: 35,
      // barPercentage: .5, 
    }
  ]
};
this.chartpage2basicOptions = {
  legend: { display: false},
  usePointStyle:true,
  plugins: {
    legend: {
      labels: {
        color: '#black'
      }
    }
  },
  scales: {  
    xAxes: [{
      align: 'start', // Adjust alignment of y-axis labels ('start' aligns them at the beginning)
      font: {
          size: 10 // Adjust font size of y-axis labels
      },
      gridLines: {
        display: false
      }
    }],
    yAxes: [{
      display:false,
      gridLines: {
        display: false
      },
      ticks: {
        beginAtZero: true,  // Start y-axis at zero
        stepSize: 5,        // Increment y-axis by 5
        suggestedMin: 0,    // Minimum value for y-axis
        suggestedMax: 20    // Maximum value for y-axis (adjust based on your data range)
      }
    }],    
      x: { 
          ticks: { 
              color: '#black',
          }, 
          grid: { 
              color: 'rgba(255,255,255,0.2)'
          } 
      }, 
      y: { 
          ticks: { 
              color: '#black',
          }, 
          grid: { 
              color: 'rgba(255,255,255,0.2)'
          } 
      } 
  } 
}
this.chartpage2basicData2 = {
  labels: ['FML', 'All Policy'],
  datasets: [
    {
      label: 'Prior Year',
      backgroundColor: '#00a9e9',
      data: [2, 2],
   

    },
    {
      label: 'Current Year',
      backgroundColor: '#004890',
      data: [4, 4],
     
    }
  ]
};
this.chartpage2basicOptions2 = {
  legend: { display: false},
  usePointStyle:true,
  plugins: {
    legend: {
      labels: {
        color: '#black'
      }
    }
  },
  scales: {  
    xAxes: [{
      align: 'start', // Adjust alignment of y-axis labels ('start' aligns them at the beginning)
      font: {
          size: 10 // Adjust font size of y-axis labels
      },
      gridLines: {
        display: false
      }
    }],
    yAxes: [{
      display:false,
      gridLines: {
        display: false
      },
      ticks: {
        beginAtZero: true,  // Start y-axis at zero
        stepSize: 5,        // Increment y-axis by 5
        suggestedMin: 0,    // Minimum value for y-axis
        suggestedMax: 15    // Maximum value for y-axis (adjust based on your data range)
      }
    }],    
      x: { 
          ticks: { 
              color: '#black',
          }, 
          grid: { 
              color: 'rgba(255,255,255,0.2)'
          } 
      }, 
      y: { 
          ticks: { 
              color: '#black',
          }, 
          grid: { 
              color: 'rgba(255,255,255,0.2)'
          } 
      } 
  } 
}
  }
  initialServiceCalls() {
    this.items1 = [
      {
        "label": "KPI",
        "items": [
          {
            "label": "Customer Experience",
            "id": 2019,
            command: (event) => { this.changeDashboardFunc("Customer Experience"); }
          },
        ]
      },
      {
        "label": "Leave",
        "items": [
          {
            "label": "Leave of Absence",
            "id": 2020,
            command: (event) => { this.changeDashboardFunc("Leave"); }
          },
          // {
          //   "label": "FMLA",
          //   "id": 2019,
          //   command: (event) => { this.changeDashboardFunc("FMLA"); }
          // }
        ]
      },
    ]
    if (!this.recentNavBool) {
      this.selectedDashboard = "Leave of Absence";
    }
    this.dashboardName = this.selectedDashboard;
  }
  changeDashboardFunc(event) {
    console.log("eve", event)
    this.selectedDashboard = event;
    if (this.selectedDashboard == "Customer Experience") {
      this.customerExperience = true;
      this.fmlaPage = false;
      this.leavePage = false;
    }
    else if (this.selectedDashboard == "FMLA") {
      this.customerExperience = false;
      this.fmlaPage = true;
      this.leavePage = false;
    }
    else if (this.selectedDashboard == "Leave") {
      this.leavePage = true;
      this.customerExperience = false;
      this.fmlaPage = false;
    }
    d3.select("#tibo-mydash-dashlist-container-id").style("display", "none")
    d3.select("#tibo-mydash-dashlist-container").style("display", "none")
    dashListPanelToggleBool = false;

  }
  dashboardListFunc() {
    d3.select("#tibo-mydash-dashlist-container-id").style("display") == "none" ? d3.select("#tibo-mydash-dashlist-container-id").style("display", "block") : d3.select("#tibo-mydash-dashlist-container-id").style("display", "none")
    d3.select("#tibo-mydash-dashlist-container-id").select("p-panelmenu").selectAll("div > div.p-panelmenu-header > a.p-panelmenu-header-link").attr("non-hide", "non-hide");
    d3.select("#tibo-mydash-dashlist-container-id").select("p-panelmenu").selectAll("div > div.p-panelmenu-header > a.p-panelmenu-header-link > span").attr("non-hide", "non-hide");

  }

  visitedDashFunc(dashboardName) {
    this.selectedDashboard = dashboardName;
  }
  addMoreIcons() {
    this.visible = true;
  }
  applyIcon(icon: string) {
    this.selectedIcon1 = icon;
    // Store the selected icon class
  }

  onApply() {
    // Perform actions with the selected icon (e.g., save to a model, update UI, etc.)
    console.log('Selected Icon:', this.selectedIcon);
    this.selectedIcon = this.selectedIcon1;
    this.visible = false; // Hide the dialog after applying
  }
  activefun(icon: string) {
    this.activeString = icon;
    console.log(this.activeString)
  }

  addMoreIcons2() {
    this.visible2 = true;
  }
  applyIcon2(icon: string) {
    this.selectedIcon1 = icon;
    // Store the selected icon class
  }

  onApply2() {
    // Perform actions with the selected icon (e.g., save to a model, update UI, etc.)
    console.log('Selected Icon:', this.selectedIcon);
    this.selectedIcon2 = this.selectedIcon1;
    this.visible2 = false; // Hide the dialog after applying
  }
  activefun2(icon: string) {
    this.activeString2 = icon;
    console.log(this.activeString2)
  }
  toggleMenu() {
    this.menuVisible = !this.menuVisible;
  }
  onGraph(type: string) {
    this.typeOfGraph = type; // Update the chart type
    console.log(this.typeOfGraph);
  }

  //leave icon methods
  addMoreIconsForLeave(value) {
    this.visibleLeave = true;
    this.orderOfIconLeave = value;
  }
  applyIconForLeave(icon) {
    if (this.orderOfIconLeave == 'first') {
      this.selectedIconForLeave1 = icon;
    }
    if (this.orderOfIconLeave == 'second') {
      this.selectedIconForLeave2 = icon;
    }
    if (this.orderOfIconLeave == 'third') {
      this.selectedIconForLeave3 = icon;
    }
    if (this.orderOfIconLeave == 'forth') {
      this.selectedIconForLeave4 = icon;
    }
  }
  activefunForLeave(icon) {
    this.activeString3 = icon;
  }
  onApplyForLeave() {
    if (this.orderOfIconLeave == 'first') {
      this.selectedIconLeave1 = this.selectedIconForLeave1;
    }
    if (this.orderOfIconLeave == 'second') {
      this.selectedIconLeave2 = this.selectedIconForLeave2;
    }
    if (this.orderOfIconLeave == 'third') {
      this.selectedIconLeave3 = this.selectedIconForLeave3;
    }
    if (this.orderOfIconLeave == 'forth') {
      this.selectedIconLeave4 = this.selectedIconForLeave4;
    }
    this.visibleLeave = false;
  }
  toggleMenu1() {
    this.menuVisible1 = !this.menuVisible1;
  }
  onGraph1(type: string) {
    this.typeOfGraph1 = type; // Update the chart type
    console.log(this.typeOfGraph1);
  }

  downloadCharts2() {
    const element = document.getElementById('chartscontainerid');
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    const totalHeight = element.scrollHeight + 610;
    // Temporarily hide the scrollbar
    element.style.overflow = 'hidden';
    setTimeout(() => {
      domToImage.toPng(element, { height: totalHeight, width: element.scrollWidth })
        .then((dataUrl) => {
          // Restore the scrollbar visibility
          element.style.overflow = '';

          // Create a PDF and add the captured image
          const pdf = new jsPDF('p', 'mm', 'a3');
          const img = new Image();

          img.onload = () => {
            // Calculate the aspect ratio to adjust the height proportionally
            const aspectRatio = img.width / img.height;

            // Calculate the width and height of the image on the PDF
            const pdfImageWidth = 570; // Adjust as needed
            const pdfImageHeight = pdfImageWidth / aspectRatio;

            // Add the image to the PDF with the calculated dimensions
            pdf.addImage(img, 'PNG', 0, 0, pdfImageWidth, pdfImageHeight);

            // Save the PDF
            pdf.save('dashboard.pdf');
          };

          img.src = dataUrl;
        });
    }, 500);
  }
  downloadCharts1() {
    const element = document.getElementById('chartscontainerid');
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    const totalHeight = element.scrollHeight + 270;
    // Temporarily hide the scrollbar
    document.getElementById('chartscontainerid').style.overflow = 'scroll';
    setTimeout(() => {
      domToImage.toPng(element, { height: totalHeight, width: element.scrollWidth })
        .then((dataUrl) => {
          // Restore the scrollbar visibility
          element.style.overflow = '';

          // Create a PDF and add the captured image
          const pdf = new jsPDF('p', 'mm', 'a4');
          const img = new Image();

          img.onload = () => {
            // Calculate the aspect ratio to adjust the height proportionally
            const aspectRatio = (img.width / img.height) + 0.05;
            const imgWidth = 200;
            const pageHeight = 310;
            const imgHeight = ((img.height * imgWidth) / img.width) + 25;
            // Calculate the width and height of the image on the PDF
            const pdfImageWidth = 180; // Adjust as needed
            const pdfImageHeight = imgWidth / aspectRatio;
            let heightLeft = pdfImageHeight;
            // Add the image to the PDF with the calculated dimensions
            let position = 16;
            pdf.addImage(img, 'PNG', 20, 25, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
              position += heightLeft - imgHeight; // top padding for other pages
              pdf.addPage();
              pdf.addImage(img, 'PNG', 20, position, imgWidth, imgHeight);
              heightLeft -= pageHeight;
            }
            // Save the PDF
            pdf.save('dashboard.pdf');
          };

          img.src = dataUrl;
        });
    }, 500);
  }
  downloadPNG() {
    setTimeout(() => {
      let data = document.getElementById('chartscontainerid');
      html2canvas(data, {
        scale: 2,
        // onclone: (cloneDoc) => {
        //   const title = cloneDoc.getElementById('title-dashboard')!.style;
        //   title.color = '#1976D2';
        //   title.fontSize = '21px';
        //   title.marginTop = '10px';
        // },
      }).then((canvas) => {
        var a = document.createElement('a');
        a.href = canvas.toDataURL('image/png');
        a.download = this.dashboardName + '.png';
        a.click();
      });
      //   let element = document.getElementById('chartContent');

      // html2canvas(element,).then(function(canvas) {
      //     // Convert the canvas to blob
      //     canvas.toBlob(function(blob){
      //         let link = document.createElement("a");
      //         link.download = "image.png";
      //         link.href = URL.createObjectURL(blob);
      //         link.click();
      //         window.saveAs(blob, 'image.png');

      //     },'image/png');
      //     this.downloadSpinner = 'pi-download';
      // });
    }, 400);
  }
  downloadJPEG() {
    setTimeout(() => {
      let data = document.getElementById('chartscontainerid');
      html2canvas(data, {
        scale: 2,
        // onclone: (cloneDoc) => {
        //   const title = cloneDoc.getElementById('title-dashboard')!.style;
        //   title.color = '#1976D2';
        //   title.fontSize = '21px';
        //   title.marginTop = '10px';
        // },
      }).then((canvas) => {
        var a = document.createElement('a');
        a.href = canvas.toDataURL('image/jpeg');
        a.download = this.dashboardName + '.jpeg';
        a.click();
      });
    }, 400);
  }
  downloadCharts() {
    const element = document.getElementById('chartscontainerid');
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    const totalHeight = element.scrollHeight + 70;
    // Temporarily hide the scrollbar
    element.style.overflow = 'hidden';
    setTimeout(() => {
      domToImage.toPng(element, { height: totalHeight, width: element.scrollWidth })
        .then((dataUrl) => {
          // Restore the scrollbar visibility
          element.style.overflow = '';

          // Create a PDF and add the captured image
          const pdf = new jsPDF('p', 'mm', 'a4');
          const img = new Image();

          img.onload = () => {
            // Calculate the aspect ratio to adjust the height proportionally
            const aspectRatio = img.width / img.height;

            // Calculate the width and height of the image on the PDF
            const pdfImageWidth = 210; // Adjust as needed
            const pdfImageHeight = pdfImageWidth / aspectRatio;

            // Add the image to the PDF with the calculated dimensions
            pdf.addImage(img, 'PNG', 0, 0, pdfImageWidth, pdfImageHeight);
            pdf.addPage();

            // Save the PDF
            pdf.save('dashboard.pdf');
          };

          img.src = dataUrl;
        });
    }, 500);
  }

  onTemplateChange(event) {
    console.log()
    if (this.selectTemplate.name == 'Template3') {
      this.leavePage = false;
      this.fmlaPage = true;
      this.chartsPage = false;
    } else if (this.selectTemplate.name == 'Template1') {
      this.leavePage = true;
      this.fmlaPage = false;
      this.chartsPage = false;
    }
    else if (this.selectTemplate.name == 'Template2') {
      this.leavePage = false;
      this.fmlaPage = false;
      this.chartsPage = true;
    }
  }
  async downloadPpt(): Promise<void> {
    const pptx = new PptxGenJS();

    // Capture chartPage1 as an image
    const chartPage1 = document.querySelector('.chartPage1') as HTMLElement;
    const chartPage1Image = await this.captureElementAsImage(chartPage1);
    
    // Capture chartPage2 as an image
    const chartPage2 = document.querySelector('.chartPage2') as HTMLElement;
    const chartPage2Image = await this.captureElementAsImage(chartPage2);

    // Add the images to slides
    let slide1 = pptx.addSlide();
    slide1.addImage({ data: chartPage1Image, x: 0, y: 0, w: '100%', h: '100%' });

    let slide2 = pptx.addSlide();
    slide2.addImage({ data: chartPage2Image, x: 0, y: 0, w: '100%', h: '100%' });

    // Download the pptx file
    pptx.writeFile({ fileName: 'charts.pptx' });
  }
  async captureElementAsImage(element: HTMLElement): Promise<string> {
    const canvas = await html2canvas(element,{
      allowTaint: false,
      useCORS: false,
      scale: 2,
    });
    return canvas.toDataURL('image/png');
  }
openEditor(event,over,data): void {
  this.data1='';
  over.toggle(event);
  this.data1=data;
}
saveText(over): void {
  over.hide();
}
cancelEdit(over): void {
  over.hide();
  this.chartInputText1=this.data1;
}
openEditor1(event,over,data): void {
  this.data1='';
  over.toggle(event);
  this.data1=data;
}
saveText1(over): void {
  over.hide();
}
cancelEdit1(over): void {
  over.hide();
  this.chartInputText2=this.data1;
}
openEditor2(event,over,data): void {
  this.data1='';
  over.toggle(event);
  this.data1=data;
}
saveText2(over): void {
  over.hide();
}
cancelEdit2(over): void {
  over.hide();
  this.chartInputText3=this.data1;
}
openEditor3(event,over,data): void {
  this.data1='';
  over.toggle(event);
  this.data1=data;
}
saveText3(over): void {
  over.hide();
}
cancelEdit3(over): void {
  over.hide();
  this.chartInputText4=this.data1;
}
openEditor4(event,over,data): void {
  this.data1='';
  over.toggle(event);
  this.data1=data;
}
saveText4(over): void {
  over.hide();
}
cancelEdit4(over): void {
  over.hide();
  this.chartInputText5=this.data1;
}
openEditor5(event,over,data): void {
  this.data1='';
  over.toggle(event);
  this.data1=data;
}
saveText5(over): void {
  over.hide();
}
cancelEdit5(over): void {
  over.hide();
  this.chartInputText6=this.data1;
}
openEditor6(event,over,data): void {
  this.data1='';
  over.toggle(event);
  this.data1=data;
}
saveText6(over): void {
  over.hide();
}
cancelEdit6(over): void {
  over.hide();
  this.chartInputText7=this.data1;
}
openEditor7(event,over,data): void {
  this.data1='';
  over.toggle(event);
  this.data1=data;
}
saveText7(over): void {
  over.hide();
}
cancelEdit7(over): void {
  over.hide();
  this.chartInputText8=this.data1;
}
openEditor8(event,over,data): void {
  this.data1='';
  over.toggle(event);
  this.data1=data;
}
saveText8(over): void {
  over.hide();
}
cancelEdit8(over): void {
  over.hide();
  this.chartInputText9=this.data1;
}
openEditor9(event,over,data): void {
  this.data1='';
  over.toggle(event);
  this.data1=data;
}
saveText9(over): void {
  over.hide();
}
cancelEdit9(over): void {
  over.hide();
  this.chartInputText90=this.data1;
}
openEditor10(event,over,data): void {
  this.data1='';
  over.toggle(event);
  this.data1=data;
}
saveText10(over): void {
  over.hide();
}
cancelEdit10(over): void {
  over.hide();
  this.chartInputHeader1=this.data1;
}
openEditor11(event,over,data): void {
  this.data1='';
  over.toggle(event);
  this.data1=data;
}
saveText11(over): void {
  over.hide();
}
cancelEdit11(over): void {
  over.hide();
  this.chartInputHeader2=this.data1;
}
openEditor12(event,over,data): void {
  this.data1='';
  over.toggle(event);
  this.data1=data;
}
saveText12(over): void {
  over.hide();
}
cancelEdit12(over): void {
  over.hide();
  this.chartInputHeader3=this.data1;
}
openEditor13(event,over,data): void {
  this.data1='';
  over.toggle(event);
  this.data1=data;
}
saveText13(over): void {
  over.hide();
}
cancelEdit13(over): void {
  over.hide();
  this.chartInputHeader4=this.data1;
}
openEditor14(event,over,data): void {
  this.data1='';
  over.toggle(event);
  this.data1=data;
}
saveText14(over): void {
  over.hide();
}
cancelEdit14(over): void {
  over.hide();
  this.chartInputText11=this.data1;
}
openEditor15(event,over,data): void {
  this.data1='';
  over.toggle(event);
  this.data1=data;
}
saveText15(over): void {
  over.hide();
}
cancelEdit15(over): void {
  over.hide();
  this.chartInputText12=this.data1;
}
openEditor16(event,over,data): void {
  this.data1='';
  over.toggle(event);
  this.data1=data;
}
saveText16(over): void {
  over.hide();
}
cancelEdit16(over): void {
  over.hide();
  this.chartInputText13=this.data1;
}
openEditor17(event,over,data): void {
  this.data1='';
  over.toggle(event);
  this.data1=data;
}
saveText17(over): void {
  over.hide();
}
cancelEdit17(over): void {
  over.hide();
  this.chartInputText14=this.data1;
}
}