<!-- <div class="content" [ngClass]="{ 'sidebarExpanded': sidebarExpanded }"> -->
    <div class="main-body" [ngClass]="{'main-body-2':!show}">
        <app-login *ngIf="!show" (boolVal)="enterAppFunc($event)"></app-login>
        ​
            <div class="internal-body-one"  *ngIf="show">
                <app-menu-panel [isExpanded]="sidebarExpanded" (toggleSidebar)="sidebarExpanded = !sidebarExpanded"
                    *ngIf="show"></app-menu-panel>
            </div>
            <div class="internal-body-two"  *ngIf="show">
                <app-top-bar *ngIf="show"></app-top-bar>
                <router-outlet></router-outlet>
            </div>
        </div>