import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Message,MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginService } from './login.service';
import * as CryptoJS from 'crypto-js';

import { Router } from "@angular/router";
import {MenuItem} from 'primeng/api';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css'],
	providers: [LoginService, MessageService]
})

export class LoginComponent implements OnInit {
	
	loginUsername = "";
	loginPassword = "";
	isLoading = false;
	loginServiceSubcribition: Subscription; 
	userServiceSubcribtion: Subscription;
	configServiceSubcribtion: Subscription;
	errorMsg = "";
	
	constructor(private messageService: MessageService, private http: HttpClient, private loginService: LoginService, private router: Router) { }

	ngOnInit(): void { }
	
	ngOnDestroy() {
		if(this.loginServiceSubcribition){ this.loginServiceSubcribition.unsubscribe(); }
		if(this.userServiceSubcribtion){ this.userServiceSubcribtion.unsubscribe(); }
    }
	
	onLoginButtonClick(){
		
		if((this.loginUsername == "") || (this.loginPassword == "")){
			this.router.navigate(['./home']);
			this.boolVal.emit(true)
			
			// this.errorMsg = "Please enter Username & Password!"
			// this.addSingle()
		} 
		// else {
			
		// 	this.isLoading = true; // showing login button loading spinner
			
		// 	var salt = CryptoJS.lib.WordArray.random(128/8);
		// 	var iv = CryptoJS.lib.WordArray.random(128/8); 
		// 	var key = CryptoJS.lib.WordArray.random(128/8); 
		// 	var key128Bits100Iterations = CryptoJS.PBKDF2( key.toString(), salt, { keySize: 128/32, iterations: 100 });
		// 	var encrypted = CryptoJS.AES.encrypt(this.loginPassword, key128Bits100Iterations, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7  });
				
		// 	var data = {
		// 			"username": this.loginUsername,
		// 			"encryptedData": encrypted.toString(),
		// 			"salt": salt.toString(),
		// 			"iv": iv.toString(),
		// 			"key": key.toString()
		// 		};

			// this.loginServiceSubcribition = this.loginService.tokenService(data).subscribe( (response: any) => {
				
			// 	if(typeof(Storage) !== "undefined") {
			// 		localStorage.setItem("token", response?.token);
			// 		//this.router.navigate(['./main-container']);
			// 		//this.router.navigate(['./home']);
			// 	}
				
			// 	//this.boolVal.emit(true);
				
			// }, error => { 
			// 	if(Number(error.status) == 401){ this.errorMsg = "Please check Username & Password!" }
			// 	else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
			// 	else { this.errorMsg = "Please try after some time." }
				
			// 	this.addSingle()
			// },
			// () => {
			// 	this.userServiceSubcribtion = this.loginService.user().subscribe( (userRes: any) => {
					
			// 		//this.isLoading = false; // hiding login button loading spinner
					
			// 		if(typeof(Storage) !== "undefined") {
			// 			localStorage.setItem("userEmail", userRes.body?.emailId);
			// 			localStorage.setItem("userName", userRes.body?.userName);
			// 			//this.router.navigate(['./home']);
			// 		}
					
			// 		//this.boolVal.emit(true);
					
			// 	}, error => { 
			// 		if(Number(error.status) == 401){ this.errorMsg = "Please check Username & Password!" }
			// 		else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
			// 		else { this.errorMsg = "Please try after some time." }
					
			// 		this.addSingle()
			// 	},
			// 	() => {
			// 		this.configServiceSubcribtion = this.loginService.config().subscribe( (configRes: any) => {
					
			// 			this.isLoading = false; // hiding login button loading spinner
						
			// 			if(typeof(Storage) !== "undefined") {
			// 				localStorage.setItem("menuItem", configRes.body?.appLandingPage);
			// 			}
						
			// 			if(configRes.body?.appLandingPage == "LandingPage"){
			// 				this.router.navigate(['./home']);
			// 			}
			// 			else if(configRes.body?.appLandingPage == "myDashboard"){
			// 				this.router.navigate(['./my-dashboard']);
			// 			}
			// 			else if(configRes.body?.appLandingPage == "myFavorites"){
			// 				this.router.navigate(['./my-favorites']);
			// 			}
			// 			else if(configRes.body?.appLandingPage == "myReports"){
			// 				this.router.navigate(['./my-reports']);
			// 			}
			// 			else if(configRes.body?.appLandingPage == "myInbox"){
			// 				this.router.navigate(['./my-report-inbox']);
			// 			}
			// 			else if(configRes.body?.appLandingPage == "myScheduledReports"){
			// 				this.router.navigate(['./scheduled-reports']);
			// 			}

			// 			this.boolVal.emit(true);

			// 		}, error => { 
			// 			if(Number(error.status) == 401){ this.errorMsg = "Please check Username & Password!" }
			// 			else if(Number(error.status) == 500){ this.errorMsg = "Internal error" }
			// 			else { this.errorMsg = "Please try after some time." }
						
			// 			this.addSingle()
			// 		})
			// 	})
			// })
			
		// }
	}
	
	addSingle() { 
		this.messageService.add({severity:'error', summary:'', detail:this.errorMsg}); 
		this.isLoading = false;
	}

    clear() { this.messageService.clear(); }

	@Output() boolVal = new EventEmitter<any>();
	
}


