 <!-- <p-toast styleClass="tibo-myrepo-toast" position="center"></p-toast> -->
	<!-- <p-progressSpinner styleClass="tibo-mydash-drillthru-spinner custom-spinner" animationDuration="2s"></p-progressSpinner> -->

	<div class="tibo-mydash-drill-thru-widget" style="position:relative; left:0px; top:5%; width:100%; height:95%; background-color:white;" (click)="chartsDivContainerClick($event)" >
        <div class="container"  *ngIf="this.showGrid">
            <div class="drpFilterChkbox" style="float:right">
                    <button type="button" class="drpButton" (click)="showdropdown=!showdropdown"><i class="pi pi-eye" pTooltip="show/hide column" tooltipPosition="top"></i></button>
                    <button class="drpButton" (click)="Refresh(coldata )"><i class="pi pi-refresh" pTooltip="Refresh" tooltipPosition="top"></i></button>
                    <button class="drpButton" (click)="showmenu=!showmenu"><i class="pi pi-download" pTooltip="Download" tooltipPosition="top"></i></button>
                    <span class="p-input-icon-right">
                        <i class="pi pi-search"></i>
                        <input type="text" pInputText [(ngModel)]="value"  placeholder="Search"/>
                    </span>
                    <div *ngIf="this.showdropdown" class="DropMenus" >
                        <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Search...">
                        <div class="dropContainer" *ngFor="let d of coldata  |filter: searchText ">
                        <input type="checkbox"  name="{{d.field}}" (change)="binSelect($event,d)" [checked]="d.checked" >{{d.headerName}}<br></div></div>
                        <div *ngIf="showmenu" class="downloadmenu">
                            <button type="button" class="downloadall" (click)="download1()" >Download All</button><br>
                            <button type="button" class="downloadall" (click)="download2()">Download filtered grid</button>
                </div>
            </div>
            <div class="aggriid" >
                <ag-grid-angular 
                style="width: 100%; height:545px;clear: both;overflow:scroll"
                class="ag-theme-alpine"
                [rowData]="rowData |filter: value" 
                [columnDefs]="columnDefs "
                [defaultColDef]="defaultColDef"
                [enableFilter]="true"
                [pagination]="true"
                [gridOptions]="gridOptions"
                [setQuickFilter]="quickFilterText" 
                (gridReady)="onGridReady($event)"
                >
            </ag-grid-angular>
            </div>
            </div>
        </div>
        <!-- <p-paginator #pagenoindicator styleClass="tibo-drillthru-model-table-pagin" [rows]="250" [totalRecords]="totalRecords" (onPageChange)="paginate($event)"></p-paginator> -->