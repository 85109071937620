<!-- <div class="container-fluid login-parent-container">
	<div class="p-d-flex image-p-grid">
		<div class="p-col-8 img-container">
			<img src="../../assets/images/New TB logo 2.png" alt="" class="time-bound-logo">
			<img src="../../assets/images/TimeBound_logo.svg" alt="" class="time-bound-login-page-image">
		</div>
		<div class="p-col-4">
			<div class="container form-container">
				<div class="p-grid">
					<div class="p-col-10 welcome-heading-class">
						<h2 class="heading1-login-form">Welcome To</h2>
						<h1 class="heading2-login-form">TimeBound</h1>
					</div>
					<div class="p-col-10 form-box">
						<h1 class="heading3-login-form">Sign In</h1>
						<div class="p-col-12">
							<label class="form-input-label">Username</label>
							<span class="p-input-icon-left p-col-12">
								<i class="pi pi-user"></i>
								<input class="p-col-12 p-py-3 form-input" type="text"
									placeholder="Enter the Username" [(ngModel)]="loginUsername" pInputText />
							</span>
						</div>
						<div class="p-col-12 p-mb-3">
							<label class="form-input-label">Password</label>
							<span class="p-input-icon-left p-col-12">
								<i class="pi pi-key"></i>
								<input class="p-col-12 p-py-3 form-input"
									(keyup.enter)="onLoginButtonClick()" type="password"
									placeholder="Enter the Password" [(ngModel)]="loginPassword" pInputText />
							</span>
						</div>
						<div class="p-col-12 p-px-3">
							<button class="p-col-12 tibo-login-loginbutton" pButton label="Log In" iconPos="right"
								loadingIcon="pi pi-spinner pi-spin" [loading]="isLoading"
								(click)="onLoginButtonClick()"></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
 -->
<!-- seocond design -->
<div class="container-fluid login-parent-container">

	<div class="p-d-flex image-p-grid">
		<div class="p-col-8 img-container">
			<div class="p-col-12">
				<img src="../../assets/images/New TB logo 2.png" alt="" class="time-bound-logo">
			</div>
			<div class="p-col-9 p-offset-1">
				<!-- <img src="../../assets/images/mindpro img 3.svg" alt="" class="time-bound-login-page-image"> -->
				<img src="../../assets/images/yogaladdy_oct9.svg" alt="" class="time-bound-login-page-image2">
			
			</div>
		</div>
		<div class="p-col-4">
			<div class="container form-container">
				<div class="p-grid">
					<div class="p-col-10 welcome-heading-class">
						<h2 class="heading1-login-form">Welcome To</h2>
						<h1 class="heading2-login-form">TimeBound</h1>
					</div>
					<div class="p-col-10 form-box">
						<h1 class="heading3-login-form">Sign In</h1>
						<div class="p-col-12">
							<label class="form-input-label">Username</label>
							<span class="p-input-icon-left p-col-12">
								<i class="pi pi-user"></i>
								<input class="p-col-12 p-py-3 form-input" type="text"
									placeholder="Enter the Username" [(ngModel)]="loginUsername" pInputText />
							</span>
						</div>
						<div class="p-col-12 p-mb-3">
							<label class="form-input-label">Password</label>
							<span class="p-input-icon-left p-col-12">
								<i class="pi pi-key"></i>
								<input class="p-col-12 p-py-3 form-input"
									(keyup.enter)="onLoginButtonClick()" type="password"
									placeholder="Enter the Password" [(ngModel)]="loginPassword" pInputText />
							</span>
						</div>
						<div class="p-col-12 p-px-3">
							<button class="p-col-12 tibo-login-loginbutton" pButton label="Log In" iconPos="right"
								loadingIcon="pi pi-spinner pi-spin" [loading]="isLoading"
								(click)="onLoginButtonClick()"></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
