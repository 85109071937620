<div class="tibo-data-disco-container-wrapper tibo-right-container">

	<div id="data-disco-scroll-wrapper" class="tibo-data-disco-scroll-wrapper">
	
		<div id="data-discovery-search-from-panel" class="tibo-data-disco-folderview-panel">
		
			<span id="search-from-id" class="tibo-data-disco-folderview-panel-title">Search</span>
			
			<button type="button" class="tibo-data-disco-folderview-panel-arrow-icon" (click)="searchFromPanelToggleFunc()"></button>
			
			<div id="data-discovery-search-from-panel-params-container" class="tibo-data-disco-folderview-panel-params-container">
			
				<!--<div class="tibo-data-disco-search-text-field-container">
					<input type="text" id="data-disco-business-metric-text-id" class="tibo-data-disco-param-inputtext" value="New claims"
						placeholder="Enter business metric name">
						<button pButton type="button" icon="pi pi-search" iconPos="center" id="data-discovery-submitbutton-id" class="tibo-data-disco-submitbtn" (click)="onSubmitClick()"></button>
				</div>-->
				
				<div class="tibo-data-disco-domains-dropdown-container">
					<span class="tibo-data-disco-domains-param-title">Select Domain <span class="tibo-data-disco-params-title-mand">*</span>
					</span>
					<p-dropdown styleClass="tibo-mydash-paramdash-param-dropdown"
						#usermenu
						appendTo="body"
						[options]="domainsArray"
						optionLabel="authorizationName"
						optionValue="authorizationId"
						placeholder="Select a domain"
						(onChange)="onChange($event)"
						#refEl>
					</p-dropdown>
					
				</div>
				
				<div class="tibo-data-disco-domains-dropdown-container">
					<span class="tibo-data-disco-domains-param-title">Business Metric or Keyword <span class="tibo-data-disco-params-title-mand">*</span>
					</span>
					<input type="text" id="data-disco-business-metric-text-id" class="tibo-data-disco-param-inputtext" 
						placeholder="Enter business metric name">
				</div>
				
				<!--<div class="tibo-data-disco-domains-dropdown-container">
					<span class="tibo-data-disco-domains-param-title">Business Metric or Keyword <span class="tibo-data-disco-domains-param-title-mand">*</span>
					</span>
					<input type="text" id="data-disco-business-metric-text-id" class="tibo-data-disco-param-inputtext" 
						placeholder="Enter business metric name">
						<button pButton type="button" icon="pi pi-search" iconPos="center" id="data-discovery-submitbutton-id" class="tibo-data-disco-submitbtn" (click)="onSubmitClick()"></button>
				</div>-->
				
				<!--<div class="tibo-data-disco-domains-dropdown-container" style="height:90%;">
					<p-scrollPanel [style]="{'position':'absolute','left':'0px','width':'100%','top':'0px','height':'100%','background-color':'#f1f1f4','padding':'0px'}">
						
						<div class="tibo-data-disco-domains-dropdown-container" style=" margin: 10px 5% 10px 5%; width:90%; border:1px solid #e1e1e1;">
							<div class="p-grid p-col-12  p-ai-center vertical-container"  style="background-color:#aecfe9; box-sizing:border-box;  margin-left:0; height:30%;">
								<label style="color:#fbfdff; font-family:Roboto; font-size:14px;">Disability</label>
								<label style="position:absolute; margin-left:22%; padding:1px 4px; background-color:white; border-radius:25px; color:#5ea7e0; font-family:Roboto; font-size:14px;">7</label>
							</div>
							
							<div class="p-grid p-col-12  p-ai-center vertical-container p-m-0"  style="position:absolute; background-color:#aecfe9; box-sizing:border-box;  margin-left:0; height:74%;">
								<p-button  class="p-mr-1 abc" type="button" label="Dashboards" badge="2" ></p-button>
								<p-button  class="p-mr-1" type="button" label="Reports" badge="2" ></p-button>
								<p-button  class="p-mr-1" type="button" label="Widgets" badge="3" ></p-button>
							</div>
							
						</div>
						
						<div class="tibo-data-disco-domains-dropdown-container" style=" margin: 10px 5% 10px 5%; width:90%;">
							<div class="p-grid p-col-12  p-ai-center vertical-container"  style="background-color:#4994d0; box-sizing:border-box;  margin-left:0; height:30%;">
								<label style="color:#fbfdff; font-family:Roboto; font-size:14px;">My Favorites</label>
								<label style="position:absolute; margin-left:30%; padding:1px 4px; background-color:white; border-radius:20px; color:#5ea7e0; font-family:Roboto; font-size:14px;">6</label>
							</div>
							
							<div class="p-grid p-col-12  p-ai-center vertical-container p-m-0"  style="position:absolute; background-color:#5ea7e0; box-sizing:border-box;  margin-left:0; height:74%;">
								<p-button  class="p-mr-1 abc" type="button" label="Dashboards" badge="3" ></p-button>
								<p-button  class="p-mr-1" type="button" label="Reports" badge="2" ></p-button>
								<p-button  class="p-mr-1" type="button" label="Widgets" badge="1" ></p-button>
							</div>
							
						</div>
						
						<div class="tibo-data-disco-domains-dropdown-container" style=" margin: 10px 5% 10px 5%; width:90%;">
							<div class="p-grid p-col-12  p-ai-center vertical-container"  style="background-color:#4994d0; box-sizing:border-box;  margin-left:0; height:30%;">
								<label style="color:#fbfdff; font-family:Roboto; font-size:14px;">Leave</label>
								<label style="position:absolute; margin-left:15%; padding:1px 4px; background-color:white; border-radius:20px; color:#5ea7e0; font-family:Roboto; font-size:14px;">4</label>
							</div>
							
							<div class="p-grid p-col-12  p-ai-center vertical-container p-m-0"  style="position:absolute; background-color:#5ea7e0; box-sizing:border-box;  margin-left:0; height:74%;">
								<p-button  class="p-mr-1 abc" type="button" label="Dashboards" badge="1" ></p-button>
								<p-button  class="p-mr-1" type="button" label="Reports" badge="1" ></p-button>
								<p-button  class="p-mr-1" type="button" label="Widgets" badge="2" ></p-button>
							</div>
							
						</div>
						
						<div class="tibo-data-disco-domains-dropdown-container" style="min-height:45px; margin: 0 5% 10px 5%; width:90%;">
							<div class="p-grid p-col-11  p-ai-center vertical-container"  style="background-color:#4994d0; box-sizing:border-box;  margin:10px; height:100%;">
								<label style="color:#fbfdff; font-family:Roboto; font-size:14px;">Workers Compensation</label>
								<label style="margin-left:auto; color:#5ea9f3; font-family:Roboto; font-size:12px; background-color:white; border-radius:25px; width:20px; text-align:center;">4</label>
							</div>
							
						</div>
						<div class="tibo-data-disco-domains-dropdown-container" style="min-height:45px; margin: 0 5% 10px 5%; width:90%;">
							<div class="p-grid p-col-11  p-ai-center vertical-container"  style="background-color:#6ab3ed; box-sizing:border-box;  margin:10px; height:100%;">
								<label style="color:#fbfdff; font-family:Roboto; font-size:15px;">Disability</label>
								<label style="margin-left:auto; color:#5ea9f3; font-family:Roboto; font-size:12px; background-color:white; border-radius:25px; width:20px; text-align:center;">3</label>
							</div>
						</div>
						<div class="tibo-data-disco-domains-dropdown-container" style="min-height:45px; margin: 0 5% 10px 5%; width:90%;">
							<div class="p-grid p-col-11  p-ai-center vertical-container"  style="background-color:#4994d0; box-sizing:border-box;  margin:10px; height:100%;">
								<label style="color:#fbfdff; font-family:Roboto; font-size:15px;">Leave of Absence</label>
								<label style="margin-left:auto; color:#5ea9f3; font-family:Roboto; font-size:12px; background-color:white; border-radius:25px; width:20px; text-align:center;">5</label>
							</div>
						</div>
						<div class="tibo-data-disco-domains-dropdown-container" style="min-height:45px; margin: 0 5% 10px 5%; width:90%;">
							<div class="p-grid p-col-11  p-ai-center vertical-container"  style="background-color:#4994d0; box-sizing:border-box;  margin:10px; height:100%;">
								<label style="color:#fbfdff; font-family:Roboto; font-size:15px;">ADA</label>
								<label style="margin-left:auto; color:#5ea9f3; font-family:Roboto; font-size:12px; background-color:white; border-radius:25px; width:20px; text-align:center;">9</label>
							</div>
						</div>
						<div class="tibo-data-disco-domains-dropdown-container" style="min-height:45px; margin: 0 5% 10px 5%; width:90%;">
							<div class="p-grid p-col-11  p-ai-center vertical-container"  style="background-color:#4994d0; box-sizing:border-box;  margin:10px; height:100%;">
								<label style="color:#fbfdff; font-family:Roboto; font-size:15px;">Voluntary</label>
								<label style="margin-left:auto; color:#5ea9f3; font-family:Roboto; font-size:12px; background-color:white; border-radius:25px; width:20px; text-align:center;">3</label>
							</div>
						</div>
					</p-scrollPanel>
				</div>-->
			</div>
			<button pButton label="Submit" type="button" id="data-discovery-submitbutton-id" class="tibo-data-disco-submitbtn" (click)="onSubmitClick()"></button>
		</div> <!-- left panel -->
		
		<div id="data-disco-list-scroll-view" class="tibo-data-disco-list-scrolview-wrapper">
			
			<!--<div class="p-grid p-col-12 p-ai-center vertical-container" style="background-color: #f1f1f4; box-sizing: border-box; margin: 0px; left: 0px; top: 0px; height: 30px;">
				<label style="color: #0f528c; font-family: Roboto; font-size: 14px;">Dashboards</label>
				<label style="margin-left:45%; color: #0f528c; font-family: Roboto; font-size: 14px;">Disability</label>
			</div>-->
			
			<div class="my-reports-tile-view" style="position:absolute; left:0px; width:100%; top:35px; height:calc(100% - 85px); background-color:#f1f1f4;">
				
				<p-scrollPanel [style]="{'position':'absolute','left':'0px','width':'100%','top':'0px','height':'100%','background-color':'#f1f1f4','padding':'0px'}">
					<div class="p-grid p-nogutter">
						<div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-4" style="border:0px solid green;  box-sizing:border-box; min-width:0px; height:180px; margin:0px;"
						*ngFor="let obj of repomatricAndWidgetsArray; let j = index;">
						
							<div class=""  style="border:px solid #eaeaea; box-sizing:border-box;  height:90%; margin:3.5%; background-color:white;">
								<div class="p-grid p-col-12  p-ai-center vertical-container"  style="background-color:#ffffff; box-sizing:border-box;  margin-left:0; height:30%;">
									<label style="color:#0f528c; font-family:Roboto; font-size:15px;">{{obj?.name}}</label>
								</div>
								<div class="p-grid p-col-12  p-ai-center vertical-container"  style="background-color:#ffffff; box-sizing:border-box;  margin-left:0; height:28%;">
									<label *ngIf="obj?.type.toLowerCase()=='widget'" style="color:#0f528c; font-family:Roboto; font-size:0.764rem;">Dashboard Name: {{obj?.matricOrDashboarName}}</label>
									<label *ngIf="obj?.type.toLowerCase()!='widget'" style="color:#0f528c; font-family:Roboto; font-size:0.764rem;">Metric Name: {{obj?.matricOrDashboarName}}</label>
								</div>
								<div class="p-grid p-col-12  p-ai-center vertical-container"  style="background-color:#ffffff; box-sizing:border-box;  margin-left:0; height:25%; ">
									<label style="color:#0f528c; font-family:Roboto; font-size:0.764rem;">Type: {{obj?.type}}</label>
									<!--<label *ngIf="obj?.type.toLowerCase()=='widget'" style="margin-left:auto; color:#0f528c; font-family:Roboto; font-size:0.764rem;">Chart: {{obj?.chart}}</label>-->
								</div>
								<div *ngIf="obj?.type.toLowerCase()=='dashboards' || obj?.type.toLowerCase()=='widget' || obj?.type.toLowerCase()=='reports'" class="p-col-12"  style="background-color:#e7f0f9; box-sizing:border-box; width:max-content; height:25%; border-top-right-radius:25px; border-bottom-right-radius:25px;">
									<button *ngIf="obj?.type.toLowerCase()=='dashboards'" class="p-mr-2" type="button" pButton icon="pi pi-play" pTooltip="Run Dashboard" tooltipPosition="top" style="background-color:#5ea7e0; width:35px; height:24px; border:0px; border-color:#398dce;" (click)="onRunButtonClick(obj?.discOrDomaId,'dashboard',obj?.name)"></button>
									<button *ngIf="obj?.type.toLowerCase()=='widget'" class="p-mr-2" type="button" pButton icon="pi pi-play" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:35px; height:24px; border:0px; border-color:#398dce;" (click)="onRunButtonClick(obj?.discOrDomaId,'widget',obj?.name)"></button>
									<button *ngIf="obj?.type.toLowerCase()=='reports'" class="p-mr-2" type="button" pButton icon="pi pi-play" pTooltip="Run Report" tooltipPosition="top" style="background-color:#5ea7e0; width:35px; height:24px; border:0px; border-color:#398dce;" (click)="onRunButtonClick(obj?.discOrDomaId,'report',obj?.name)"></button>
								</div>
							</div>
						
						</div>
					</div>
				</p-scrollPanel>
			</div>
				<!--<p-scrollPanel [style]="{'position':'absolute','left':'0px','width':'100%','top':'0px','height':'100%','background-color':'#f1f1f4','padding':'0px'}">
					<div class="p-grid p-nogutter" style="">
						<div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-4" style="border:0px solid green;  box-sizing:border-box; min-width:0px; height:130px; margin:0px;">
							<p-menu #menu1 [model]="items"  [popup]="true" appendTo="body"></p-menu>
							<div class=""  style="border:px solid #eaeaea; box-sizing:border-box;  height:90%; margin:3.5%; background-color:white;">
								<div class="p-grid p-col-12  p-ai-center vertical-container"  style="background-color:#4994d0; box-sizing:border-box;  margin-left:0; height:40%;">
									<label style="color:#fbfdff; font-family:Roboto; font-size:15px;">Disability Dashboard</label>
								</div>
								<div class="p-grid p-col-12  p-ai-center vertical-container"  style="background-color:#4994d0; box-sizing:border-box;  margin-left:0; height:50%;">
									<label style="color:#c6e9ff; font-family:Roboto; font-size:0.764rem;">New Claims - New disability claims filed </label>
									<label style="color:#c6e9ff; font-family:Roboto; font-size:0.764rem;">STD Aging Calendar days - Duration of the claims</label>
									<label style="margin-left:85%; color:#c6e9ff; font-family:Roboto; font-size:0.764rem; text-decoration:underline;">Show All</label>
								</div>
								<div class="p-col-12"  style="background-color:#5ea7e0; box-sizing:border-box;  height:36%;">
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-play" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:35px; height:24px; border-radius:3px; border-color:#398dce;" (click)="menu1.toggle($event)"></button>
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-save" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:35px; height:24px; border-radius:3px; border-color:#398dce;" (click)="menu1.toggle($event)"></button>
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-star" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:35px; height:24px; border-radius:3px; border-color:#398dce;" (click)="menu1.toggle($event)"></button>
								</div>
							</div>
						</div>
						<div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-4" style="border:0px solid green;  box-sizing:border-box; min-width:0px; height:130px; margin:0px;">
							<p-menu #menu2 [model]="items"  [popup]="true" appendTo="body"></p-menu>
							<div class=""  style="border:px solid #eaeaea; box-sizing:border-box;  height:90%; margin:3.5%; background-color:white;">
								<div class="p-grid p-col-12  p-ai-center vertical-container"  style="background-color:#4994d0; box-sizing:border-box;  margin-left:0; height:40%;">
									<label style="color:#fbfdff; font-family:Roboto; font-size:15px;">STD Dashboard</label>
								</div>
								<div class="p-grid p-col-12  p-ai-center vertical-container"  style="background-color:#4994d0; box-sizing:border-box;  margin-left:0; height:50%;">
									<label style="width:80%; color:#c6e9ff; font-family:Roboto; font-size:0.764rem;">New claims filed</label>
									<label style="width:70%; color:#c6e9ff; font-family:Roboto; font-size:0.764rem;">Approval Denial Rate</label>
									<label style="margin-left:85%; color:#c6e9ff; font-family:Roboto; font-size:0.764rem; text-decoration:underline;">Show All</label>
									<!--<label style="width:80%; color:#c6e9ff; font-family:Roboto; font-size:0.764rem;">Claims denied by reason <button id="" class="p-mr-2" type="button" pButton icon="pi pi-play" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:35px; height:24px; border-radius:3px; border-color:#398dce;"></button></label>-->
									
								<!--</div>
								<div class="p-col-12"  style="background-color:#5ea7e0; box-sizing:border-box;  height:36%;">
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-play" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:35px; height:24px; border-radius:3px; border-color:#398dce;" (click)="menu1.toggle($event)"></button>
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-save" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:35px; height:24px; border-radius:3px; border-color:#398dce;" (click)="menu1.toggle($event)"></button>
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-star" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:35px; height:24px; border-radius:3px; border-color:#398dce;" (click)="menu1.toggle($event)"></button>
								</div>
							</div>
						</div>-->
						<!--<div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-4" style="border:0px solid green;  box-sizing:border-box; min-width:0px; height:130px; margin:0px;">
							<p-menu #menu3 [model]="items"  [popup]="true" appendTo="body"></p-menu>
							<div class=""  style="border:px solid #eaeaea; box-sizing:border-box;  height:90%; margin:3.5%; background-color:white;">
								<div class="p-grid p-col-12  p-ai-center vertical-container"  style="background-color:#4994d0; box-sizing:border-box;  margin-left:0; height:40%;">
									<label style="color:#fbfdff; font-family:Roboto; font-size:15px;">Annual</label>
								</div>
								<div class="p-grid p-col-12  p-ai-center vertical-container"  style="background-color:#4994d0; box-sizing:border-box;  margin-left:0; height:30%;">
									<label style="color:#c6e9ff; font-family:Roboto; font-size:0.764rem;">Dashboard Name: Claims</label>
								</div>
								<div class="p-col-12"  style="background-color:#5ea7e0; box-sizing:border-box;  height:36%;">
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-play" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:35px; height:24px; border-radius:3px; border-color:#398dce;" (click)="menu1.toggle($event)"></button>
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-save" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:35px; height:24px; border-radius:3px; border-color:#398dce;" (click)="menu1.toggle($event)"></button>
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-plus" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:35px; height:24px; border-radius:3px; border-color:#398dce;" (click)="menu1.toggle($event)"></button>
								</div>
							</div>
						</div>-->
					<!--</div>-->
					
				<!--</p-scrollPanel>-->
				
				<!--<p-table [value]="[[]]" [scrollable]="true" scrollHeight="flex" 
						 [style]="{'position':'absolute','left':'0px','width':'calc(100% - 16px)','top':'0px','height':'100%','background-color':'#f1f1f4','margin':'8px'}">
						 
					<ng-template pTemplate="header">
						<tr>
							<th >
								<div class="p-d-flex p-jc-between p-ai-center">
									Dashboards
								</div>
							</th>
							<th pSortableColumn="reportType">
								<div class="p-d-flex p-jc-between p-ai-center">
									Widgets
								</div>
							</th>
							<th>Actions</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body">
						<tr>
							<td>Disability Dashboard</td>
							<td>2 <label style="margin-left:5%; color:#5ea7e0; font-family:Roboto; font-size:0.764rem; text-decoration:underline;">Show All</label></td>
							<td><div class=""  style="width:max-content; min-width:106px; padding:3px 18px; background-color:#5ea7e0; border-radius:20px; box-sizing:border-box;">
									<button class="p-mr-2" type="button" pButton icon="pi pi-play" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:30px; height:24px; border-radius:3px; border-color:#398dce;"></button>
									<button class="p-mr-2" type="button" pButton icon="pi pi-save" pTooltip="Save Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:26px; height:24px; border-radius:3px; border-color:#398dce;"></button>
									<button class="p-mr-2" type="button" pButton icon="pi pi-star" pTooltip="Add to My orites" tooltipPosition="top" style="background-color:#5ea7e0; width:26px; height:24px; border-radius:3px; border-color:#398dce;"></button>
								</div>
							</td>
						</tr>
						<tr>
							<td>STD Dashboard</td>
							<td>2 <label style="margin-left:5%; color:#5ea7e0; font-family:Roboto; font-size:0.764rem; text-decoration:underline;">Show All</label></td>
							<td><div class=""  style="width:max-content; min-width:106px; padding:3px 18px; background-color:#5ea7e0; border-radius:20px; box-sizing:border-box;">
									<button class="p-mr-2" type="button" pButton icon="pi pi-play" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:30px; height:24px; border-radius:3px; border-color:#398dce;"></button>
									<button class="p-mr-2" type="button" pButton icon="pi pi-save" pTooltip="Save Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:26px; height:24px; border-radius:3px; border-color:#398dce;"></button>
									<button class="p-mr-2" type="button" pButton icon="pi pi-star" pTooltip="Add to My Favorites" tooltipPosition="top" style="background-color:#5ea7e0; width:26px; height:24px; border-radius:3px; border-color:#398dce;"></button>
								</div>
							</td>
						</tr>
					</ng-template>
				</p-table>-->
				
			
			
			<!--<div class="p-grid p-col-12 p-ai-center vertical-container" style="position:absolute; background-color: #f1f1f4; box-sizing: border-box; margin: 0px; left: 0px; top: 33%; height: 30px;">
				<label style="color: #0f528c; font-family: Roboto; font-size: 14px;">Reports</label>
			</div>
				
			<div class="my-reports-tile-view" style="position:absolute; left:0px; width:75%; top:38%; height:calc(50% - 85px); background-color:#f1f1f4;">
				
				<p-table [value]="[[]]" [scrollable]="true" scrollHeight="flex" 
						 [style]="{'position':'absolute','left':'0px','width':'calc(100% - 16px)','top':'0px','height':'100%','background-color':'#f1f1f4','margin':'8px'}">
						 
					<ng-template pTemplate="header">
						<tr>
							<th >
								<div class="p-d-flex p-jc-between p-ai-center">
									Reports
								</div>
							</th>
							<th pSortableColumn="reportType">
								<div class="p-d-flex p-jc-between p-ai-center">
									Description
								</div>
							</th>
							<th>Actions</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body">
						<tr>
							<td>Claims Status and Experience Report</td>
							<td>Claim payment info by status and disability type</td>
							<td><div class=""  style="width:max-content; min-width:106px; padding:3px 18px; background-color:#5ea7e0; border-radius:20px; box-sizing:border-box;">
									<button class="p-mr-2" type="button" pButton icon="pi pi-play" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:30px; height:24px; border-radius:3px; border-color:#398dce;"></button>
									<button class="p-mr-2" type="button" pButton icon="pi pi-save" pTooltip="Save Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:26px; height:24px; border-radius:3px; border-color:#398dce;"></button>
									<button class="p-mr-2" type="button" pButton icon="pi pi-star" pTooltip="Add to My Favorites" tooltipPosition="top" style="background-color:#5ea7e0; width:26px; height:24px; border-radius:3px; border-color:#398dce;"></button>
									<button class="p-mr-2" type="button" pButton icon="pi pi-info" pTooltip="Info" tooltipPosition="top" style="background-color:#5ea7e0; width:26px; height:24px; border-radius:3px; border-color:#398dce;"></button>
								</div>
							</td>
						</tr>
						<tr>
							<td>Disability Check Register</td>
							<td>Payments, reversals and cancellations of payments info</td>
							<td><div class=""  style="width:max-content; min-width:106px; padding:3px 18px; background-color:#5ea7e0; border-radius:20px; box-sizing:border-box;">
									<button class="p-mr-2" type="button" pButton icon="pi pi-play" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:30px; height:24px; border-radius:3px; border-color:#398dce;"></button>
									<button class="p-mr-2" type="button" pButton icon="pi pi-save" pTooltip="Save Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:26px; height:24px; border-radius:3px; border-color:#398dce;"></button>
									<button class="p-mr-2" type="button" pButton icon="pi pi-star" pTooltip="Add to My Favorites" tooltipPosition="top" style="background-color:#5ea7e0; width:26px; height:24px; border-radius:3px; border-color:#398dce;"></button>
									<button class="p-mr-2" type="button" pButton icon="pi pi-info" pTooltip="Info" tooltipPosition="top" style="background-color:#5ea7e0; width:26px; height:24px; border-radius:3px; border-color:#398dce;"></button>
								</div>
							</td>
						</tr>
					</ng-template>
				</p-table>
				
				<!--<p-scrollPanel [style]="{'position':'absolute','left':'0px','width':'100%','top':'0px','height':'100%','background-color':'#f1f1f4','padding':'0px'}">
					<div class="p-grid p-nogutter" style="">
						<div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-4" style="border:0px solid green;  box-sizing:border-box; min-width:0px; height:130px; margin:0px;">
							<p-menu #menu1 [model]="items"  [popup]="true" appendTo="body"></p-menu>
							<div class=""  style="border:px solid #eaeaea; box-sizing:border-box;  height:90%; margin:3.5%; background-color:white;">
								<div class="p-grid p-col-12  p-ai-center vertical-container"  style="background-color:#4994d0; box-sizing:border-box;  margin-left:0; height:40%;">
									<label style="color:#fbfdff; font-family:Roboto; font-size:15px;">Claims Status and Experience Report</label>
								</div>
								<div class="p-grid p-col-12  p-ai-center vertical-container"  style="background-color:#4994d0; box-sizing:border-box;  margin-left:0; height:35%;">
									<label style="width:100%; color:#c6e9ff; font-family:Roboto; font-size:0.764rem;">Claim payment info by status and disability type</label>
									<label style="width:100%; color:#c6e9ff; font-family:Roboto; font-size:0.764rem;"></label>
								</div>
								<div class="p-col-12"  style="background-color:#5ea7e0; box-sizing:border-box;  height:36%;">
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-play" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:35px; height:24px; border-radius:3px; border-color:#398dce;" (click)="menu1.toggle($event)"></button>
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-calendar" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:35px; height:24px; border-radius:3px; border-color:#398dce;" (click)="menu1.toggle($event)"></button>
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-star" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:35px; height:24px; border-radius:3px; border-color:#398dce;" (click)="menu1.toggle($event)"></button>
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-info" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:35px; height:24px; border-radius:3px; border-color:#398dce;" (click)="menu1.toggle($event)"></button>
								</div>
							</div>
						</div>
						<div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-4" style="border:0px solid green;  box-sizing:border-box; min-width:0px; height:130px; margin:0px;">
							<p-menu #menu2 [model]="items"  [popup]="true" appendTo="body"></p-menu>
							<div class=""  style="border:px solid #eaeaea; box-sizing:border-box;  height:90%; margin:3.5%; background-color:white;">
								<div class="p-grid p-col-12  p-ai-center vertical-container"  style="background-color:#4994d0; box-sizing:border-box;  margin-left:0; height:40%;">
									<label style="color:#fbfdff; font-family:Roboto; font-size:15px;">Disability Check Register</label>
								</div>
								<div class="p-grid p-col-12  p-ai-center vertical-container"  style="background-color:#4994d0; box-sizing:border-box;  margin-left:0; height:35%;">
									<label style="width:100%; color:#c6e9ff; font-family:Roboto; font-size:0.764rem;">Payments, reversals and cancellations of payments info</label>
									<label style="width:100%; color:#c6e9ff; font-family:Roboto; font-size:0.764rem;"></label>
								</div>
								<div class="p-col-12"  style="background-color:#5ea7e0; box-sizing:border-box;  height:36%;">
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-play" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:35px; height:24px; border-radius:3px; border-color:#398dce;" (click)="menu1.toggle($event)"></button>
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-calendar" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:35px; height:24px; border-radius:3px; border-color:#398dce;" (click)="menu1.toggle($event)"></button>
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-star" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:35px; height:24px; border-radius:3px; border-color:#398dce;" (click)="menu1.toggle($event)"></button>
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-info" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:35px; height:24px; border-radius:3px; border-color:#398dce;" (click)="menu1.toggle($event)"></button>
								</div>
							</div>
						</div>-->
						<!--<div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-4" style="border:0px solid green;  box-sizing:border-box; min-width:0px; height:130px; margin:0px;">
							<p-menu #menu3 [model]="items"  [popup]="true" appendTo="body"></p-menu>
							<div class=""  style="border:px solid #eaeaea; box-sizing:border-box;  height:90%; margin:3.5%; background-color:white;">
								<div class="p-grid p-col-12  p-ai-center vertical-container"  style="background-color:#4994d0; box-sizing:border-box;  margin-left:0; height:40%;">
									<label style="color:#fbfdff; font-family:Roboto; font-size:15px;">Incurred Claim Statistics - Detail</label>
								</div>
								<div class="p-grid p-col-12  p-ai-center vertical-container"  style="background-color:#4994d0; box-sizing:border-box;  margin-left:0; height:30%;">
									<label style="color:#c6e9ff; font-family:Roboto; font-size:0.764rem;">Report includes claim detail summarized Incurred Claim Statistics</label>
								</div>
								<div class="p-col-12"  style="background-color:#5ea7e0; box-sizing:border-box;  height:36%;">
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-play" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:35px; height:24px; border-radius:3px; border-color:#398dce;" (click)="menu1.toggle($event)"></button>
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-plus" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:35px; height:24px; border-radius:3px; border-color:#398dce;" (click)="menu1.toggle($event)"></button>
								</div>
							</div>
						</div>-->
					<!--</div>-->
					
				<!--</p-scrollPanel>-->
			<!--</div>
			
			<div class="p-grid p-col-12 p-ai-center vertical-container" style="position:absolute; background-color: #f1f1f4; box-sizing: border-box; margin: 0px; left: 0px; top: 65%; height: 30px;">
				<label style="color: #0f528c; font-family: Roboto; font-size: 14px;">Widgets</label>
			</div>
				
			<div class="my-reports-tile-view" style="position:absolute; left:0px; width:75%; top:70%; height:calc(50% - 85px); background-color:#f1f1f4;">
				<!--<p-scrollPanel [style]="{'position':'absolute','left':'0px','width':'100%','top':'0px','height':'100%','background-color':'#f1f1f4','padding':'0px'}">
					<div class="p-grid p-nogutter" style="">
						<div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-4" style="border:0px solid green;  box-sizing:border-box; min-width:0px; height:130px; margin:0px;">
							<p-menu #menu1 [model]="items"  [popup]="true" appendTo="body"></p-menu>
							<div class=""  style="border:px solid #eaeaea; box-sizing:border-box;  height:90%; margin:3.5%; background-color:white;">
								<div class="p-grid p-col-12  p-ai-center vertical-container"  style="background-color:#4994d0; box-sizing:border-box;  margin-left:0; height:40%;">
									<label style="color:#fbfdff; font-family:Roboto; font-size:15px;">New Claims</label>
								</div>
								<div class="p-grid p-col-12  p-ai-center vertical-container"  style="background-color:#4994d0; box-sizing:border-box;  margin-left:0; height:30%;">
									<label style="color:#c6e9ff; font-family:Roboto; font-size:0.764rem;">New disability claims filed</label>
								</div>
								<div class="p-col-12"  style="background-color:#5ea7e0; box-sizing:border-box;  height:36%;">
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-play" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:35px; height:24px; border-radius:3px; border-color:#398dce;" (click)="menu1.toggle($event)"></button>
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-save" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:35px; height:24px; border-radius:3px; border-color:#398dce;" (click)="menu1.toggle($event)"></button>
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-star" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:35px; height:24px; border-radius:3px; border-color:#398dce;" (click)="menu1.toggle($event)"></button>
								</div>
							</div>
						</div>
						<div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-4" style="border:0px solid green;  box-sizing:border-box; min-width:0px; height:130px; margin:0px;">
							<p-menu #menu2 [model]="items"  [popup]="true" appendTo="body"></p-menu>
							<div class=""  style="border:px solid #eaeaea; box-sizing:border-box;  height:90%; margin:3.5%; background-color:white;">
								<div class="p-grid p-col-12  p-ai-center vertical-container"  style="background-color:#4994d0; box-sizing:border-box;  margin-left:0; height:40%;">
									<label style="color:#fbfdff; font-family:Roboto; font-size:15px;">STD Duration</label>
								</div>
								<div class="p-grid p-col-12  p-ai-center vertical-container"  style="background-color:#4994d0; box-sizing:border-box;  margin-left:0; height:30%;">
									<label style="color:#c6e9ff; font-family:Roboto; font-size:0.764rem;">Duration of STD claim info</label>
								</div>
								<div class="p-col-12"  style="background-color:#5ea7e0; box-sizing:border-box;  height:36%;">
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-play" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:35px; height:24px; border-radius:3px; border-color:#398dce;" (click)="menu1.toggle($event)"></button>
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-save" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:35px; height:24px; border-radius:3px; border-color:#398dce;" (click)="menu1.toggle($event)"></button>
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-star" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:35px; height:24px; border-radius:3px; border-color:#398dce;" (click)="menu1.toggle($event)"></button>
								</div>
							</div>
						</div>
						<div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-4" style="border:0px solid green;  box-sizing:border-box; min-width:0px; height:130px; margin:0px;">
							<p-menu #menu3 [model]="items"  [popup]="true" appendTo="body"></p-menu>
							<div class=""  style="border:px solid #eaeaea; box-sizing:border-box;  height:90%; margin:3.5%; background-color:white;">
								<div class="p-grid p-col-12  p-ai-center vertical-container"  style="background-color:#4994d0; box-sizing:border-box;  margin-left:0; height:40%;">
									<label style="color:#fbfdff; font-family:Roboto; font-size:15px;">Top 5 STD Major Diagnostic Categories</label>
								</div>
								<div class="p-grid p-col-12  p-ai-center vertical-container"  style="background-color:#4994d0; box-sizing:border-box;  margin-left:0; height:30%;">
									<label style="color:#c6e9ff; font-family:Roboto; font-size:0.764rem;">Claims filed for diagnostic categories</label>
								</div>
								<div class="p-col-12"  style="background-color:#5ea7e0; box-sizing:border-box;  height:36%;">
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-play" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:35px; height:24px; border-radius:3px; border-color:#398dce;" (click)="menu1.toggle($event)"></button>
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-save" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:35px; height:24px; border-radius:3px; border-color:#398dce;" (click)="menu1.toggle($event)"></button>
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-star" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:35px; height:24px; border-radius:3px; border-color:#398dce;" (click)="menu1.toggle($event)"></button>
								</div>
							</div>
						</div>
					</div>
					
				</p-scrollPanel>-->
				
				<!--<p-table [value]="[[]]" [scrollable]="true" scrollHeight="flex" 
						 [style]="{'position':'absolute','left':'0px','width':'calc(100% - 16px)','top':'0px','height':'100%','background-color':'#f1f1f4','margin':'8px'}">
						 
					<ng-template pTemplate="header">
						<tr>
							<th >
								<div class="p-d-flex p-jc-between p-ai-center">
									Widgets
								</div>
							</th>
							<th pSortableColumn="reportType">
								<div class="p-d-flex p-jc-between p-ai-center">
									Dashboard
								</div>
							</th>
							<th>Actions</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body">
						<tr>
							<td>New Claims</td>
							<td>New disability claims filed</td>
							<td><div class=""  style="width:max-content; min-width:106px; padding:3px 18px; background-color:#5ea7e0; border-radius:20px; box-sizing:border-box;">
									<button class="p-mr-2" type="button" pButton icon="pi pi-play" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:30px; height:24px; border-radius:3px; border-color:#398dce;"></button>
									<button class="p-mr-2" type="button" pButton icon="pi pi-save" pTooltip="Save Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:26px; height:24px; border-radius:3px; border-color:#398dce;"></button>
									<button class="p-mr-2" type="button" pButton icon="pi pi-star" pTooltip="Add to My Favorites" tooltipPosition="top" style="background-color:#5ea7e0; width:26px; height:24px; border-radius:3px; border-color:#398dce;"></button>
								</div>
							</td>
						</tr>
						<tr>
							<td>STD Duration</td>
							<td>Duration of STD claim info</td>
							<td><div class=""  style="width:max-content; min-width:106px; padding:3px 18px; background-color:#5ea7e0; border-radius:20px; box-sizing:border-box;">
									<button class="p-mr-2" type="button" pButton icon="pi pi-play" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:30px; height:24px; border-radius:3px; border-color:#398dce;"></button>
									<button class="p-mr-2" type="button" pButton icon="pi pi-save" pTooltip="Save Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:26px; height:24px; border-radius:3px; border-color:#398dce;"></button>
									<button class="p-mr-2" type="button" pButton icon="pi pi-star" pTooltip="Add to My Favorites" tooltipPosition="top" style="background-color:#5ea7e0; width:26px; height:24px; border-radius:3px; border-color:#398dce;"></button>
								</div>
							</td>
						</tr>
						<tr>
							<td>Top 5 STD Major Diagnostic Categories</td>
							<td>Claims filed for diagnostic categories</td>
							<td><div class=""  style="width:max-content; min-width:106px; padding:3px 18px; background-color:#5ea7e0; border-radius:20px; box-sizing:border-box;">
									<button class="p-mr-2" type="button" pButton icon="pi pi-play" pTooltip="Run Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:30px; height:24px; border-radius:3px; border-color:#398dce;"></button>
									<button class="p-mr-2" type="button" pButton icon="pi pi-save" pTooltip="Save Widget" tooltipPosition="top" style="background-color:#5ea7e0; width:26px; height:24px; border-radius:3px; border-color:#398dce;"></button>
									<button class="p-mr-2" type="button" pButton icon="pi pi-star" pTooltip="Add to My Favorites" tooltipPosition="top" style="background-color:#5ea7e0; width:26px; height:24px; border-radius:3px; border-color:#398dce;"></button>
								</div>
							</td>
						</tr>
					</ng-template>
				</p-table>
				
			</div>-->
			
			<p-paginator [rows]="25" [totalRecords]="totalRecords"  (onPageChange)="paginate($event)"
				[style]="{'position':'absolute','left':'2px','width':'calc(100% - 4px)','bottom':'2px','height':'45px','border-radius':'0px','padding':'0px'}"></p-paginator>
			
		</div>
		
	</div>
</div>
