<div class="tibo-myrepo-scheduler-container-wrapper tibo-right-container">

	<!-- <p-toast styleClass="tibo-myrepo-schelr-toast" position="center"></p-toast> -->

	<div class="modal-header cus-bg">
		<!-- Children styling available in .css file -->
		<label>Add Schedule for {{header}}</label>
	</div>


	<div class="row" style=" margin-top: 1rem" class="ScrollStyle">
		<div class="column Report">
			<p-panel header="Report Parameter" toggleable="true" *ngIf="header != 'Absence Blueprint SSO Report' && header != 'Absence Blueprint Open URL Report'">
				

				<div class="p-field p-col-12 p-md-4">
					<label>Select A Client</label>
					<p-dropdown [options]="lang" placeholder="All" optionLabel="name">
					</p-dropdown>
				</div>

				<div style=" margin-top: 1rem">
					<label>Select A Type</label><br>
					<div class="d-block my-3">

						<div class="custom-control custom-radio">
							<input id="male" type="radio" class="custom-control-input" value="STD" name="value">
							<span class="custom-control-label" for="std"
								*ngIf="header === 'Leave Details' || header === 'Leave Summary'">Intermittent</span>
							<span class="custom-control-label" for="std"
								*ngIf="header === 'Disability Details' || header === 'Disability Summary'">Short Term
								Disability</span>

						</div>

						<div class="custom-control custom-radio">
							<input id="female" type="radio" class="custom-control-input" value="LTD" name="value">
							<span class="custom-control-label" for=""
								*ngIf="header === 'Leave Details' || header === 'Leave Summary'">Continuous</span>
							<span class="custom-control-label" for=""
								*ngIf="header === 'Disability Details' || header === 'Disability Summary'">Long Term
								Disability</span>

						</div>
						<div class="custom-control custom-radio">
							<input id="female" type="radio" class="custom-control-input" value="BOTH" name="value">
							<span class="custom-control-label" for="female">Both</span>
						</div>


					</div>
				</div>

				<!-- <div class="p-field p-col-12 p-md-4">
					<label>Select a dataset</label>
					<p-radioButton value="AngularJS"  inputId=" gfg1">
					</p-radioButton>
					<label for=" gfg1">AngularJS</label>
				</div>
				<div class="p-field-radiobutton">
					<p-radioButton value="ReactJS"  inputId=" gfg2">
					</p-radioButton>
					<label for=" gfg2">ReactJS</label>
				</div>
				<div class="p-field- radiobutton">
					<p-radioButton value="HTML"  inputId="gfg3">
					</p-radioButton>
					<label for="gfg3">HTML</label>
				</div>
				<div class="p-field-radiobutton">
					<p-radioButton value="JavaScript"  inputId=" gfg4">
					</p-radioButton>
					<label for=" gfg4">JavaScript</label>s

				</div> -->


				<div class="col-6 md-4" style=" margin-top: 1rem">
					<label>Select A Data Set</label>
					<div class="d-block my-3">

						<div class="custom-control custom-radio">
							<input id="male" type="radio" class="custom-control-input" value="male" name="gender">
							<span class="custom-control-label" for="male">Current Open Claims</span>
						</div>

						<div class="custom-control custom-radio">
							<input id="female" type="radio" class="custom-control-input" value="female" name="gender">
							<span class="custom-control-label" for="female">Actual Return to Work In The Period</span>
						</div>
						<div class="custom-control custom-radio">
							<input id="female" type="radio" class="custom-control-input" value="female" name="gender">
							<span class="custom-control-label" for="female">Expected Return To Work In The Period</span>
						</div>
						<div class="custom-control custom-radio">
							<input id="female" type="radio" class="custom-control-input" value="female" name="gender">
							<span class="custom-control-label" for="female">Claim Field iIn The Period</span>
						</div>
						<div class="custom-control custom-radio">
							<input id="female" type="radio" class="custom-control-input" value="female" name="gender">
							<span class="custom-control-label" for="female">Feature Time requested</span><br><br>
						</div>


					</div>
				</div>




				<div class="p-field p-col-12 p-md-4">
					<label>Start Date</label>
					<p-calendar [showIcon]="true" inputId="icon" placeholder="MM/DD/YYYY"></p-calendar>
				</div>

				<div class="p-field p-col-12 p-md-4">
					<label for="basic">End Date</label>
					<p-calendar inputId="basic" [showIcon]="true" placeholder="MM/DD/YYYY"></p-calendar>
				</div>
				<div class="row">
					<div style="text-align:center">
						<h1>(Or)</h1>
					</div>

				</div>
				<div class="p-field p-col-12 p-md-4">
					<label>Date Range</label>
					<p-dropdown [options]="dateRange" placeholder="Select" optionLabel="name">
					</p-dropdown>
				</div>

			</p-panel>
			<p-panel header="Report Parameter" toggleable="true" *ngIf="header == 'Absence Blueprint SSO Report' || header == 'Absence Blueprint Open URL Report'">

			</p-panel>
		</div>

		<div class="column Schedule">
			<p-panel header="Schedule" toggleable="true">
				<div class="p-field p-col-12 p-md-4">
					<label>Job Name</label>
					<input type="text" placeholder="Enter Job Name" pInputText style="width: 35rem;">
				</div>
				<div class="">
					<span class="">Occurrence</span>
					<div class="p-grid tibo-myrepo-schelr-secttwo-occur-pselectbtn-cont">
						<p-selectButton [options]="stateOptions" [(ngModel)]="scheduleOccurrenceValue"
							optionLabel="label" optionValue="value" (onChange)="changeSchedulerView($event)">
						</p-selectButton>
					</div>

					<div id="daily-scheduler">


						<div class="p-grid p-ai-center vertical-container p-mx-1">
							<input type="radio" name="daily_occurrence" value="every days(s)"
								(change)="dailyOccurSchedFunc($event,undefined)">
							Every
							<p-inputNumber id="refElmval1" class="p-mx-1"
								styleClass="tibo-myrepo-schelr-elements-inputnumber"
								[(ngModel)]="monthlyOccurrenceDayVal1" [showButtons]="true" mode="decimal" [min]="1"
								[max]="31" [maxlength]="2" (onInput)="monthlyOccurSchedFunc($event,refElmval1)"
								#refElmval1></p-inputNumber>
							Day(s)

						</div>

						<div class="p-grid p-ai-center vertical-container p-mx-1 p-py-2">
							<input type="radio" name="daily_occurrence" value="every week day"
								(change)="dailyOccurSchedFunc($event,undefined)">
							Every week day
						</div>

						<div class="p-grid p-ai-center vertical-container p-mx-1 p-py-2">
							<input type="radio" name="daily_occurrence" value="every day"
								(change)="dailyOccurSchedFunc($event,undefined)">
							Every day
						</div>
						<div style="margin-top: 0.5rem;">
							<label>Produce this report at (PST):</label>
							<div style="margin-top: 0.5rem;">
								<p-calendar styleClass="p-mx-1 daily-time" id="weekly-occurrence-report-time"
									[timeOnly]="true" hourFormat="12" readonlyInput="true" appendTo="body"
									showIcon="true" icon="pi pi-clock" placeholder="HH:MM AM/PM"
									(onSelect)="weeklyOccurSchedFunc($event,refElw1)" #refElw1>
								</p-calendar>
							</div>
						</div>

						<div style="margin-top: 0.5rem;">
							<label>Schedule Start Date:</label><span>*</span>
							<div style="margin-top: 0.5rem;">
								<p-calendar styleClass="p-mx-1 daily-time" id="weekly-occurrence-report-start-date"
									readonlyInput="true" dateFormat="mm/dd/yy" appendTo="body" [monthNavigator]="true"
									[yearNavigator]="true" yearRange="2000:2030" showIcon="true" [minDate]="minDate"
									placeholder="MM/DD/YYYY" (onSelect)="weeklyOccurSchedFunc($event,refElw2)" #refElw2>
								</p-calendar>
							</div>
						</div>

						<div style="margin-top: 0.5rem;">
							<label>Schedule End Date:</label><span>*</span>
							<div style="margin-top: 0.5rem;">
								<p-calendar styleClass="p-mx-1 daily-time" id="weekly-occurrence-report-end-date"
									readonlyInput="true" dateFormat="mm/dd/yy" appendTo="body" [monthNavigator]="true"
									[yearNavigator]="true" yearRange="2000:2030" showIcon="true" [minDate]="minDate"
									placeholder="MM/DD/YYYY" (onSelect)="weeklyOccurSchedFunc($event,refElw3)" #refElw3>
								</p-calendar>
							</div>
						</div>


					</div>


					<div id="weekly-scheduler">
						<div class="">Produce this report on - Select Day(s) <span class="">*</span></div>

						<div style="margin-top: 1rem;">
							<p-checkbox class="p-mr-2" label="SUN" value="1"
								(onChange)="weeklyOccurSchedFunc($event,refEl11)" #refEl11></p-checkbox>
							<p-checkbox class="p-mr-2" label="MON" value="2"
								(onChange)="weeklyOccurSchedFunc($event,refE222)" #refE222></p-checkbox>
							<p-checkbox class="p-mr-2" label="TUE" value="3"
								(onChange)="weeklyOccurSchedFunc($event,refE333)" #refE333></p-checkbox>
							<p-checkbox class="p-mr-2" label="WED" value="4"
								(onChange)="weeklyOccurSchedFunc($event,refE444)" #refE444></p-checkbox>
							<p-checkbox class="p-mr-2" label="THU" value="5"
								(onChange)="weeklyOccurSchedFunc($event,refE555)" #refE555></p-checkbox>
							<p-checkbox class="p-mr-2" label="FRI" value="6"
								(onChange)="weeklyOccurSchedFunc($event,refE666)" #refE666></p-checkbox>
							<p-checkbox class="p-mr-2" label="SAT" value="7"
								(onChange)="weeklyOccurSchedFunc($event,refE777)" #refE777></p-checkbox>

						</div>

						<!-- <div class="">
							<span class="">
								Produce this report at (PST):
								<span class="">*</span>
							</span>
							 <p-calendar styleClass="tibo-myrepo-schelr-elements-calendar"
								id="weekly-occurrence-report-time"
								[timeOnly]="true"
								hourFormat="12"
								readonlyInput="true" 
								appendTo="body"
								showIcon="true"
								icon="pi pi-clock"
								placeholder="HH:MM AM/PM"
								(onSelect)="weeklyOccurSchedFunc($event,refElw1)"
								#refElw1>
							</p-calendar> 
						</div>
						 -->
						<div style="margin-top: 0.5rem;">
							<label>Produce this report at (PST):</label>
							<div style="margin-top: 0.5rem;">
								<p-calendar styleClass="p-mx-1 daily-time" id="weekly-occurrence-report-time"
									[timeOnly]="true" hourFormat="12" readonlyInput="true" appendTo="body"
									showIcon="true" icon="pi pi-clock" placeholder="HH:MM AM/PM"
									(onSelect)="weeklyOccurSchedFunc($event,refElw1)" #refElw1>
								</p-calendar>
							</div>
						</div>

						<div style="margin-top: 0.5rem;">
							<label>Schedule Start Date:</label><span>*</span>
							<div style="margin-top: 0.5rem;">
								<p-calendar styleClass="p-mx-1 daily-time" id="weekly-occurrence-report-start-date"
									readonlyInput="true" dateFormat="mm/dd/yy" appendTo="body" [monthNavigator]="true"
									[yearNavigator]="true" yearRange="2000:2030" showIcon="true" [minDate]="minDate"
									placeholder="MM/DD/YYYY" (onSelect)="weeklyOccurSchedFunc($event,refElw2)" #refElw2>
								</p-calendar>
							</div>
						</div>

						<div style="margin-top: 0.5rem;">
							<label>Schedule End Date:</label><span>*</span>
							<div style="margin-top: 0.5rem;">
								<p-calendar styleClass="p-mx-1 daily-time" id="weekly-occurrence-report-end-date"
									readonlyInput="true" dateFormat="mm/dd/yy" appendTo="body" [monthNavigator]="true"
									[yearNavigator]="true" yearRange="2000:2030" showIcon="true" [minDate]="minDate"
									placeholder="MM/DD/YYYY" (onSelect)="weeklyOccurSchedFunc($event,refElw3)" #refElw3>
								</p-calendar>
							</div>
						</div>

					</div>


					<div id="monthly-scheduler">
						<div class="p-grid p-ai-center vertical-container p-mx-1" style="margin-top: 1rem;">
							<input type="radio" name="monthly_occurrence" value="first"
								(change)="monthlyOccurSchedFunc($event,undefined)">
							Day
							<p-inputNumber id="refElmval1" class="p-mx-1"
								styleClass="tibo-myrepo-schelr-elements-inputnumber"
								[(ngModel)]="monthlyOccurrenceDayVal1" [showButtons]="true" mode="decimal" [min]="1"
								[max]="31" [maxlength]="2" (onInput)="monthlyOccurSchedFunc($event,refElmval1)"
								#refElmval1></p-inputNumber>
							of every
							<p-inputNumber id="refElmval2" class="p-mx-1"
								styleClass="tibo-myrepo-schelr-elements-inputnumber"
								[(ngModel)]="monthlyOccurrenceMonthVal" [showButtons]="true" mode="decimal" [min]="1"
								[max]="12" [maxlength]="2" (onInput)="monthlyOccurSchedFunc($event,refElmval2)"
								#refElmval2></p-inputNumber>
							month(s)
						</div>

						<div class="p-grid p-ai-center vertical-container p-mx-1 p-py-2" style="margin-top: 0.5rem;">
							<input type="radio" name="monthly_occurrence" value="second"
								(change)="monthlyOccurSchedFunc($event,undefined)">
							The <p-dropdown styleClass="dropdown p-mx-1  value" id="monthly-occurrence-drop-one"
								appendTo="body" [options]="monthlyOccurList1" optionLabel="label" optionValue="value"
								placeholder="Eg: First" (onChange)="monthlyOccurSchedFunc($event,refElFirst)"
								#refElFirst>
							</p-dropdown>
							<p-dropdown styleClass="dropdown p-mx-1" id="monthly-occurrence-drop-two" appendTo="body"
								[options]="monthlyOccurList2" optionLabel="label" optionValue="value"
								placeholder="Eg: Day" (onChange)="monthlyOccurSchedFunc($event,refElSecond)"
								#refElSecond>
							</p-dropdown>
							of every
							<p-inputNumber id="refElmval3" class="p-mx-1"
								styleClass="tibo-myrepo-schelr-elements-inputnumber"
								[(ngModel)]="monthlyOccurrenceMonthVal2" [showButtons]="true" mode="decimal" [min]="1"
								[max]="12" [maxlength]="2" (onInput)="monthlyOccurSchedFunc($event,refElmval3)"
								#refElmval3></p-inputNumber>
							month(s)
						</div>

						<div style="margin-top: 0.5rem;">
							<label>Produce this report at (PST):</label><span>*</span>
							<div style="margin-top: 0.5rem;">
								<p-calendar styleClass="p-mx-1 daily-time" id="monthly-occurrence-report-time"
									[timeOnly]="true" hourFormat="12" readonlyInput="true" appendTo="body"
									showIcon="true" icon="pi pi-clock" placeholder="HH:MM AM/PM"
									(onSelect)="monthlyOccurSchedFunc($event,refElm1)" #refElm1>
								</p-calendar>
							</div>
						</div>

						<div style="margin-top: 0.5rem;">
							<label>Schedule Start Date:</label><span>*</span>
							<div style="margin-top: 0.5rem;">
								<p-calendar styleClass="p-mx-1 daily-time" id="monthly-occurrence-report-start-date"
									readonlyInput="true" dateFormat="mm/dd/yy" appendTo="body" [monthNavigator]="true"
									[yearNavigator]="true" yearRange="2000:2030" showIcon="true" [minDate]="minDate"
									placeholder="MM/DD/YYYY" (onSelect)="monthlyOccurSchedFunc($event,refElm2)"
									#refElm2>
								</p-calendar>
							</div>
						</div>

						<div style="margin-top: 0.5rem;">
							<label>Schedule End Date:</label><span>*</span>
							<div style="margin-top: 0.5rem;">
								<p-calendar styleClass="p-mx-1 daily-time" id="monthly-occurrence-report-end-date"
									readonlyInput="true" dateFormat="mm/dd/yy" appendTo="body" [monthNavigator]="true"
									[yearNavigator]="true" yearRange="2000:2030" showIcon="true" [minDate]="minDate"
									placeholder="MM/DD/YYYY" (onSelect)="monthlyOccurSchedFunc($event,refElm3)"
									#refElm3>
								</p-calendar>
							</div>
						</div>

					</div>

				</div>
			</p-panel>
		</div>

		<div class="column Output">
			<p-panel header="Report Output Type" toggleable="true">
				<div class="p-field p-col-12 p-md-4">
					<label>File Formats</label>
					<div class="p-grid tibo-myrepo-schelr-secttwo-occur-pselectbtn-cont">
						<p-selectButton [options]="Options" [(ngModel)]="fileValue" optionLabel="label"
							optionValue="value" (onChange)="changeSchedulerView($event)"></p-selectButton>
					</div>

					<!-- <div  class="p-grid tibo-myrepo-schelr-secttwo-occur-pselectbtn-cont">
						<p-selectButton [options]="stateOptions" [(ngModel)]="scheduleOccurrenceValue" optionLabel="label" optionValue="value" (onChange)="changeSchedulerView($event)"></p-selectButton>
					</div> -->

				</div>
				<div class="p-field p-col-12 p-md-4">
					<label>File Name</label>
					<input type="text" placeholder="Enter File Name" pInputText>
				</div>
			</p-panel>
		</div>

		<div class="column mail pull-right">
			<p-panel header=" Email Report Output File" toggleable="true">
				<div class="p-field p-col-12 p-md-4">
					<label>To</label>
					<input type="text" placeholder="anand@timebound.com" pInputText>
				</div>
				<div class="p-field p-col-12 p-md-4">
					<label>CC</label>
					<input type="text" placeholder="Enter Email Address" pInputText>
				</div>
				<div class="p-field p-col-12 p-md-4">
					<label>Subject</label>
					<input type="text" placeholder=" Subject" pInputText>
				</div>
				<div class="p-field p-col-12 p-md-4">
					<label>Message</label>

					<textarea pInputTextarea placeholder="Please find the Standard Report"
						style="width:15rem"></textarea>

				</div>
			</p-panel>
		</div>
	</div>
	<div class="modal-footer ">
		<button type="button" pButton label="Cancel" value="Update" class="button-tertiary"
			style="margin-right: 1rem;"></button>
		<button type="button" class="schedule">Schedule</button>
	</div>
</div>