import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from './login/login.service';

import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
  
})
export class AuthGuard implements CanActivate {
  /*canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }*/
  
  constructor(private loginService: LoginService, private router: Router){}
  
	canActivate(): boolean{
		if(this.loginService.loggedIn()){
			return true;
		}
		else {
			this.router.navigate(['./'])
			return false;
		}
	}
}
