import { Component, OnInit } from '@angular/core';
import { SettingsService } from './settings.service';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
// import { SharedService } from '../shared.service';


declare var d3:any;

interface DashboardModel {
	name: string,
	code: string
}

interface LandingPage {
	name: string,
	code: string
}

@Component({
	selector: 'app-settings',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.css'],
	providers: [SettingsService, MessageService]
})

export class SettingsComponent implements OnInit {
	
	displayOptions: any[];
	reportLists: any[];
	landingPages: LandingPage[];
	defaultDashboardData: DashboardModel[];
	defaultDashDataCopy = [];
	fonts: any[];
	fontSize: any[];
	fontStyle:any[];
	
	savedDefaultDashboard: any;
	autoRefreshCheckBool: boolean = false;
	savedLandingPage: string = "";
	autoSuggestCheckBool: boolean = false;

	alertSeverity:string = "error";
	alertMsg:string = "";
	
	requestPayload = {
		appLandingPage: "",
		defaultDashboard: 0,
		repositoryLandingPage: "",
		retentionDays: 0,
		dashboardRefreshTime: 0,
		userDashboardWidgetLimit: 0,
		autoSuggest: "",
		dashboardRefreshOnOROff: "",
		viewStyle: "",
		directoryView: "",
	}
	
	backColor:any;
	value: number;
	wigetcolor: string;
	showClass: boolean=false;
	Disabled:boolean=false;
	buttonIndex :number;
	allColorButton:boolean = false;
	wigetBackgroundColor:any;
	event:any;
	selectedChartsColorSet: string;
	widgetBackColor:any;

	constructor(private settings: SettingsService, private messageService: MessageService) { 
		
		this.displayOptions = [
			{ label: "List", value: "List" },
			{ label: "Tile", value: "Tile" }
		];

		this.reportLists = [
			{ label: "List View", value: "ClientView" },
			{ label: "Folder View", value: "TreeView" }
		]

		this.landingPages = [
			{name: 'Home', code: 'LandingPage'},
			{name: 'Dashboard', code: 'myDashboard'},
			{name: 'Favorites', code: 'myFavorites'},
			{name: 'Reports', code: 'myReports'},
			{name: 'Reports Inbox', code: 'myInbox'},
			{name: 'Scheduled Reports', code: 'myScheduledReports'}
		];
      
		this.fonts = [
			{name:'Verdana', value: 'verdana'},
			{name:'San-Serif', value: 'san-serif'},
			{name:'Inter', value: 'inter'},
			{name:'Roboto', value:'roboto'},

		]
		this.fontSize = [
			{size: '10px', value:'10px'},
			{size: '12px', value:'12px'},
			{size: '14px', value:'14px'},
			{size: '16px', value:'16px'},

		]
		this.fontStyle = [
			{style: 'Normal', value:'normal'},
			{style: 'Bold', value:'bold'},
			{style: 'Italic', value:'italic'},
		]
	}

	colorOne = ["#FFFF", "#CFE2F3", "#E1CCB6", "#ffd670","#d9d9d9", "#ebb9b9","#e1d0e8", "#E3F2FD","#B2DFDB","#C8E6C9","#FFF9C4"];

	colorSetOne = ["#00A9E9" ,"#BC851C" ,"#004990" ,"#87189D" ,"#C5003E" ,"#28724F" ,"#92C1E9" ,"#CEB888" ,"#678787" ,"#B884CB" ,"#EF6079" ,"#85B09A"]
    colorSetTwo = ["#E95044","#F28A4C","#F0D444","#A2DF9C","#93DBE4","#B091D8","#DD1D24","#E35724","#EFE60C","#39A654","#0C9FDC","#812888"] //third set
    colorSetThree = ["#01204F","#006A8D","#03848C","#64A79D","#E9CFA1","#F2A465","#EF6727","#F03113","#E6DCAB","#E6DCAB","#DDBD36","#926A00"]; //yellow
	colorSetFour = ["#f7e37a", "#fbdd38", "#ebc403", "#f8c32a", "#e2a83d", "#f3b064", "#f68933", "#f5636d", "#ff4e5b", "#e42f24", "#bc2025", "#c02024"]; //yellow to red	
	colorSetFive = ["#012c66", "#86bedf", "#cfe8f7", "#cb2624", "#e34746", "#f47473", "#046365", "#13a1a4", "#68dddb", "#e7c414", "#ffe242", "#ffe87b"]; //Neon
	colorSetSix = ["#6E2C00", "#873600", "#A04000", "#BA4A00", "#D35400", "#DC7633", "#E59866", "#EDBB99", "#F6DDCC", "#FBEEE6", "#fff2eb", "#fff7f2"];// 6 cube set brown
	colorSetSeven = ["#002E55","#014C7C","#005B7C","#008098","#0E89B5","#0695AA","#35B5C1","#397C82","#87C9CF","#ACCBC6","#C0E0DB","#E4EBEA"]; //Brown color set
	colorSetEight = ["#3d818c","#63adb3","#95cdcf","#b9d0d0","#cfe6e6","#e5eeee","#eeebe3","#c3bca1","#c3bca1","#c4ad91","#b89f87","#a08d76"]; //4 cube set
	


	dashboardListSubscrip: Subscription;
	savedSettingsSubscrip: Subscription;
	saveNewSettingsSubscrip: Subscription;

	ngOnInit(): void {
		
		this.widgetBackColor = "#fff";

		d3.select(".tibo-topbar-title").text("Home / Settings"); // setting title

		this.dashboardListSubscrip = this.settings.getDashboardsList().subscribe((data: any) => { // getting the dashboards list
			
			this.defaultDashboardData = [];
			
			data.body.forEach((d,i) => {
				this.defaultDashDataCopy.push({name: d.widgetName, code: Number(d.widgetMasterId)})
			})
			
			this.defaultDashboardData = [...this.defaultDashDataCopy];
			
		}, error => { 
			
			this.alertSeverity = "error";
			
			if(Number(error.status) == 401){ this.alertMsg = "Please try after some time!" }
			else if(Number(error.status) == 500){ this.alertMsg = "Internal error" }
			else { this.alertMsg = "Please try after some time." }
			
			this.addSingle();
		}, () => {})
		
		this.savedSettingsSubscrip = this.settings.getSavedSettings().subscribe((data: any)=> { // getting the saved settings
				
			this.requestPayload.appLandingPage = data.body["appLandingPage"]
			this.requestPayload.defaultDashboard = data.body["defaultDashboard"]
			this.requestPayload.repositoryLandingPage = data.body["repositoryLandingPage"]
			this.requestPayload.retentionDays = data.body["retentionDays"]
			this.requestPayload.dashboardRefreshTime = data.body["dashboardRefreshTime"]
			this.requestPayload.userDashboardWidgetLimit = data.body?.userDashboardWidgetLimit == undefined ? 6 : data.body["userDashboardWidgetLimit"]
			this.requestPayload.autoSuggest = data.body["autoSuggest"]
			this.requestPayload.dashboardRefreshOnOROff = data.body["dashboardRefreshOnOROff"]
			this.requestPayload.viewStyle = data.body["viewStyle"]
			this.requestPayload.directoryView = data.body["directoryView"]
			
			this.savedDefaultDashboard = Number(data.body["defaultDashboard"])
			
			if(this.requestPayload.dashboardRefreshOnOROff == "Y"){
				this.autoRefreshCheckBool = true;
			}
			
			this.savedLandingPage = data.body["appLandingPage"]
			
			if(this.requestPayload.autoSuggest == "Y"){
				this.autoSuggestCheckBool = true;
			}
				
		}, error => { 

			this.alertSeverity = "error";
			
			if(Number(error.status) == 401){ this.alertMsg = "Please try after some time!" }
			else if(Number(error.status) == 500){ this.alertMsg = "Internal error" }
			else { this.alertMsg = "Please try after some time." }
			
			this.addSingle();
		}, () => {});

		
		d3.select(".tibo-settings-themes-container").selectAll("div.tibo-stgs-color-theme").on("click", function() {

			d3.select(".tibo-settings-themes-container").selectAll("div.tibo-stgs-color-theme").style("border","none")
			d3.select(this).style("border","5px solid #e0dbdb")

			d3.select("#tibo-min-leftpanel-container").style("background-color",d3.select(this).style("background-color"))
			d3.select("#tibo-large-leftpanel-container").style("background-color",d3.select(this).style("background-color"))
			d3.selectAll(".tibo-menupanel-largepanel-accrdn-wrapper .p-accordion .p-accordion-header .p-accordion-header-link")
			.style("background-color","transparent")
			
			d3.select("#lrg-header8 a").style("background-color","#3b586e");
			
		});

	}

	ngOnDestroy() {
		if(this.dashboardListSubscrip){
			this.dashboardListSubscrip.unsubscribe();
		}
		if(this.savedSettingsSubscrip){
			this.savedSettingsSubscrip.unsubscribe();
		}
		if(this.saveNewSettingsSubscrip){
			this.saveNewSettingsSubscrip.unsubscribe();
		}
	}
	
	defaultDashboard(event:any) {
		this.requestPayload.defaultDashboard = event.value
	}
	
	handleChange(event: any) {
		if(event.checked) {
		  this.requestPayload.dashboardRefreshOnOROff = "Y"
		}
		else {
		  this.requestPayload.dashboardRefreshOnOROff = "N"
		}
	}

	displayStyleChange(event: any) {
		this.requestPayload.viewStyle = event.value
	}
  
	displayStyleViewChange(event: any){
		this.requestPayload.directoryView = event.value
	}

	autoSuggestSearch(event:any){
		if(event.checked) {
			this.requestPayload.autoSuggest = "Y"
		}
		else {
			this.requestPayload.autoSuggest = "N"
		}
	}

	landingPage(event:any){
		this.requestPayload.appLandingPage = event.value
	}

	onChangeFrequency(event:any){
		this.requestPayload.dashboardRefreshTime = event.value
	}

	onChangeWidgetLimit(event:any){
		this.requestPayload.userDashboardWidgetLimit = event.value
	}
	
	onChangeRepInboxLimit(event:any){
		this.requestPayload.retentionDays = event.value
	}
	
	applyChanges(event: any) {
		this.saveNewSettingsSubscrip = this.settings.saveSettings(this.requestPayload).subscribe((data:any) => {

			if(data.status){
				this.alertSeverity = "success";
				this.alertMsg = "Changes applied!";
				this.addSingle();
			}
			
		}, error => { 

			this.alertSeverity = "error";
			
			if(Number(error.status) == 401){ this.alertMsg = "Please try after some time!" }
			else if(Number(error.status) == 500){ this.alertMsg = "Internal error" }
			else { this.alertMsg = "Please try after some time." }
			
			this.addSingle();
		}, () => {});


	}

	addSingle() { this.messageService.add({severity:this.alertSeverity, summary:"", detail:this.alertMsg}); }

    clear() { this.messageService.clear(); }


	getcolor(event, index){
		this.buttonIndex = index;
		this.widgetBackColor = event;
		// this.shared.DefaultWidgetBgColor(event);
	  }

	  changeChartColorsFunc(colorSet){
		// this.shared.DefaultWidgetColor(colorSet)
	}

}
