<div class="tibo-mydash-wrapper tibo-right-container">
    <div class="tibo-mydash-dashlistbtn-dashoptsbtn-container">

        <button id="mydash-dashlist-toggle-btn" class="tibo-mydash-dashlist-btn" type="button" pButton
            icon="pi pi-chevron-down" pTooltip="Dashboards" (click)="dashboardListFunc()"></button>
        <div id="tibo-mydash-dashlist-container-id" class="tibo-mydash-dashlist-container">
            <div class="dashlist-search">
                <span>Dashboard List</span><input type="text" [(ngModel)]="searchText" placeholder="search......"> <i
                    class="fa fa-search"></i>
            </div>
            <p-panelMenu [model]="items1" styleClass="tibo-mydash-dashlist-panel" [multiple]=false></p-panelMenu>
        </div>

        <div class="tibo-mydash-openeddashlist-chip-container">
            <p-chip styleClass="tibo-mydash-openeddashlist-chips"
                (click)="visitedDashFunc(selectedDashboard)">{{selectedDashboard}}</p-chip>
        </div>
        <p-overlayPanel #dashlistoverlaypanel styleClass="tibo-mydash-openeddashlist-overlaypanel"
            [showTransitionOptions]="'100ms'" appendTo="body" [showCloseIcon]=false>
            <ng-template pTemplate="body">
                <div>
                    <p-chip *ngFor="let dashboard of viewDashboardsList"
                        styleClass="tibo-mydash-openeddashlist-overlaypanel-chips"
                        (click)="visitedDashFunc(dashboard); dashlistoverlaypanel.hide()">{{dashboard}}</p-chip>
                </div>
            </ng-template>
        </p-overlayPanel>
        <div class="tibo-mydash-openeddashlist-chip-history">
            <i class="fa fa-history" (click)="dashlistoverlaypanel.toggle($event)"></i>
        </div>

        <label id="mydash-countdown-timer-label" class="tibo-mydash-refreshtime-label"></label>

        <button class="tibo-mydash-more-btn-icon tibo-mydash-chartcoloroptions-btn" type="button" pButton
            icon="pi pi-ellipsis-v" pTooltip="Options" (click)="moreoptions.toggle($event)"></button>
        <p-tieredMenu #moreoptions [model]="moreItems" [popup]="true" appendTo="body"></p-tieredMenu>
        <button class="tibo-mydash-chartcoloroptions-btn" type="button" pButton icon="pi pi-plus"
            pTooltip="Create-Dashboard"></button>
        <button class="tibo-mydash-chartcoloroptions-btn" type="button" pButton icon="pi pi-download"
            pTooltip="Download" (click)="downloadOptions.toggle($event)"></button>
        <p-tieredMenu #downloadOptions [model]="DownloadItems" [popup]="true" my="left top"
            at="left bottom"></p-tieredMenu>
        <button class="tibo-mydash-chartcoloroptions-btn" type="button" pButton icon="pi pi-window-maximize"
            pTooltip="Full Screen"></button>
        <button class="tibo-mydash-chartcoloroptions-btn tibo-mydash-chartcoloroptions-btn-icon" type="button" pButton
            icon="pi pi-palette" pTooltip="Color Sets" (click)="chartscolorsetsoverlaypanel.toggle($event)"></button>
        <p-overlayPanel #chartscolorsetsoverlaypanel styleClass="" [showTransitionOptions]="'100ms'" appendTo="body"
            [showCloseIcon]=false>
            <label class="p-grid p-ml-2 p-mb-2 theme-color" style="font-size: 16px;">Theme Colors</label>
            <p-tabView>
                <p-tabPanel header="Set Widget Color">
                    <div class="color-main">
                        <div class="theme-color">
                        </div>
                        <div class="color-set">
                            <div class="single-row-color-set">
                                <div class="p-p-2"
                                    [ngStyle]="{'background-color': colorSetOne[i], cursor:'pointer', heigth:'30px',width:'50px'}"
                                    *ngFor='let row of colorSetOne let i = index'
                                    (click)="changeChartColorsFunc('one')"><span pTooltip="{{row}}"
                                        class="inside-color">{{row}}</span></div>
                            </div>
                            <div class="single-row-color-set">
                                <div class="p-p-2" [ngStyle]="{'background-color': colorSetTwo[i], cursor:'pointer'}"
                                    *ngFor='let row of colorSetTwo let i = index'
                                    (click)="changeChartColorsFunc('two')"><span pTooltip="{{row}}"
                                        class="inside-color">{{row}}</span></div>
                            </div>
                            <div class="single-row-color-set">
                                <div class="p-p-2" [ngStyle]="{'background-color': colorSetThree[i], cursor:'pointer'}"
                                    *ngFor='let row of colorSetThree let i = index'
                                    (click)="changeChartColorsFunc('three')"><span pTooltip="{{row}}"
                                        class="inside-color">{{row}}</span></div>
                            </div>
                            <div class="single-row-color-set">
                                <div class="p-p-2" [ngStyle]="{'background-color': colorSetFour[i], cursor:'pointer'}"
                                    *ngFor='let row of colorSetFour let i = index'
                                    (click)="changeChartColorsFunc('four')"><span pTooltip="{{row}}"
                                        class="inside-color">{{row}}</span></div>
                            </div>
                            <div class="single-row-color-set">
                                <div class="p-p-2" [ngStyle]="{'background-color': colorSetFive[i], cursor:'pointer'}"
                                    *ngFor='let row of colorSetFive let i = index'
                                    (click)="changeChartColorsFunc('five')"><span pTooltip="{{row}}"
                                        class="inside-color">{{row}}</span></div>
                            </div>
                            <div class="single-row-color-set">
                                <div class="p-p-2" [ngStyle]="{'background-color': colorSetSix[i], cursor:'pointer'}"
                                    *ngFor='let row of colorSetSix let i = index'
                                    (click)="changeChartColorsFunc('six')"><span pTooltip="{{row}}"
                                        class="inside-color">{{row}}</span></div>
                            </div>
                            <div class="single-row-color-set">
                                <div class="p-p-2" [ngStyle]="{'background-color': colorSetSeven[i], cursor:'pointer'}"
                                    *ngFor='let row of colorSetSeven let i = index'
                                    (click)="changeChartColorsFunc('seven')"><span pTooltip="{{row}}"
                                        class="inside-color">{{row}}</span></div>
                            </div>
                            <div class="single-row-color-set">
                                <div class="p-p-2" [ngStyle]="{'background-color': colorSetEight[i], cursor:'pointer'}"
                                    *ngFor='let row of colorSetEight let i = index'
                                    (click)="changeChartColorsFunc('seven')"><span pTooltip="{{row}}"
                                        class="inside-color">{{row}}</span></div>
                            </div>
                        </div>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="Set Background Color">
                    <div class="color-setone">
                        <button class="rowOne" *ngFor="let color of colorOne ; let i = index"
                            [ngStyle]="{'background-color': color}" (click)="getcolor(color, i)"><span class="focus"
                                *ngIf="i === buttonIndex" [ngValue]="color">&#10003;</span></button>
                        <button class="rowOne"
                            [ngStyle]="{'background-image': 'linear-gradient(red,lightgreen,lightblue)'}"><span
                                class="focus" *ngIf="allColorButton">&#10003;</span></button>
                    </div>
                    <!-- <button class="rowOne" *ngFor="let color of colorOne,index as i" [ngStyle]="{'background-color': color}" (click)="getcolor(color)"><span >&#10003;</span></button> -->
                </p-tabPanel>
            </p-tabView>
        </p-overlayPanel>

    </div>
    <div #chartContainerDiv id="chartscontainerid">
        <div class="customer-experience-page" *ngIf="customerExperience">
            <div class="main-heading">
                <textarea [(ngModel)]="customerInputHeader" rows="4" class="input-header1"></textarea>
            </div>
            <textarea [(ngModel)]="customerInputText1" rows="4" class="input-text1"></textarea>
            <div class="p-grid" style="padding: 0px 23px;">
                <div class="chart-main">
                    <div class="p-col-6">
                        <textarea [(ngModel)]="inputText" rows="4" class="input-header"></textarea>
                        <div class="textarea">
                            <textarea [(ngModel)]="inputText2" rows="4" class="input-text"></textarea>
                        </div>
                    </div>
                    <div class="p-col-6">
                        <div class="change-chart">
                            <i class="pi pi-bars" (click)="toggleMenu()" pTooltip="Menu"></i>
                        </div>
                        <div *ngIf="menuVisible" class="menu">
                            <!-- Your menu content goes here -->
                            <ul>
                                <li (click)="onGraph('line')" pTooltip="Line Chart"><img
                                        src="images/linearLineIcon1.png" alt="" width="15px" height="15px"></li>
                                <li (click)="onGraph('bar')" pTooltip="Column Chart"><img src="images/columnIcon.png"
                                        alt="" width="15px" height="15px"></li>
                                <li (click)="onGraph('horizontalBar')" pTooltip="Horizontal Bar"><img
                                        src="images/horizontalIcon.png" alt="" width="15px" height="15px"></li>
                                <li (click)="onGraph('polarArea')" pTooltip="Circul Area Chart"><img
                                        src="images/circularAreaIcon.png" alt="" width="15px" height="15px"></li>
                                <li (click)="onGraph('pie')" pTooltip="Pie Chart"><img src="images/pieIcon.png" alt=""
                                        width="15px" height="15px"></li>
                            </ul>
                        </div>
                        <p-chart type="bar" [data]="basicData11" [options]="basicOptions"
                            *ngIf="typeOfGraph === 'bar'"></p-chart>
                        <p-chart type="line" [data]="basicDataa" [options]="basicOptions"
                            *ngIf="typeOfGraph === 'line'"></p-chart>
                        <p-chart type="horizontalBar" [data]="basicData" [options]="basicOptions"
                            *ngIf="typeOfGraph === 'horizontalBar'"></p-chart>
                        <p-chart type="polarArea" [data]="basicData" [options]="basicOptions"
                            *ngIf="typeOfGraph === 'polarArea'"></p-chart>
                        <p-chart type="pie" [data]="piedata" [options]="piechartOptions"
                            *ngIf="typeOfGraph === 'pie'"></p-chart>
                        <!-- <p-chart type="pie" [data]="basicData" [options]="basicOptions" *ngIf="typeOfGraph === 'pie'"></p-chart> -->
                    </div>
                </div>
            </div>
            <div class="p-grid">
                <div class="p-col-6">
                    <div class="chart-cont" style="margin-right: -24px;">
                        <h1 class="header">Docker Details</h1>
                        <div class="mobile-parent">
                            <div>
                                <i [class]="selectedIcon" style="font-size:70px" (click)="addMoreIcons()"></i>
                            </div>
                            <div style="text-align: center;">
                                <h1>69</h1>
                                <h2>Android</h2>
                            </div>
                        </div>
                        <div class="mobile-parent">
                            <div>
                                <i [class]="selectedIcon2" style="font-size:70px" (click)="addMoreIcons2()"></i>
                            </div>
                            <div style="text-align: center;">
                                <h1>110</h1>
                                <h2>iPhone</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-col-6">
                    <div class="chart-cont">
                        <h1 class="header">IAR</h1>
                        <p-chart type="doughnut" width="250" height="250" [data]="basicdata2" [options]="chartOptions">
                        </p-chart>
                    </div>
                </div>

            </div>
            <div class="p-grid chart-main11">
                <div class="p-col-12">
                    <div class="main">
                        <div class="first">
                            <i class="pi pi-upload"></i>
                        </div>
                        <div class="sec">
                            <textarea [(ngModel)]="inputText3" rows="4" class="input-header"></textarea>
                            <div class="textarea">
                                <textarea [(ngModel)]="inputText4" rows="4" class="input-text"></textarea>
                            </div>
                        </div>
                    </div>
                    <p-chart type="line" [data]="basicdata3" width="250" height="250" [options]="chartOptions2">
                    </p-chart>

                </div>

            </div>
            <div class="p-grid chart-main11" style="margin-top: 7px;">
                <div class="p-col-12">
                    <div class="main">
                        <div class="first">
                            <i class="pi pi-send"></i>
                        </div>
                        <div class="sec">
                            <textarea [(ngModel)]="inputText5" rows="4" class="input-header"></textarea>
                            <div class="textarea">
                                <textarea [(ngModel)]="inputText6" rows="4" class="input-text"></textarea>
                            </div>
                        </div>
                    </div>
                    <p-chart type="bar" [data]="basicData4" width="250" height="250" [options]="basicOptions3">
                    </p-chart>
                </div>

            </div>
        </div>
        <!-- <div *ngIf="fmlaPage">
    <div class="Fmla-page">
        <div class="main-heading">
            <div class="temp-dropdown">
                <p-dropdown 
            [options]="templates" 
            [(ngModel)]="selectTemplate" 
            optionLabel="name" 
            placeholder="Template2" (onChange)="onTemplateChange($event)"></p-dropdown>
        </div>
            <textarea [(ngModel)]="fmlaiInputHeader" rows="4" class="input-header1"></textarea>
        </div>
       <textarea [(ngModel)]="fmlaincidenceInputHeader" rows="4" class="input-text1"></textarea>           
        <div class="p-grid">
            <div class="p-col-9">
                <div>
                    <div class="one">
                        <div class="abc">
                            <h1>10</h1>
                            <h3>BOB (SIC)</h3>
                        </div>
                        <div class="para">
                            <textarea [(ngModel)]="l" rows="4" class="Fmlainput-text"></textarea> 
                        </div>
                    </div>
                    <div class="one">
                        <div class="abc">
                            <h1>10</h1>
                            <h3>IBI(SIC)</h3>
                        </div>
                        <div class="para">
                            <textarea [(ngModel)]="clientAllfmlaiInputText0" rows="4" class="Fmlainput-text"></textarea> 
                        </div>
                    </div>
                    <div class="one">
                        <div class="abc">
                            <h1>8</h1>
                            <h3>Client All Policy(prior 12 mo )</h3>
                        </div>
                        <div class="para">
                            <textarea [(ngModel)]="clientAllfmlaiInputText1" rows="4" class="Fmlainput-text"></textarea> 
                        </div>
                    </div>
                    <div class="one">
                        <div class="abc">
                            <h1>7</h1>
                            <h3>Client All Policy(period queried)</h3>
                        </div>
                        <div class="para">
                            <textarea [(ngModel)]="clientAllfmlaiInputText2" rows="4" class="Fmlainput-text"></textarea> 
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-3">
                <div class="sub-one">
                    <i class="pi pi-calendar"></i>
                     <h1>6</h1>
                     <h3>Client F M L A<br>
                        (prior 12 month)</h3>
                </div>
                <div class="sub-one">
                    <i class="pi pi-clock"></i>
                    <h1>7</h1>
                    <h3>Client F M L A<br>
                       (prior 12 month)</h3>
                </div>
                <div class="sub-one">
                    <i class="fa fa-percent"></i>
                    <h1>6</h1>
                    <h3>Client F M L A<br>
                       (prior 12 month)</h3>
                </div>
            </div>

        </div>
    </div>
    <div class="Fmla-page2">
        <div class="main-heading">
            <textarea [(ngModel)]="fmlaiInputHeader2" rows="4" class="input-header1"></textarea>
        </div>
        <textarea [(ngModel)]="fmlaiInputText2" rows="4" class="input-text1"></textarea> 
        <div class="p-grid">
            <div class="p-col-3">
                <div class="dashboard-div">
                <h3 class="header">Client All Policy(prior 12 mo )</h3>
                <p-chart type="doughnut" width="200" height="200" [data]="fmlabasicdata" [options]="fmlachartOptions">
                </p-chart>
                    <textarea [(ngModel)]="chartInputPara" rows="4" class="input-para"></textarea>
                </div>
           </div>
           <div class="p-col-3">
            <div class="dashboard-div">
                <h3 class="header">Client All Policy(prior 6 mo )</h3>
                <p-chart type="doughnut" width="200" height="200" [data]="fmlabasicdata" [options]="fmlachartOptions">
                </p-chart>
                    <textarea [(ngModel)]="chartInputPara1" rows="4" class="input-para"></textarea>
                </div> 
           </div>
           <div class="p-col-3">
            <div class="dashboard-div">
                <h3 class="header">Client All Policy(prior 3 mo )</h3>
                <p-chart type="doughnut" width="200" height="200" [data]="fmlabasicdata" [options]="fmlachartOptions">
                </p-chart>
                    <textarea [(ngModel)]="chartInputPara2" rows="4" class="input-para"></textarea>
                </div>
           </div>
           <div class="p-col-3">
            <div class="dashboard-div">
                <h3 class="header">Client All Policy(prior 2 mo )</h3>
                <p-chart type="doughnut" width="200" height="200" [data]="fmlabasicdata" [options]="fmlachartOptions">
                </p-chart>
                    <textarea [(ngModel)]="chartInputPara3" rows="4" class="input-para"></textarea>
                </div>
           </div>
</div>
</div>
<div class="Fmla-page3">
    <div class="main-heading">
        <textarea [(ngModel)]="fmlaiInputHeader3" rows="4" class="input-header1"></textarea>
    </div>
   <textarea [(ngModel)]="fmlaiInputText3" rows="4" class="input-text1"></textarea>  
    <div class="p-grid" style="justify-content: center;">
        <div class="p-col-9">
            <div>
                <div class="one">
                    <div class="abc">
                        <h1>10</h1>
                        <h3>Client FMLA(Current Year)</h3>
                    </div>
                    <div class="para">
                        <textarea [(ngModel)]="fteInputText" rows="4" class="Fmlainput-text"></textarea> 
                    </div>
                </div>
                <div class="one">
                    <div class="abc">
                        <h1>10</h1>
                        <h3>Client All Policy(prior 12 mo )</h3>
                    </div>
                    <div class="para">
                        <textarea [(ngModel)]="fteInputText1" rows="4" class="Fmlainput-text"></textarea> 
                    </div>
                </div>
                <div class="one">
                    <div class="abc">
                        <h1>8</h1>
                        <h3>Client FMLA(Current Year)</h3>
                    </div>
                    <div class="para">
                        <textarea [(ngModel)]="fteInputText2" rows="4" class="Fmlainput-text"></textarea> 
                    </div>
                </div>
                <div class="one">
                    <div class="abc">
                        <h1>7</h1>
                        <h3>Client All Policy(period queried)</h3>
                    </div>
                    <div class="para">
                        <textarea [(ngModel)]="fteInputText3" rows="4" class="Fmlainput-text"></textarea> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="Fmla-page4">
    <div class="main-heading">
        <textarea [(ngModel)]="fmlaiInputHeader4" rows="4" class="input-header1"></textarea>
    </div>
   <textarea [(ngModel)]="standaloneInputText1" rows="4" class="input-text1"></textarea> 
   <div class="p-grid" style="margin: 0px 63px 0px 63px;">
<div class="p-col-6">
    <div class="dashboard-div1">
        <h3 class="header">Client All Policy(prior 12 mo )</h3>
        <p-chart 
        type="horizontalBar" 
        width="200" 
        height="200" 
        [data]="horizontalBasicData"
        [options]="horizontalOptions">
      </p-chart>
      
            <textarea [(ngModel)]="standaloneInputText1" rows="4" class="input-para"></textarea>
        </div>
</div>
<div class="p-col-6">
    <div class="dashboard-div1">
        <h3 class="header">Client All Policy(prior 12 mo )</h3>
        <p-chart 
        type="horizontalBar" 
        width="200" 
        height="200" 
        [data]="horizontalBasicData"
        [options]="horizontalOptions">
      </p-chart>
            <textarea [(ngModel)]="fmlaiInputPara" rows="4" class="input-para"></textarea>
        </div>
</div>
   </div>
</div>
<div class="fmla-page5">
    <div class="main-heading">
        <textarea [(ngModel)]="fmlaiReasonHeader" rows="4" class="input-header1"></textarea>
    </div>
   <textarea [(ngModel)]="fmlareasonInputText4" rows="4" class="input-text1"></textarea>
    <div class="p-grid dashboardpage4">
        <div class="p-col-6">
            <textarea [(ngModel)]="fmlaInputText55" rows="4" class="input-header"></textarea>
            <div class="textarea">
                <textarea [(ngModel)]="fmlareasonInputParasec" rows="4" class="input-text"></textarea>
            </div>
        </div>
        <div class="p-col-6">
            <p-chart type="bar" [data]="basicData4" width="250" height="250" [options]="basicOptions3">
            </p-chart>
        </div>

    </div>
    <div class="p-grid dashboardpage4">
        <div class="p-col-6">
            <textarea [(ngModel)]="fmlaInputText5" rows="4" class="input-header"></textarea>
            <div class="textarea">
                <textarea [(ngModel)]="fmlareasonInputPara" rows="4" class="input-text"></textarea>
            </div>
        </div>
        <div class="p-col-6">
            <p-chart type="bar" [data]="basicData4" width="250" height="250" [options]="basicOptions3">
            </p-chart>
        </div>

    </div>
</div>
<div class="fmla-page6">
    <div class="main-heading">
        <textarea [(ngModel)]="fmlaDurationHeader" rows="4" class="input-header1"></textarea>
    </div>
   <textarea [(ngModel)]="fmlaiInputText4" rows="4" class="input-text1"></textarea>
   <div class="p-grid dashboardpage4">
    <div class="p-col-12">
        <div class="main-heading5">
            <textarea [(ngModel)]="fmlaDurationHeader" rows="4" class="input-header5"></textarea>
            <textarea [(ngModel)]="fmlaiInputPara" rows="4" class="input-text5"></textarea>
        </div>
       <p-chart type="bar" [data]="fmlaDurationbasicData" width="250" height="250" [options]="fmlaDurationbasicOptions">
    </p-chart>
    </div>
   </div>
</div>
<div class="fmla-page6">
    <div class="main-heading">
        <textarea [(ngModel)]="fmlaApprovalRate" rows="4" class="input-header1"></textarea>
    </div>
   <textarea [(ngModel)]="fmlaiInputText4" rows="4" class="input-text1"></textarea>
   <div class="p-grid dashboardpage4">
    <div class="p-col-12">
        <div class="main-heading5">
            <textarea [(ngModel)]="fmlaDurationHeader" rows="4" class="input-header5"></textarea>
            <textarea [(ngModel)]="fmladurationInputPara" rows="4" class="input-text5"></textarea>
        </div>
       <p-chart type="bar" [data]="fmlaDurationbasicData" width="250" height="250" [options]="fmlaDurationbasicOptions">
    </p-chart>
    </div>
   </div>
</div>
</div> -->
        <div *ngIf="leavePage">
            <div class="leave-page">
                <div class="main-heading">
                    <div class="temp-dropdown">
                        <p-dropdown [options]="templates" [(ngModel)]="selectTemplate" optionLabel="name"
                            placeholder="Template1" (onChange)="onTemplateChange($event)"></p-dropdown>
                    </div>
                    <textarea [(ngModel)]="LeaveInputHeader" rows="4" class="input-header1"></textarea>
                </div>
                <textarea [(ngModel)]="fmlaiInputText1" rows="4" class="input-text1"></textarea>
                <div class="p-grid">
                    <div class="p-col-6">
                        <div class="chart-cont1">
                            <div class="change-chart">
                                <i class="pi pi-bars" (click)="toggleMenu1()" pTooltip="Menu"></i>
                            </div>
                            <div *ngIf="menuVisible1" class="menu">
                                <!-- Your menu content goes here -->
                                <ul>
                                    <li (click)="onGraph1('line')" pTooltip="Line Chart"><img
                                            src="images/linearLineIcon1.png" alt="" width="15px" height="15px"></li>
                                    <li (click)="onGraph1('bar')" pTooltip="Column Chart"><img
                                            src="images/columnIcon.png" alt="" width="15px" height="15px"></li>
                                    <li (click)="onGraph1('horizontalBar')" pTooltip="Horizontal Bar"><img
                                            src="images/horizontalIcon.png" alt="" width="15px" height="15px"></li>
                                    <li (click)="onGraph1('polarArea')" pTooltip="Circul Area Chart"><img
                                            src="images/circularAreaIcon.png" alt="" width="15px" height="15px"></li>
                                    <li (click)="onGraph1('pie')" pTooltip="Pie Chart"><img src="images/pieIcon.png"
                                            alt="" width="15px" height="15px"></li>
                                </ul>
                            </div>
                            <h3 class="header2">How Many Request Per 100 Employee</h3>
                            <p-chart type="horizontalBar" [data]="leavebasicData" [options]="leavebasicOptions"
                                width="350" height="230" *ngIf="typeOfGraph1 === 'horizontalBar'"></p-chart>
                            <p-chart type="bar" [data]="leavebasicData" [options]="leavebasicOptions"
                                *ngIf="typeOfGraph1 === 'bar'" width="350" height="230"></p-chart>
                            <p-chart type="line" [data]="leaveLinebasicdata" [options]="leavebasicOptions"
                                *ngIf="typeOfGraph1 === 'line'" width="350" height="230"></p-chart>
                            <p-chart type="polarArea" [data]="leavePolarAreadata" [options]="leavePolarAreachartOptions"
                                *ngIf="typeOfGraph1 === 'polarArea'" width="350" height="230"></p-chart>
                            <p-chart type="pie" [data]="Leavepiedata" [options]="leavepiechartOptions"
                                *ngIf="typeOfGraph1 === 'pie'" width="350" height="230"></p-chart>
                        </div>
                    </div>
                    <div class="p-col-6">
                        <div class="chart-cont1" style="margin-right: 15px;">
                            <h3 class="header">FMLA Utility</h3>
                            <div class="male">
                                <div>
                                    <i [class]="selectedIconLeave1" (click)="addMoreIconsForLeave('first')"
                                        (click)="activefunForLeave(selectedIconLeave1)"></i>
                                    <h1>43%</h1>
                                </div>
                                <div>
                                    <i [class]="selectedIconLeave2" (click)="addMoreIconsForLeave('second')"
                                        (click)="activefunForLeave(selectedIconLeave2)"></i>
                                    <h1>57%</h1>
                                </div>

                            </div>
                            <h3 class="header">FMLA Headcount</h3>
                            <div class="male">
                                <div>
                                    <i [class]="selectedIconLeave3" (click)="addMoreIconsForLeave('third')"
                                        (click)="activefunForLeave(selectedIconLeave3)"></i>
                                    <h1>49%</h1>
                                </div>
                                <div>
                                    <i [class]="selectedIconLeave4" (click)="addMoreIconsForLeave('forth')"
                                        (click)="activefunForLeave(selectedIconLeave4)"></i>
                                    <h1>51%</h1>
                                </div>

                            </div>
                            <textarea [(ngModel)]="leaveInputPara" rows="3" class="leave-input-para"></textarea>
                        </div>
                    </div>
                </div>
                <div class="p-grid chart-main1">
                    <div class="p-col-6">
                        <h3 class="header2">What is the Absence Time Expressed as a Full Time Equivalency?</h3>
                        <p-chart type="horizontalBar" [data]="leavebasicDataset" [options]="leavehorizontalOptions"
                            width="350" height="300"></p-chart>
                    </div>
                    <div class="p-col-6">
                        <h1 class="xheader">X%</h1>
                        <div class="textarea">
                            <textarea [(ngModel)]="leaveinputText2" rows="4" class="input-text"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="leave-page">
                <!-- <div class="main-heading">
        <textarea [(ngModel)]="fmlaiInputHeader2" rows="4" class="input-header1"></textarea>
    </div>
    <textarea [(ngModel)]="fmlaiInputText2" rows="4" class="input-text1"></textarea>  -->
                <div class="p-grid" style="margin: 0px 6px;">
                    <div class="p-col-6">
                        <div class="leave-dashboard">
                            <h3 class="header">What are the FMLA Claim Decisions?</h3>
                            <p-chart type="doughnut" width="200" height="200" [data]="fmlabasicdata"
                                [options]="fmlachartOptions">
                            </p-chart>
                            <textarea [(ngModel)]="leaverelateInputPara" rows="4" class="input-para"></textarea>
                        </div>
                    </div>
                    <div class="p-col-6">
                        <div class="leave-dashboard">
                            <h3 class="header">Why are FMLA Requests Denied?</h3>
                            <p-chart type="doughnut" width="200" height="200" [data]="leavedonutbasicdata"
                                [options]="leavedonutchartOptions">
                            </p-chart>
                            <textarea [(ngModel)]="leaverelateInputPara1" rows="4" class="input-para"></textarea>
                        </div>
                    </div>
                    <div class="p-col-6">
                        <div class="leave-dashboard">
                            <h3 class="header">What are the Claim Decison for all Leaves?</h3>
                            <p-chart type="doughnut" width="200" height="200" [data]="fmlabasicdata"
                                [options]="fmlachartOptions">
                            </p-chart>
                            <textarea [(ngModel)]="leaverelateInputPara2" rows="4" class="input-para"></textarea>
                        </div>
                    </div>
                    <div class="p-col-6">
                        <div class="leave-dashboard">
                            <h3 class="header">Why are all Leaves Requests Denied?</h3>
                            <p-chart type="doughnut" width="200" height="200" [data]="leavedonutbasicdata"
                                [options]="leavedonutchartOptions">
                            </p-chart>
                            <textarea [(ngModel)]="leaverelateInputPara3" rows="4" class="input-para"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="leave-page">
                <!-- <div class="main-heading">
        <textarea [(ngModel)]="fmlaiInputHeader2" rows="4" class="input-header1"></textarea>
    </div>
    <textarea [(ngModel)]="fmlaiInputText2" rows="4" class="input-text1"></textarea>  -->
                <div class="p-grid" style="margin: 0px 6px;">
                    <div class="p-col-9">
                        <div class="leave1-div">
                            <textarea [(ngModel)]="leaveInputText5" rows="4" class="input-header"></textarea>
                            <div class="textarea">
                                <textarea [(ngModel)]="fmlaiInputPara" rows="4" class="input-text"></textarea>
                            </div>
                            <p-chart type="bar" [data]="basicData45" width="250" height="150" [options]="basicOptions3">
                            </p-chart>
                        </div>
                        <div class="leave1-div" style="margin-top: 10px;">
                            <textarea [(ngModel)]="leaveInputText56" rows="4" class="input-header"></textarea>
                            <div class="textarea">
                                <textarea [(ngModel)]="fmlaiInputPara" rows="4" class="input-text"></textarea>
                            </div>
                            <p-chart type="bar" [data]="basicData45" width="250" height="150" [options]="basicOptions3">
                            </p-chart>
                        </div>
                    </div>
                    <div class="p-col-3">
                        <div class="leave-dashboard-div">
                            <h3 class="header">Note</h3>
                            <textarea [(ngModel)]="leaveInputParaa" rows="4" class="leave-input-para1"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="chartsPage" class="temp3-chartpage">
            <div class="chartPage1">
                <div style="padding:7px;display: flex;">
                     <textarea [(ngModel)]="LeaveInputHeader" rows="4" class="input-header1" ></textarea>
                <div class="temp3-main-heading">
                    <div class="">
                        <p-dropdown [options]="templates" [(ngModel)]="selectTemplate" optionLabel="name"
                            placeholder="Template3" (onChange)="onTemplateChange($event)"></p-dropdown>
                    </div>
                </div></div>
                <div class="p-grid" >
                    <div class="p-col-9 temp3-charts-container">
                        <div class="p-grid">
                            <div class="p-col-4" style="padding:7px;margin-bottom: 9px;">
                                <div class="temp3-eachchart3">
                                    <!-- <textarea [innerHTML]="chartInputText1" rows="4" class="input-text0" (click)="openEditor($event)"></textarea> -->
                                    <div style="overflow: auto;height: 48px;"><div [innerHTML]="chartInputText1" style="margin-top: 0px;padding-top: 10px;" class="input-text0" (click)="openEditor($event,overlayPanel,chartInputText1)"></div>
                                    <p-overlayPanel #op>
                                        <p-editor [(ngModel)]="chartInputText1" [style]="{'height': '255px', 'width': '100%'}"></p-editor>
                                        <div style="margin-top: 10px;">
                                          <button pButton type="button" style="margin-right:9px" label="Save" icon="pi pi-check" (click)="saveText(overlayPanel)"></button>
                                          <button pButton type="button" label="Cancel" icon="pi pi-times" (click)="cancelEdit(overlayPanel)"></button>
                                        </div>
                                      </p-overlayPanel></div>
                                   <div class="change-chart">
                                        <i class="pi pi-bars" (click)="toggleMenu()" pTooltip="Menu"></i>
                                    </div>
                                    <div *ngIf="menuVisible" class="menu">
                                        <!-- Your menu content goes here -->
                                        <ul>
                                            <li (click)="onGraph('line')" pTooltip="Line Chart"><img
                                                    src="images/linearLineIcon1.png" alt="" width="15px" height="15px">
                                            </li>
                                            <li (click)="onGraph('bar')" pTooltip="Column Chart"><img
                                                    src="images/columnIcon.png" alt="" width="15px" height="15px"></li>
                                            <li (click)="onGraph('horizontalBar')" pTooltip="Horizontal Bar"><img
                                                    src="images/horizontalIcon.png" alt="" width="15px" height="15px">
                                            </li>
                                            <li (click)="onGraph('polarArea')" pTooltip="Circul Area Chart"><img
                                                    src="images/circularAreaIcon.png" alt="" width="15px" height="15px">
                                            </li>
                                            <li (click)="onGraph('pie')" pTooltip="Pie Chart"><img
                                                    src="images/pieIcon.png" alt="" width="15px" height="15px"></li>
                                        </ul>
                                    </div>
                                    <p-chart type="bar" [data]="templ3basicData" [options]="templ3basicOptions"
                                        *ngIf="typeOfGraph === 'bar'"></p-chart>
                                    <p-chart type="line" [data]="chartLinebasicDataa" [options]="chartLinebasicOptions"
                                        *ngIf="typeOfGraph === 'line'"></p-chart>
                                    <p-chart type="horizontalBar" [data]="templ3basicData"
                                        [options]="chartLinebasicOptions"
                                        *ngIf="typeOfGraph === 'horizontalBar'"></p-chart>
                                    <p-chart type="polarArea" [data]="templ3basicData" [options]="templ3basicOptions"
                                        *ngIf="typeOfGraph === 'polarArea'"></p-chart>
                                    <p-chart type="pie" [data]="templ3basicData" [options]="templ3basicOptions"
                                        *ngIf="typeOfGraph === 'pie'"></p-chart>
                                </div>
                            </div>
                            <div class="p-col-4" style="padding:7px;margin-bottom: 9px;">
                                <div class="temp3-eachchart3">
                                    <!-- <div class="chartHeader">Who is requesting leave?</div> -->
                                    <!-- <textarea [(ngModel)]="chartInputText2" rows="4" class="input-text0"></textarea> -->
                                    <div style="overflow: auto;height: 48px;"><div [innerHTML]="chartInputText2" style="margin-top: 0px;padding-top: 10px;" class="input-text0" (click)="openEditor1($event,overlayPanel1,chartInputText2)"></div>
                                    <p-overlayPanel #op1>
                                        <p-editor [(ngModel)]="chartInputText2" [style]="{'height': '255px', 'width': '100%'}"></p-editor>
                                        <div style="margin-top: 10px;">
                                          <button pButton type="button" style="margin-right:9px" label="Save" icon="pi pi-check" (click)="saveText1(overlayPanel1)"></button>
                                          <button pButton type="button" label="Cancel" icon="pi pi-times" (click)="cancelEdit1(overlayPanel1)"></button>
                                        </div>
                                      </p-overlayPanel></div>
                                    <p-chart type="horizontalBar" [data]="templ3basicData1"
                                        [options]="templ3basicOptions1">
                                    </p-chart>
                                </div>
                            </div>
                            <div class="p-col-4" style="padding:7px;margin-bottom: 9px;">
                                <div class="temp3-eachchart3">
                                    <!-- <div class="chartHeader">What is the absence time expressed as a full time equivalency?</div> -->
                                    <!-- <textarea [(ngModel)]="chartInputText3" rows="4" class="input-text00"></textarea> -->
                                    <div [innerHTML]="chartInputText3" style="margin-top: 0px;padding-top: 10px;" class="input-text0" (click)="openEditor2($event,overlayPanel2,chartInputText3)"></div>
                                    <p-overlayPanel #op2>
                                        <p-editor [(ngModel)]="chartInputText3" [style]="{'height': '255px', 'width': '100%'}"></p-editor>
                                        <div style="margin-top: 10px;">
                                          <button pButton type="button" style="margin-right:9px" label="Save" icon="pi pi-check" (click)="saveText2(overlayPanel2)"></button>
                                          <button pButton type="button" label="Cancel" icon="pi pi-times" (click)="cancelEdit2(overlayPanel2)"></button>
                                        </div>
                                      </p-overlayPanel>
                                    <p-chart type="bar" [data]="temp3groupedbasicData"
                                        [options]="temp3groupedbasicOptions">
                                    </p-chart>
                                </div>
                            </div>
                        </div>
                        <div class="p-grid">
                            <div class="p-col-6" style="padding:7px;margin-bottom: 9px;">
                                <div style="display: flex;height:100%">
                                <div class="temp3-eachchart1">
                                    <!-- <div class="chartHeader">What are the FML claim decisions?</div> -->
                                    <!-- <textarea [(ngModel)]="chartInputText4" rows="4" class="input-text00"></textarea> -->
                                    <div [innerHTML]="chartInputText4" style="margin-top: 0px;padding-top: 10px;" class="input-text0" (click)="openEditor3($event,overlayPanel3,chartInputText4)"></div>
                                    <p-overlayPanel #op3>
                                        <p-editor [(ngModel)]="chartInputText4" [style]="{'height': '255px', 'width': '100%'}"></p-editor>
                                        <div style="margin-top: 10px;">
                                          <button pButton type="button" style="margin-right:9px" label="Save" icon="pi pi-check" (click)="saveText3(overlayPanel3)"></button>
                                          <button pButton type="button" label="Cancel" icon="pi pi-times" (click)="cancelEdit3(overlayPanel3)"></button>
                                        </div>
                                      </p-overlayPanel>
                                    <p-chart type="pie" [data]="temp3piedata"
                                        [options]="temp3piechartOptions"></p-chart>
                                </div>

                                <div class="temp3-eachchart1">
                                    <!-- <div class="chartHeader2">Why are FML requests denied?</div> -->
                                    <!-- <textarea [(ngModel)]="chartInputText5" rows="4" class="input-text00"></textarea> -->
                                    <div [innerHTML]="chartInputText5" style="margin-top: 0px;padding-top: 10px;" class="input-text0" (click)="openEditor4($event,overlayPanel4,chartInputText5)"></div>
                                    <p-overlayPanel #op4>
                                        <p-editor [(ngModel)]="chartInputText5" [style]="{'height': '255px', 'width': '100%'}"></p-editor>
                                        <div style="margin-top: 10px;">
                                          <button pButton type="button" style="margin-right:9px" label="Save" icon="pi pi-check" (click)="saveText4(overlayPanel4)"></button>
                                          <button pButton type="button" label="Cancel" icon="pi pi-times" (click)="cancelEdit4(overlayPanel4)"></button>
                                        </div>
                                      </p-overlayPanel>
                                    <p-chart type="pie" [data]="temp3piedata1" [options]="temp3piechartOptions1"
                                        width="302" height="125"></p-chart>
                                </div>
                            </div>
                            </div>
                            <div class="p-col-6" style="padding:7px;margin-bottom: 9px;">
                                <div style="display: flex;height:100%">
                                <div class="temp3-eachchart1">
                                    <!-- <div class="chartHeader">What are the claim decisions for All Leaves?</div> -->
                                    <!-- <textarea [(ngModel)]="chartInputText6" rows="4" class="input-text00"></textarea> -->
                                    <div [innerHTML]="chartInputText6" style="margin-top: 0px;padding-top: 10px;" class="input-text0" (click)="openEditor5($event,overlayPanel5,chartInputText6)"></div>
                                    <p-overlayPanel #op5>
                                        <p-editor [(ngModel)]="chartInputText6" [style]="{'height': '255px', 'width': '100%'}"></p-editor>
                                        <div style="margin-top: 10px;">
                                          <button pButton type="button" style="margin-right:9px" label="Save" icon="pi pi-check" (click)="saveText5(overlayPanel5)"></button>
                                          <button pButton type="button" label="Cancel" icon="pi pi-times" (click)="cancelEdit5(overlayPanel5)"></button>
                                        </div>
                                      </p-overlayPanel>
                                    <p-chart type="pie" [data]="temp3piedata2"
                                        [options]="temp3piechartOptions2"></p-chart>
                                </div>


                                <div class="temp3-eachchart1">
                                    <!-- <div class="chartHeader2">Why are all requests denied?</div> -->
                                    <!-- <textarea [(ngModel)]="chartInputText7" rows="4" class="input-text00"></textarea> -->
                                    <div [innerHTML]="chartInputText7" style="margin-top: 0px;padding-top: 10px;" class="input-text0" (click)="openEditor6($event,overlayPanel6,chartInputText7)"></div>
                                    <p-overlayPanel #op6>
                                        <p-editor [(ngModel)]="chartInputText7" [style]="{'height': '255px', 'width': '100%'}"></p-editor>
                                        <div style="margin-top: 10px;">
                                          <button pButton type="button" style="margin-right:9px" label="Save" icon="pi pi-check" (click)="saveText6(overlayPanel6)"></button>
                                          <button pButton type="button" label="Cancel" icon="pi pi-times" (click)="cancelEdit6(overlayPanel6)"></button>
                                        </div>
                                      </p-overlayPanel>
                                    <p-chart type="pie" [data]="temp3piedata1" [options]="temp3piechartOptions1"
                                        width="302" height="125"></p-chart>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="p-grid">
                            <div class="p-col-6" style="padding:7px;margin-bottom: 9px;">
                                <div class="temp3-eachchart">
                                    <!-- <div class="chartHeader">Why are employees requesting FML?</div> -->
                                    <!-- <textarea [(ngModel)]="chartInputText8" rows="4" class="input-text0"></textarea> -->
                                    <div [innerHTML]="chartInputText8" style="margin-top: 0px;padding-top: 10px;" class="input-text0" (click)="openEditor7($event,overlayPanel7,chartInputText8)"></div>
                                    <p-overlayPanel #op7>
                                        <p-editor [(ngModel)]="chartInputText8" [style]="{'height': '255px', 'width': '100%'}"></p-editor>
                                        <div style="margin-top: 10px;">
                                          <button pButton type="button" style="margin-right:9px" label="Save" icon="pi pi-check" (click)="saveText7(overlayPanel7)"></button>
                                          <button pButton type="button" label="Cancel" icon="pi pi-times" (click)="cancelEdit7(overlayPanel7)"></button>
                                        </div>
                                      </p-overlayPanel>
                                    <p-chart type="bar" [data]="temp3basicData" [options]="temp3basicOptions"
                                        width="250" height="250">
                                    </p-chart>
                                </div>
                            </div>
                            <div class="p-col-6" style="padding:7px;margin-bottom: 9px;">
                                <div class="temp3-eachchart">
                                    <!-- <div class="chartHeader">Why are employees requesting Leave?</div> -->
                                    <!-- <textarea [(ngModel)]="chartInputText9" rows="4" class="input-text0"></textarea> -->
                                    <div [innerHTML]="chartInputText9" style="margin-top: 0px;padding-top: 10px;" class="input-text0" (click)="openEditor8($event,overlayPanel8,chartInputText9)"></div>
                                    <p-overlayPanel #op8>
                                        <p-editor [(ngModel)]="chartInputText9" [style]="{'height': '255px', 'width': '100%'}"></p-editor>
                                        <div style="margin-top: 10px;">
                                          <button pButton type="button" style="margin-right:9px" label="Save" icon="pi pi-check" (click)="saveText8(overlayPanel8)"></button>
                                          <button pButton type="button" label="Cancel" icon="pi pi-times" (click)="cancelEdit8(overlayPanel8)"></button>
                                        </div>
                                      </p-overlayPanel>
                                    <p-chart type="bar" [data]="temp3basicData" [options]="temp3basicOptions" width="250" height="250">
                                    </p-chart>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-2 " style="padding:7px;margin-bottom: 25px;width:23%">
                        <div class="temp3-text-container">
                            <div style="display: inline-flex">
                                <span class="x-class">X%</span>
                            <!-- Both FML and All Policy Utilization increased over the prior Year and has Exceeded the incident cap.(Be Aware of any carveouts) -->
                            <!-- <textarea [(ngModel)]="chartInputText90" rows="3" class="input-text-chart"></textarea> -->
                            <div [innerHTML]="chartInputText90" style="margin-top: 0px;padding-top: 10px;" class="input-text0" (click)="openEditor9($event,overlayPanel9,chartInputText90)"></div>
                            <p-overlayPanel #op9>
                                <p-editor [(ngModel)]="chartInputText90" [style]="{'height': '255px', 'width': '100%'}"></p-editor>
                                <div style="margin-top: 10px;">
                                  <button pButton type="button" style="margin-right:9px" label="Save" icon="pi pi-check" (click)="saveText9(overlayPanel9)"></button>
                                  <button pButton type="button" label="Cancel" icon="pi pi-times" (click)="cancelEdit9(overlayPanel9)"></button>
                                </div>
                              </p-overlayPanel>
                            </div>
                            <div style="margin-top: 50px;">
                                <h3><span class="x-class">60%</span>
                                    <textarea [(ngModel)]="chartInputText10" rows="2"
                                        class="input-text-chart1"></textarea>
                                </h3>
                            </div>
                            <ul class="list-class">
                                <li>FML Time used equals 518 full time employess per day.but all other leaves policy
                                    types increase FTE to 938</li>
                                <li>FML Time used equals 518 full time employess per day.but all other leaves policy
                                    types increase FTE to 938</li>
                                <li>FML Time used equals 518 full time employess per day.but all other leaves policy
                                    types increase FTE to 938</li>
                                <li>FML Time used equals 518 full time employess per day.but all other leaves policy
                                    types increase FTE to 938</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chartPage2" style="margin-top: 12px;">
                <div class="p-grid">
                    <div class="p-col-9 temp3-charts-container">
                        <div class="p-grid">
                            <div class="p-col-6" style="padding:7px;margin-bottom: 9px;">
                                <div class="chart-a" style="display: flex;height:100%;flex-wrap: wrap;">
                                    <!-- <textarea [(ngModel)]="chartInputHeader1" rows="4" class="input-text0"></textarea> -->
                                    <div [innerHTML]="chartInputHeader1" style="margin-top: 0px;padding-top: 10px;" class="input-text0" (click)="openEditor10($event,overlayPanel10,chartInputHeader1)"></div>
                            <!-- <p-overlayPanel #op10>
                                <p-editor [(ngModel)]="chartInputHeader1" [style]="{'height': '255px', 'width': '100%'}"></p-editor>
                                <div style="margin-top: 10px;">
                                  <button pButton type="button" style="margin-right:9px" label="Save" icon="pi pi-check" (click)="saveText10(overlayPanel10)"></button>
                                  <button pButton type="button" label="Cancel" icon="pi pi-times" (click)="cancelEdit10(overlayPanel10)"></button>
                                </div>
                              </p-overlayPanel> -->
                                    <div class="p-col-6 main-chart-divs" style=" border-right: 2px dotted #352525;">
                                        <div class="a">
                                            <h3>Continuous</h3>
                                            <p-chart type="bar" [data]="chartpage2basicData"
                                                [options]="chartpage2basicOptions" width="150" height="150">
                                            </p-chart>
                                        </div>

                                    </div>
                                    <div class="p-col-6 main-chart-divs">
                                        <div class="a">
                                            <h3>Intermittent</h3>
                                            <p-chart type="bar" [data]="chartpage2basicData2"
                                                [options]="chartpage2basicOptions2" width="150" height="150">
                                            </p-chart>
                                        </div>
                                    </div>
                                    <div style="margin-left: 115px;margin-bottom: 10px;">
                                        <span class="check1"></span>&nbsp;Prior Year
                                        &nbsp;&nbsp;<span class="check2"></span>&nbsp;Current Year
                                    </div>
                                </div>

                            </div>
                            <div class="p-col-6" style="padding:7px;margin-bottom: 9px;">
                                <div class="chart-a" style="display: flex;height:100%;flex-wrap: wrap;">
                                    <!-- <textarea [(ngModel)]="chartInputHeader2" rows="4" class="input-text0"></textarea> -->
                                    <div [innerHTML]="chartInputHeader2" style="margin-top: 0px;padding-top: 10px;" class="input-text0" (click)="openEditor11($event,overlayPanel11,chartInputHeader2)"></div>
                                    <!-- <p-overlayPanel #op11>
                                        <p-editor [(ngModel)]="chartInputHeader2" [style]="{'height': '255px', 'width': '100%'}"></p-editor>
                                        <div style="margin-top: 10px;">
                                          <button pButton type="button" style="margin-right:9px" label="Save" icon="pi pi-check" (click)="saveText11(overlayPanel11)"></button>
                                          <button pButton type="button" label="Cancel" icon="pi pi-times" (click)="cancelEdit11(overlayPanel11)"></button>
                                        </div>
                                      </p-overlayPanel> -->
                                    <!-- <div> -->
                                    <div class="p-col-6 main-chart-divs" style=" border-right: 2px dotted #352525;">
                                        <div class="a">
                                            <h3>Continuous</h3>
                                            <p-chart type="bar" [data]="chartpage2basicData"
                                                [options]="chartpage2basicOptions" width="150" height="150">
                                            </p-chart>
                                        </div>

                                    </div>
                                    <div class="p-col-6 main-chart-divs">
                                        <div class="a">
                                            <h3>Intermittent</h3>
                                            <p-chart type="bar" [data]="chartpage2basicData2"
                                                [options]="chartpage2basicOptions2">
                                            </p-chart>
                                        </div>
                                    </div>
                                    <div style="margin-left: 115px; margin-bottom: 10px;">
                                        <span class="check1"></span>&nbsp;Prior Year
                                        &nbsp;&nbsp;<span class="check2"></span>&nbsp;Current Year
                                    </div>
                                <!-- </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="p-grid">
                            <div class="p-col-4" style="padding:7px;margin-bottom: 9px;">
                                <div class="chart1" style="height: 293px;">
                                    <!-- <textarea [(ngModel)]="chartInputHeader3" rows="2" class="input-text3"></textarea> -->
                                    <div [innerHTML]="chartInputHeader3" style="margin-top: 0px;padding-top: 10px;" class="input-text0" (click)="openEditor12($event,overlayPanel12,chartInputHeader3)"></div>
                                    <p-overlayPanel #op12>
                                        <p-editor [(ngModel)]="chartInputHeader3" [style]="{'height': '255px', 'width': '100%'}"></p-editor>
                                        <div style="margin-top: 10px;">
                                          <button pButton type="button" style="margin-right:9px" label="Save" icon="pi pi-check" (click)="saveText12(overlayPanel12)"></button>
                                          <button pButton type="button" label="Cancel" icon="pi pi-times" (click)="cancelEdit12(overlayPanel12)"></button>
                                        </div>
                                      </p-overlayPanel>
                                    <p-chart type="bar" [data]="templ3basicData3" [options]="templ3basicOptions3"
                                        width="150" height="150">
                                    </p-chart>
                                </div>
                            </div>
                            <div class="p-col-8" style="padding:7px;margin-bottom: 9px;">
                                <div class="chart1">
                                    <!-- <textarea [(ngModel)]="chartInputHeader4" rows="4" class="input-text4"></textarea> -->
                                    <div [innerHTML]="chartInputHeader4" style="margin-top: 0px;padding-top: 10px;" class="input-text0" (click)="openEditor13($event,overlayPanel13,chartInputHeader4)"></div>
                                    <p-overlayPanel #op13>
                                        <p-editor [(ngModel)]="chartInputHeader4" [style]="{'height': '255px', 'width': '100%'}"></p-editor>
                                        <div style="margin-top: 10px;">
                                          <button pButton type="button" style="margin-right:9px" label="Save" icon="pi pi-check" (click)="saveText13(overlayPanel13)"></button>
                                          <button pButton type="button" label="Cancel" icon="pi pi-times" (click)="cancelEdit13(overlayPanel13)"></button>
                                        </div>
                                      </p-overlayPanel>
                                    <p-chart type="bar" [data]="templ3basicData4" [options]="templ3basicOptions4"
                                        width="250" height="250">
                                    </p-chart>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-2" style="padding:7px;margin-bottom: 25px;width:23%">
                        <div class="side-div">
                            <div class="text-div-p">
                                <span class="x-class10">X%</span>
                                <!-- Both FML and All Policy Utilization increased over the prior Year and has Exceeded the incident cap.(Be Aware of any carveouts) -->
                                <!-- <textarea [(ngModel)]="chartInputText90" rows="3" class="input-text-chart10"></textarea> -->
                                <div [innerHTML]="chartInputText90" style="margin-top: 0px;padding-top: 10px;" class="input-text0" (click)="openEditor14($event,overlayPanel14,chartInputText90)"></div>
                                <p-overlayPanel #op14>
                                    <p-editor [(ngModel)]="chartInputText90" [style]="{'height': '255px', 'width': '100%'}"></p-editor>
                                    <div style="margin-top: 10px;">
                                      <button pButton type="button" style="margin-right:9px" label="Save" icon="pi pi-check" (click)="saveText14(overlayPanel14)"></button>
                                      <button pButton type="button" label="Cancel" icon="pi pi-times" (click)="cancelEdit14(overlayPanel14)"></button>
                                    </div>
                                  </p-overlayPanel>
                            </div>
                            <div class="text-div-p">
                                <span class="x-class10">X%</span>
                                <!-- Both FML and All Policy Utilization increased over the prior Year and has Exceeded the incident cap.(Be Aware of any carveouts) -->
                                <!-- <textarea [(ngModel)]="chartInputText11" rows="3" class="input-text-chart10"></textarea> -->
                                <div [innerHTML]="chartInputText11" style="margin-top: 0px;padding-top: 10px;" class="input-text0" (click)="openEditor15($event,overlayPanel15,chartInputText11)"></div>
                                <p-overlayPanel #op15>
                                    <p-editor [(ngModel)]="chartInputText11" [style]="{'height': '255px', 'width': '100%'}"></p-editor>
                                    <div style="margin-top: 10px;">
                                      <button pButton type="button" style="margin-right:9px" label="Save" icon="pi pi-check" (click)="saveText15(overlayPanel15)"></button>
                                      <button pButton type="button" label="Cancel" icon="pi pi-times" (click)="cancelEdit15(overlayPanel15)"></button>
                                    </div>
                                  </p-overlayPanel>
                            </div>
                            <div class="text-div-p">
                                <span class="x-class10">X%</span>
                                <!-- Both FML and All Policy Utilization increased over the prior Year and has Exceeded the incident cap.(Be Aware of any carveouts) -->
                                <!-- <textarea [(ngModel)]="chartInputText12" rows="3" class="input-text-chart10"></textarea> -->
                                <div [innerHTML]="chartInputText12" style="margin-top: 0px;padding-top: 10px;" class="input-text0" (click)="openEditor15($event,overlayPanel15,chartInputText12)"></div>
                                <p-overlayPanel #op15>
                                    <p-editor [(ngModel)]="chartInputText12" [style]="{'height': '255px', 'width': '100%'}"></p-editor>
                                    <div style="margin-top: 10px;">
                                      <button pButton type="button" style="margin-right:9px" label="Save" icon="pi pi-check" (click)="saveText15(overlayPanel15)"></button>
                                      <button pButton type="button" label="Cancel" icon="pi pi-times" (click)="cancelEdit15(overlayPanel15)"></button>
                                    </div>
                                  </p-overlayPanel>
                            </div>
                            <div class="text-div-p">
                                <span class="x-class10">X%</span>
                                <!-- Both FML and All Policy Utilization increased over the prior Year and has Exceeded the incident cap.(Be Aware of any carveouts) -->
                                <!-- <textarea [(ngModel)]="chartInputText13" rows="3" class="input-text-chart10"></textarea> -->
                                <div [innerHTML]="chartInputText13" style="margin-top: 0px;padding-top: 10px;" class="input-text0" (click)="openEditor16($event,overlayPanel16,chartInputText13)"></div>
                                <p-overlayPanel #op16>
                                    <p-editor [(ngModel)]="chartInputText13" [style]="{'height': '255px', 'width': '100%'}"></p-editor>
                                    <div style="margin-top: 10px;">
                                      <button pButton type="button" style="margin-right:9px" label="Save" icon="pi pi-check" (click)="saveText16(overlayPanel16)"></button>
                                      <button pButton type="button" label="Cancel" icon="pi pi-times" (click)="cancelEdit16(overlayPanel16)"></button>
                                    </div>
                                  </p-overlayPanel>
                            </div>
                            <div class="text-div-p">
                                <span class="x-class10">X%</span>
                                <!-- Both FML and All Policy Utilization increased over the prior Year and has Exceeded the incident cap.(Be Aware of any carveouts) -->
                                <!-- <textarea [(ngModel)]="chartInputText14" rows="3" class="input-text-chart10"></textarea> -->
                                <div [innerHTML]="chartInputText14" style="margin-top: 0px;padding-top: 10px;" class="input-text0" (click)="openEditor17($event,overlayPanel17,chartInputText14)"></div>
                                <p-overlayPanel #op17>
                                    <p-editor [(ngModel)]="chartInputText14" [style]="{'height': '255px', 'width': '100%'}"></p-editor>
                                    <div style="margin-top: 10px;">
                                      <button pButton type="button" style="margin-right:9px" label="Save" icon="pi pi-check" (click)="saveText14(overlayPanel14)"></button>
                                      <button pButton type="button" label="Cancel" icon="pi pi-times" (click)="cancelEdit14(overlayPanel14)"></button>
                                    </div>
                                  </p-overlayPanel>
                            </div>



                        </div>
                    </div>
                </div>
            </div>
        </div>
        <p-dialog header="Profile" [draggable]="false" class="confirm-dialog" [position]="'top'" [modal]="true"
            [(visible)]="visible" [style]="{ width: '780px' }">
            <div class="p-card parent-card">
                <div class="icon-class" *ngFor="let icon of iconsArray" (click)="applyIcon(icon)"
                    (click)="activefun(icon)" [ngClass]="{'active':activeString === icon}">
                    <i class={{icon}}></i>
                </div>
            </div>
            <ng-template pTemplate="footer">
                <p-button class="updateButton" label="Apply" (click)="onApply()"></p-button>
            </ng-template>
        </p-dialog>
        <p-dialog header="Profile" [draggable]="false" class="confirm-dialog" [position]="'top'" [modal]="true"
            [(visible)]="visibleLeave" [style]="{ width: '780px' }">
            <div class="p-card parent-card">
                <div class="icon-class" *ngFor="let icon of iconsArrayLeave" (click)="applyIconForLeave(icon)"
                    (click)="activefunForLeave(icon)" [ngClass]="{'active':activeString3 === icon}">
                    <i class={{icon}}></i>
                </div>
            </div>
            <ng-template pTemplate="footer">
                <p-button class="updateButton" label="Apply" (click)="onApplyForLeave()"></p-button>
            </ng-template>
        </p-dialog>
        <p-dialog header="Profile" [draggable]="false" class="confirm-dialog" [position]="'top'" [modal]="true"
            [(visible)]="visible2" [style]="{ width: '780px' }">
            <div class="p-card parent-card">
                <div class="icon-class" *ngFor="let icon of iconsArray2" (click)="applyIcon(icon)"
                    (click)="activefun2(icon)" [ngClass]="{'active':activeString2 === icon}">
                    <i class={{icon}}></i>
                </div>
            </div>
            <ng-template pTemplate="footer">
                <p-button class="updateButton" label="Apply" (click)="onApply2()"></p-button>
            </ng-template>
        </p-dialog>
        <!-- <p-dialog header="Edit Text" [(visible)]="displayEditor" [modal]="true" [closable]="false" [style]="{width: '1000px'}">
            <div>
              <p-editor [(ngModel)]="chartInputText1" [style]="{'height': '200px'}"></p-editor>
            </div>
            <p-footer>
              <button type="button" pButton label="Cancel" icon="pi pi-times" (click)="cancelEdit()"></button>
              <button type="button" pButton label="Save" icon="pi pi-check" (click)="saveText()"></button>
            </p-footer>
          </p-dialog> -->
    </div>