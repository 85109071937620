import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
//import { Leave } from '../build-report/customer';

interface Leave {
    LAST_NAME?: string;
    FIRST_NAME?: string;
    LEAVE_NO?: string;
    EMPLOYEE_ID?: string;
    REASON?: string;
    ABSENCE_TYPE?: string;
     
  }
@Injectable()
export class DiscoveryService {
    
    constructor(private http: HttpClient) { }
	
	requestPayload = {"sort":[], "filters":[], "filterType":"and"}
	
	domainsService() {
        return this.http.get('api/v1/custom-reports/domains/dataDiscovery', { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
    }
	
	reportAndMatricsGetService(domainId,keyword,pageNo){
		return this.http.post('api/v1/datadiscovery/view/'+domainId+'/matrics?searchString='+keyword+'&limit=250&page='+pageNo, this.requestPayload,
		{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	
	dashWidgetsGetService(keyword){
		return this.http.get('api/v1/user-dashboard/widget/search/'+keyword, { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	
	
	
	reqPayOfDashboard = {"sort":[], "filters":[], "filterType":"and", 
                     "parameters":[{"name":"User","value":localStorage.userEmail,"questionId":1234567890,"id":1234567890,"isVisible":"Y"},
                    {"name":"User","value":localStorage.userEmail,"questionId":0,"id":80,"isVisible":"Y"}]}
					
	requestPayload1 = {"parameters":[{"name":"User","value":localStorage.userEmail}]}
					
	/*getADashboard(dashboardID: any) { // for a single widget
		return this.http.get('api/v1/widget/details/'+dashboardID, 
                { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
    }*/
	
	getADashboard(dashboardID: any,type: any ) { // for a whole dashboard
	
		if(type == "dashboard"){
			return this.http.get('api/v1/dashboard/widget-details/'+dashboardID, 
                { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
		}
		else if(type == "widget"){
			return this.http.get('api/v1/widget/details/'+dashboardID, 
                { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
		}
		
    }

    getWidgetData(widgetID: any, limit) {
        return this.http.post('api/v1/dashboard/controls/'+widgetID+'/run?page=1&'+limit, this.reqPayOfDashboard,
                { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
    }
	
	
	
	getParameterizedDashboardItemRecords(dashboardID: any){

        return this.http.post('api/v1/dashboard/cascade/dashboard/'+ dashboardID +'/0', this.requestPayload1,
        { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
    }
	
	getParameterizedDashboardCascadingRecords(dashboardID: any, paramId: any, reqPay){

        return this.http.post('api/v1/dashboard/cascade/dashboard/'+ dashboardID +'/' + paramId, reqPay,
        { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
    }

    getParameterizedDashboardWidgetData(widgetID: any, limit, resPalLoad: any) {
        
        return this.http.post('api/v1/dashboard/controls/'+widgetID+'/run?page=1&'+limit, resPalLoad,
                { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
    }

    dashboardsSearchService(keyword){
        return this.http.post('https://search.canstringai.com/searchEngine', {"mytext":keyword}
                )
    }
    getLeave() {
        return this.http.get<any>('assets/Employee_data/leave-data.json')
            .toPromise()
            .then(res => <Leave[]>res)
            .then(data => { return data; });
    }
	
}

